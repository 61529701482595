/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage, RemoveSession } from '../../../Utils/Utils'
import axios from 'axios'
import Select from 'react-select';
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
import { activeInactiveTabs, addSubCategoryTabData, deleteApplicationTabData, getFontFamilyData, getFormTabListDataSort, getSubCategoriesDropdown, updateSubCategoryTabData } from '../../../services/api/admin'
import { BsChevronLeft } from 'react-icons/bs'
import '../AdminGuidelines/AdminGuildlines.css'
import { HiPlus } from 'react-icons/hi'
import ReactPaginate from 'react-paginate'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import sortByIcon from '../../../assets/images/sort.png';
import { Message } from '../../../components/message';
import { checkPatternFontSize, checkPattern, formatDateWithSlash, customStylesAcc, selectTheme } from '../../../Utils/common'
import NotFoundTable from '../../../components/notFound/NotFoundTable'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkPermission, permissionsConfig } from '../AdminPermission/PermissionChecker';

const ApplicationTabs = () => {
    const navigate = useNavigate();
    const token = GetLocalStorage("userInfo")
    const [nameEn, setNameEn] = useState('')
    const [nameHi, setNameHi] = useState('')
    const [searchData, setSearchData] = useState('')
    const [sortBy, setSortBy] = useState('created_at')
    const [nameEnError, setNameEnError] = useState()
    const [nameHiError, setNameHiError] = useState()
    const [searchTerm, setSearchTerm] = useState()
    const [searchSubCategoryList, setSearchSubCategoryList] = useState([])
    const [editSubCategoryList, setEditSubCategoryList] = useState([])
    const [editSubCategoryId, setEditSubCategoryId] = useState()
    const [editSubCategoryIdError, setEditSubCategoryIdError] = useState()
    const [searchSubCategoryId, setSearchSubCategoryId] = useState();
    const [editData, setEditData] = useState()
    const [formTabList, setFormTabList] = useState()
    const [tableDataLoaded, setTableDataLoaded] = useState(false)
    const [fontFamily, setFontFamily] = useState({ label: "Arial", value: 1 });
    const [fontFamilyError, setFontFamilyError] = useState();
    const [backgroundColor, setBackgroundColor] = useState("#ffffff");
    const [fontColor, setFontColor] = useState("#000000");
    const [fontColorError, setFontColorError] = useState();

    const [fontSize, setFontSize] = useState('16');
    const [fontSizeError, setFontSizeError] = useState();
    const [backgroundColorError, setBackgroundColorError] = useState();
    const [fontsList, setFontsList] = useState()
    const [deleteData, setDeleteData] = useState()
    const [editContainer, setEditContainer] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [orderBy, setOrderBy] = useState("asc");
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    };

    const grantPermissionForAddApplicationTab = checkPermission(permissionsConfig.addApplicationTab.role, permissionsConfig.addApplicationTab.action, permissionsConfig.addApplicationTab.type);
    const grantPermissionForEditApplicationTab = checkPermission(permissionsConfig.editApplicationTab.role, permissionsConfig.editApplicationTab.action, permissionsConfig.editApplicationTab.type);

    //validations
    const validateForm = (item) => {
        let isValid = true;
        if (item === "subCategoryId" || item === "both") {
            if (!editSubCategoryId) {
                setEditSubCategoryIdError("Please Select Sub Category");
                isValid = false;
            } else {
                setEditSubCategoryIdError("");
            }
        }
        if (item === "nameEn" || item === "both") {
            // if (!subCategoryId) {
            //     setSubCategoryIdError("Please Select Sub Category");
            //     isValid = false;
            // }
            if (!nameEn || nameEn.trim() === "") {
                setNameEnError("Please Enter English Name");
                isValid = false;
            } else {
                setNameEnError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            // if (!nameEn || nameEn.trim() === "") {
            //     setNameEnError("Please Enter English Name");
            //     isValid = false;
            // }
            if (!nameHi || nameHi.trim() === "") {
                setNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setNameHiError("");
            }
        }
        // if (item === "keyName" || item === "both") {
        //     if (!nameHi || nameHi.trim() === "") {
        //         setNameHiError("Please Enter Hindi Name");
        //         isValid = false;
        //     }
        //     if (!keyName || keyName.trim() === "") {
        //         setKeyNameError("Please Enter Name");
        //         isValid = false;
        //     } else {
        //         setKeyNameError("");
        //     }
        // }

        // if (item === "family" || item === "both") {
        //     if (!fontFamily) {
        //         setFontFamilyError("Please Select Font Family");
        //         isValid = false;
        //     } else {
        //         setFontFamilyError("");
        //     }
        // }

        if (item === "color" || item === "both") {
            if (!fontColor || fontColor.trim() === "") {
                setFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setFontColorError("");
            }
        }

        if (item === "fontSize" || item === "both") {
            if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
                setFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setFontSizeError("");
            }
        }

        if (item === "bgcolor" || item === "both") {
            // if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
            //     setFontSizeError("Please Enter Valid Font Size");
            //     isValid = false;
            // }
            if (!backgroundColor || backgroundColor.trim() === "") {
                setBackgroundColorError("Please Select Background Color");
                isValid = false;
            } else if (backgroundColor == fontColor) {
                setBackgroundColorError("Font color and background color cannot be same.");
                isValid = false;
            } else {
                setBackgroundColorError("");
            }
        }

        return isValid;
    };
    // add Category tab
    const addSubCategoryTab = async () => {
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            formData.append("category_id", editSubCategoryId.value)
            formData.append("tab_id", editData?.id)
            formData.append("sub_category_id", editSubCategoryId.value)
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            const inputName = nameEn.length > 20 ? nameEn.substring(0, 20) : nameEn
            const specialCharacterRemovedStr = inputName.toLowerCase()?.replaceAll(/[^a-zA-Z0-9 ]/g, "")
            const keyName = specialCharacterRemovedStr.replaceAll(" ", "_")
            formData.append("tab_key", keyName)
            formData.append("status", "1")
            formData.append("font_family_id", fontFamily?.value ?? "1")
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("sort", 1)
            formData.append("background_color", backgroundColor)
            const data = await addSubCategoryTabData(formData)
            if (data.status == 200) {
                setEditContainer(false)
                setNameEn("")
                setNameHi("")
                // setKeyName("")
                setEditSubCategoryId("")
                setFontFamily("");
                setFontColor("#000000")
                setFontSize("16")
                setBackgroundColor("#ffffff")
                getFormTabData('', "created_at", 1, "desc", "");
                setApiMessage({ type: 'success', message: data?.message });
            } else {
                setApiMessage({ type: 'error', message: data.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }
    // sub category list
    const getSubCategoryList = async () => {
        try {
            const subCategoryTabData = await getSubCategoriesDropdown()
            const data = subCategoryTabData?.data?.map(i => ({
                label: i?.category_name, value: i?.id
            }))
            const editData = subCategoryTabData?.data?.filter((s) => s.status !== "0")?.map(i => ({
                label: i?.category_name, value: i?.id
            }))
            setSearchSubCategoryList(data)
            setEditSubCategoryList(editData)
        } catch (error) {
            console.log("fetch subcategory list", error)
            if (error?.code === "ERR_NETWORK") {
                RemoveSession(navigate);
            }
        };
    };

    const resetFilter = () => {
        setSearchSubCategoryId('')
        getFormTabData('', sortBy, 1, orderBy, searchData);
    }

    const handlePageChange = (selectedPage) => {
        getFormTabData(searchSubCategoryId, sortBy, selectedPage.selected + 1, orderBy, searchData); // Increment by 1 if the API starts page numbers from 1
    };

    // form Tab list
    const getFormTabData = async (searchedSubCategory, sortBy, pageNo, orderBy, searchBy) => {
        try {
            setSearchData(searchBy)
            setOrderBy(orderBy)
            setSortBy(sortBy)
            const tabList = await getFormTabListDataSort(sortBy, orderBy, searchBy, pageNo, searchedSubCategory?.value ? searchedSubCategory?.value : '')
            setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo)
            setTotalPages(Math.ceil(tabList?.data?.total / tabList?.data?.per_page));
            const data = tabList?.data?.data
            setFormTabList(data)
            setTableDataLoaded(true)
        } catch (err) {
            console.log("error on categoryList: ", err)
        }
    }

    //get font families
    const getFontFamily = async () => {
        try {
            const fontFamilyData = await getFontFamilyData()
            const data = fontFamilyData?.data?.map(i => ({
                label: i?.name, value: i?.id
            }))

            setFontsList(data)
        } catch (err) {
            console.log("font families error :", err)
        }
    };

    useEffect(() => {
        getFontFamily()
        getFormTabData('', "created_at", 1, "desc", "");
        getSubCategoryList()
    }, [])

    // delete sub-Category
    const deleteApplicationTab = async (item) => {
        try {
            const formData = new FormData();
            // formData.append("sub_category_id", item?.category_id)
            formData.append("tab_id", item?.id)
            const data = await deleteApplicationTabData(formData)
            if (data.status == 200) {
                setEditContainer(false)
                setDeleteData("")
                getFormTabData(searchSubCategoryId, sortBy, currentPage, orderBy, searchData);
            }
        } catch (error) {
            console.error(error);
        }
    }
    //edit sub-category
    const editSubCategory = (item) => {
        setEditData(item)
        setEditSubCategoryId(searchSubCategoryList?.find(i => i?.value == item?.category_id))
        setNameEn(item?.name_en);
        setNameHi(item?.name_hi)
        setFontColor(item?.font_color)
        // setKeyName(item?.form_key)
        setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setFontColor(item?.font_color)
        setFontSize(item?.font_size)
        setBackgroundColor(item?.background_color)
        window.scrollTo(0, 0);
    }
    //update sub-category tab
    const updateSubCategoryTab = async () => {
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            formData.append("category_id", editData?.category_id)
            formData.append("tab_id", editData?.id)
            formData.append("sub_category_id", editSubCategoryId.value)
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            const inputName = nameEn.length > 20 ? nameEn.substring(0, 20) : nameEn
            const specialCharacterRemovedStr = inputName.toLowerCase()?.replaceAll(/[^a-zA-Z0-9 ]/g, "")
            const keyName = specialCharacterRemovedStr.replaceAll(" ", "_")
            formData.append("tab_key", keyName)
            formData.append("status", "1")
            formData.append("font_family_id", fontFamily?.value ?? "1")
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("sort", 1)
            formData.append("background_color", backgroundColor)
            const data = await updateSubCategoryTabData(formData)
            if (data?.status == 200) {
                setEditContainer(false)
                setNameEn("")
                setNameHi("")
                // setKeyName("")
                setSearchSubCategoryId("")
                setEditSubCategoryId("")
                setFontFamily("");
                setFontColor("#000000")
                setFontSize("16")
                setBackgroundColor("#ffffff")
                setEditData("")
                getFormTabData('', "created_at", 1, "desc", "");
                setApiMessage({ type: 'success', message: data?.message });
            } else if (data?.status == 403) {
                setApiMessage({ type: 'error', message: data?.message });
            } else {
                setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }
    const handleEditBack = () => {
        setEditContainer(false)
        setNameEn("")
        setNameHi("")
        setEditSubCategoryId("")
        setSearchSubCategoryId("")
        setFontFamily("");
        setFontColor("#000000")
        setFontSize("16")
        setBackgroundColor("#ffffff")
        setNameEnError("")
        setNameHiError("")
        setEditSubCategoryIdError("")
        setFontFamilyError("");
        setFontColorError("")
        setSearchTerm("")
        setFontSizeError("")
        setBackgroundColorError("")
        setSortBy("created_at")
        setOrderBy("desc")
        getFormTabData('', "created_at", 1, "desc", "");
        setEditData()
    }
    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("sub_category_id", item.category_id)
            formData.append("tab_id", item?.id)
            formData.append("status", e?.value)
            const data = await activeInactiveTabs(formData)
            if (data?.status == 200) {
                getFormTabData(searchSubCategoryId, sortBy, currentPage + 1, orderBy, searchData);
            } else {
                setApiMessage({ type: 'error', message: data?.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }


    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        const pattern = /^[a-zA-Z\s]*$/;
        const cursorPosition = e.target.selectionStart;

        // Check for "nameEn" field
        if (name === "nameEn") {

            if (await checkPattern(value)) {
                setNameEnError("")
                setNameEn(value);
            } else {
                e.preventDefault()
                return;
            }
        }

        // Check for "nameHi" field
        if (name === "nameHi") {
            if (await checkPattern(value)) {
                setNameHiError("")
                setNameHi(value);
            } else {
                e.preventDefault()
                return;
            }
        }
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                {editContainer ? <>
                    <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                        <div>
                            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span>Tab Form</span>

                        </div>
                    </div>
                    <div className="aod-inner">
                        <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
                            <div className="aod-head text-center adm-head"> <button onClick={() => handleEditBack()}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Form Tab</div>
                            <div className="row py-4">
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='lf-label w-100'>
                                            Select Sub Category
                                        </label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            isDisabled={editData?.id}
                                            value={editSubCategoryId}
                                            options={editSubCategoryList}
                                            onChange={(e) => { setEditSubCategoryId(e); setEditSubCategoryIdError("") }}
                                            placeholder="Select Sub Category"
                                            onMenuOpen={() => handleKeyUpLoginDetails("subCategoryId")}
                                            onMenuClose={() => handleKeyUpLoginDetails("subCategoryId")}
                                        />
                                        {editSubCategoryIdError && <p className='validate-error'>{editSubCategoryIdError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            English Name
                                        </label>
                                        <input className='w-100' placeholder='Enter English Name' type="text" name="nameEn" value={nameEn} onChange={handleInputChange} />

                                        {nameEnError && <p className='validate-error'>{nameEnError}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Hindi Name
                                        </label>
                                        <input className='w-100' placeholder='Enter Hindi Name' type="text" name="nameHi" value={nameHi} onChange={handleInputChange} />

                                        {nameHiError && <p className='validate-error'>{nameHiError}</p>}
                                    </div>
                                </div>
                                {/*   <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Family</label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={fontFamily}
                                            options={fontsList}
                                            onChange={(e) => { setFontFamily(e); setFontFamilyError("") }}
                                            placeholder="Select Font Family"
                                            onMenuOpen={() => handleKeyUpLoginDetails("family")}
                                            onMenuClose={() => handleKeyUpLoginDetails("family")}
                                        />
                                        {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                    </div>
                                </div> */}
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Color</label>
                                        <label className='adm-label-color'>
                                            {fontColor ? fontColor : <span>Select Font Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                        </label>
                                        {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                        <input className='w-100' max={20} placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                            onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={async (e) => {
                                                const value = e.target.value;

                                                if (value > 0) {
                                                    if (await checkPatternFontSize(value)) {
                                                        setFontSize(value);
                                                        setFontSizeError("")
                                                    }
                                                } else {
                                                    setFontSize("");

                                                }
                                            }} />
                                        {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Background Color</label>
                                        <label className='adm-label-color'>
                                            {backgroundColor ? backgroundColor : <span>Select Background Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("bgcolor")} onBlur={() => handleKeyUpLoginDetails("bgcolor")} value={backgroundColor} onChange={(e) => setBackgroundColor(e?.target?.value)} />
                                        </label>
                                        {backgroundColorError && <p className='validate-error'>{backgroundColorError}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row ad-cat-row">
                                <div className=" update-btns-block">
                                    {
                                        editData?.id ?
                                            <>
                                                <button onClick={() => updateSubCategoryTab()} className='me-3'>
                                                    Update
                                                </button> </> :

                                            <button onClick={addSubCategoryTab}>
                                                Submit
                                            </button>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </> :
                    <>
                        <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                            <div>
                                <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span> Form Tabs</span>

                            </div>
                            {(grantPermissionForAddApplicationTab) ? (
                                <button className='adm-tab-btn' onClick={() => setEditContainer(true)}><span><HiPlus />
                                </span> Add</button>
                            ) : ''}
                        </div>
                        <div className="aod-inner pt-0">
                            <div className="aod-bottom">
                                <div className="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                                    <h4 className='mb-0 w-100'>Form Tabs</h4>
                                    <div className='suf-input-box aod-form-input mb-2 ms-3 w-50'>
                                        {/* <input className='w-100 form-control' placeholder='search' type="text" onChange={(e) => getFormTabData(searchSubCategoryId, sortBy, 1, orderBy, e.target.value)} /> */}
                                        <div className="position-relative w-100">
                                            <input
                                                className="px-2 form-control"
                                                placeholder="Search"
                                                type="text"
                                                value={searchTerm} // Add a state for the input value
                                                onChange={(e) => {
                                                    setSearchTerm(e.target.value); // Update the state
                                                    getFormTabData(searchSubCategoryId, sortBy, 1, orderBy, e.target.value)
                                                }}
                                            />
                                            {searchTerm && ( // Show the clear button only when there's text
                                                <button
                                                    className="btn btn-clear position-absolute"
                                                    onClick={(e) => {
                                                        setSearchTerm(""); // Clear the input value
                                                        getFormTabData(searchSubCategoryId, sortBy, 1, orderBy, e.target.value)// Fetch data with empty search term
                                                    }}
                                                    style={{
                                                        top: "50%",
                                                        right: "10px",
                                                        transform: "translateY(-50%)",
                                                        border: "none",
                                                        background: "transparent",
                                                        fontSize: "1rem",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    &times; {/* This is the "X" icon */}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="aod-head"></div>
                                <div className="row py-4">
                                    <div className="col-md-4 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <Select
                                                styles={customStylesAcc}
                                                theme={selectTheme}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                className='menu_open_select w-100'
                                                classNamePrefix='ad-menu_open_select_class'
                                                aria-label="Default select example"
                                                value={searchSubCategoryId}
                                                options={searchSubCategoryList}
                                                onChange={(e) => {
                                                    setSearchSubCategoryId(e);
                                                    getFormTabData(e, sortBy, 1, orderBy, searchData)
                                                }}
                                                placeholder="Select Sub Category"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <button onClick={resetFilter} className='ad-reset-btn w-auto px-4'>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                                <div className="ad-reg-table">
                                    <Table size="sm" className='ad-cat-table'>
                                        <thead>
                                            <tr>
                                                <th className='ad-sno'>S. No</th>
                                                <th className='ad-long cursor-pointer' onClick={(e) => getFormTabData(searchSubCategoryId, 'name_en', currentPage + 1, orderBy == 'asc' ? 'desc' : 'asc', searchData)}>English Name <span><i className="fa-solid fa-sort"></i></span></th>
                                                <th className='ad-long cursor-pointer' onClick={(e) => getFormTabData(searchSubCategoryId, 'name_hi', currentPage + 1, orderBy == 'asc' ? 'desc' : 'asc', searchData)}>Hindi Name <span><i className="fa-solid fa-sort"></i></span></th>
                                                <th className='ad-long cursor-pointer' onClick={(e) => getFormTabData(searchSubCategoryId, 'category_name_en', currentPage + 1, orderBy == 'asc' ? 'desc' : 'asc', searchData)}>Sub Category <span><i className="fa-solid fa-sort"></i></span></th>
                                                <th className='ad-long cursor-pointer' onClick={(e) => getFormTabData(searchSubCategoryId, 'created_at', currentPage + 1, orderBy == 'asc' ? 'desc' : 'asc', searchData)}>Created At <span><i className="fa-solid fa-sort"></i></span></th>
                                                {(grantPermissionForEditApplicationTab) ? (<>
                                                    <th className='ad-long'>Status</th>
                                                    <th className='ad-long'>Actions</th>
                                                </>) : ''}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                formTabList?.length > 0 ? formTabList?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{(currentPage * 10) + (index + 1)}</td>

                                                        <td>{item?.name_en}</td>
                                                        <td>{item?.name_hi}</td>
                                                        <td>{item?.category_name_en}</td>
                                                        <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                                                        {(grantPermissionForEditApplicationTab) ? (<>
                                                            <td>
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={item?.status == 1}
                                                                        onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                                    />
                                                                    <span className="slider"></span>
                                                                </label>
                                                            </td>

                                                            {/* <td><div className='action-btn d-flex'>
                                                            <span className='ad-cat-edit' onClick={() => { editSubCategory(item); setEditContainer(true) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></div></td> */}
                                                            <td>
                                                                <div className="action-btn d-flex">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                                    >
                                                                        <span
                                                                            className="ad-cat-edit"
                                                                            onClick={() => { editSubCategory(item); setEditContainer(true) }}
                                                                        >
                                                                            <EditIcon />
                                                                        </span>
                                                                    </OverlayTrigger>


                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id={`delete-tooltip-${item.id}`}>Delete</Tooltip>}
                                                                    >
                                                                        <span
                                                                            className="ad-cat-delete"
                                                                            onClick={() => setDeleteData(item)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </>) : ''}
                                                    </tr>
                                                )) : tableDataLoaded ? <NotFoundTable colSpan="6"></NotFoundTable> : <></>
                                            }
                                        </tbody>
                                    </Table>
                                    {totalPages > 1 && formTabList?.length > 0 &&
                                        <div className="d-flex w-100 justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                pageCount={totalPages} // Total number of pages from API
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={1}
                                                onPageChange={handlePageChange} // Method to handle page click
                                                containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={`page-item${!formTabList?.length > 0 ? ' disabled' : ''}`}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                forcePage={currentPage}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this Tab ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2">
                        <button onClick={() => { deleteApplicationTab(deleteData) }}>Yes</button>
                        <button className='suf-can-butt no-btn' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default ApplicationTabs
