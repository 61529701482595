import { DeleteIcon, EditIcon } from "../AdminIcon";
import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { getUserOTPLogs } from '../../../services/api/admin';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import ReactPaginate from 'react-paginate';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import { formatDateWithSlash } from '../../../Utils/common';
import { useNavigate } from 'react-router-dom';
import { Modal, Table } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Message } from "../../../components/message";

const OtpLogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate()
  const [userList, setUserList] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderBy, setOrderBy] = useState("asc");
  const [deleteData, setDeleteData] = useState();
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState();


  useEffect(() => {
    userDataList(currentPage);
  }, [currentPage]);


const userDataList = async (currentPage) => {
    try {
        const usersListData = await getUserOTPLogs(currentPage)
        if (usersListData.status == 200) {
            setUserList(usersListData?.data?.data)
            setTotalPages(usersListData?.data?.last_page || 0); // Ensure the correct key for total pages
            setTableDataLoaded(true);
        }
    } catch (err) {
        setApiMessage({ type: 'error', message: err?.message })
    }
}


const handlePageClick = (data) => {
  setCurrentPage(data.selected + 1);
  userDataList(currentPage);
};

  return (
    <AdminDashboard>
    <div className="aod-outer">
      <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
        <div>
          <HomeIcon />
          <span>OTP-Logs</span>
        </div>
      </div>
      <div className="aod-inner pt-0">
        <div className="aod-bottom">
          <div className="adm-tabs-outer">
            <h4 className="mb-0 w-100">OTP Logs</h4>
          </div>
          <div className="aod-head"></div>
          <div className="ad-reg-table">
            <Table size="sm" className="ad-cat-table mt-4">
              <thead>
                <tr>
                  <th className="ad-sno">S. No</th>
                  <th className="ad-long">Email</th>
                  <th className="ad-long">Mobile Number</th>
                  <th className="ad-long">OTP</th>
                  <th className="ad-long">Created At</th>
                </tr>
              </thead>
              <tbody>
                {userList.length > 0 ? (
                  userList.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(currentPage - 1) * 10 + index + 1}</td>
                      <td>{item.email ? item.email : "--"}</td>
                      <td>{item.mobile_number ? item.mobile_number : "--"}</td>
                      <td>{item.otp}</td>                      
                      <td>{item.created_at ? formatDateWithSlash(item.created_at) : '-'}</td>
                    </tr>
                  ))
                ) : (
                  <NotFoundTable colSpan="9"></NotFoundTable>
                )}
              </tbody>
            </Table>
            <div className="d-flex w-100 justify-content-end">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  </AdminDashboard>
  );
};

export default OtpLogs;
