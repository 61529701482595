import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {
    GoogleMap,
    Autocomplete,
    Marker,
    useJsApiLoader
} from "@react-google-maps/api";
import { Language } from '@mui/icons-material';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
import { CgPen } from 'react-icons/cg';
import { createApplicationForm } from '../../services/api/user';

const GeoLocationAddress = ({ subCatId, mapLocation, isEditable, wholeFormData }) => {
    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [searchAddress, setSearchAddress] = useState(mapLocation);
    const [autocomplete, setAutocomplete] = useState(null);
    const [showEditIcon, setShowEditIcon] = useState(true);
    const [error, setError] = useState(false);
    const { language, setLanguage } = useLanguage();
    const inputRef = useRef(null);
   
    useEffect(()=>{
        setError(false) 
    }, [searchAddress])

    const [formData, setFormData] = useState({
        latitude: null,
        longitude: null
    });

    const defaultCenter = { lat: 30.3165, lng: 78.0322 }; // Fallback coordinates for Dehradun center

    const GmapOptions = {
        disableDefaultUI: false,
        draggable: true,
        scrollwheel: true,
        streetViewControl: false,
    };

    const loaderOptions = useMemo(() => ({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCnOLiPcQ3RCoPoLG5tEd8gcqGqHVL3Nuc',
        libraries: ["places"],
    }), []);

    const { isLoaded } = useJsApiLoader(loaderOptions);

    const geocodeAddress = useCallback((address) => {
        if (window.google) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
                if (status === "OK" && results[0]) {
                    const location = results[0].geometry.location;
                    const latLng = { lat: location.lat(), lng: location.lng() };
                    setMarkerPosition(latLng);
                    // setGeoLocationAddress(address)
                    setSearchAddress(address);
                    setFormData({
                        latitude: latLng.lat,
                        longitude: latLng.lng
                    });
                    map?.setCenter(latLng);
                    map?.setZoom(15);
                } else {
                    setMarkerPosition(defaultCenter); // Fallback if geocode fails
                    setFormData({
                        latitude: defaultCenter.lat,
                        longitude: defaultCenter.lng
                    });
                    map?.setCenter(defaultCenter);
                    map?.setZoom(12);
                }
            });
        }
    }, [map]);

    const onLoad = useCallback((mapInstance) => {
        setMap(mapInstance);
        if (mapLocation) {
            geocodeAddress(mapLocation);
        } else {
            setMarkerPosition(defaultCenter);
            setFormData({
                latitude: defaultCenter.lat,
                longitude: defaultCenter.lng,
            });
            mapInstance.setCenter(defaultCenter);
            mapInstance.setZoom(12);
        }
    }, [mapLocation, geocodeAddress]);


    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                const location = place.geometry.location;
                const latLng = { lat: location.lat(), lng: location.lng() };
                setMarkerPosition(latLng);
                // setGeoLocationAddress(`${place.name} ${place.formatted_address}` || "")
                setSearchAddress(`${place.name} ${place.formatted_address}` || "");
                map?.panTo(latLng);
                map?.setZoom(15);
                setFormData({
                    latitude: location.lat(),
                    longitude: location.lng(),
                });
            }
        }
    };

    const onAutocompleteLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onMarkerDragEnd = (e) => {
        const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setMarkerPosition(latLng);
        setFormData({ latitude: latLng.lat, longitude: latLng.lng });

        if (window.google) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === "OK" && results[0]) {
                    // setGeoLocationAddress(results[0].formatted_address)
                    setSearchAddress(results[0].formatted_address);
                }
            });
        }
    };

    if (!isLoaded) return <div>{language ? "लोड हो रहा है..." : "Loading..."}</div>;

    const handleSubmit = async() => {
        if (searchAddress == '' || searchAddress.length == 0){
          setError(true)
          return;
      }
        try {
            const sectionData = Object.entries(wholeFormData || {}).reduce((acc, [key, value]) => {
                if (key.startsWith("f_") && !key.endsWith("json") && value) {
                    acc[key] = value;
                }
                return acc;
            }, {});
            sectionData['geo_location_address'] = searchAddress

            const subsectionData = Object.entries(wholeFormData || {}).reduce((acc, [key, value]) => {
                if (key.endsWith("json") && value) {
                    acc[key] = value;
                }
                return acc;
            }, {});
            const payload = {
                sub_category_id: subCatId,
                user_id: wholeFormData?.user_id,
                application_id: wholeFormData?.application_id,
                section_fields: sectionData, // Section data as an object
                sub_section_fields: subsectionData, // Subsection data as an object
            };

            console.log("Payload:", payload);
            // Make API request
            const sectionResponse = await createApplicationForm(payload); // Send as JSON
            if (sectionResponse.status == 200) {
                setShowEditIcon(true)
            } else {
                console.error("Failed to submit the form", sectionResponse?.message);
            }
        } catch (err) {
            console.error("Error submitting the form:", err?.message);
        }

    }


    return (
        <>

            <div className="col-md-12 mt-4">
                <div className="data-wrapper">
                    <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                        <div className="data-wrapper-heading">
                            <h5 className="mb-0">{language ? "भू-स्थान" : "Geo Location"}</h5>
                        </div>
                        {isEditable ? <div className="data-wrapper-edit-icon">
                            {showEditIcon ? <CgPen onClick={() => setShowEditIcon(showEditIcon ? false : true)} /> : (
                                <>
                                    <div onClick={handleSubmit}>Save</div>
                                    <div onClick={() => setShowEditIcon(showEditIcon ? false : true)}>{!language ? "Cancel" : "रद्द करें"}</div>

                                </>
                            )}
                        </div> : <></>}
                    </div>
                    <div className="row">
                        <div className="mt-4">
                            <div className="form-block">
                                {!showEditIcon ?
                                   <>
                                        <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
                                            <input
                                                type="text"
                                                ref={inputRef}
                                                placeholder={language ? "कोई पता खोजें" : "Search for an address"}
                                                value={searchAddress}
                                                onChange={(e) => {
                                                    setSearchAddress(e.target.value)
                                                    setError(false)
                                                }}
                                                className="form-control"
                                               onBlur={(e)=>{
                                                const value = e?.target?.value
                                                if(!value || value == ''){
                                                    setError(true)
                                                }
                                               }}
                                            />
                                        </Autocomplete>
                                        {error ?
                                            <p className='validate-error'>{language ? "कृपया पता दर्ज करें" : 'Please enter address'}</p> : null
                                        } 
                                   </>
                                    :<p>{searchAddress}</p>
                                }

                                <GoogleMap
                                    onLoad={onLoad}
                                    center={markerPosition || defaultCenter}
                                    zoom={15}
                                    mapContainerStyle={{ height: "400px", width: "100%" }}
                                    options={GmapOptions}
                                    
                                >
                                    {markerPosition && (
                                        <Marker
                                            position={markerPosition}
                                            draggable={!showEditIcon}
                                            onDragEnd={onMarkerDragEnd}
                                        />
                                    )}
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default GeoLocationAddress;
