/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Apiurl, GetLocalStorage, ImageurlIcons } from "../../../Utils/Utils";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import axios from "axios";
import { Table } from "react-bootstrap";
import { EditIcon } from "../AdminIcon";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { getForgotPasswordFieldsData } from "../../../services/api/admin";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const AdminForgotPassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);

  const token = GetLocalStorage("userInfo");
  const [loginData, setLoginData] = useState([]);
  const [buttData, setButtData] = useState([]);

  const grantPermissionForEditForgotPassword = checkPermission(
    permissionsConfig.editForgotPassword.role,
    permissionsConfig.editForgotPassword.action,
    permissionsConfig.editForgotPassword.type
  );

  //login fields list
  const getLoginFields = async () => {
    try {
      const forgotFormField = await getForgotPasswordFieldsData();
      const data = forgotFormField?.data;
      setLoginData(data);
      setButtData([
        ...forgotFormField?.data?.button_fields,
        ...forgotFormField?.data?.form_fields,
      ]);
    } catch (error) {
      // navigate("/")
    }
  };
  useEffect(() => {
    getLoginFields();
  }, []);

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-4'>
                    <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b><span>Forgot Password Form</span>
                </div>
                {/* Tabs field Table */}
                <div className="aod-inner">
                    <div className={"aod-bottom"}>
                        <div className="adm-tabs-outer">
                            <button className={activeTab == 1 ? "active-adm-tab btn-first" : "inactive-adm-tab btn-first"} onClick={() => setActiveTab(1)}>Forgot Password Form Tabs </button>
                            <button className={activeTab == 2 ? "active-adm-tab" : "inactive-adm-tab"} onClick={() => setActiveTab(2)}>Forgot Password Form Fields </button>
                            <button className={activeTab == 3 ? "active-adm-tab btn-last" : "inactive-adm-tab btn-last"} onClick={() => setActiveTab(3)}>Forgot Password Form Buttons </button>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            {
                                activeTab == 1 ? <Table size="sm" className='ad-cat-table mt-4'>
                                    <thead>
                                        <tr>
                                            <th className='ad-sno'>S. No</th>
                                            <th className='ad-long'>English Name</th>
                                            <th className='ad-long'>Hindi Name</th>
                                            {(grantPermissionForEditForgotPassword) ? (<>
                                                <th className='ad-long'>Status</th>
                                                <th className='ad-long'>Actions</th></>
                                            ) : ''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loginData?.tab_fields?.map((item, index) => (
                                                <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                    <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                    {(grantPermissionForEditForgotPassword) ? (<>
                                                        <td>{item?.status == "1" ? "Active" : "Inactive"}</td>
                                                        {/* <td>
                                                            <label className="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={item?.status == 1}
                                                                />
                                                                <span className="slider"></span>
                                                            </label>
                                                        </td> */}
                                                        <td>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                            >
                                                                <span className='ad-cat-edit' onClick={() => navigate("/admin/forgot-password/update-tabs", { state: item })}><EditIcon /></span>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </>) : ''}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table> : activeTab == 2 ? <Table size="sm" className='ad-cat-table mt-4'>
                                    <thead>
                                        <tr>
                                            <th className='ad-sno'>S. No</th>
                                            <th className='ad-long'>Icon</th>
                                            <th className='ad-long'>English Name</th>
                                            <th className='ad-long'>Hindi Name</th>
                                            <th className='ad-long'>Placeholder English Name</th>
                                            <th className='ad-long'>Placeholder Hindi Name</th>
                                            {(grantPermissionForEditForgotPassword) ? (
                                                <th className='ad-long'>Actions</th>
                                            ) : ''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loginData?.input_fields?.map((item, index) => (
                                                <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.icon ? <img className='ad-cat-td-img' src={`${ImageurlIcons()}/${item?.icon}`} alt="" /> : "--"}</td>
                                                    <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                    <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                    <td>{item?.placeholder_en ? item?.placeholder_en : "--"}</td>
                                                    <td>{item?.placeholder_hi ? item?.placeholder_hi : "--"}</td>
                                                    {(grantPermissionForEditForgotPassword) ? (
                                                        <td>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                            >
                                                                <span className='ad-cat-edit' onClick={() => navigate("/admin/forgot-password/update", { state: item })}><EditIcon /></span>
                                                            </OverlayTrigger>
                                                        </td>
                                                    ) : ''}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table> : activeTab == 3 ? <Table size="sm" className='ad-cat-table mt-4'>
                                    <thead>
                                        <tr>
                                            <th className='ad-sno'>S. No</th>
                                            <th className='ad-long'>English Name</th>
                                            <th className='ad-long'>Hindi Name</th>
                                            <th className='ad-long'>Type</th>
                                            {(grantPermissionForEditForgotPassword) ? (
                                                <th className='ad-long'>Actions</th>
                                            ) : ''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            buttData?.map((item, index) => (
                                                <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                    <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                    <td>{item?.type ? item?.type : "--"}</td>
                                                    {(grantPermissionForEditForgotPassword) ? (
                                                        <td>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                            >
                                                                <span className='ad-cat-edit' onClick={() => navigate("/admin/forgot-password/update-buttons", { state: item })}><EditIcon /></span>
                                                            </OverlayTrigger>
                                                        </td>
                                                    ) : ''}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table> : null
                            }

                        </div>

                    </div>
                </div>
                {/* Tabs field Table End */}
            </div>
            
    </AdminDashboard>
  );
};

export default AdminForgotPassword;
