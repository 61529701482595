import React, { useEffect, useState } from 'react'
import { Apiurl } from '../../Utils/Utils';
import axios from 'axios';
import HeaderTop from '../Header/HeaderTop';
import HeaderBottom from '../Header/HeaderBottom';
import Footer from '../Footer/Footer';

const MainLayout = ({ children, homePageData }) => {
    

    // const [homePageContent, setHomePageContent] = useState([])
    // const fetchHomePageContent = () => {
    //     //getHomePageContent().then(res => {
    //         if (homePageData) {
    //             setHomePageContent(homePageData?.data)
    //         }

    //     //}).catch(err => {

    //     //});
    //     // const option = {
    //     //     method: "GET",
    //     //     headers: {
    //     //         "access-control-allow-origin": "*",
    //     //         "content-type": "application/json",
    //     //     },
    //     //     url: `${Apiurl()}/home-page`,
    //     // };
    //     // axios(option)
    //     //     .then((e) => {
    //     //         setHomePageContent(e?.data?.data)
    //     //     })
    //     //     .catch((error) => {
    //     //     });
    // }
    // useEffect(() => {
    //     fetchHomePageContent()
    // }, [])
    return (
        <>
            <div className='main-layout-cont'>
                <HeaderTop />
                <HeaderBottom homePageContent={homePageData} />
            </div>
            {children}
            <Footer homePageContent={homePageData} />
        </>
    )
}
export default MainLayout