import React, { useEffect, useState } from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { Message } from '../../../components/message'
import { updateWebsiteLogo } from '../../../services/api/admin'
import { checkPattern, checkPatternFontSize, customStylesAcc, selectTheme } from '../../../Utils/common'
import { GetLocalStorage } from '../../../Utils/Utils'
import "../Admin.css"
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { UploadIcon } from '../AdminIcon'

const HomepageLogoUpdate = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const location = useLocation()
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const token = GetLocalStorage("userInfo");
    
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState();

    const [image, setImage] = useState();
    const [imageError, setImageError] = useState();

    const [editData, setEditData] = useState()
    const [file, setFile] = useState();

    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    }

    useEffect(() => {
       editBanner(location?.state)
    }, [location?.state])

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        const cursorPosition = e.target.selectionStart;
        if (name === "nameEn") {
            if (await checkPattern(value)) {
                setNameError("")
                setName(value);
            } else {
                e.preventDefault()
                return;
            }
        }
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };



    //validations
    const validateForm = (item) => {
        let isValid = true;
        if (item === "img" || item === "both") {
            if (!image) {
                console.log('imageErr', "Please Upload Image")
                setImageError("Please Upload Image");
                isValid = false;
            } else {
                setImageError("");
            }
        }
        return isValid;
    };

    //edit category
    const editBanner = (item) => {
        setEditData(item)
        setName(item?.name);
        setImage(item?.path)
        window.scrollTo(0, 0);
    }
    //update category
    const updateLogo = async () => {
        if (!validateForm("both")) {
            return
        }
        try {
            const formData = new FormData();
            formData.append("logo_id", editData?.id)
            if(typeof image === "string"){
            }else{
                formData.append("image", image)
            }
            const response = await updateWebsiteLogo(formData)
            if (response.status == 200) {
                navigate("/admin/homepage-logo", { state: { message: response.message } })
            } else {
                setApiMessage({ type: 'error', message: response.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }

    const handleFileChange = (e) => {
        const file = e?.target?.files?.[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(file);
                setFile(URL.createObjectURL(e.target.files[0]));
                setImageError('');
            } else {
                setImage(null);
                setImageError('Please Upload Image.');
                setFile('')
                e.target.value = '';
            }
        }
    };
    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <b onClick={() => navigate("/admin/")}>Home  /</b><span>Banner Update</span>
                    </div>
                </div>
                <div className="aod-inner">
                    <div className={editData?.id ? "aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/homepage-logo")}><BsChevronLeft /> Back</button>Update Banner</div>
                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab d-flex  align-items-top">
                                <div className='suf-input-box aod-form-input mb-2 w-100'>
                                    <label className='mb-1 w-100'>
                                        Image <span className="text-danger required_f">*</span>
                                    </label>
                                    <input type="file" name="partsuploadfile" id="profileimg" className="w-100 ad-cat-input-field-image" accept="image/*" onKeyUp={() => handleKeyUpLoginDetails("img")} onChange={(e) => handleFileChange(e)} />
                                    <label htmlFor="profileimg" className="ad-cat-input-upload-image p-0">
                                        <p className="ad-cat-text-upload-image">
                                            <span><UploadIcon /></span>  {
                                                typeof image === 'string' ? (
                                                    <p className="mb-0">{image.split('\\').pop().split('/').pop()}</p>
                                                ) : (
                                                    image?.name ? (
                                                        <p className="mb-0 fw-semibold">{image?.name}</p>
                                                    ) : (
                                                        "Choose Category Image"
                                                    )
                                                )
                                            }
                                        </p>
                                    </label>
                                    {
                                        imageError ? <p className="validate-error">{imageError}</p> : ""
                                    }
                                </div>
                                {file &&
                                    <div id="img_preview" className="pt-4">
                                        <img className="ms-1 uplaoed_img_preview" src={file} alt="" />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row ad-cat-row">
                            <div className=" update-btns-block">
                                <button onClick={() => updateLogo()}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default HomepageLogoUpdate
