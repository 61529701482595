import React from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const SuccessModal = ({
  onHide,
  handleDelete,
  headerMsg,
  message,
  ...props
}) => {
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (headerMsg == "false") {
      MySwal.fire({
        title: "Success",
        text: message,
        icon: "success",
        showConfirmButton: true,
        confirmButtonText: "OK",
        preConfirm: () => onHide(),
      });
    } else if (headerMsg == "true") {
      MySwal.fire({
        title: "ARE YOU SURE ?",
        text: message,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "OK",
        allowOutsideClick: false,
        confirmButtonColor: "grey",
        denyButtonColor: "red",
        showDenyButton: true,
        denyButtonText: "Cancel",
        preConfirm: () => {
          handleClick();
        },
        preDeny: () => {
          // onHide();
          MySwal.fire({
            // title: 'Success!',
            text: "file remain safe",
            // icon: 'success',
            showConfirmButton: true,
            confirmButtonText: "OK",
            preConfirm: () => onHide(),
          });
        },
      });
    }
  }, [headerMsg, message, onHide, MySwal]);

  const handleClick = () => {
    onHide();
    handleDelete();
  };

  return <></>;
};
