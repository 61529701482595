import { DeleteIcon, EditIcon } from "../AdminIcon";
import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import {
  getallUsersListData,
  getUserLoginLogs,
  getUsersListData,
} from "../../../services/api/admin";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import ReactPaginate from "react-paginate";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { formatDateTimeWithSlash, formatDateWithSlash } from "../../../Utils/common";
import { useNavigate } from "react-router-dom";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Message } from "../../../components/message";

const UserLogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [listOfUser, setListOfUser] = useState([]);
  useEffect(() => {
    userDataList(currentPage);
    getListofUser();
  }, [currentPage]);

  const getListofUser = async () => {
    try {
      const userDetailsData = await getallUsersListData();
      const data = userDetailsData?.data?.map((user) => ({
        label: user?.name,
        value: user?.id,
      }));
      console.log(data);
      setListOfUser(data);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const userDataList = async (pageNo) => {
    try {
      const usersListData = await getUserLoginLogs(pageNo);
      if (usersListData.status === 200) {
        setUserList(usersListData?.data?.data || []);
        setTotalPages(usersListData?.data?.last_page || 0); // Ensure the correct key for total pages
        setTableDataLoaded(true);
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    userDataList(currentPage);
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  / </b> 
            <span>User-Logs</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">User Logs</h4>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-sno">user Id</th>
                    <th className="ad-sno">IP Address</th>
                    <th className="ad-sno">Browser</th>
                    <th className="ad-long">Action</th>
                    <th className="ad-sno">Status</th>
                    <th className="ad-long">Remark</th>
                    <th className="ad-long">Login Time</th>
                    <th className="ad-long">Logout Time</th>
                    <th className="ad-long">Spend Time</th>
                  </tr>
                </thead>
                <tbody>
                  {userList.length > 0 ? (
                    userList.map((item, index) => (
                      <tr key={item.id}>
                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                        <td>
                          {item?.user_id
                            ? listOfUser.find((x) => x.value === item.user_id)
                                ?.label || "--"
                            : "--"}
                        </td>
                        <td>{item?.ip_address}</td>
                        <td>{item?.browser}</td>
                        <td>{item.action}</td>
                        <td>
                          <span
                            className={
                              item?.status == "Success"
                                ? "badge rounded-pill text-bg-success"
                                : "badge rounded-pill text-bg-danger"
                            }
                          >
                            {item.status}
                          </span>
                        </td>
                        <td>{item.remark}</td>
                        <td>{formatDateTimeWithSlash(item.login_time)}</td>
                        <td>
                          {item.logout_time
                            ? formatDateTimeWithSlash(item.logout_time)
                            : "-"}
                        </td>
                        <td>{item.spend_time || "-"}</td>
                      </tr>
                    ))
                  ) : (
                    <NotFoundTable colSpan="9"></NotFoundTable>
                  )}
                </tbody>
              </Table>
              <div className="d-flex w-100 justify-content-end">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default UserLogs;
