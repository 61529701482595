import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom';
import { Message } from '../../../components/message';
import { addEmailLabelData, addNewSMSlabelData, getAllEmailData, getAllEmaillabelListData, getAllSMSData, getAllSMSlabelListData, getEmailData, updateEmaillabelData, updateSMSlabelData } from '../../../services/api/admin';
import { checkPatternWithSpecialCharacter } from '../../../Utils/common';
import ReactQuill from 'react-quill';

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']
    ],
};

const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block"
];
const EmailSettingPage = () => {
    const navigate = useNavigate();
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [selectedModuleIndex, setSelectedModuleIndex] = useState(1);
    const [templateList, setTemplateList] = useState([])
    const [templateData, setTemplateData] = useState({})
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')
    const [selectedLabel, setSelectedLabel] = useState('')
    const [err, setErr] = useState({
        subject: '',
        content: ''
    })

    useEffect(() => {
        getTemplateList()
    }, [])

    const emailDataByLabel = async (item) => {
        try {
            let formErr = { ...err }
            formErr.subject = ''
            formErr.content = ''
            setSelectedLabel(item?.label)
            setErr(formErr)

            const dataList = await getEmailData(item?.id)
            if (dataList?.success) {
                setTemplateData(dataList?.data)
                setSubject(dataList?.data?.subject)
                setContent(dataList?.data?.content)
            } else {
                setTemplateData()
                setSubject('')
                setContent('')
            }
        } catch (err) {

        }
    }

    const getTemplateList = async () => {
        try {
            const listData = await getAllEmaillabelListData()
            if (listData?.status == 200) {
                setTemplateList(listData?.data)
                const firstLabelData = listData?.data[0];
                if (firstLabelData) {
                    emailDataByLabel(firstLabelData);
                }
            }
        } catch (err) {

        }
    }

    const handleInput = async (e, name, value) => {
        // const { name, value } = e?.target

        if (name == "subject") {
            const cursorPosition = e.target.selectionStart;
            if (await checkPatternWithSpecialCharacter(value) == false) {
                return;
            } else {
                setSubject(value)
                setTimeout(() => {
                    e.target.setSelectionRange(cursorPosition, cursorPosition);
                }, 0);
            }
        } else if (name == "content") {
            setContent(value)
        }

        validateForm(name, value);
    }

    const validateForm = (name, value) => {
        let formErr = { ...err }
        let vaild = true
        if (name == "subject") {
            if (!value.trim()) {
                formErr.subject = "Please Enter the Subject"
                vaild = false
            } else {
                formErr.subject = ''
            }
        } else if (name == "content") {
            if (!value.trim()) {
                formErr.content = "Please Enter the Content"
                vaild = false
            } else {
                formErr.content = ""
            }
        }
        setErr(formErr)
        return vaild;
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const isSubjectValid = validateForm("subject", subject);
            const isContentValid = validateForm("content", content);

            if (!isSubjectValid || !isContentValid) {
                return;
            }


            const editordata = {
                'content': content,
            }
            if (templateData?.id) {
                const formData = new FormData()
                formData.append("slug", templateData?.slug)
                formData.append("label", templateData?.label)
                formData.append("content", editordata.content)
                formData.append("tags", templateData?.tags)
                formData.append("subject", subject)

                const dataUpdate = await updateEmaillabelData(formData, templateData.id)
                if (dataUpdate?.success) {
                    await emailDataByLabel(templateData)
                    setApiMessage({ type: 'success', message: dataUpdate?.data?.message || 'Success' });

                } else {
                    setApiMessage({ type: 'error', message: dataUpdate?.message });
                }
            } else {
                const slug = subject?.toLowerCase()?.replaceAll(" ", "_")
                const formData = new FormData()
                formData.append("slug", slug)
                formData.append("label", selectedLabel)
                formData.append("content", editordata.content)
                formData.append("tags", `{${subject}}`)
                formData.append("subject", subject)

                const dataUpdate = await addEmailLabelData(formData)
                if (dataUpdate?.success) {
                    await emailDataByLabel(templateData)
                    setApiMessage({ type: 'success', message: dataUpdate?.data?.message || 'Success' });


                } else {
                    setApiMessage({ type: 'error', message: dataUpdate?.message });
                }
            }

        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }

    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <b onClick={() => navigate("/admin/")}>Home  /</b><span> Email Settings</span>
                    </div>
                </div>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className="aod-inner">
                    <div className="content-wrapper" id="sms-page">
                        <div className="flex-grow-1 container-p-y">
                            <h4 className="mb-4">Email settings</h4>
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card mb-4 border-0">
                                        <h5 className="card-header">List</h5>
                                        <ul className="template-list">
                                            {
                                                templateList?.map((item, index) => (
                                                    <a >
                                                        <li
                                                            className={`odd ${selectedModuleIndex === item.id ? "active" : ""}`}
                                                            id={`v-pills-${item.label}-tab`}
                                                            aria-selected={selectedModuleIndex === item.id}
                                                            onClick={() => { setSelectedModuleIndex(item.id); emailDataByLabel(item) }}
                                                        >{item.label}</li>
                                                    </a>
                                                ))}
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8">
                                    <div className="card mb-4 border-0">


                                        <form className="card-body admin-role-form" >
                                            <div className="col-md-12">
                                                <label className="form-label" htmlFor="Map-provider">Subject</label>
                                                <input type="text" id="map-provider" className="form-control"
                                                    placeholder="Enter Your Subject" name="subject" value={subject} onChange={(e) => handleInput(e, e?.target?.name, e?.target?.value)} />
                                                {err.subject && <p className='validate-error'>{err.subject}</p>}

                                            </div>
                                            <div className="col-md-12 mt-4">

                                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Content</label>
                                                <ReactQuill
                                                    name="content"
                                                    value={content}
                                                    onChange={(value) => handleInput(null, 'content', value)}
                                                    modules={modules}
                                                    formats={formats}
                                                    theme="snow"
                                                />
                                                {err.content && <p className='validate-error'>{err.content}</p>}

                                            </div>
                                            {
                                                templateData && templateData?.tags ?

                                                    <p className="pt-4">Tags: {templateData?.tags}</p> : null}

                                            <div className="pt-4">
                                                <button type="button"
                                                    className="btn btn-primary me-sm-3 me-1 theme-cta" onClick={handleSubmit}>Submit</button>

                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>

                    <div className="content-backdrop fade"></div>
                </div>

            </div>
        </AdminDashboard >
    )
}

export default EmailSettingPage
