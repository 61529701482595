/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import "../Admin.css";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import { Modal, Table } from "react-bootstrap";
import { Apiurl, GetLocalStorage, RemoveSession } from "../../../Utils/Utils";
import axios from "axios";
import Select from "react-select";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";
import {
  addFormFieldData,
  deleteFormFieldData,
  getAllDropdownList,
  getFontFamilyData,
  getFormFieldListDataSort,
  getFormFieldsListData,
  getFormTabSubCategoryListData,
  getSectionListDataByTabId,
  getSubCategoriesDropdown,
  getSubCategoryListData,
  getSubSectionList,
  getSubSectionsDropdown,
  getValidationsListData,
  handleEnableDisableFormFieldsData,
  updateFormFieldData,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import sortByIcon from "../../../assets/images/sort.png";
import { BsChevronLeft } from "react-icons/bs";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { HiPlus } from "react-icons/hi";
import { Message } from "../../../components/message";
import {
  checkPatternWithSpecialCharacter,
  checkPatternFontSize,
  checkPattern,
  customStylesAcc,
  selectTheme,
  formatDateWithSlash,
} from "../../../Utils/common";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const FormFields = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState();
  const token = GetLocalStorage("userInfo");
  const [enName, setEnName] = useState("");
  const [enNameError, setEnNameError] = useState();
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [hnName, setHnName] = useState("");
  const [hnNameError, setHnNameError] = useState();
  const [dropdownOptionList, setDropdownOptionList] = useState([]);
  const [enPlaceholder, setEnPlaceholder] = useState("");
  const [enPlaceholderError, setEnPlaceholderError] = useState();

  const [hnPlaceholder, setHnPlaceholder] = useState("");
  const [hnPlaceholderError, setHnPlaceholderError] = useState();
  const [showValidations, setShowValidations] = useState({
    label: "No",
    value: false,
  });
  const [preventData, setPreventData] = useState([
    {
      id: Date.now(),
      type: "",
      unit: "",
      value: "",
      numberUpperLimit: "",
      numberLowerLimit: "",
      messageEnglish: "",
      messageHindi: "",
    },
  ]);
  const [otherValidations, setOtherValidations] = useState([
    {
      id: Date.now(),
      type: "",
      unit: "",
      value: "",
      numberUpperLimit: "",
      numberLowerLimit: "",
      messageEnglish: "",
      messageHindi: "",
    },
  ]);

  const grantPermissionForAddFormFields = checkPermission(
    permissionsConfig.addFormFields.role,
    permissionsConfig.addFormFields.action,
    permissionsConfig.addFormFields.type
  );
  const grantPermissionForEditFormFields = checkPermission(
    permissionsConfig.editFormFields.role,
    permissionsConfig.editFormFields.action,
    permissionsConfig.editFormFields.type
  );

  const [validationsList, setValidationList] = useState([]);
  const [validationsType, setValidationType] = useState([]);
  const [validationError, setValidationError] = useState();
  // const [inputName, setInputName] = useState();
  // const [inputNameError, setInputNameError] = useState();

  const [inputType, setInputType] = useState();
  const [inputTypeError, setInputTypeError] = useState();

  // const [dropdownTypeOptionList, setDropdownTypeOptionList] = useState()
  const [dropdownTypeOptionListErr, setDropdownTypeOptionListErr] = useState(
    ""
  );
  const [dropdownTypeOption, setDropdownTypeOption] = useState();

  const [fontFamily, setFontFamily] = useState({ label: "Arial", value: 1 });
  const [fontFamilyError, setFontFamilyError] = useState();

  const [fontColor, setFontColor] = useState("#000000");
  const [fontColorError, setFontColorError] = useState();

  const [bgColor, setBgColor] = useState("#ffffff");
  const [bgColorError, setBgColorError] = useState();

  const [fontSize, setFontSize] = useState("14");
  const [fontSizeError, setFontSizeError] = useState();

  const [inputRequired, setInputRequired] = useState();
  const [inputRequiredError, setInputRequiredError] = useState();

  const [dropdownValues, setDropDownValues] = useState([{ value: "" }]);
  const [dropdownValuesError, setDropDownValuesError] = useState();

  const [validation, setValidation] = useState([{ value: "" }]);
  const [subCategoryId, setSubCategoryId] = useState();
  const [searchSubCategoryId, setSearchSubCategoryId] = useState();
  const [subCategoryIdError, setSubCategoryIdError] = useState();
  const [formTabId, setFormTabId] = useState();
  const [searchFormTabId, setSearchFormTabId] = useState();

  const [formTabIdError, setFormTabIdError] = useState();
  const [sectionId, setSectionId] = useState();
  const [searchSectionId, setSearchSectionId] = useState();
  const [sectionIdError, setSectionIdError] = useState();
  const [formFields, setFormFields] = useState([]);
  const [formFieldsFull, setFormFieldsFull] = useState([]);
  const [fontsList, setFontsList] = useState();
  const [sectionList, setSectionList] = useState([]);
  const [editData, setEditData] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [editSubCategoryList, setEditSubCategoryList] = useState([]);
  const [formTabList, setFormTabList] = useState();
  const [searchFormTabList, setSearchFormTabList] = useState();
  const [searchSectionList, setSearchSectionList] = useState();
  const [deleteData, setDeleteData] = useState();
  const [editContainer, setEditContainer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderBy, setOrderBy] = useState("desc");
  const [subSection, setSubSection] = useState();
  const [subSectionErr, setSubSectionErr] = useState();
  const [selectedSubSection, setSelectedSubSection] = useState();
  const [selectedSubSectionErr, setSelectedSubSectionErr] = useState();
  const [searchData, setSearchData] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [inputTypeDisabled, setInputTypeDisabled] = useState(false);
  const [hasSubSectionDisabled, setHasSubSectionDisabled] = useState(false);
  const [subSectionList, setSubSectionList] = useState([]);

  const isSubSectionList = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;

    // Check for "enName" field
    if (name === "enName") {
      if (await checkPatternWithSpecialCharacter(value)) {
        setEnName(value);
      } else {
        e.preventDefault();
        return;
      }
    }

    // Check for "hnName" field
    if (name === "hnName") {
      if (await checkPatternWithSpecialCharacter(value)) {
        setHnName(value);
      } else {
        e.preventDefault();
        return;
      }
    }

    // Check for "enPlaceholder" field
    if (name === "enPlaceholder") {
      if (await checkPatternWithSpecialCharacter(value)) {
        setEnPlaceholder(value);
      } else {
        e.preventDefault();
        return;
      }
    }

    // Check for "enPlaceholder" field
    if (name === "hnPlaceholder") {
      if (await checkPatternWithSpecialCharacter(value)) {
        setHnPlaceholder(value);
      } else {
        e.preventDefault();
        return;
      }
    }

    setTimeout(() => {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const handleKeyUpLoginDetails = (item) => {
    validateForm(item);
  };
  //validations
  const validateForm = (item) => {
    let isValid = true;
    if (item === "subCategoryId" || item === "both" || item === "all") {
      if (!subCategoryId) {
        setSubCategoryIdError("Please Select Sub Category");
        isValid = false;
      } else {
        setSubCategoryIdError("");
      }
    }
    if (item === "formTabId" || item === "both" || item === "all") {
      if (!formTabId) {
        setFormTabIdError("Please Select Form Tab");
        isValid = false;
      } else {
        setFormTabIdError("");
      }
    }
    if (item === "sectionId" || item === "both" || item === "all") {
      if (!sectionId) {
        setSectionIdError("Please Select Section");
        isValid = false;
      } else {
        setSectionIdError("");
      }
    }
    if (item === "enName" || item === "all") {
      if (!enName || enName.trim() === "") {
        setEnNameError("Please Enter English Name");
        isValid = false;
      } else {
        setEnNameError("");
      }
    }

    if (item === "hnName" || item === "all") {
      if (!hnName || hnName.trim() === "") {
        setHnNameError("Please Enter Hindi Name");
        isValid = false;
      } else {
        setHnNameError("");
      }
    }

    if (item === "enPlaceholder" || item === "all") {
      if (!enPlaceholder || enPlaceholder.trim() === "") {
        setEnPlaceholderError("Please Enter English Placeholder");
        isValid = false;
      } else {
        setEnPlaceholderError("");
      }
    }

    if (item === "hnPlaceholder" || item === "all") {
      if (!hnPlaceholder || hnPlaceholder.trim() === "") {
        setHnPlaceholderError("Please Enter Hindi Placeholder");
        isValid = false;
      } else {
        setHnPlaceholderError("");
      }
    }

    // if (item === "fontFamily" || item === "all") {
    //   if (!fontFamily) {
    //     setFontFamilyError("Please Select Font Family");
    //     isValid = false;
    //   } else {
    //     setFontFamilyError("");
    //   }
    // }

    if (item === "fontColor" || item === "all") {
      if (!fontColor || fontColor.trim() === "") {
        setFontColorError("Please Select Font Color");
        isValid = false;
      } else {
        setFontColorError("");
      }
    }

    if (item === "bgColor" || item === "all") {
      if (!bgColor || bgColor.trim() === "") {
        setBgColorError("Please Select Background Color");
        isValid = false;
      } else {
        setBgColorError("");
      }
    }

    if (item === "fontSize" || item === "all") {
      if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
        setFontSizeError("Please Enter Valid Font Size");
        isValid = false;
      } else {
        setFontSizeError("");
      }
    }
    if (item === "inputType" || item === "all") {
      if (!inputType) {
        setInputTypeError("Please Select Input Type");
        isValid = false;
      } else {
        setInputTypeError("");
      }
    }
    if (inputType?.label == "Dropdown") {
      if (item === "dropdown" || item === "all") {
        if (!dropdownTypeOption) {
          setDropdownTypeOptionListErr("Please Select DropDown Option");
          isValid = false;
        } else {
          setDropdownTypeOptionListErr("");
        }
      }
    }

    // console.log(subSection)
    if (subSection?.value == 1) {
      if (item === "Sub Section" || item === "all") {
        if (!selectedSubSection) {
          setSelectedSubSectionErr("Please Select Sub Section Option");
          isValid = false;
        } else {
          setSelectedSubSectionErr("");
        }
      }
    } else {
      setSelectedSubSectionErr("");
    }

    if (item === "Sub-Section" || item === "all") {
      if (!subSection) {
        setSubSectionErr("Please Select Sub Section Required");
        isValid = false;
      } else {
        setSubSectionErr("");
      }
    }

    if (item === "required" || item === "all") {
      if (inputType?.value == "dropdown" && dropdownValues?.length < 2) {
        setDropDownValuesError("Drop down must contain atleast two values");
      }
      if (!inputRequired) {
        setInputRequiredError("Please select Status");
        isValid = false;
      } else {
        setInputRequiredError("");
      }
    }

    return isValid;
  };

  // add Form field
  const addFormField = async () => {
    if (!validateForm("all")) return;
    try {
      const formData = new FormData();
      const inputName = enName.length > 40 ? enName.substring(0, 40) : enName;

      const specialCharacterRemovedStr = inputName
        .toLowerCase()
        ?.replaceAll(/[^a-zA-Z0-9 ]/g, "");
      const keyName = specialCharacterRemovedStr.replaceAll(" ", "_");
      formData.append("has_sub_section", subSection?.value);
      // formData.append("sub_section_id", selectedSubSection?.value)
      formData.append("sub_section_id", selectedSubSection ?? "");

      formData.append("sub_category_id", subCategoryId?.value);
      formData.append("tab_id", formTabId?.value);
      formData.append("section_id", sectionId?.value);
      formData.append("name_en", enName);
      formData.append("name_hi", hnName);
      formData.append("placeholder_en", enPlaceholder);
      formData.append("placeholder_hi", hnPlaceholder);
      formData.append("field_key", keyName);
      formData.append("font_family_id", Number(fontFamily?.value ?? "1"));
      formData.append("font_color", fontColor);
      formData.append("font_size", Number(fontSize));
      formData.append("type", inputType?.value);
      formData.append("dropdown_id", dropdownTypeOption ?? "");
      formData.append("status", 1);
      formData.append("sort", "1");
      formData.append("background_color", bgColor);
      // formData.append("validations", validData)
      const preventdata = preventData
        ?.filter((i) => i?.type?.value)
        ?.map((item) => ({
          type: item?.type?.value,
          unit: item?.unit?.label,
          value: item?.value,
          numberUpperLimit: item?.numberUpperLimit,
          numberLowerLimit: item?.numberLowerLimit,
          messageEnglish: item?.messageEnglish,
          messageHindi: item?.messageHindi,
        }));
      const otherdata = otherValidations
        ?.filter((i) => i?.type?.value)
        ?.map((item) => ({
          type: item?.type?.value,
          unit: item?.unit?.label,
          value: item?.value,
          numberUpperLimit: item?.numberUpperLimit,
          numberLowerLimit: item?.numberLowerLimit,
          messageEnglish: item?.messageEnglish,
          messageHindi: item?.messageHindi,
        }));
      const validData = JSON.stringify({
        validation: JSON.stringify({
          onChange: preventdata,
          onKeyUp: otherdata,
        }),
      });
      console.log(showValidations);
      formData.append("validations", validData);
      // formData.append("validations", showValidations?.label == undefined || showValidations?.label == 'No' ? '' : validData);

      formData.append("required", inputRequired?.value);
      formData.append("required", inputRequired?.value);
      const data = await addFormFieldData(formData);
      if (data?.status == 200) {
        handleEmptyData();
        setApiMessage({ type: "success", message: data.message });
      } else {
        // handleEmptyData()
        setApiMessage({ type: "error", message: data.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error.message });
    }
  };
  // sub category list
  const getSubCategoryList = async () => {
    try {
      const subCategoryData = await getSubCategoriesDropdown();
      const data = subCategoryData?.data?.map((i) => ({
        label: i?.category_name,
        value: i?.id,
      }));
      const editData = subCategoryData?.data
        ?.filter((s) => s.status !== "0")
        ?.map((i) => ({
          label: i?.category_name,
          value: i?.id,
        }));
      setSubCategoryList(data);
      setEditSubCategoryList(editData);
    } catch (error) {
      console.log("error on sub category list: ", error);
      // navigate("/")
    }
  };

  // sub category list
  const getSubSectionList = async () => {
    try {
      const subSectionData = await getSubSectionsDropdown();
      const data = subSectionData?.data
        ?.filter((s) => s.status !== "0")
        ?.map((i) => ({
          label: i?.name_en,
          value: i?.id,
        }));
      setSubSectionList(data);
    } catch (error) {
      console.log("error on sub section list: ", error);
    }
  };

  // tab list
  const getFormTabList = async (item) => {
    const formData = new FormData();
    if (item == "search") {
      formData.append("category_id", searchSubCategoryId.value);
    } else {
      formData.append("category_id", subCategoryId.value);
    }
    await getFormTabSubCategoryListData(formData)
      .then((e) => {
        const data = e?.data?.map((i) => ({
          label: i?.name_en,
          value: i?.id,
        }));
        if (item == "search") {
          setSearchFormTabList(data);
        } else {
          setFormTabList(data);
        }
      })
      .catch((error) => {
        setApiMessage({ type: "error", message: error?.message });
      });
  };
  // section list
  const getSectionList = async (item) => {
    const formData = new FormData();
    if (item == "search") {
      formData.append("tab_id", searchFormTabId.value);
    } else {
      formData.append("tab_id", formTabId.value);
    }
    await getSectionListDataByTabId(formData)
      .then((e) => {
        const data = e?.data?.map((i) => ({
          label: i?.name_en,
          value: i?.id,
        }));
        if (item == "search") {
          setSearchSectionList(data);
        } else {
          setSectionList(data);
        }
        if (editData?.id) {
          setSectionId(data?.find((i) => i?.value == editData?.section_id));
        }
      })
      .catch((error) => {
        setApiMessage({ type: "error", message: error?.message });
      });
  };
  //Form fields list
  const getFormFieldData = async (
    searchedSubCategory,
    searchedCategoryFromTab,
    searchedFormTab,
    sortBy,
    pageNo,
    orderBy,
    searchBy
  ) => {
    try {
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const formfieldList = await getFormFieldListDataSort(
        sortBy,
        orderBy,
        searchBy ?? "",
        pageNo,
        searchedSubCategory?.value ? searchedSubCategory?.value : "",
        searchedCategoryFromTab?.value ? searchedCategoryFromTab?.value : "",
        searchedFormTab?.value ? searchedFormTab?.value : ""
      );
      setTotalPages(
        Math.ceil(formfieldList?.data?.total / formfieldList?.data?.per_page)
      );
      setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
      const data = formfieldList?.data?.data?.filter(
        (i) => i?.deleted_at == null
      );
      setFormFields(data);
      setTableDataLoaded(true);
    } catch (err) {
      console.log("error on formfieldList: ", err);
    }
  };

  //get font families
  const getFontFamily = async () => {
    try {
      const fontFamilyData = await getFontFamilyData();
      const data = fontFamilyData?.data?.map((i) => ({
        label: i?.name,
        value: i?.id,
      }));

      setFontsList(data);
    } catch (err) {
      console.log("font families error :", err);
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  useEffect(() => {
    if (subCategoryId?.value) {
      getFormTabList(null);
    } else if (searchSubCategoryId?.value) {
      getFormTabList("search");
    }
  }, [subCategoryId, searchSubCategoryId]);

  useEffect(() => {
    if (formTabId?.value) {
      getSectionList();
    } else if (searchFormTabId?.value) {
      setSearchSectionId("");
      getSectionList("search");
    }
  }, [formTabId, searchFormTabId]);

  useEffect(() => {
    getFontFamily();
    getSubCategoryList();
    dropdownList();
    getFormFieldData("", "", "", "created_at", 1, "desc", "");
    getSubSectionList();
  }, []);

  const getValidationsList = async () => {
    try {
      let validationListData = await getValidationsListData();
      const data = validationListData?.data;
      const uniqueValidationTypes = data
        .map((item) => item.validation_type)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((item) => ({ label: item, value: item }));
      // const uniqueValidationTypes = [...new Set(data)];

      setValidationList(data);
      setValidationType(uniqueValidationTypes);
    } catch (err) {
      console.log("error in validation list", err);
    }
  };
  useEffect(() => {
    getValidationsList();
  }, []);

  //edit sub-category
  const editFormField = async (item) => {
    setEditData(item);
    const list = await getFormTabSubCategoryListData({
      category_id: item?.category_id,
    });
    const data = await list?.data?.map((i) => ({
      label: i?.name_en,
      value: i?.id,
    }));
    const tabName = data?.find((i) => i?.value == item?.tab_id);
    if (item?.sub_section_id) {
      setSubSection(isSubSectionList?.find((i) => i.value === 1));
      setSelectedSubSection(item?.sub_section_id);
    } else {
      setSubSection(isSubSectionList?.find((i) => i.value === 0));
    }
    console.log(item?.sub_section_id);
    setSubCategoryId(
      subCategoryList?.find((i) => i?.value == item?.category_id)
    );
    setFormTabId(tabName);
    setSectionId(sectionList?.find((i) => i?.value == item?.section_id));
    setEnName(item?.name_en);
    setHnName(item?.name_hi);
    setEnPlaceholder(item?.placeholder_en);
    setHnPlaceholder(item?.placeholder_hi);
    setInputType(inputTypeList?.find((i) => i.value == item?.type));
    setFontFamily(fontsList?.find((i) => i?.value == item?.font_family_id));
    setFontColor(item?.font_color);
    setBgColor(item?.background_color);
    setFontSize(item?.font_size);
    setDropdownTypeOption(item.dropdown_id);
    setInputRequired(requiredList?.find((i) => i?.value == item?.required));

    if(inputTypeList?.find((i) => i.value == item?.type)?.label !== "Text") {
      setHasSubSectionDisabled(true)
    } else{
      setHasSubSectionDisabled(false)
    }

    

    const validations = item?.validations
      ? JSON.parse(item?.validations)
      : null;
    const validationsparsed = JSON.parse(
      validations?.validation ? validations?.validation : null
    );
    if (validationsparsed) {
      setShowValidations({ label: "Yes", value: true });
      const preventData = validationsparsed?.onChange?.length
        ? validationsparsed?.onChange?.map((item, index) => {
            const unitList = validationsList
              ?.filter((i) => i?.validation_type == item?.type)
              ?.map((i) => ({ label: i?.name, value: i?.id }));
            return {
              id: Date.now() + index,
              type: item?.type
                ? validationsType?.find((i) => i?.value == item?.type)
                : "",
              unit: item?.unit
                ? unitList?.find((i) => i?.label == item?.unit)
                : "",
              value: item?.value,
              numberUpperLimit: item?.numberUpperLimit,
              numberLowerLimit: item?.numberLowerLimit,
              messageEnglish: item?.messageEnglish,
              messageHindi: item?.messageHindi,
            };
          })
        : [
            {
              id: Date.now(),
              type: "",
              value: "",
              unit: "",
              numberUpperLimit: "",
              numberLowerLimit: "",
              messageEnglish: "",
              messageHindi: "",
            },
          ];
      const otherData = validationsparsed?.onKeyUp?.length
        ? validationsparsed?.onKeyUp?.map((item, index) => {
            const unitList = validationsList
              ?.filter((i) => i?.validation_type == item?.type)
              ?.map((i) => ({ label: i?.name, value: i?.id }));
            return {
              id: Date.now() + index,
              type: item?.type
                ? validationsType?.find((i) => i?.value == item?.type)
                : "",
              unit: item?.unit
                ? unitList?.find((i) => i?.label == item?.unit)
                : "",
              value: item?.value,
              numberUpperLimit: item?.numberUpperLimit,
              numberLowerLimit: item?.numberLowerLimit,
              messageEnglish: item?.messageEnglish,
              messageHindi: item?.messageHindi,
            };
          })
        : [
            {
              id: Date.now(),
              type: "",
              value: "",
              unit: "",
              numberUpperLimit: "",
              numberLowerLimit: "",
              messageEnglish: "",
              messageHindi: "",
            },
          ];
      setPreventData(preventData);
      setOtherValidations(otherData);
    } else {
      setShowValidations();
    }

    window.scrollTo(0, 0);
  };

  //update sub-category
  const updateFormField = async () => {
    if (!validateForm("all")) return;
    try {
      const inputName = enName.length > 40 ? enName.substring(0, 40) : enName;
      const specialCharacterRemovedStr = inputName
        .toLowerCase()
        ?.replaceAll(/[^a-zA-Z0-9 ]/g, "");
      const keyName = specialCharacterRemovedStr.replaceAll(" ", "_");
      const formData = new FormData();
      formData.append("has_sub_section", subSection?.value);
      formData.append("sub_section_id", selectedSubSection ?? "");
      formData.append("sub_category_id", subCategoryId?.value);
      formData.append("tab_id", formTabId?.value);
      formData.append("section_id", sectionId?.value);
      formData.append("field_id", editData?.id);
      formData.append("name_en", enName);
      formData.append("name_hi", hnName);
      formData.append("placeholder_en", enPlaceholder);
      formData.append("placeholder_hi", hnPlaceholder);
      // const inputName = enName?.toLowerCase()?.replaceAll(" ", "_");
      //formData.append("field_key", keyName);
      formData.append("font_family_id", Number(fontFamily?.value ?? "1"));
      formData.append("font_color", fontColor);
      formData.append("font_size", Number(fontSize));
      formData.append("type", inputType?.value);
      formData.append("dropdown_id", dropdownTypeOption ?? "");
      formData.append("status", 1);
      formData.append("sort", "10");
      formData.append("background_color", bgColor);
      const dropdata = JSON.stringify({
        [inputName]: dropdownValues?.map((i) => i?.value),
      });
      formData.append("dropdown_values", dropdata ? dropdata : null);
      const preventdata = preventData
        ?.filter((i) => i?.type?.value)
        ?.map((item) => ({
          type: item?.type?.value,
          unit: item?.unit?.label,
          value: item?.value,
          numberUpperLimit: item?.numberUpperLimit,
          numberLowerLimit: item?.numberLowerLimit,
          messageEnglish: item?.messageEnglish,
          messageHindi: item?.messageHindi,
        }));
      const otherdata = otherValidations
        ?.filter((i) => i?.type?.value)
        ?.map((item) => ({
          type: item?.type?.value,
          unit: item?.unit?.label,
          value: item?.value,
          numberUpperLimit: item?.numberUpperLimit,
          numberLowerLimit: item?.numberLowerLimit,
          messageEnglish: item?.messageEnglish,
          messageHindi: item?.messageHindi,
        }));
      const validData = JSON.stringify({
        validation: JSON.stringify({
          onChange: preventdata,
          onKeyUp: otherdata,
        }),
      });

      // console.log(showValidations, showValidations?.label)
      formData.append("validations", validData);
      // formData.append("validations", showValidations?.label == undefined || showValidations?.label == 'No' ? '' : validData);

      formData.append("required", inputRequired?.value);
      const data = await updateFormFieldData(formData);
      if (data?.status == 200) {
        handleEmptyData();
        setApiMessage({ type: "success", message: data?.message });
      } else {
        setApiMessage({ type: "error", message: data?.message });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  const dropdownList = async () => {
    try {
      const list = await getAllDropdownList();
      if (list?.status == 200) {
        const data = list?.data?.map((i) => ({
          label: i?.name_en,
          value: i?.id,
        }));

        setDropdownOptionList(data);
      }
    } catch (error) {
      setApiMessage({ type: "error", message: error?.message });
    }
  };
  const inputTypeList = [
    { label: "Text", value: 1 },
    // { label: "Radio", value: 4 },
    { label: "Password", value: 7 },
    { label: "File", value: 5 },
    { label: "Dropdown", value: 2 },
    // { label: "Sub Section", value: 6 },
    // { label: "Checkbox", value: 3 },
    { label: "Designation Table", value: 8 },
    { label: "Date", value: 9 },
  ];
  const statusList = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];
  const requiredList = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];
  const handleDropdownChange = (index, event) => {
    const values = [...dropdownValues];
    values[index].value = event.target.value;
    setDropDownValues(values);
  };

  const handleDropDownAddInput = () => {
    const values = [...dropdownValues];
    values.push({ value: "" });
    setDropDownValues(values);
  };

  const handleDropDownRemoveInput = (index) => {
    const values = [...dropdownValues];
    values.splice(index, 1);
    setDropDownValues(values);
  };
  const handleValidationChanges = (index, event) => {
    const values = [...validation];
    values[index].value = event.target.value;
    setValidation(values);
  };

  const handleValidationAddInput = () => {
    if (validation.length < 5) {
      const values = [...validation];
      values.push({ value: "" });
      setValidation(values);
    }
  };

  const handleValidationRemoveInput = (index) => {
    const values = [...validation];
    values.splice(index, 1);
    setValidation(values);
  };
  const handleEmptyData = () => {
    setEditContainer(false);
    setShowValidations(false);
    setSearchFormTabId("");
    setSearchSubCategoryId("");
    setSearchFormTabList([]);
    setSearchSectionId("");
    setSearchSectionList([]);
    setSubCategoryId("");
    setSubCategoryIdError("");
    setFormTabId("");
    setFormTabIdError("");
    setSectionId();
    setSectionIdError("");
    setEnName("");
    setHnName("");
    setEnPlaceholder("");
    setHnPlaceholder("");
    setInputType("");
    setFontFamily("");
    setBgColor("#ffffff");
    setFontColor("#000000");
    setFontSize("");
    setValidation([{ value: "" }]);
    setPreventData();
    setSearchTerm("");
    setOtherValidations();
    setDropDownValues([{ value: "" }]);
    setInputRequired("");
    setEditData();
    setEnPlaceholderError("");
    setHnPlaceholderError("");
    setInputTypeError("");
    setFontFamilyError("");
    setFontColorError("");
    setBgColorError("");
    setSubSection();
    setEnNameError("");
    setHnNameError("");
    setDropdownTypeOptionListErr("");
    setSelectedSubSection();
    setFontSizeError("");
    setDropDownValuesError("");
    setInputRequiredError("");
    getFormFieldData("", "", "", "created_at", 1, "desc", "");
    setPreventData([  
      {  
       id: Date.now(),  
       type: "",  
       unit: "",  
       value: "",  
       numberUpperLimit: "",  
       numberLowerLimit: "",  
       messageEnglish: "",  
       messageHindi: "",  
      },  
     ]);  
     setOtherValidations([  
      {  
       id: Date.now(),  
       type: "",  
       unit: "",  
       value: "",  
       numberUpperLimit: "",  
       numberLowerLimit: "",  
       messageEnglish: "",  
       messageHindi: "",  
      },  
     ]);  
   };
  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("field_id", item?.id);
      formData.append("section_id", item?.section_id);
      formData.append("status", e?.value);
      const data = await handleEnableDisableFormFieldsData(formData);
      if (data?.status == 200) {
        getFormFieldData(
          searchSubCategoryId,
          searchFormTabId,
          searchSectionId,
          sortBy,
          currentPage + 1,
          orderBy,
          searchData
        );
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  const resetFilter = () => {
    setSearchFormTabId("");
    setSearchSubCategoryId("");
    setSearchFormTabList([]);
    setSearchSectionId("");
    setSearchSectionList([]);
    getFormFieldData("", "", "", sortBy, 1, orderBy, searchData);
  };

  const handlePageChange = (selectedPage) => {
    getFormFieldData(
      searchSubCategoryId,
      searchFormTabId,
      searchSectionId,
      sortBy,
      selectedPage.selected + 1,
      orderBy,
      searchData
    ); // Increment by 1 if the API starts page numbers from 1
  };

  const handleCheckboxChange = (event) => {
    setShowValidations(event);
    if (event.value === false) {
      setPreventData([
        {
          id: Date.now(),
          type: "",
          unit: "",
          value: "",
          numberUpperLimit: "",
          numberLowerLimit: "",
          messageEnglish: "",
          messageHindi: "",
        },
      ]);
      setOtherValidations([
        {
          id: Date.now(),
          type: "",
          unit: "",
          value: "",
          numberUpperLimit: "",
          numberLowerLimit: "",
          messageEnglish: "",
          messageHindi: "",
        },
      ]);
    }
  };


  const handleValidationChange = (setter, list, id, field, value) => {
    setter(
      list?.map((item) => {
        if (item.id === id) {
          if (field == "type") {
            return {
              id: item.id,
              type: value,
              value: "",
              unit: "",
              numberUpperLimit: "",
              numberLowerLimit: "",
              messageEnglish: "",
              messageHindi: "",
            };
          } else {
            return { ...item, [field]: value };
          }
        }
        return item;
      })
    );
    setValidationError("");
  };

  const validationRequired = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const handleAddRow = (setter, list) => {
    setter([...list, { id: Date.now(), selectValue: "", value: "" }]);
  };

  const handleRemoveRow = (setter, list, id) => {
    setter(list.filter((item) => item.id !== id));
    if(list?.length === 1){
      handleCheckboxChange({ label: "No", value: false })
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        {editContainer ? (
          <>
            <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
              <div>
                <HomeIcon />
                <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
                <span>Form Field Form</span>
              </div>
            </div>
            <div className="aod-inner">
              <div
                className={
                  editData?.id ? "aod-update aod-bottom" : "aod-bottom"
                }
              >
                {/* <div className="aod-head">{editData?.id ? "Update" : "Add"} Form Field</div> */}
                <div className="aod-head text-center adm-head">
                  {" "}
                  <button onClick={() => handleEmptyData()}>
                    <BsChevronLeft /> Back
                  </button>
                  {editData?.id ? "Update" : "Add"} Field
                </div>
                <div className="row py-4">
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="lf-label w-100">
                        Select Sub Category
                      </label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        isDisabled={editData?.id}
                        value={subCategoryId}
                        options={editSubCategoryList}
                        onChange={(e) => {
                          setSubCategoryId(e);
                          setSubCategoryIdError("");
                          setFormTabId("");
                          setSectionId("");
                        }}
                        placeholder="Select Sub Category"
                        onMenuOpen={() =>
                          handleKeyUpLoginDetails("subCategoryId")
                        }
                        onMenuClose={() =>
                          handleKeyUpLoginDetails("subCategoryId")
                        }
                      />
                      {subCategoryIdError && (
                        <p className="validate-error">{subCategoryIdError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="lf-label w-100">Select Form Tab</label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        isDisabled={editData?.id}
                        value={formTabId}
                        options={formTabList}
                        onChange={(e) => {
                          setFormTabId(e);
                          setFormTabIdError("");
                          setSectionId("");
                        }}
                        placeholder="Select Form Tab"
                        onMenuOpen={() => handleKeyUpLoginDetails("formTabId")}
                        onMenuClose={() => handleKeyUpLoginDetails("formTabId")}
                      />
                      {formTabIdError && (
                        <p className="validate-error">{formTabIdError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="lf-label w-100">Select Section</label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        isDisabled={editData?.id}
                        value={sectionId}
                        options={sectionList}
                        onChange={(e) => {
                          setSectionId(e);
                          setSectionIdError("");
                        }}
                        placeholder="Select Section"
                        onMenuOpen={() => handleKeyUpLoginDetails("sectionId")}
                        onMenuClose={() => handleKeyUpLoginDetails("sectionId")}
                      />
                      {sectionIdError && (
                        <p className="validate-error">{sectionIdError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">English Name</label>
                      <input
                        className="w-100"
                        placeholder="Enter English Name"
                        type="text"
                        name="enName"
                        value={enName}
                        onChange={handleInputChange}
                      />
                      {enNameError && (
                        <p className="validate-error">{enNameError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Hindi Name</label>
                      <input
                        className="w-100"
                        placeholder="Enter Hindi Name"
                        type="text"
                        name="hnName"
                        value={hnName}
                        onChange={handleInputChange}
                      />
                      {hnNameError && (
                        <p className="validate-error">{hnNameError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">English PlaceHolder</label>
                      <input
                        maxLength={60}
                        className="w-100"
                        placeholder="Enter English PlaceHolder"
                        type="text"
                        name="enPlaceholder"
                        value={enPlaceholder}
                        onChange={handleInputChange}
                      />
                      {enPlaceholderError && (
                        <p className="validate-error">{enPlaceholderError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Hindi PlaceHolder</label>
                      <input
                        maxLength={60}
                        className="w-100"
                        placeholder="Enter Hindi PlaceHolder"
                        type="text"
                        name="hnPlaceholder"
                        value={hnPlaceholder}
                        onChange={handleInputChange}
                      />
                      {hnPlaceholderError && (
                        <p className="validate-error">{hnPlaceholderError}</p>
                      )}
                    </div>
                  </div>
                 {/*  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Font Family</label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={fontFamily}
                        options={fontsList}
                        onChange={(e) => {
                          setFontFamily(e);
                          setFontFamilyError("");
                        }}
                        placeholder="Select Font Family"
                        onMenuOpen={() => handleKeyUpLoginDetails("fontFamily")}
                        onMenuClose={() =>
                          handleKeyUpLoginDetails("fontFamily")
                        }
                      />
                      {fontFamilyError && (
                        <p className="validate-error">{fontFamilyError}</p>
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Font Color</label>
                      <label className="adm-label-color">
                        {fontColor ? fontColor : <span>Select Font Color</span>}
                        <input
                          className="adm-color"
                          placeholder="Enter Font Color"
                          type="color"
                          onKeyUp={() => handleKeyUpLoginDetails("color")}
                          onBlur={() => handleKeyUpLoginDetails("color")}
                          value={fontColor}
                          onChange={(e) => setFontColor(e?.target?.value)}
                        />
                      </label>
                      {fontColorError && (
                        <p className="validate-error">{fontColorError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Background Color</label>
                      <label className="adm-label-color">
                        {bgColor ? (
                          bgColor
                        ) : (
                          <span>Select Background Color</span>
                        )}
                        <input
                          className="adm-color"
                          placeholder="Enter Font Color"
                          type="color"
                          onKeyUp={() => handleKeyUpLoginDetails("bgColor")}
                          onBlur={() => handleKeyUpLoginDetails("bgColor")}
                          value={bgColor}
                          onChange={(e) => setBgColor(e?.target?.value)}
                        />
                      </label>
                      {bgColorError && (
                        <p className="validate-error">{bgColorError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">
                        Font Size (in pixels)
                      </label>
                      <input
                        className="w-100"
                        max={99}
                        placeholder="Enter Font Size"
                        type="number"
                        value={fontSize}
                        onKeyUp={() => handleKeyUpLoginDetails("size")}
                        onKeyDown={(e) => {
                          if (
                            e.key === "-" ||
                            e.key === "+" ||
                            e.key === "e" ||
                            e.key === "E"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={async (e) => {
                          const value = e.target.value;
                          if (value > 0) {
                            if (await checkPatternFontSize(value)) {
                              setFontSize(value);
                              setFontSizeError("");
                            }
                          } else {
                            setFontSize("");
                          }
                        }}
                      />
                      {fontSizeError && (
                        <p className="validate-error">{fontSizeError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Input Type</label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        isDisabled={inputTypeDisabled}
                        value={inputType}
                        options={inputTypeList}
                        onChange={(e) => {
                          setInputType(e);
                          setHasSubSectionDisabled(e?.label !== "Text");
                          setSubSection(
                            isSubSectionList?.find((i) => i.value === 0)
                          );
                          setInputTypeError("");
                          setDropdownTypeOptionListErr("");
                          setDropdownTypeOption();
                        }}
                        placeholder="Select Input Type"
                        onMenuOpen={() => handleKeyUpLoginDetails("inputType")}
                        onMenuClose={() => handleKeyUpLoginDetails("inputType")}
                      />
                      {inputTypeError && (
                        <p className="validate-error">{inputTypeError}</p>
                      )}
                    </div>
                  </div>

                  {inputType?.label == "Dropdown" ? (
                    <div className="col-md-4 aod-resp-tab">
                      <div className="suf-input-box aod-form-input mb-2">
                        <label className="mb-1 w-100">
                          {" "}
                          DropDown Option Type
                        </label>
                        <Select
                          styles={customStylesAcc}
                          theme={selectTheme}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="menu_open_select"
                          classNamePrefix="ad-menu_open_select_class"
                          aria-label="Default select example"
                          value={dropdownOptionList?.find(
                            (i) => i.value == dropdownTypeOption
                          )}
                          options={dropdownOptionList}
                          onChange={(selectedOption) => {
                            setDropdownTypeOption(selectedOption.value);
                            setDropdownTypeOptionListErr("");
                          }}
                          placeholder="Select DropDown Option"
                          onMenuOpen={() => handleKeyUpLoginDetails("dropdown")}
                          onMenuClose={() =>
                            handleKeyUpLoginDetails("dropdown")
                          }
                        />
                        {dropdownTypeOptionListErr && (
                          <p className="validate-error">
                            {dropdownTypeOptionListErr}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1">Input Required</label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={inputRequired}
                        options={requiredList}
                        onChange={(e) => {
                          setInputRequired(e);
                          setInputRequiredError("");
                        }}
                        placeholder="Select Input Required"
                        onMenuOpen={() => handleKeyUpLoginDetails("required")}
                        onMenuClose={() => handleKeyUpLoginDetails("required")}
                      />
                      {inputRequiredError && (
                        <p className="validate-error">{inputRequiredError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Has Sub Section ?</label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={subSection}
                        options={isSubSectionList}
                        isDisabled={hasSubSectionDisabled}
                        onChange={(e) => {
                          setSelectedSubSection("");
                          setInputTypeDisabled(e?.value === 1);
                          setInputType(
                            e?.value === 1
                              ? inputTypeList?.find((i) => i.label === "Text")
                              : inputType
                          );
                          setSubSection(e);
                          setSubSectionErr("");
                        }}
                        placeholder="Select"
                        onMenuOpen={() =>
                          handleKeyUpLoginDetails("Sub-Section")
                        }
                        onMenuClose={() =>
                          handleKeyUpLoginDetails("Sub-Section")
                        }
                      />
                      {subSectionErr && (
                        <p className="validate-error">{subSectionErr}</p>
                      )}
                    </div>
                  </div>
                  {subSection?.value == 1 ? (
                    <div className="col-md-4 aod-resp-tab">
                      <div className="suf-input-box aod-form-input mb-2">
                        <label className="mb-1 w-100">Sub-Section List</label>
                        <Select
                          styles={customStylesAcc}
                          theme={selectTheme}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="menu_open_select"
                          classNamePrefix="ad-menu_open_select_class"
                          aria-label="Default select example"
                          value={subSectionList?.find(
                            (i) => i.value == selectedSubSection
                          )}
                          options={subSectionList}
                          onChange={(selected) => {
                            setSelectedSubSection(selected.value);
                            setSelectedSubSectionErr("");
                          }}
                          placeholder="Select Sub-Section"
                          onMenuOpen={() =>
                            handleKeyUpLoginDetails("Sub-Section-list")
                          }
                          onMenuClose={() =>
                            handleKeyUpLoginDetails("Sub-Section-list")
                          }
                        />
                        {selectedSubSectionErr && (
                          <p className="validate-error">
                            {selectedSubSectionErr}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Validations</label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={showValidations}
                        onChange={handleCheckboxChange}
                        options={validationRequired}
                        placeholder="Select "
                      />
                      {validationError && (
                        <p className="validate-error">{validationError}</p>
                      )}
                    </div>
                  </div>
                  {showValidations && showValidations?.value == true && (
                    <div className="col-md-12 w-100 d-">
                      <div className="row">
                        <div className="row-head-ad d-none">
                          Prevent Data from Entering
                        </div>

                        {preventData &&
                          preventData?.map((item, index) => (
                            <div className="row ad-reg-res d-none">
                              <div className="ad-col-width-1 aod-resp-tab">
                                <div className="suf-input-box aod-form-input mb-2">
                                  <Select
                                    styles={customStylesAcc}
                                    theme={selectTheme}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    className="menu_open_select"
                                    classNamePrefix="ad-menu_open_select_class"
                                    aria-label="Default select example"
                                    value={item.type}
                                    onChange={(e) =>
                                      handleValidationChange(
                                        setPreventData,
                                        preventData,
                                        item.id,
                                        "type",
                                        e
                                      )
                                    }
                                    options={validationsType}
                                    placeholder="Select Type"
                                  />
                                </div>
                              </div>
                              {item?.type && (
                                <div className="ad-col-width-1 aod-resp-tab">
                                  <div className="suf-input-box aod-form-input mb-2">
                                    <Select
                                      styles={customStylesAcc}
                                      theme={selectTheme}
                                      components={{
                                        IndicatorSeparator: () => null,
                                      }}
                                      className="menu_open_select"
                                      classNamePrefix="ad-menu_open_select_class"
                                      aria-label="Default select example"
                                      value={item.unit}
                                      onChange={(e) =>
                                        handleValidationChange(
                                          setPreventData,
                                          preventData,
                                          item.id,
                                          "unit",
                                          e
                                        )
                                      }
                                      options={validationsList
                                        ?.filter(
                                          (i) =>
                                            i?.validation_type ==
                                            item?.type?.value
                                        )
                                        ?.map((i) => ({
                                          label: i?.name,
                                          value: i?.id,
                                        }))}
                                      placeholder="Select Validation"
                                    />
                                  </div>
                                </div>
                              )}
                              {item?.unit?.value ? (
                                <>
                                  {validationsList?.find(
                                    (i) => i?.id == item?.unit?.value
                                  )?.input_required == 1 ? (
                                    <div className="ad-col-width-1 aod-resp-tab">
                                      <div className="suf-input-box aod-form-input mb-2">
                                        <input
                                          type="number"
                                          className="w-100"
                                          placeholder="Enter value"
                                          value={item.value}
                                          onChange={(e) =>
                                            handleValidationChange(
                                              setPreventData,
                                              preventData,
                                              item.id,
                                              "value",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : validationsList?.find(
                                      (i) => i?.id == item?.unit?.value
                                    )?.input_required == 2 ? (
                                    <div className="ad-col-width-1 ad-reg-btn aod-resp-tab">
                                      <div className="suf-input-box aod-form-input mb-2">
                                        <input
                                          maxLength={60}
                                          type="number"
                                          className="w-50"
                                          placeholder="Lower limit"
                                          value={item?.numberLowerLimit}
                                          onChange={(e) =>
                                            handleValidationChange(
                                              setPreventData,
                                              preventData,
                                              item.id,
                                              "numberLowerLimit",
                                              e.target.value
                                            )
                                          }
                                        />
                                        <input
                                          maxLength={60}
                                          type="number"
                                          className="w-50"
                                          placeholder="Upper limit"
                                          value={item?.numberUpperLimit}
                                          onChange={(e) =>
                                            handleValidationChange(
                                              setPreventData,
                                              preventData,
                                              item.id,
                                              "numberUpperLimit",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : null}

                              {item?.unit?.value && (
                                <>
                                  <div className="ad-col-width-1 aod-resp-tab">
                                    <div className="suf-input-box aod-form-input mb-2">
                                      <input
                                        maxLength={60}
                                        type="text"
                                        className="w-100"
                                        placeholder="Message English"
                                        value={item.messageEnglish}
                                        onChange={(e) =>
                                          handleValidationChange(
                                            setPreventData,
                                            preventData,
                                            item.id,
                                            "messageEnglish",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="ad-col-width-1 aod-resp-tab">
                                    <div className="suf-input-box aod-form-input mb-2">
                                      <input
                                        maxLength={60}
                                        type="text"
                                        className="w-100"
                                        placeholder="Message Hindi"
                                        value={item.messageHindi}
                                        onChange={(e) =>
                                          handleValidationChange(
                                            setPreventData,
                                            preventData,
                                            item.id,
                                            "messageHindi",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="ad-col-width-btn ad-reg-btn">
                                {preventData?.length > 1 && (
                                  <span
                                    className="ad-reg-delete"
                                    onClick={() =>
                                      handleRemoveRow(
                                        setPreventData,
                                        preventData,
                                        item.id
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </span>
                                )}
                                {preventData.length == index + 1 &&
                                  preventData.length < 7 && (
                                    <span
                                      className="ad-reg-plus"
                                      onClick={() =>
                                        handleAddRow(
                                          setPreventData,
                                          preventData
                                        )
                                      }
                                    >
                                      +
                                    </span>
                                  )}
                              </div>
                            </div>
                          ))}

                        <div className="row-head-ad">Validations</div>
                        {otherValidations?.map((item, index) => (
                          <div className="row ad-reg-res">
                            <div className="ad-col-width-1 aod-resp-tab">
                              <div className="suf-input-box aod-form-input mb-2">
                                <Select
                                  styles={customStylesAcc}
                                  theme={selectTheme}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  className="menu_open_select"
                                  classNamePrefix="ad-menu_open_select_class"
                                  aria-label="Default select example"
                                  value={item.type}
                                  onChange={(e) =>
                                    handleValidationChange(
                                      setOtherValidations,
                                      otherValidations,
                                      item.id,
                                      "type",
                                      e
                                    )
                                  }
                                  options={validationsType}
                                  placeholder="Select Type"
                                />
                              </div>
                            </div>

                            {item?.type && (
                              <div className="ad-col-width-1 aod-resp-tab">
                                <div className="suf-input-box aod-form-input mb-2">
                                  <Select
                                    styles={customStylesAcc}
                                    theme={selectTheme}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    className="menu_open_select"
                                    classNamePrefix="ad-menu_open_select_class"
                                    aria-label="Default select example"
                                    value={item.unit}
                                    onChange={(e) =>
                                      handleValidationChange(
                                        setOtherValidations,
                                        otherValidations,
                                        item.id,
                                        "unit",
                                        e
                                      )
                                    }
                                    options={validationsList
                                      ?.filter(
                                        (i) =>
                                          i?.validation_type ==
                                          item?.type?.value
                                      )
                                      ?.map((i) => ({
                                        label: i?.name,
                                        value: i?.id,
                                      }))}
                                    placeholder="Select Validation"
                                  />
                                </div>
                              </div>
                            )}
                            {item?.unit?.value ? (
                              <>
                                {validationsList?.find(
                                  (i) => i?.id == item?.unit?.value
                                )?.input_required == 1 ? (
                                  <div className="ad-col-width-1 aod-resp-tab">
                                    <div className="suf-input-box aod-form-input mb-2">
                                      <input
                                        maxLength={60}
                                        type="number"
                                        className="w-100"
                                        placeholder="Enter value"
                                        value={item.value}
                                        onChange={(e) =>
                                          handleValidationChange(
                                            setOtherValidations,
                                            otherValidations,
                                            item.id,
                                            "value",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : validationsList?.find(
                                    (i) => i?.id == item?.unit?.value
                                  )?.input_required == 2 ? (
                                  <div className="ad-col-width-1 ad-reg-btn aod-resp-tab">
                                    <div className="suf-input-box aod-form-input mb-2">
                                      <input
                                        maxLength={60}
                                        type="number"
                                        className="w-50"
                                        placeholder="Lower limit"
                                        value={item?.numberLowerLimit}
                                        onChange={(e) =>
                                          handleValidationChange(
                                            setOtherValidations,
                                            otherValidations,
                                            item.id,
                                            "numberLowerLimit",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <input
                                        maxLength={60}
                                        type="number"
                                        className="w-50"
                                        placeholder="Upper limit"
                                        value={item?.numberUpperLimit}
                                        onChange={(e) =>
                                          handleValidationChange(
                                            setOtherValidations,
                                            otherValidations,
                                            item.id,
                                            "numberUpperLimit",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : null}

                            {item.unit?.value && (
                              <>
                                <div className="ad-col-width-1 aod-resp-tab">
                                  <div className="suf-input-box aod-form-input mb-2">
                                    <input
                                      maxLength={60}
                                      type="text"
                                      className="w-100"
                                      placeholder="Message English"
                                      value={item.messageEnglish}
                                      onChange={(e) =>
                                        handleValidationChange(
                                          setOtherValidations,
                                          otherValidations,
                                          item.id,
                                          "messageEnglish",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="ad-col-width-1 aod-resp-tab">
                                  <div className="suf-input-box aod-form-input mb-2">
                                    <input
                                      maxLength={60}
                                      type="text"
                                      className="w-100"
                                      placeholder="Message Hindi"
                                      value={item.messageHindi}
                                      onChange={(e) =>
                                        handleValidationChange(
                                          setOtherValidations,
                                          otherValidations,
                                          item.id,
                                          "messageHindi",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="ad-col-width-btn ad-reg-btn">
                              {otherValidations.length > 0 && (
                                <span
                                  className="ad-reg-delete"
                                  onClick={() =>
                                    handleRemoveRow(
                                      setOtherValidations,
                                      otherValidations,
                                      item.id
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </span>
                              )}
                              {otherValidations.length == index + 1 &&
                                otherValidations.length < 5 && (
                                  <span
                                    className="ad-reg-plus"
                                    onClick={() =>
                                      handleAddRow(
                                        setOtherValidations,
                                        otherValidations
                                      )
                                    }
                                  >
                                    +
                                  </span>
                                )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row ad-cat-row">
                  <div className=" update-btns-block">
                    {editData?.id ? (
                      <>
                        {/* <button
                          onClick={() => handleEmptyData()}
                          className="suf-can-butt w-auto px-4"
                        >
                          Cancel
                        </button> */}
                        <button onClick={() => updateFormField()} className="">
                          Update
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() => {
                          addFormField();
                        }}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
              <div>
                <HomeIcon />
                <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
                <span> Form Field</span>
              </div>
              {grantPermissionForAddFormFields ? (
                <button
                  className="adm-tab-btn"
                  onClick={() => {
                    setHasSubSectionDisabled(false)
                    setEditContainer(true)
                  }}
                >
                  <span>
                    <HiPlus />
                  </span>{" "}
                  Add
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="aod-inner pt-0">
              <div className="aod-bottom">
                <div className="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                  <h4 className="mb-0 w-100">Form Fields</h4>
                  <div className="position-relative w-25">
                    <input
                      className="px-2 form-control"
                      placeholder="Search"
                      type="text"
                      value={searchTerm} // Add a state for the input value
                      onChange={(e) => {
                        setSearchTerm(e.target.value); // Update the state
                        getFormFieldData(
                          searchSubCategoryId,
                          searchFormTabId,
                          searchSectionId,
                          sortBy,
                          1,
                          orderBy,
                          e.target.value.trim()
                        );
                      }}
                    />
                    {searchTerm && ( // Show the clear button only when there's text
                      <button
                        className="btn btn-clear position-absolute"
                        onClick={(e) => {
                          setSearchTerm(""); // Clear the input value
                          getFormFieldData(
                            searchSubCategoryId,
                            searchFormTabId,
                            searchSectionId,
                            sortBy,
                            1,
                            orderBy,
                            e.target.value.trim()
                          ); // Fetch data with empty search term
                        }}
                        style={{
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          border: "none",
                          background: "transparent",
                          fontSize: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        &times; {/* This is the "X" icon */}
                      </button>
                    )}
                  </div>
                </div>
                <div className="aod-head"></div>
                <div className="row py-4">
                  <div className="col-md-3">
                    <div className="suf-input-box aod-form-input mb-2">
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={searchSubCategoryId}
                        options={subCategoryList}
                        onChange={(e) => {
                          setSearchSubCategoryId(e);
                          setSearchFormTabId("");
                          setSearchSectionId("");
                          getFormFieldData(
                            e,
                            "",
                            "",
                            sortBy,
                            1,
                            orderBy,
                            searchData
                          );
                        }}
                        placeholder="Select Sub Category"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="suf-input-box aod-form-input mb-2">
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={searchFormTabId}
                        options={searchFormTabList}
                        onChange={(e) => {
                          setSearchFormTabId(e);
                          setSearchSectionId("");
                          getFormFieldData(
                            searchSubCategoryId,
                            e,
                            "",
                            sortBy,
                            1,
                            orderBy,
                            searchData
                          );
                        }}
                        placeholder="Select Form Tab"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="suf-input-box aod-form-input mb-2">
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={searchSectionId}
                        options={searchSectionList}
                        onChange={(e) => {
                          setSearchSectionId(e);
                          getFormFieldData(
                            searchSubCategoryId,
                            searchFormTabId,
                            e,
                            sortBy,
                            1,
                            orderBy,
                            searchData
                          );
                        }}
                        placeholder="Select Section"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <button
                      onClick={resetFilter}
                      className="ad-reset-btn w-auto px-4"
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <div className="ad-reg-table">
                  <Table size="sm" className="ad-cat-table ">
                    <thead>
                      <tr>
                        <th className="ad-sno">S. No</th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getFormFieldData(
                              searchSubCategoryId,
                              searchFormTabId,
                              searchSectionId,
                              "name_en",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          English Name
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getFormFieldData(
                              searchSubCategoryId,
                              searchFormTabId,
                              searchSectionId,
                              "name_hi",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Hindi Name
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getFormFieldData(
                              searchSubCategoryId,
                              searchFormTabId,
                              searchSectionId,
                              "placeholder_en",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          English PlaceHolder{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getFormFieldData(
                              searchSubCategoryId,
                              searchFormTabId,
                              searchSectionId,
                              "placeholder_hi",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Hindi PlaceHolder{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getFormFieldData(
                              searchSubCategoryId,
                              searchFormTabId,
                              searchSectionId,
                              "sub_category_name_en",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Sub Category{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getFormFieldData(
                              searchSubCategoryId,
                              searchFormTabId,
                              searchSectionId,
                              "tabs_name_en",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          From Tab
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getFormFieldData(
                              searchSubCategoryId,
                              searchFormTabId,
                              searchSectionId,
                              "section_name_en",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          From Section
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getFormFieldData(
                              searchSubCategoryId,
                              searchFormTabId,
                              searchSectionId,
                              "created_at",
                              currentPage + 1,
                              orderBy == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Created At{" "}
                          <span>
                            <i className="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        {grantPermissionForEditFormFields ? (
                          <>
                            <th className="ad-long">Status</th>
                            <th className="ad-long">Action</th>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {formFields?.length > 0 ? (
                        formFields?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{currentPage * 10 + (index + 1)}</td>
                              <td>
                                {" "}
                                {item?.name_en
                                  ? item.name_en.length > 15
                                    ? item.name_en.substring(0, 15) + "..."
                                    : item.name_en
                                  : "--"}
                              </td>
                              <td>
                                {item?.name_en
                                  ? item.name_hi.length > 15
                                    ? item.name_hi.substring(0, 15) + "..."
                                    : item.name_hi
                                  : "--"}
                              </td>
                              <td>
                                {item?.placeholder_en
                                  ? item.placeholder_en.length > 15
                                    ? item.placeholder_en.substring(0, 15) +
                                      "..."
                                    : item.placeholder_en
                                  : "--"}
                              </td>
                              <td>
                                {item?.placeholder_hi
                                  ? item.placeholder_hi.length > 15
                                    ? item.placeholder_hi.substring(0, 15) +
                                      "..."
                                    : item.placeholder_hi
                                  : "--"}
                              </td>
                              <td>
                                {item?.sub_category_name_en
                                  ? item?.sub_category_name_en
                                  : item?.sub_category_name_hi
                                  ? item?.sub_category_name_hi
                                  : "--"}
                              </td>
                              <td>
                                {item?.tabs_name_en
                                  ? item?.tabs_name_en
                                  : item?.tabs_name_hi
                                  ? item?.tabs_name_hi
                                  : "--"}
                              </td>
                              <td>
                                {item?.section_name_en
                                  ? item?.section_name_en
                                  : item?.section_name_hi
                                  ? item?.section_name_hi
                                  : "--"}
                              </td>
                              <td>
                                {item?.created_at
                                  ? formatDateWithSlash(item?.created_at)
                                  : "--"}
                              </td>
                              {grantPermissionForEditFormFields ? (
                                <>
                                  <td>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={item?.status == 1}
                                        onChange={(e) =>
                                          handleEnableDisable(
                                            e.target.checked
                                              ? { value: "1" }
                                              : { value: "0" },
                                            item
                                          )
                                        }
                                      />
                                      <span className="slider"></span>
                                    </label>
                                  </td>
                                  {/* <td><div className='action-btn d-flex'>
                                                                <span className='ad-cat-edit' onClick={() => { editFormField(item); setEditContainer(true) }}><EditIcon /></span></div></td> */}
                                  <td>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`edit-tooltip-${item.id}`}>
                                          Edit
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="ad-cat-edit"
                                        onClick={() => {
                                          editFormField(item);
                                          setEditContainer(true);
                                        }}
                                      >
                                        <EditIcon />
                                      </span>
                                    </OverlayTrigger>
                                  </td>
                                </>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })
                      ) : tableDataLoaded ? (
                        <NotFoundTable colSpan="8"></NotFoundTable>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                  {totalPages > 1 && formFields?.length > 0 && (
                    <div className="d-flex w-100 justify-content-end">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={totalPages} // Total number of pages from API
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageChange} // Method to handle page click
                        forcePage={currentPage}
                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={`page-item${
                          !formFields?.length > 0 ? " disabled" : ""
                        }`}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this Field ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2">
                        <button onClick={() => { deleteFormField(deleteData) }}>Yes</button>
                        <button className='suf-can-butt' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal> */}
    </AdminDashboard>
  );
};

export default FormFields;
