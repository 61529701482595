import React, { useEffect, useRef, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import "../../../../src/pages/Admin/ApplicationView/ApplicationView.css";
import Applicationicon from "../../../../src/assets/images/application-icon.png";
import RoomIcon from "../../../../src/assets/images/room-details.png";
import PartnerIcon from "../../../../src/assets/images/partners.png";
import LocationIcon from "../../../../src/assets/images/loaction.png";
import AccommodationIcon from "../../../../src/assets/images/accommodation-details.png";
import AdditionalIcon from "../../../../src/assets/images/additional-details.png";
import UploadIcon from "../../../../src/assets/images/upload-documents.png";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Baseurl,
  GetLocalStorage,
  RemoveLocalStorage,
  validateonSubmitHandle,
} from "../../../Utils/Utils";
import { BsChevronLeft } from "react-icons/bs";
import Select from "react-select";
import {
  customStylesAcc,
  formatDateTime,
  formatDateWithdash,
  selectTheme,
} from "../../../Utils/common";
import { stateDistrict } from "../../../services/api/auth";
import {
  applicationStatusChange,
  createApplicationForm,
  getApplicationForm,
  getTabs,
  sendNotificationData,
  toUploadFileAdmin,
  uploadDocumentlistData,
} from "../../../services/api/user";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import {
  applicationCertificatelistData,
  applicationLogData,
  approvedCategoryList,
  certifiateGenerate,
  uploadedDocumentDelete,
  uploadedDocumentlistData,
} from "../../../services/api/admin";
import Geolocation from "../../../components/inputType/geoloaction";
import { Message } from "../../../components/message";
import InputTableData from "../../../components/ApplicationDraft/InputTableData";
import ApplicationDataView from "./ApplicationDataView";
import { EditIcon } from "../AdminIcon";
import { Upload } from "@mui/icons-material";
import DesignationTable from "../../../components/ApplicationDraft/DesignationTable";
import DesignationTableView from "./DesignationTableView";
import { UploadIconPhoto } from "../../../components/Application/ApplicationIcon";
import GeoLocationAddress from "../../../components/ApplicationDraft/GeoLocationAddress";
import GeoLocationView from "./GeoLocationView";

const ApplicationView = () => {
  const MySwal = withReactContent(Swal);
  const [sectionFields, setSectionFields] = useState({});
  const [subSectionFields, setsubSectionFields] = useState({});
  const [invalidSubFieldErr, setInvalidSubFieldErr] = useState([]);
  const [fileErrors, setFileErrors] = useState({});
  const [statusChanged, setStatusChanged] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [baseUrl, setBaseUrl] = useState([]);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const token = GetLocalStorage("userInfo");
  const [mainPageApplicationData, setMainPageApplicationData] = useState();
  const [pageFrom, setPageFrom] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [tabsData, setTabsData] = useState();
  const [tabsWholeData, setTabsWholeData] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [pendingDocumentList, setPendingDocumentList] = useState([]);
  const [certificateList, setCertificateList] = useState({});
  const [applicationLog, setApplicationLog] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [inspectionDoc, setInspectionDoc] = useState(null);
  const [inspectionRemark, setInspectionRemark] = useState(null);
  const [inspectionDate, setInspectionDate] = useState(null);
  const [statusId, setStatusId] = useState();
  const [remark, setRemark] = useState("");
  const [error, setError] = useState({});
  const [applicantDataError, setApplicantDataError] = useState({});
  const [accomadationUnitFacility, setAccomadationUnitFacility] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDocIds, setSelectedDocIds] = useState([]);
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [districtId, setDistrictId] = useState("");
  const [invalidFields, setInvalidFields] = useState([]);
  const [isDesignationData, setIsDesignationData] = useState();
  const [fileName, setFileName] = useState("");
  const [editButtonShow, setEditButtonShow] = useState(true)
  const [geoLocationAddress, setGeoLocationAddress] = useState('');

  const applicationStatusList = [
    { value: "2", label: "Required Document Pending" },
    { value: "4", label: "Approved" },
    { value: "5", label: "Rejected" },
    { value: "7", label: "Under Review" },
    { value: "10", label: "Draft" }
  ];
  const fileTypeList = [
    { label: "Docx", value: "1", accept: ".docx" },
    { label: "PDF", value: "2", accept: ".pdf" },
    { label: "Xlsx", value: "3", accept: ".xlsx" },
    { label: "Pptx", value: "4", accept: ".pptx" },
    { label: "Txt", value: "5", accept: ".txt" },
    { label: "Csv", value: "6", accept: ".csv" },
    { label: "Jpg/Jpeg", value: "7", accept: ".jpg,.jpeg" },
    { label: "Png", value: "8", accept: ".png" },
  ];
  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      if (location?.state) {
        setBaseUrl(Baseurl);
        getTabsBySubCategory(location?.state?.data);
        setMainPageApplicationData(location?.state?.data);
        getApplicationData(location?.state?.data);
        docsList(location?.state?.data);
        certList(location?.state?.data);
        getLogData(location?.state?.data);
        getDistrictList();
        approvedCategory();
        switch (location?.state?.pageFrom) {
          case "totalApplication":
            setPageFrom("total-applications");
            break;
          case "pendingApplication":
            setPageFrom("pending-applications");
            break;
          case "rejectedApplication":
            setPageFrom("rejected-applications");
            break;
          case "approvedApplication":
            setPageFrom("approved-applications");
            break;
          case "renewApplication":
            setPageFrom("renewal-applications");
            break;
        }
      }
    }
  }, [location?.state, showEditIcon]);

  const approvedCategory = async () => {
    try {
      const categoryListData = await approvedCategoryList();
      const data = categoryListData?.data?.map((list) => ({
        label: list?.name_en,
        value: list?.id,
      }));
      setCategoryList(data);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const getDistrictList = async () => {
    try {
      const districtData = await stateDistrict();
      const data = districtData?.data?.data?.map((district) => ({
        label: district?.name_en,
        value: district?.id,
      }));
      setDistrictList(data);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const docsList = async (data) => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", data?.category_id);
      formData.append("application_id", data?.id);
      formData.append("user_id", data?.user_id);

      const getList = await uploadedDocumentlistData(formData);
      if (getList?.status === 200) {
        const uploadedDocsObjlist = getList?.data?.document_list?.map(
          (element) => {
            const filterUploadedDoc = getList?.data?.upload_document?.filter(
              (x) => x?.document_type_id === element?.id
            );
            return {
              ...element,
              upload_docs: filterUploadedDoc,
            };
          }
        );
        setPendingDocumentList(uploadedDocsObjlist);
        const filterData = uploadedDocsObjlist?.filter(
          (x) => x?.upload_docs?.length > 0
        );
        setDocumentList(filterData);
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const certList = async (data) => {
    try {
      const formData = new FormData();
      formData.append("application_id", data?.id);
      formData.append("user_id", data?.user_id);
      const list = await applicationCertificatelistData(formData);
      setCertificateList(list?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getLogData = async (data) => {
    try {
      const formData = new FormData();
      formData.append("application_id", data?.id);
      formData.append("user_id", data?.user_id);
      const appLogData = await applicationLogData(formData);
      if (appLogData?.status == 200) {
        setApplicationLog(appLogData?.data);
        const latestLog = appLogData?.data[appLogData?.data?.length - 1]
        const noRemarkStatus = ['3', '8', '6']
        if (!noRemarkStatus.includes(latestLog?.status)) {
          setRemark(latestLog?.remarks)
          setStatusId(applicationStatusList?.find((option) => option.value == latestLog?.status)?.value)
          setSelectedDocIds(typeof latestLog?.document_type_id == "string" && latestLog?.document_type_id?.length > 0 && latestLog?.status == '2' ? latestLog.document_type_id.split(",")?.map(Number) : []);
        }
      } else {
        setApplicationLog([]);
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const getApplicationData = async (data) => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", data?.category_id);
      formData.append("application_detail_id", data?.id);
      const applicationFormResponse = await getApplicationForm(formData);
      if (
        applicationFormResponse.status === "200" ||
        applicationFormResponse.status === 200
      ) {
        setTabsWholeData((prevdata) => ({
          ...prevdata,
          ...applicationFormResponse?.data,
        }));
        const geolocation = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("geo_location_address")
        );
        geolocation?.length > 0 ? setGeoLocationAddress(geolocation[1]) : setGeoLocationAddress('')
        const designationEntry = Object.entries(
          applicationFormResponse?.data || {}
        ).find(([key]) => key.startsWith("f_designation"));
        setIsDesignationData(designationEntry);
      } else {
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const getTabsBySubCategory = async (data) => {
    try {
      if (data?.category_id) {
        const payload = {
          category_id: data?.category_id,
        };
        const response = await getTabs(payload);
        if (response?.status == 200) {
          const filteredTabs = response?.data.filter(
            (i) => i?.tableName !== null
          );
          setTabsData(filteredTabs);
        }
      }
    } catch (error) {
      console.error("Error fetching tabs:", error);
    }
  };

  const submitStatus = async (e) => {
    // console.log(selectedIds, selectedDocIds)
    // return;
    e.preventDefault();
    let isValid = true;
    if (!statusId) {
      setError((prev) => ({
        ...prev,
        status: "Please select the status",
      }));
      isValid = false;
    }
    if (selectedDocIds?.length == 0 && statusId == "2") {
      setError((prev) => ({
        ...prev,
        checkBoxList: "Please select document",
      }));
      isValid = false;
    }

    if (!remark?.trim()) {
      setError((prev) => ({
        ...prev,
        remark: "Please enter the remark",
      }));
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("application_id", mainPageApplicationData?.id);
      formData.append("status", statusId);
      formData.append("remarks", remark);
      if (statusId == "4") {
        formData.append("inspection_date", inspectionDate);
        formData.append("inspection_doc", inspectionDoc);
        formData.append("inspection_remarks", inspectionRemark);
        formData.append("certificate_type", selectedCategory);
      }
      if (statusId == "2") {
        formData.append("doc_id", selectedIds);
        formData.append("document_type_id", selectedDocIds)
      }
      const statusChange = await applicationStatusChange(formData);
      if (statusChange?.status == 200) {
        if (statusId == "4") {
          try {
            const payload = new FormData()
            payload.append("application_id", mainPageApplicationData?.id);
            const certGen = await certifiateGenerate(payload)
            try {
              const notifcation = new FormData()
              notifcation.append("user_id", mainPageApplicationData?.users_list?.id);
              notifcation.append('district_id', mainPageApplicationData?.district_id)
              notifcation.append("remark_en", "Certificate Generated");
              notifcation.append("remark_hi", 'प्रमाणपत्र जनरेट किया गया');
              notifcation.append("user_type", 1);
              const notificationSend = await sendNotificationData(notifcation)
            } catch (err) {
              console.error("Error submitting the form:", err);
            }
          } catch (err) {
            setApiMessage({ type: "error", message: err?.message });
          }
        }
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    } finally {
      if (statusId == "2") {
        setStatusChanged(true);
      }
      MySwal.fire({
        title: "Success",
        text: "Application status changed successfully",
        icon: "success",
        showConfirmButton: true,
        confirmButtonText: "OK",
        // preConfirm: () => onHide(),
      });
      certList(mainPageApplicationData)
      getLogData(mainPageApplicationData);
      docsList(mainPageApplicationData);
      getApplicationData(mainPageApplicationData);
      try {
        const notifcation = new FormData()
        notifcation.append("user_id", mainPageApplicationData?.users_list?.id);
        notifcation.append("remark_en", "Application Status Updated");
        notifcation.append("remark_hi", 'आवेदन की स्थिति अपडेट की गई');
        notifcation.append("user_type", 1);
        notifcation.append('district_id', mainPageApplicationData?.district_id)
        const notificationSend = await sendNotificationData(notifcation)
      } catch (err) {
        console.error("Error submitting the form:", err);
      }
    }
  };

  const handleCheckboxChange = (item, isChecked) => {
    const errors = { ...error };
    errors.checkBoxList = "";
    setError(errors);
    if (item?.upload_docs[0]?.id != undefined) {
      setSelectedIds((prev) =>
        isChecked
          ? [...prev, item?.upload_docs[0]?.id]
          : prev.filter((x) => x != item)
      );
    }
    setSelectedDocIds((prev) =>
      isChecked
        ? [...prev, item?.id]
        : prev.filter((x) => x != item)
    );
    if (!isChecked && item?.upload_docs[0]?.id != undefined) {
      let index = selectedIds.indexOf(item?.upload_docs[0]?.id);
      if (index != -1) {
        selectedIds.splice(index, 1);
      }
    }
    if (!isChecked) {
      let docIndex = selectedDocIds.indexOf(item?.id);
      if (docIndex != -1) {
        selectedDocIds.splice(docIndex, 1);
      }
    }
    // console.log(item)
  };

  const handleFileChange = (doc, index) => async (e) => {
    const uploadedFile = e?.target.files?.[0];
    const fileType = fileTypeList.find((x) => x.label === doc?.type);
    console.log("=-====================>>>>>>>>>>>>>>>>>>", doc.upload_docs[0]?.id, uploadedFile)
    // return;
    // Validate file type
    if (
      fileType &&
      fileType.accept &&
      !fileType.accept.includes(uploadedFile.type.split("/")[1])
    ) {
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [doc.id]: `Invalid file type. Only ${doc?.type} files are allowed.`,
      }));
      return;
    }

    const maxSizeInBytes = parseInt(doc?.size) * 1024;
    if (isNaN(maxSizeInBytes)) {
      return;
    }

    if (uploadedFile.size == 0) {
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [doc.id]: `Invalid file size. Only 0 Kb files are allowed.`,
      }));
      return;
    }

    if (uploadedFile.size > maxSizeInBytes) {
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [doc.id]: `Invalid file size. Only ${doc?.size / 1000
          } MB files are allowed.`,
      }));
      return;
    }

    try {
      const formData = new FormData();
      formData.append("upload_doc", uploadedFile);
      formData.append("upload_date", formatDateWithdash(new Date()));
      formData.append("category_id", mainPageApplicationData?.category_id);
      formData.append("document_type_id", doc?.id);
      formData.append("user_id", mainPageApplicationData?.user_id);
      formData.append("application_id", mainPageApplicationData?.id);
      formData.append("upload_document_id", doc.upload_docs[0]?.id);
      const data = await toUploadFileAdmin(formData);
      if (data) {
        setFileNames((prevFileNames) => {
          const updatedFileNames = [...prevFileNames];
          updatedFileNames[index] = uploadedFile ? uploadedFile.name : "No file chosen";
          return updatedFileNames;
        });
        docsList(mainPageApplicationData);
        setFileName(uploadedFile.name);
        setFileErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[doc.id];
          return updatedErrors;
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSubmit = async () => {
    const validate = validateFunction();

    if (!validate) {
      setShowEditIcon(false);
      return;
    }
    try {
      // Filter and prepare section data
      const sectionData = Object.entries(tabsWholeData || {}).reduce(
        (acc, [key, value]) => {
          if (key.startsWith("f_") && !key.endsWith("json") && value) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      sectionData['geo_location_address'] = geoLocationAddress
      const updatedTabsWholeData = { ...sectionData };
      const designationKey = Object.keys(updatedTabsWholeData).find((key) =>
        key.startsWith("f_designation")
      );
      if (designationKey) {
        updatedTabsWholeData[designationKey] = GetLocalStorage("empDetails");
      }
      // Filter and prepare subsection data
      const subsectionData = Object.entries(tabsWholeData || {}).reduce(
        (acc, [key, value]) => {
          if (key.endsWith("json") && value) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      // Prepare the payload
      const payload = {
        sub_category_id: mainPageApplicationData?.category_id,
        user_id: mainPageApplicationData?.users_list?.id,
        application_id: mainPageApplicationData?.id,
        section_fields: updatedTabsWholeData, // Section data as an object
        sub_section_fields: subsectionData, // Subsection data as an object
      };

      console.log("Payload:", payload);
      const sectionResponse = await createApplicationForm(payload); // Send as JSON
      if (sectionResponse.status == 200) {
        setShowEditIcon(true);
        getApplicationData(mainPageApplicationData);
        setInvalidFields([]);
        RemoveLocalStorage("empDetails");
        try {
          const notifcation = new FormData()
          notifcation.append("user_id", mainPageApplicationData?.users_list?.id);
          notifcation.append("remark_en", "Application Data Updated");
          notifcation.append("remark_hi", 'एप्लिकेशन डेटा अपडेट किया गया');
          notifcation.append("user_type", 2);
          const notificationSend = await sendNotificationData(notifcation)
        } catch (err) {
          console.error("Error submitting the form:", err);
        }
      } else {
        console.error("Failed to submit the form", sectionResponse);
      }
    } catch (err) {
      console.error("Error submitting the form:", err);
    }
  };

  const validateFunction = () => {
    const invalidSubFields = [];
    const invalidFieldsArray = [];
    console.log(tabsWholeData);
    const sectionFieldsForTab = sectionFields[tabsData.id] || [];
    sectionFieldsForTab.forEach((item) => {
      const fieldKey = `f_${item.field_key}`;
      const required = item?.required === "1";
      const value = tabsWholeData[fieldKey];

      if (required && (!value || value == "" || value == "--")) {
        invalidFieldsArray.push({
          fieldKey,
          message: `${item.name_en} is required`, // Customize error message
        });
      } else {
        if (
          JSON.parse(JSON.parse(item?.validations).validation)?.onKeyUp &&
          JSON.parse(JSON.parse(item?.validations).validation)?.onKeyUp
            ?.length > 0
        ) {
          const validations = validateonSubmitHandle(item, value, null);
          if (validations != "") {
            invalidFieldsArray.push({
              fieldKey,
              message: validations?.en, // Customize error message
            });
          }
        }
      }
    });

    // Validate subsection fields
    const subSectionFieldsForTab = subSectionFields[tabsData.id] || [];
    subSectionFieldsForTab.forEach((item) => {
      const fieldKey = `f_${item.field_key}`;
      const tableDataKey = `${fieldKey}_json`;
      const tableData = tabsWholeData[tableDataKey] || [];

      tableData.forEach((row, rowIndex) => {
        if (!invalidSubFields[rowIndex]) {
          invalidSubFields[rowIndex] = {};
        }
        Object.entries(row).forEach(([fieldName, fieldValue]) => {
          if (!fieldValue || fieldValue === "--") {
            invalidSubFields[rowIndex][fieldName] = `${fieldName} is required`;
          }
        });
      });
    });

    // Filter out empty objects from invalidSubFields
    const filteredInvalidSubFields = invalidSubFields.filter(
      (subField) => Object.keys(subField).length > 0
    );

    setInvalidSubFieldErr(invalidSubFields);
    const allInvalidFields = [
      ...invalidFieldsArray,
      ...filteredInvalidSubFields,
    ];
    setInvalidFields(allInvalidFields);
    return allInvalidFields.length == 0;
  };

  const handleDocumnetUpload = (e) => {
    const file = e?.target?.files?.[0];
    const erorrs = { ...error };
    if (file) {
      setInspectionDoc(file);
      erorrs.docment = "";
    } else {
      erorrs.docment = "please Upload the Document";
    }
    setError(erorrs);
  };

  const handleTabClick = (event) => {
    const clickedTabId = event.target.closest("button").id;
    if (clickedTabId == 'nav-home-tab') {
      setEditButtonShow(true)
    } else {
      setEditButtonShow(false)
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Application</span>
          </div>
          <div>
            {editButtonShow ?
              showEditIcon ? (
                <button
                  className="adm-tab-btn"
                  onClick={() => setShowEditIcon(false)}
                >
                  <span>
                    <EditIcon />
                  </span>{" "}
                  Edit Details
                </button>
              ) : (
                <button
                  className="adm-tab-btn"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <span></span> Update
                </button>
              ) : null
            }
          </div>
        </div>
        <div className=" text-center adm-head mb-5">
          <button
            onClick={() => {
              if (showEditIcon) {
                navigate(`/admin/${pageFrom}`);
              } else {
                setApplicantDataError([]);
                setFileErrors([]);
                setShowEditIcon(true);
                getApplicationData(mainPageApplicationData);
              }
            }}
          >
            <BsChevronLeft /> Back
          </button>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card mb-3 mt-3 border-0 custom_tabs_wrapper">
              {showEditIcon && (
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={(e) => handleTabClick(e)}
                    >
                      <div className="custom_card_header d-flex p-0 m-0 border-0">
                        <img src={Applicationicon} alt="" srcSet="" />{" "}
                        <h6 className="fw-bolder mb-0">All</h6>
                      </div>
                    </button>
                    <button
                      className="nav-link"
                      id="nav-applicationStatus-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-applicationStatus"
                      type="button"
                      role="tab"
                      aria-controls="nav-applicationStatus"
                      aria-selected="false"
                      onClick={(e) => handleTabClick(e)}
                    >
                      <div className="custom_card_header d-flex p-0 m-0 border-0">
                        <img src={PartnerIcon} alt="" srcSet="" />{" "}
                        <h6 className="fw-bolder mb-0">Application log</h6>
                      </div>
                    </button>
                    <button
                      className="nav-link"
                      id="nav-certificateList-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-certificateList"
                      type="button"
                      role="tab"
                      aria-controls="nav-certificateList"
                      aria-selected="false"
                      onClick={(e) => handleTabClick(e)}
                    >
                      <div className="custom_card_header d-flex p-0 m-0 border-0">
                        <img src={PartnerIcon} alt="" srcSet="" />{" "}
                        <h6 className="fw-bolder mb-0">Certificates</h6>
                      </div>
                    </button>
                  </div>
                </nav>

              )}
              <div className="tab-content p-4" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="row row gx-3 row-gap-3">
                    <div className="col-md-12">
                      <div className="card h-100  custom_card ">
                        <div className="custom_card_header d-flex">
                          {/* <img
                            src="https://utdbhomestayapi.dilonline.in/assets/backend/img/Applicant_Detail.png"
                            alt=""
                            srcSet=""
                          /> */}
                          <h6 className="fw-bolder ms-2">Applicant Details</h6>
                        </div>
                        <div className="card-body">
                          <div className="row gx-2 row-gap-2">
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Tracking Number:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>
                                  {mainPageApplicationData?.tracking_number ??
                                    "--"}
                                </span>
                              </div>
                            </div>

                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Customer ID:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>
                                  {mainPageApplicationData?.customer_id ?? "--"}
                                </span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Application number:</strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>
                                  {mainPageApplicationData?.application_number ??
                                    "--"}
                                </span>
                              </div>
                            </div>
                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Applied date: </strong>
                              </div>
                              <div className="card_feild_main_itm">
                                <span>
                                  {formatDateTime(
                                    mainPageApplicationData?.created_at
                                  ) ?? "--"}
                                </span>
                              </div>
                            </div>

                            <div className="card_feild_main col-md-6">
                              <div className="card_feild_main_itm">
                                <strong>Applied For District:</strong>
                              </div>

                              <div className="card_feild_main_itm">
                                {showEditIcon ? (
                                  <>
                                    {mainPageApplicationData?.district_id
                                      ? districtList?.find(
                                        (option) =>
                                          option.value ==
                                          mainPageApplicationData?.district_id
                                      )?.label
                                      : "--"}
                                  </>
                                ) : (
                                  <>
                                    {mainPageApplicationData?.district_id
                                      ? districtList?.find(
                                        (option) =>
                                          option.value ==
                                          mainPageApplicationData?.district_id
                                      )?.label
                                      : "--"}
                                    {/* <div className="col-md-8 aod-resp-tab">
                                      <div className="suf-input-box aod-form-input mb-2">
                                        <Select
                                          styles={customStylesAcc}
                                          theme={selectTheme}
                                          components={{
                                            IndicatorSeparator: () => null,
                                          }}
                                          className="my-select selectpicker"
                                          classNamePrefix="ad-menu_open_select_class"
                                          aria-label="Default select example"
                                          name="role"
                                          value={
                                            districtList?.find(
                                              (option) =>
                                                option.value ==
                                                mainPageApplicationData?.district_id
                                            ) || null
                                          }
                                          options={districtList}
                                          onBlur={(e) => {
                                            const applicantDetailsErr = {
                                              ...applicantDataError,
                                            };
                                            if (!districtId) {
                                              applicantDetailsErr.district =
                                                "Please select the district";
                                            } else {
                                              applicantDetailsErr.district = "";
                                            }
                                            setApplicantDataError(
                                              applicantDetailsErr
                                            );
                                          }}
                                          onChange={(selectedOption) => {
                                            setDistrictId(selectedOption.value);
                                            setMainPageApplicationData(
                                              (prev) => ({
                                                ...prev,
                                                district_id:
                                                  selectedOption.value, // Correctly update 'district_id'
                                              })
                                            );
                                            applicantDataError.district = "";
                                          }} // Use onChange for real-time validation
                                          placeholder="Select District"
                                        />
                                        {applicantDataError.district && (
                                          <p className="validate-error">
                                            {applicantDataError.district}
                                          </p>
                                        )}
                                      </div>
                                    </div> */}
                                  </>
                                )}
                              </div>
                            </div>
                            {mainPageApplicationData?.users_list
                              ?.address &&
                              <div className="card_feild_main col-md-6">
                                <div className="card_feild_main_itm">
                                  <strong>Address</strong>
                                </div>
                                <div className="card_feild_main_itm">
                                  {showEditIcon ? (
                                    <span>
                                      {mainPageApplicationData?.users_list
                                        ?.address ?? "--"}
                                    </span>
                                  ) : (
                                    <span>
                                      {mainPageApplicationData?.users_list
                                        ?.address ?? "--"}
                                    </span>
                                    // <span>
                                    //   <div className="col-md-8 aod-resp-tab">
                                    //     <div className="suf-input-box aod-form-input mb-2">
                                    //       <input
                                    //         className="w-100 form-control"
                                    //         maxLength={100}
                                    //         name="name"
                                    //         placeholder="Enter Address "
                                    //         type="text"
                                    //         onChange={(e) => {
                                    //           setMainPageApplicationData(
                                    //             (prev) => ({
                                    //               ...prev,
                                    //               ["users_list"]: {
                                    //                 address: e?.target?.value,
                                    //               },
                                    //             })
                                    //           );
                                    //           applicantDataError.name = "";
                                    //         }} // Use onChange for real-time validation
                                    //         onBlur={(e) => {
                                    //           const applicantDetailsErr = {
                                    //             ...applicantDataError,
                                    //           };
                                    //           if (!e?.target?.value) {
                                    //             applicantDetailsErr.name =
                                    //               "Please Enter name";
                                    //           } else {
                                    //             applicantDetailsErr.name = "";
                                    //           }
                                    //           setApplicantDataError(
                                    //             applicantDetailsErr
                                    //           );
                                    //         }}
                                    //         value={
                                    //           mainPageApplicationData?.users_list
                                    //             ?.address
                                    //         }
                                    //       />
                                    //       {applicantDataError.name && (
                                    //         <p className="validate-error">
                                    //           {applicantDataError.name}
                                    //         </p>
                                    //       )}
                                    //     </div>
                                    //   </div>
                                    // </span>
                                  )}
                                </div>
                              </div>
                            }
                            {mainPageApplicationData?.users_list
                              ?.mobile_number &&
                              <div className="card_feild_main col-md-6">
                                <div className="card_feild_main_itm">
                                  <strong>Phone Number: </strong>
                                </div>
                                <div className="card_feild_main_itm">
                                  {showEditIcon ? (
                                    <span>
                                      {mainPageApplicationData?.users_list
                                        ?.mobile_number ?? "--"}
                                    </span>
                                  ) : (
                                    <span>
                                      {mainPageApplicationData?.users_list
                                        ?.mobile_number ?? "--"}
                                    </span>
                                    // <div className="col-md-8 aod-resp-tab">
                                    //   <div className="suf-input-box aod-form-input mb-2">
                                    //     <input
                                    //       className="w-100 form-control"
                                    //       maxLength={100}
                                    //       name="name"
                                    //       placeholder="Enter Phone Number of owner "
                                    //       type="text"
                                    //       onChange={(e) => {
                                    //         const value = e.target.value;

                                    //         // Regular Expression for Validation
                                    //         const validNumberRegex = /^[6-9][0-9]{0,9}$/; // Starts with 6-9, and up to 10 digits

                                    //         // Validate and Update State
                                    //         if (
                                    //           value == "" ||
                                    //           validNumberRegex.test(value)
                                    //         ) {
                                    //           setMainPageApplicationData(
                                    //             (prev) => ({
                                    //               ...prev,
                                    //               users_list: {
                                    //                 ...prev.users_list, // Ensure other properties in users_list are preserved
                                    //                 mobile_number: value,
                                    //               },
                                    //             })
                                    //           );
                                    //         }
                                    //         applicantDataError.phoneNumber = "";
                                    //       }}
                                    //       onBlur={(e) => {
                                    //         const applicantDetailsErr = {
                                    //           ...applicantDataError,
                                    //         };
                                    //         if (!e?.target?.value) {
                                    //           applicantDetailsErr.phoneNumber =
                                    //             "Please Enter Phone Number of owner";
                                    //         } else {
                                    //           applicantDetailsErr.phoneNumber =
                                    //             "";
                                    //         }
                                    //         setApplicantDataError(
                                    //           applicantDetailsErr
                                    //         );
                                    //       }}
                                    //       value={
                                    //         mainPageApplicationData?.users_list
                                    //           ?.mobile_number
                                    //       }
                                    //     />
                                    //     {applicantDataError.phoneNumber && (
                                    //       <p className="validate-error">
                                    //         {applicantDataError.phoneNumber}
                                    //       </p>
                                    //     )}
                                    //   </div>
                                    // </div>
                                  )}
                                </div>
                              </div>
                            }

                            {mainPageApplicationData?.users_list
                              ?.email &&
                              <div className="card_feild_main col-md-6">
                                <div className="card_feild_main_itm">
                                  <strong>Email: </strong>
                                </div>
                                <div className="card_feild_main_itm lowerCaseEmail">
                                  {showEditIcon ? (
                                    <span>
                                      {mainPageApplicationData?.users_list
                                        ?.email ?? "--"}
                                    </span>
                                  ) : (
                                    <span>
                                      {mainPageApplicationData?.users_list
                                        ?.email ?? "--"}
                                    </span>
                                    // <div className="col-md-8 aod-resp-tab">
                                    //   <div className="suf-input-box aod-form-input mb-2">
                                    //     <input
                                    //       className="w-100 form-control"
                                    //       maxLength={100}
                                    //       name="name"
                                    //       placeholder="Enter email of owner "
                                    //       type="text"
                                    //       onChange={(e) => {
                                    //         const value = e.target.value;
                                    //         const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation pattern

                                    //         // Validate and Update State
                                    //         setMainPageApplicationData(
                                    //           (prev) => ({
                                    //             ...prev,
                                    //             users_list: {
                                    //               ...prev.users_list, // Preserve other properties in users_list
                                    //               email: value, // Update the email field
                                    //             },
                                    //           })
                                    //         );
                                    //         if (
                                    //           validEmailRegex.test(value) ||
                                    //           value == ""
                                    //         ) {
                                    //           applicantDataError.email = "";
                                    //         }
                                    //       }}
                                    //       onBlur={(e) => {
                                    //         const value = e?.target?.value;
                                    //         const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation pattern

                                    //         // Clone the error state to ensure immutability
                                    //         const applicantDetailsErr = {
                                    //           ...applicantDataError,
                                    //         };

                                    //         if (!value) {
                                    //           // Check if the value is empty
                                    //           applicantDetailsErr.email =
                                    //             "Please enter the email of the owner";
                                    //         } else if (
                                    //           !validEmailRegex.test(value)
                                    //         ) {
                                    //           // Validate email format
                                    //           applicantDetailsErr.email =
                                    //             "Please enter a valid email";
                                    //         } else {
                                    //           // Clear error if valid
                                    //           applicantDetailsErr.email = "";
                                    //         }

                                    //         // Update the error state
                                    //         setApplicantDataError(
                                    //           applicantDetailsErr
                                    //         );
                                    //       }}
                                    //       value={
                                    //         mainPageApplicationData?.users_list
                                    //           ?.email
                                    //       }
                                    //     />
                                    //     {applicantDataError.email && (
                                    //       <p className="validate-error">
                                    //         {applicantDataError.email}
                                    //       </p>
                                    //     )}
                                    //   </div>
                                    // </div>
                                  )}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* umncoomment geolocation */}
                    <div className="col-md-12">
                      <div className="card h-100  custom_card ">
                        <div className="custom_card_header d-flex">
                          <img src={LocationIcon} alt="" srcSet="" />{" "}
                          <h6 className="fw-bolder mb-0">Geo-Location</h6>
                        </div>
                        <div className="card-body">
                          <div className="row gx-2 row-gap-2"></div>
                          <GeoLocationView subCatId={mainPageApplicationData?.category_id} mapLocation={geoLocationAddress} setGeoLocationAddress={setGeoLocationAddress} wholeFormData={tabsWholeData} showEditIcon={showEditIcon} />
                        </div>
                      </div>
                    </div>

                    {/* dyanmic tabs data starts */}

                    <div className="col-md-12">
                      <div className=" custom_card">
                        <div className="row">
                          <div className="col-md-12">
                            {tabsData && tabsData.length > 0
                              ? tabsData?.map((item) => (
                                <div key={item?.id}>
                                  <ApplicationDataView
                                    tabsData={item}
                                    mainPageApplicationData={
                                      mainPageApplicationData
                                    }
                                    showEditIcon={showEditIcon}
                                    setTabsWholeData={setTabsWholeData}
                                    tabsWholeData={tabsWholeData}
                                    setInvalidFields={setInvalidFields}
                                    invalidFields={invalidFields}
                                    invalidSubFieldErr={invalidSubFieldErr}
                                    setInvalidSubFieldErr={
                                      setInvalidSubFieldErr
                                    }
                                  />
                                </div>
                              ))
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {isDesignationData &&
                      isDesignationData?.length > 0 &&
                      isDesignationData[1]?.length > 0 ? (
                      <div className="col-lg-12 mt-4">
                        {tabsData?.some(
                          (item) => item.name_en == "Additional Details"
                        ) && (
                            <div>
                              <DesignationTableView
                                showEditIcon={showEditIcon}
                                // setTabsWholeData={setTabsWholeData}
                                tabsWholeData={tabsWholeData}
                                mainPageApplicationData={mainPageApplicationData}
                                setShowEditIcon={setShowEditIcon}
                              />
                            </div>
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* dyanmic tabs data ends */}

                    {documentList && documentList?.length > 0 ? (
                      <div className="col-md-12">
                        <div className="card h-100  custom_card ">
                          <div className="custom_card_header d-flex">
                            {/* <img src={UploadIcon} alt="" srcSet="" />{" "} */}
                            <h6 className="fw-bolder mb-0 ms-2">
                              Upload Documents
                            </h6>
                          </div>

                          <div className="card-body">
                            <div className="row gx-2 row-gap-2">
                              <div className="col-md-12 ">
                                <div className="">
                                  <div className="col-md-12">
                                    <div className="application_list_itms_align">
                                      {documentList?.map((doc, index) => (
                                        <div
                                          className="document_list_itm"
                                          key={index}
                                        >
                                          <div className="documentname_wrapper">
                                            <p className="mb-0 text-black">
                                              {doc?.name_en}
                                            </p>
                                            {fileErrors[doc.id] && (
                                              <div className="validate-error">
                                                {fileErrors[doc.id]}
                                              </div>
                                            )}
                                          </div>
                                          {!showEditIcon ? (
                                            <>
                                              <input
                                                type="file"
                                                id={`general_certificate_${index}`}
                                                name="partsuploadfile"
                                                accept={
                                                  fileTypeList.find((x) => x.label === doc?.type)?.accept || ""
                                                }
                                                onChange={(e) => {
                                                  handleFileChange(
                                                    doc,
                                                    index
                                                  )(e);
                                                  e.target.value = null;
                                                }}
                                                style={{ display: "none" }}
                                              />
                                              <label
                                                htmlFor={`general_certificate_${index}`}
                                                className="ad-cat-input-upload-image p-0"
                                              >
                                                <p className="ad-cat-text-upload-image">
                                                  <span>Choose File</span> <><p className="up_file_name">{fileNames[index] || "No file chosen"}</p></>
                                                </p>
                                              </label>

                                            </>
                                          ) : null}
                                          <a
                                            className="badge upload-view-btn small pointer-class"
                                            onClick={(e) => {
                                              e.preventDefault();

                                              window.open(
                                                baseUrl +
                                                "/" +
                                                doc.upload_docs[0].path,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            View
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {showEditIcon && (
                      <div className="col-md-12">
                        <div className="card h-100 custom_card">
                          <div className="custom_card_header d-flex">
                            <h6 className="fw-bolder mb-0">Update Status</h6>
                          </div>
                          <div className="card-body">
                            <div className="gx-2 row-gap-2">
                              <div className="col-md-12">
                                <div className="w-100">
                                  <div className="col-md-12 mb-4">
                                    <label
                                      htmlFor="categoryList"
                                      className="mb-1 w-100"
                                    >
                                      <span className="en-lable">
                                        Application status
                                      </span>
                                    </label>
                                    <Select
                                      styles={customStylesAcc}
                                      theme={selectTheme}
                                      components={{
                                        IndicatorSeparator: () => null,
                                      }}
                                      className="my-select selectpicker"
                                      classNamePrefix="ad-menu_open_select_class"
                                      aria-label="Default select example"
                                      name="title"
                                      value={
                                        applicationStatusList?.find(
                                          (option) => option.value == statusId
                                        ) || null
                                      }
                                      options={applicationStatusList}
                                      onBlur={() => {
                                        if (!statusId) {
                                          setError((prev) => ({
                                            ...prev,
                                            status: "Please select the status",
                                          }));
                                        }
                                      }}
                                      onChange={(selectedOption) => {
                                        setSelectedIds([]);
                                        if (!selectedOption) {
                                          setError((prev) => ({
                                            ...prev,
                                            status: "Please select the status",
                                          }));
                                        } else {
                                          setStatusId(selectedOption.value);
                                          setStatusChanged(false);
                                          setError((prev) => ({
                                            ...prev,
                                            status: "",
                                            checkBoxList: "",
                                          }));
                                        }
                                      }}
                                      placeholder="Select Title"
                                    />
                                    {error.status && (
                                      <p className="validate-error">
                                        {error.status}
                                      </p>
                                    )}
                                  </div>
                                  {statusId == "2" ? (
                                    <div className="col-md-12">
                                      <div className="row">
                                        {pendingDocumentList &&
                                          pendingDocumentList.map(
                                            (item, index) => (
                                              <div
                                                key={item?.id}
                                                className="col-md-4 d-flex align-items-start mb-3 pending-doc-name"
                                              >
                                                <input
                                                  disabled={statusChanged}
                                                  type="checkbox"
                                                  id={`checkbox-${item?.id}`}
                                                  className="form-check-input"
                                                  checked={selectedDocIds.includes(item?.id)}
                                                  value={item?.id}
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      item,
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                                <label
                                                  htmlFor={`checkbox-${item?.id}`}
                                                  className="ms-2 text-wrap check_label"
                                                  style={{
                                                    wordBreak: "break-word",
                                                  }}
                                                >
                                                  {item?.name_en}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        {error.checkBoxList && (
                                          <p className="validate-error">
                                            {error.checkBoxList}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="col-md-12 mb-3">
                                    <label
                                      htmlFor="categoryList"
                                      className="mb-1 w-100"
                                    >
                                      <span className="en-lable">Remarks</span>
                                    </label>
                                    <textarea
                                      className="w-100 form-control"
                                      maxLength={100}
                                      name="remark"
                                      placeholder="Enter Remark"
                                      type="text"
                                      onBlur={(e) => {
                                        if (!e.target?.value?.trim()) {
                                          setError((prev) => ({
                                            ...prev,
                                            remark: "Please enter the remark",
                                          }));
                                        }
                                      }}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setRemark(value);
                                        setError((prev) => ({
                                          ...prev,
                                          remark: value?.trim()
                                            ? ""
                                            : "Please enter the remark",
                                        }));
                                      }}
                                      value={remark}
                                    />
                                    {error.remark && (
                                      <p className="validate-error">
                                        {error.remark}
                                      </p>
                                    )}
                                  </div>
                                  {statusId == "4" && (
                                    <>
                                      <div className="className='suf-input-box aod-form-input mb-3">
                                        <label
                                          htmlFor="categoryList"
                                          className="mb-1 w-100"
                                        >
                                          <span className="en-lable">
                                            Certificate Type
                                          </span>
                                        </label>
                                        <Select
                                          styles={customStylesAcc}
                                          theme={selectTheme}
                                          components={{
                                            IndicatorSeparator: () => null,
                                          }}
                                          className="my-select selectpicker"
                                          classNamePrefix="ad-menu_open_select_class"
                                          aria-label="Default select example"
                                          name="title"
                                          value={
                                            categoryList?.find((option) => option.value == selectedCategory) || null
                                          }
                                          options={categoryList}
                                          onBlur={() => {
                                            // Check if selectedCategory has been set, and update the error accordingly
                                            setError((prev) => ({
                                              ...prev,
                                              inspetion_category: selectedCategory
                                                ? ""
                                                : "Please select the category",
                                            }));
                                          }}
                                          onChange={(selectedOption) => {
                                            // Update the selected category and clear the error
                                            setSelectedCategory(selectedOption.value);
                                            setError((prev) => ({
                                              ...prev,
                                              inspetion_category: "",
                                            }));
                                          }}
                                          placeholder="Select Title"
                                        />

                                        {error.inspetion_category && (
                                          <p className="validate-error">
                                            {error.inspetion_category}
                                          </p>
                                        )}
                                      </div>
                                      <div className="col-md-12 d-flex justify-content-between inspection-docs-fields mb-2">
                                        <div className="col-md-6 me-2 aod-resp-tab d-flex  align-items-top">
                                          <div className="suf-input-box aod-form-input mb-2 w-100">
                                            <label
                                              htmlFor="categoryList"
                                              className="mb-1 w-100"
                                            >
                                              <span className="en-lable">
                                                Inspection Document
                                              </span>
                                            </label>
                                            <input
                                              type="file"
                                              name="uploadDocument"
                                              id="uploadDocument"
                                              className="w-100 ad-cat-input-field-image"
                                              onChange={(e) =>
                                                handleDocumnetUpload(e)
                                              }
                                            />
                                            <label
                                              htmlFor="uploadDocument"
                                              className="ad-cat-input-upload-image p-0"
                                            >
                                              <p className="ad-cat-text-upload-image">
                                                <span>
                                                  <UploadIconPhoto />
                                                </span>
                                                {inspectionDoc?.name ? (
                                                  <p className="mb-0">
                                                    {inspectionDoc?.name}
                                                  </p>
                                                ) : (
                                                  "Upload Document"
                                                )}
                                              </p>
                                            </label>
                                            {error.document ? (
                                              <p className="validate-error">
                                                {error.document}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-md-6 inspection-date-wrap">
                                          <label
                                            htmlFor="categoryList"
                                            className="mb-1 w-100"
                                          >
                                            <span className="en-lable">
                                              Inspection Date
                                            </span>
                                          </label>
                                          <input
                                            className="w-100 form-control"
                                            maxLength={100}
                                            name="remark"
                                            placeholder="Enter the Inpection Date"
                                            type="date"
                                            onBlur={(e) => {
                                              if (!e.target?.value?.trim()) {
                                                setError((prev) => ({
                                                  ...prev,
                                                  inspectiondate:
                                                    "Please enter the Inpection Date",
                                                }));
                                              }
                                            }}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setInspectionDate(value);
                                              setError((prev) => ({
                                                ...prev,
                                                inspectiondate: value?.trim()
                                                  ? ""
                                                  : "Please enter the Inpection Date",
                                              }));
                                            }}
                                            value={inspectionDate}
                                          />
                                          {error.remark && (
                                            <p className="validate-error">
                                              {error.remark}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <label
                                          htmlFor="categoryList"
                                          className="mb-1 w-100"
                                        >
                                          <span className="en-lable">
                                            Inspection Remarks
                                          </span>
                                        </label>
                                        <textarea
                                          className="w-100 form-control"
                                          maxLength={100}
                                          name="remark"
                                          placeholder="Enter Remark"
                                          type="text"
                                          onBlur={(e) => {
                                            if (!e.target?.value?.trim()) {
                                              setError((prev) => ({
                                                ...prev,
                                                remark:
                                                  "Please enter the inspection remark",
                                              }));
                                            }
                                          }}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setInspectionRemark(value);
                                          }}
                                          value={inspectionRemark}
                                        />
                                      </div>
                                    </>
                                  )}
                                  <div className="row ad-cat-row">
                                    <div className="update-btns-block mt-3">
                                      <button onClick={(e) => submitStatus(e)}>
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div className="col-md-12">
                    <div className=" custom_card">
                      <div className="card-body">
                        {isDesignationData &&
                          isDesignationData?.length > 0 &&
                          isDesignationData[1]?.length > 0 ? (
                          <div className="col-lg-12 mt-4">
                            {tabsData?.some(
                              (item) => item.name_en == "Additional Details"
                            ) && (
                                <div>
                                  <DesignationTableView
                                    showEditIcon={showEditIcon}
                                    setTabsWholeData={setTabsWholeData}
                                    tabsWholeData={tabsWholeData}
                                    mainPageApplicationData={
                                      mainPageApplicationData
                                    }
                                    setShowEditIcon={setShowEditIcon}
                                  />
                                </div>
                              )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-applicationStatus"
                  role="tabpanel"
                  aria-labelledby="nav-applicationStatus-tab"
                >
                  <div className="col-md-12">
                    <div className="card custom_card">
                      <div className="custom_card_header d-flex">
                        <img src={PartnerIcon} alt="" srcSet="" />{" "}
                        <h6 className="fw-bolder">
                          Application log
                          {/* <span className="detailcount">2</span> */}
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table  bg-table mb-0 custom_table mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Sr. No </th>
                                <th scope="col"> Date and Time </th>
                                <th scope="col">Status</th>
                                <th scope="col">Remarks</th>

                                {/* <th scope="col">Upload Photo</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {applicationLog && applicationLog?.length > 0 ? (
                                applicationLog?.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {item?.created_at
                                        ? formatDateTime(item?.created_at)
                                        : "--"}
                                    </td>
                                    <td>
                                      {item?.status
                                        ? applicationStatusList?.find(
                                          (option) =>
                                            option.value == item?.status
                                        )?.label
                                        : "--"}
                                    </td>
                                    <td>
                                      {item?.status == '4' ? (
                                        <div className="d-flex flex-column mb-3">
                                          <p>{item?.remarks ?? "--"}</p>
                                          <p>
                                            {item?.inspection_date ? (
                                              <>
                                                <strong>Inspection Date:</strong> {formatDateTime(item?.inspection_date)}
                                              </>
                                            ) : (
                                              null
                                            )}
                                          </p>
                                          <p>
                                            {item?.inspection_doc ? (
                                              <>
                                                <strong>Inspection Document:</strong> {item?.inspection_doc.split('/').pop()}
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </p>
                                          <p>
                                            {item?.inspection_remarks ? (
                                              <>
                                                <strong>Inspection Remarks:</strong> {item?.inspection_remarks}
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </p>
                                          <p>
                                            {item?.inspection_category_id ? (
                                              <>
                                                <strong>Inspection Category:</strong> {categoryList?.find((x) => x.value === item?.inspection_category_id)?.label}
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </p>

                                        </div>
                                      ) : item?.status == '2' ? (
                                        <div className="d-flex flex-column mb-3">
                                          <p>{item?.remarks ?? "--"}</p>
                                          {item?.document_names[0] && item?.document_names.length > 0 ? (
                                            <ul>
                                              {item?.document_names.map((doc, index) => (
                                                <li key={index} style={{ 'list-style-type': 'disc' }}>{doc}</li>
                                              ))}
                                            </ul>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <p>{item?.remarks ?? "--"}</p>
                                      )}
                                    </td>

                                  </tr>
                                ))
                              ) : (
                                <NotFoundTable colSpan={4} />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-certificateList"
                  role="tabpanel"
                  aria-labelledby="nav-certificateList-tab"
                >
                  <div className="col-md-12">
                    <div className="card custom_card">
                      <div className="custom_card_header d-flex">
                        <img src={PartnerIcon} alt="" srcSet="" />{" "}
                        <h6 className="fw-bolder">
                          Certificates
                          {/* <span className="detailcount">2</span> */}
                        </h6>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table  bg-table mb-0 custom_table mb-0">
                            <thead>
                              <tr>
                                <th scope="col"> Certificate Number</th>
                                <th scope="col">Download</th>
                                <th scope="col">Generate Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {certificateList?.length != 0 ? (
                                <tr>
                                  <td>
                                    {certificateList?.certificate_number ??
                                      "--"}
                                  </td>
                                  <td>
                                    {certificateList.path ? (
                                      <a
                                        className="pointer-class"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          window.open(
                                            baseUrl +
                                            "/" +
                                            certificateList.path,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        Download
                                      </a>
                                    ) : (
                                      "--"
                                    )}
                                  </td>
                                  <td>
                                    {certificateList?.created_at
                                      ? formatDateTime(
                                        certificateList?.created_at
                                      )
                                      : "--"}
                                  </td>
                                </tr>
                              ) : (
                                <NotFoundTable colSpan={3} />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default ApplicationView;
