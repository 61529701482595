import { React, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { Message } from "../../../components/message";
import {
  getTermsConditionsDetails,
  updateRules,
} from "../../../services/api/admin";
import AdminDashboard from "../AdminDashboard/AdminDashboard";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "image", "video", "formula"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

const Rules = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [Rules_en, setRules_en] = useState("");
  const [Rules_hi, setRules_hi] = useState("");
  const [
    mandatoryTermsConditionsEnErr,
    setMandatoryTermsConditionsEnErr,
  ] = useState("");
  const [
    mandatoryTermsConditionsHiErr,
    setMandatoryTermsConditionsHiErr,
  ] = useState("");

  useEffect(() => {
    getTermsConditions();
  }, []);

  const getTermsConditions = async () => {
    try {
      const formData = new FormData();
      formData.append("page_id", 4);
      const usersListData = await getTermsConditionsDetails(formData);
      if (usersListData.status === 200) {
        setUserList(usersListData?.data || []);
        setTableDataLoaded(true);
        setRules_en(usersListData?.data?.content_en);
        setRules_hi(usersListData?.data?.content_hi);
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    getTermsConditions();
  };

  const handleEditorChange = (field, value) => {
    // Update content
    if (field === "Rules_en") setRules_en(value);
    if (field === "Rules_hi") setRules_hi(value);
  };

  const updateFormField = async (item) => {
    try {
      setMandatoryTermsConditionsEnErr("");
      setMandatoryTermsConditionsHiErr("");

      if (!Rules_en.trim() || Rules_en === "<p><br></p>") {
        setMandatoryTermsConditionsEnErr("Rules in English cannot be empty");
      }
      if (!Rules_hi.trim() || Rules_hi === "<p><br></p>") {
        setMandatoryTermsConditionsHiErr("Rules in Hindi cannot be empty");
      }

      if (
        !Rules_en.trim() ||
        !Rules_hi.trim() ||
        Rules_hi === "<p><br></p>" ||
        Rules_en === "<p><br></p>"
      ) {
        return;
      }
      const formData = new FormData();
      formData.append("rule_id", 4);
      formData.append("content_en", Rules_en);
      formData.append("content_hi", Rules_hi);
      const updateTermsConditions = await updateRules(formData);
      if (updateTermsConditions) {
        setApiMessage({
          type: "success",
          message: updateTermsConditions.message,
        });
        getTermsConditions();
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Rules</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Rules</h4>
            </div>
            <div className="aod-head"></div>
            <div className="suf-input-box aod-form-input mb-5 mt-3">
              <label className="lf-label w-100">Rules English</label>
              <ReactQuill
                name="Rules_en"
                value={Rules_en}
                onChange={(value) => handleEditorChange("Rules_en", value)}
                modules={modules}
                formats={formats}
                theme="snow"
              />
              {mandatoryTermsConditionsEnErr && (
                <p className="validate-error">
                  {mandatoryTermsConditionsEnErr}
                </p>
              )}
            </div>
            <div className="suf-input-box aod-form-input mb-5">
              <label className="lf-label w-100">Rules Hindi</label>
              <ReactQuill
                name="Rules_hi"
                value={Rules_hi}
                onChange={(value) => handleEditorChange("Rules_hi", value)}
                modules={modules}
                formats={formats}
                theme="snow"
              />
              {mandatoryTermsConditionsHiErr && (
                <p className="validate-error">
                  {mandatoryTermsConditionsHiErr}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="row ad-cat-row">
          <div className="col-md-12 mt-4 gap-4 d-flex">
            <button onClick={() => updateFormField()}>Update</button>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default Rules;
