import React, { useEffect, useState } from "react";
import HomeBanner from "../../../components/Home/HomeBanner/HomeBanner";
import HomeCategory from "../../../components/Home/HomeCategory/HomeCategory";
import HomeExplore from "../../../components/Home/HomeExplore/HomeExplore";
import HomeStepByStep from "../../../components/Home/HomeStepByStep/HomeStepByStep";
import "../../../components/Home/Home.css";
import { ClearLocalStorage } from "../../../Utils/Utils";
import MainLayout from "../../../components/MainLayout/MainLayout";
import { getLoginFormField } from "../../../services/api/auth";
import {
  getCategories,
  getSubCategories,
  getHomePageContent,
} from "../../../services/api/outer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../components/Home/HomeBanner/Slider.css";
import { Baseurl } from "../../../Utils/Utils";

const Home = () => {
  const [bannerData, setBannerData] = useState(null);
  const [homePageData, setHomePageData] = useState(null);
  const [filterCategoryData, setFilterCategoryData] = useState(null);
  const [filterSubCategoryData, setFilterSubCategoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterSelectedCategoryId, setFilterSelectedCategoryId] =
    useState(null);
  const [slider, setSlider] = useState([{ image: "", id: 0, path: "" }]);
  const [isLoginRendered, setIsLoginRendered] = useState(false); // Track if HomebannerLogin is fully rendered

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [bannerResponse, categoryResponse, homePageDataResponse] =
          await Promise.all([
            getLoginFormField(),
            getCategories(),
            getHomePageContent(),
          ]);

        const updatedData = bannerResponse?.data?.input_fields?.map((item) => ({
          ...item,
          value: "",
          error: "",
        }));
        const formFields = bannerResponse?.data?.formFields;
        const buttonFields = [
          ...bannerResponse?.data?.button_fields,
          ...bannerResponse?.data?.link_fields,
        ];

        const filteredCategories = categoryResponse?.filter(
          (i) => i?.status !== "0"
        );

        setBannerData({ updatedData, formFields, buttonFields });
        setHomePageData(homePageDataResponse);
        setFilterCategoryData(filteredCategories);
        setFilterSelectedCategoryId(filteredCategories?.[0]);

        console.log('filterCategoryData', filteredCategories)
        console.log('filteredCategories?.[0]', filteredCategories?.[0])

        const activeSliders = homePageDataResponse?.banner_slider.map(
          (item) => ({
            image: Baseurl() + "/" + item.image_path ?? "",
            id: item.id ?? 0,
            path: Baseurl() + "/" +  item.image_path ?? "",
          })
        );
        setSlider(activeSliders);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    ClearLocalStorage();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      console.log('usefilterSelectedCategoryId',filterSelectedCategoryId)
      console.log(filterSubCategoryData === null && (filterSelectedCategoryId !== null && filterSelectedCategoryId !== undefined))
      if (filterSelectedCategoryId) {
        try {
          console.log('filterSelectedCategoryId', filterSelectedCategoryId)
          const formData = new FormData();
          formData.append("category_id", filterSelectedCategoryId?.id);
          const res = await getSubCategories(formData);
          setFilterSubCategoryData(res);
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      } 
    };

    fetchSubCategories(); // Fetch subcategories when selectedCategoryId changes
  }, [filterSelectedCategoryId]);

  const htmlRendered = async () => {
    setIsLoginRendered(true);
  }

  if (
    loading ||
    filterCategoryData === null ||
    homePageData === null ||
    (filterSubCategoryData === null && (filterSelectedCategoryId !== null && filterSelectedCategoryId !== undefined))
  ) {
    return <></>;
  }

  return (
    <div style={{ opacity: isLoginRendered ? '1' : '0' }}>
      <MainLayout homePageData={homePageData}>
        <div className="slider-main">
          {slider && slider.length > 1 && slider[0].path && (
            <Slider {...settings}>
              {slider.map((slide, index) => (
                <div key={index} className="carousel-item ratio custom_ratio">
                  <img src={slide.path} className="d-block w-100" alt={`slide-${slide.id}`} />
                </div>
              ))}
            </Slider>
          )}
          {slider && slider.length === 1 && slider[0].path && (
              <div className="ratio custom_ratio">
                <img src={slider[0].path} className="d-block w-100" alt={`slide-${slider[0].id}`} />
              </div>
          )}
          <HomeBanner bannerData={bannerData} htmlRendered={htmlRendered} banner={homePageData?.banner[0]} />
        </div>
        {filterCategoryData && filterCategoryData.length > 0 ? <HomeCategory
          key={filterSelectedCategoryId.id}
          filterCategoryData={filterCategoryData}
          filterSubCategoryData={filterSubCategoryData}
        /> : <></> }
        <HomeStepByStep registrationSteps={homePageData?.registration_steps}/>
      </MainLayout>
    </div>
  );
};

export default Home;
