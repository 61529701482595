import React, { useEffect } from 'react'
import GuidelinesComponent from '../../components/Guidelines/GuidelinesComponent'
import Dashboard from '../Dashboard/Dashboard'
import { useLanguage } from '../../components/LanguageProvider/LanguageProvider '

const Guidelines = () => {
  const {language,setLanguage}=useLanguage();

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <Dashboard first={language ? "होम": "Home"} second={language ? "दिशानिर्देश":"Guidelines"} head={language ? "दिशानिर्देश":"Guidelines "}>
        <GuidelinesComponent />
      </Dashboard>
    </>
  )
}

export default Guidelines
