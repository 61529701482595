import React, { createContext, useContext, useState } from 'react';

// Create a Context
const LanguageContext = createContext();

// Create a provider component
export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(false);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

// Create a custom hook to use the LanguageContext
export const useLanguage = () => {
    return useContext(LanguageContext);
};
