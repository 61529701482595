import React, { useEffect, useRef, useState } from "react";
import HomebannerLogin from "./HomebannerLogin";
import { useLanguage } from "../../LanguageProvider/LanguageProvider ";
import { Baseurl } from "../../../Utils/Utils";
import { Modal } from "react-bootstrap";
import { faL } from "@fortawesome/free-solid-svg-icons";
const HomeBanner = ({ bannerData, htmlRendered, banner }) => {
  const { language, setLanguage } = useLanguage();
  const loginRef = useRef(null); // Ref to check the DOM
  const [showApplicationStatus, setShowApplicationStatus] = useState(false);

  useEffect(() => {
    if (loginRef.current) {
      htmlRendered();
    }
  }, [loginRef.current]);

  const handleClick = () => {
    setShowApplicationStatus(showApplicationStatus ? false : true);
  };

  return (
    <>
      <div className="hgbg-wrap" ref={loginRef}>
        <section
          className="hb-bg-main ratio custom_ratio"
          id="hg-bh-main-slider"
        >
          <div className="hb-bg-inner">
            <div className="container px-5">
              <div className="HomeBanner-main">
                <div className="HB-first-box">
                  {banner && banner?.status === "1" ? (
                    <>
                      <p>{language ? banner?.content_hi : banner?.content}</p>
                      <div className="btns-hb-main">
                        {banner?.banner_link.map((bann, index) => {
                          return (
                            <div
                              className="HB-first-box-btn"
                              key={index}
                              onClick={handleClick}
                            >
                              <div className="HB-btn-icon">
                                {" "}
                                <img
                                  src={`${Baseurl()}/${bann?.icon}`}
                                  alt=""
                                />{" "}
                              </div>
                              <p>{language ? bann?.name_hi : bann?.name}</p>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="HB-second-box">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <HomebannerLogin bannerData={bannerData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal className="status-modal" show={showApplicationStatus}>
        <Modal.Header
          closeButton
          onClick={() => setShowApplicationStatus(false)}
        >
          <Modal.Title>Know Your Application status</Modal.Title>
        </Modal.Header>
        <div class="modal-body">
        <div class="mb-3 f_fields">
          <label for="name_of_accommodation_unit_2" class="form-label">
            <span class="field-label required">Application Number</span>
            </label>
            <div>
              <input type="text" id="" name="" class="form-control" placeholder="Enter Application Number" value=""/></div>
             </div>
       
        <div class="mb-3 f_fields">
          <label for="name_of_accommodation_unit_2" class="form-label">
            <span class="field-label required">Mobile Number</span>
            </label>
            <div>
              <input type="number" id="" name="" class="form-control" placeholder="Enter Mobile Number" value=""/></div>
            </div>
        </div>
        <Modal.Footer>
          <div className="modal-status-btn p-2 d-flex flex-row">
            <button
              className="mx-2"
              // onClick={() => {
              //   deleteCategory(deleteData);
              // }}
            >
              Submit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default HomeBanner;
