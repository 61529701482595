import React, { useEffect, useState } from 'react'
import Banner from '../../components/Banner/Banner'
import CategoryInfoDetail from '../../components/CategoryInfo/CategoryInfoDetail'
import { useLanguage } from '../../components/LanguageProvider/LanguageProvider '
import MainLayout from '../../components/MainLayout/MainLayout'
import { getHomePageContent } from "../../services/api/outer"
import { ClearLocalStorage } from "../../Utils/Utils"

const CategoryInfo = () => {
    const {language}=useLanguage();
    const [homePageData, setHomePageData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      window.scrollTo(0, 0);
      const fetchData = async () => {
        try {
          const [homePageDataResponse] = await Promise.all([
            getHomePageContent(),
          ]);
          setHomePageData(homePageDataResponse);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data", error);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (loading || homePageData === null) {
      return <></>;
    }
    return (
        <MainLayout homePageData={homePageData}>
              <section className="contact-inner sub-cat-section-padding">
            <Banner first={language ? "होम": "Home"} second={language ? "श्रेणी जानकारी":"Category Info"} head={language ? "श्रेणी जानकारी":"Category Info "} />
            <CategoryInfoDetail/></section>
        </MainLayout>
    )
}

export default CategoryInfo
