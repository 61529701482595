import React, { useEffect, useState } from 'react'
import Dashboard from "../../pages/Dashboard/Dashboard";
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
import { CgCheck, CgDanger, CgRedo, CgStopwatch, CgTimer } from 'react-icons/cg';
import { trackApplicationData } from '../../services/api/user';
import { GetLocalStorage, GetLocalStorageParsed } from '../../Utils/Utils';
import { formatDateTime, formatDateTimeWithSlash } from '../../Utils/common';
import { approvedCategoryList } from '../../services/api/admin';
const TrackApplication = () => {
    const { language, setLanguage } = useLanguage();
    const [ trackDetails, setTrackDetails] = useState([])
    const userDetail = GetLocalStorageParsed("userDetail");
    const applicationId = GetLocalStorage("application_id");
    const [categoryList, setCategoryList] = useState([]);
    useEffect(()=>{
      fetchDetails()
        approvedCategory()
    },[])

    const fetchDetails =async() =>{
        try{
            const formData = new FormData()
            formData.append("user_id", userDetail?.id);
            formData.append("application_id", applicationId);
            const details = await trackApplicationData(formData)
            if(details?. status == 200){
                setTrackDetails(details?.data)
            }
        }catch(err){
            console.log(err?.message)
        }
    }

    const approvedCategory = async () => {
        try {
            const categoryListData = await approvedCategoryList();
            const data = categoryListData?.data?.map((list) => ({
                label: list?.name_en,
                value: list?.id,
            }));
            setCategoryList(data);
        } catch (err) {
            // setApiMessage({ type: "error", message: err?.message });
            console.log(err?.message)
        }
    };

  return (
      <Dashboard
          first={language ? "होम" : "Home"}
          second={language ? "ट्रैक एप्लिकेशन" : "Track Application"}
          head={language ? "ट्रैक एप्लिकेशन" : "Track Application"}
      >
          <div className="">
              <div className="row">
                  <div className="form-inner-wrapper p-3">
                      <div className="application_traking_outer col-lg-12 col-xl-12 mx-auto">
                          <ul className="application_traking_timline_main d-flex flex-column gap-3 ps-0">
                              <li className="application_traking_timline_itm d-flex">
                                  <div class="application_traking_status_icon bg-success d-flex justify-content-center align-items-center">
                                      <CgCheck />
                                  </div>
                                  <div className="application_tracking_timeline_block d-flex justify-content-between justify-content-center">
                                      <div className="application_comments me-5">
                                          <p class=" fw-bold pt-2">
                                              Application Review
                                          </p>
                                          <p class="mb-0">
                                              Application is send to respective
                                              district DTDO for review. If any other
                                              document is required. You will get a
                                              notification through SMS and Email.
                                          </p>
                                      </div>
                                      <div className="application_review_time ms-5">
                                          <small class="application_traking_timline_itm_header">
                                              <span class="text-muted d-block">
                                                  <span class="fw-bold d-block"> {trackDetails?.length > 0 ? formatDateTimeWithSlash(trackDetails[0].change_status_date_at) : '--'}</span>
                                              </span>
                                          </small>
                                      </div>
                                  </div>
                              </li>

                              {trackDetails && trackDetails.length > 0 && trackDetails?.map((item, index) => (
                                 <>
                                      {item?.status != 8 &&
                                          <li className="application_traking_timline_itm d-flex">
                                                  <>
                                                      {(() => {
                                                          switch (item?.status) {
                                                              case "2": return <div class="application_traking_status_icon bg-warning d-flex justify-content-center align-items-center"><CgDanger/></div>;
                                                              case "4": return <div class="application_traking_status_icon bg-success d-flex justify-content-center align-items-center"><CgCheck /></div>;
                                                              case "5": return <div class="application_traking_status_icon bg-danger d-flex justify-content-center align-items-center"><CgDanger/></div>;
                                                              case "6": return <div class="application_traking_status_icon bg-warning d-flex justify-content-center align-items-center"><CgRedo/></div>;
                                                              case "7": return <div class="application_traking_status_icon bg-warning d-flex justify-content-center align-items-center"><CgTimer/></div>;
                                                              case "8": return <div class="application_traking_status_icon bg-success d-flex justify-content-center align-items-center"><CgCheck /></div>;
                                                              case "10": return <div class="application_traking_status_icon bg-danger d-flex justify-content-center align-items-center"><CgDanger/></div>;
                                                              default: return language ? "अज्ञात" : "Unknown"; // in case of an undefined status
                                                          }
                                                      })()}
                                                  </>
                                              
                                              <div className="application_tracking_timeline_block d-flex justify-content-between justify-content-center">
                                                  <div className="application_comments me-5">
                                                      <p class=" fw-bold pt-2">
                                                           <span>
                                                              {(() => {
                                                                  switch (item?.status) {
                                                                      case "0": return language ? "निष्क्रिय" : "Inactive";
                                                                      case "1": return language ? "सक्रिय" : "Active";
                                                                      case "2": return language ? "आवश्यक दस्तावेज़ लंबित" : "Required Document Pending";
                                                                      case "3": return language ? "मसौदा" : "Draft";
                                                                      case "4": return language ? "आवेदन स्वीकृत" : "Application Approved";
                                                                      case "5": return language ? "आवेदन अस्वीकृत" : "Application Rejected";
                                                                      case "6": return language ? "नवीकरण" : "Renew";
                                                                      case "7": return language ? "आवेदन समीक्षाधीन" : "Application Under Review";
                                                                      case "8": return language ? "आवेदन प्रस्तुत" : "Application Submitted";
                                                                      case "10": return language ? "वापस लिया गया" : "Withdrawn";
                                                                      default: return language ? "अज्ञात" : "Unknown"; // in case of an undefined status
                                                                  }
                                                              })()}
                                                          </span>
                                                      </p>
                                                      <p class="mb-0">
                                                          <span class="text-muted d-block">
                                                              {item?.status == '4' ? (
                                                                  <div className="d-flex flex-column mb-3">
                                                                      <p>{item?.remarks ?? "--"}</p>
                                                                      <p>
                                                                          {item?.inspection_date ? (
                                                                              <>
                                                                                  <strong>Inspection Date:</strong> {formatDateTime(item?.inspection_date)}
                                                                              </>
                                                                          ) : (
                                                                            null
                                                                          )}
                                                                      </p>
                                                                      <p>
                                                                          {item?.inspection_doc ? (
                                                                              <>
                                                                                  <strong>Inspection Document:</strong> {item?.inspection_doc.split('/').pop()}
                                                                              </>
                                                                          ) : (
                                                                              ''
                                                                          )}
                                                                      </p>
                                                                      <p>
                                                                          {item?.inspection_remarks ? (
                                                                              <>
                                                                                  <strong>Inspection Remarks:</strong> {item?.inspection_remarks}
                                                                              </>
                                                                          ) : (
                                                                              ''
                                                                          )}
                                                                      </p>
                                                                      <p>
                                                                          {item?.inspection_category_id ? (
                                                                              <>
                                                                                  <strong>Inspection Category:</strong> {categoryList?.find((x) => x.value === item?.inspection_category_id)?.label}
                                                                              </>
                                                                          ) : (
                                                                              ''
                                                                          )}
                                                                      </p>

                                                                  </div>
                                                              ) : item?.status == '2' ? (
                                                                  <div className="d-flex flex-column mb-3">
                                                                      <p>{item?.remarks ?? "--"}</p>
                                                                      {item?.document_names[0] && item?.document_names.length > 0 ? (
                                                                          <ul>
                                                                              {item?.document_names.map((doc, index) => (
                                                                                  <li key={index} style={{ 'list-style-type': 'disc' }}>{doc}</li>
                                                                              ))}
                                                                          </ul>
                                                                      ) : null}
                                                                  </div>
                                                              ) : (
                                                                  <p>{item?.remarks ?? "--"}</p>
                                                              )}
                                                          </span>
                                                      </p>
                                                  </div>
                                                  <div className="application_review_time ms-5">
                                                      <small class="application_traking_timline_itm_header">
                                                          <span class="fw-bold d-block"> {item?.change_status_date_at ? formatDateTimeWithSlash(item?.change_status_date_at) : '--'}</span>
                                                      </small>
                                                  </div>
                                              </div>
                                          </li>

                                      }
                                 </>
                              ))}
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </Dashboard>
  )
}

export default TrackApplication
