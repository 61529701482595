import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import { Message } from '../../../components/message'
import { BsChevronLeft } from 'react-icons/bs'
import { GetLocalStorage } from '../../../Utils/Utils'
import { getCategoriesDropdown, getRolesAllListData, getRolesListData, getSubCategoriesDropdown, submitAdminUserData, submitCategoryFaqData, updateAdminUserData, updateCategoryFaqdData } from '../../../services/api/admin'
import Select from 'react-select';
import { checkPattern, checkPatternWithSpecialCharacter, checkValidEmail, checkValidMobile, customStylesAcc, selectTheme } from '../../../Utils/common'
import { UploadIcon } from '../AdminIcon'

const AddAdminCategoryFaqs = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const token = GetLocalStorage("userInfo")
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [catList, setCatList] = useState([]);
    const [catId, setCatId] = useState('');
    const [faqId, setFaqId] = useState('')
    const [formData, setFormData] = useState({
        questionEn: '',
        questionHi: '',
        answerEn: '',
        answerHi: '',
    })
    const [error, setError] = useState({
        questionEn: '',
        questionHi: '',
        answerEn: '',
        answerHi: '',
        catId: ''
    })

    useEffect(() => {
        if (!token) {
            navigate("/")
        } else {
            if (location?.state && location?.pathname == "/admin/faqs/update") {
                editFaqData(location?.state)
            }
        }
    }, [location?.state])

    useEffect(() => {
        getCategoryList();
    }, []);

    const getCategoryList = async () => {
        try {
            const categoryList = await getSubCategoriesDropdown()
            const data = categoryList?.data?.filter(i => i?.status === "1")?.map(item => ({
                value: item?.id,
                label: item?.category_name
            }))
            setCatList(data)
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const handleInputChange = async (e) => {
        const { name, value } = e.target
        const cursorPosition = e.target.selectionStart;
        if (name == 'questionEn') {
            if (await checkPatternWithSpecialCharacter(value) == false) {
                // e.preventDefault()
                return;
            }
        } else if (name == 'questionHi') {
            if (await checkPatternWithSpecialCharacter(value) == false) {
                // e.preventDefault()
                return;
            }
        } else if (name == 'answerEn') {
            if (await checkPatternWithSpecialCharacter(value) == false) {
                // e.preventDefault()
                return;
            }
        } else if (name == 'answerHi') {
            if (await checkPatternWithSpecialCharacter(value) == false) {
                // e.preventDefault()
                return;
            }
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
        validateOnChangeHandle(name, value);
    }

    const validateOnChangeHandle = async (name, value) => {
        let formErrors = error;
        switch (name) {
            case 'questionEn':
                if (!value?.trim()) {
                    formErrors.questionEn = 'Please Enter English Question';
                } else {
                    formErrors.questionEn = ''
                }
                break;
            case 'questionHi':
                if (!value?.trim()) {
                    formErrors.questionHi = 'Please Enter Hindi Question';
                } else {
                    formErrors.questionHi = ''
                }
                break;
            case 'answerEn':
                if (!value?.trim()) {
                    formErrors.answerEn = 'Please Enter English Answer';
                } else {
                    formErrors.answerEn = ''
                }
                break;
            case 'answerHi':
                if (!value?.trim()) {
                    formErrors.answerHi = 'Please Enter  Hindi Answer';
                } else {
                    formErrors.answerHi = ''
                }
                break;
        }
        setError(formErrors);
        return formErrors;
    }

    const validateOnBlurHandle = (e) => {
        const { name, value } = e.target;

        let formErrors = { ...error };

        if (name === 'questionEn') {
            if (!value.trim()) {
                formErrors.questionEn = 'Please enter English Question';
            } else {
                formErrors.questionEn = '';
            }
        } else if (name === 'questionHi') {
            if (!value.trim()) {
                formErrors.questionHi = 'Please Enter Hindi Question';
            } else {
                formErrors.questionHi = '';
            }
        } else if (name === 'answerEn') {
            if (!value.trim()) {
                formErrors.answerEn = 'Please Enter English Answer';
            } else {
                formErrors.answerEn = '';
            }
        } else if (name === 'answerHi') {
            if (!value.trim()) {
                formErrors.answerHi = 'Please Enter  Hindi Answer';
            } else {
                formErrors.answerHi = '';
            }
        }

        setError(formErrors);
    };


    const validateCategory = () => {
        let formErrors = { ...error };
        if (!catId) {
            formErrors.catId = 'Please Select Sub category';
        } else {
            formErrors.catId = '';
        }
        setError(formErrors);
    };



    const editFaqData = (data) => {
        setFormData({
            questionEn: data.question_en,
            questionHi: data.question_hi,
            answerEn: data.answer_en,
            answerHi: data.answer_hi,
        })
        setFaqId(data.id)
        setCatId(data?.category_id)

    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            let validationErrors = { ...error };
            await Promise.all(Object.keys(formData).map(async (key) => {
                const value = formData[key];
                const errors = await validateOnChangeHandle(key, value);
                if (!catId) {
                    error.catId = " Please Select Sub Category"
                }
                validationErrors = { ...validationErrors, ...errors };

            }));
            setError(validationErrors);
            if (Object.values(validationErrors).some(error => error !== '')) {
                return;
            }


            const AdminUserData = new FormData();
            AdminUserData.append("question_en", formData.questionEn)
            AdminUserData.append("question_hi", formData.questionHi)
            AdminUserData.append("answer_en", formData.answerEn)
            AdminUserData.append("answer_hi", formData.answerHi)
            AdminUserData.append("category_id", catId)

            const userData = await submitCategoryFaqData(AdminUserData)
            if (userData?.status == 200) {
                navigate("/admin/faqs", { state: { message: userData.message } })
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err.message })
        }
    }

    const updateFaq = async (e) => {
        try {
            e.preventDefault();

            let validationErrors = { ...error };
            await Promise.all(Object.keys(formData).map(async (key) => {
                const value = formData[key];
                const errors = await validateOnChangeHandle(key, value);
                if (!catId) {
                    error.catId = " Please Select Sub Category"
                }
                validationErrors = { ...validationErrors, ...errors };

            }));
            setError(validationErrors);
            if (Object.values(validationErrors).some(error => error !== '')) {
                return;
            }


            const AdminUserData = new FormData();
            AdminUserData.append("question_en", formData.questionEn)
            AdminUserData.append("question_hi", formData.questionHi)
            AdminUserData.append("answer_en", formData.answerEn)
            AdminUserData.append("answer_hi", formData.answerHi)
            AdminUserData.append("category_id", catId)
            AdminUserData.append("faq_id", faqId)

            const userData = await updateCategoryFaqdData(AdminUserData)
            if (userData?.status == 200) {
                navigate("/admin/faqs", { state: { message: userData.message } })
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err.message })
        }
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <b onClick={() => navigate("/admin/")}>Home  /</b><span> FAQ Form</span>
                    </div>
                </div>
                <div className="aod-inner">
                    <div className={faqId ? "aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/faqs")}><BsChevronLeft /> Back</button>{faqId ? "Update" : "Add"} FAQ</div>
                        <form>
                            <div className="row py-4">
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label htmlFor="roles" className='mb-1 w-100'><span className="en-lable">Select Sub Category</span>
                                        </label>

                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className="my-select selectpicker"
                                            classNamePrefix="ad-menu_open_select_class"
                                            aria-label="Default select example"
                                            name="catId"
                                            value={catList?.find(option => option.value === catId) || null}
                                            options={catList}
                                            onBlur={validateCategory}
                                            onChange={(selectedOption) => {
                                                setCatId(selectedOption.value)
                                            }}// Use onChange for real-time validation
                                            placeholder="Select Sub Category"
                                        />
                                        {error.catId && <p className='validate-error'>{error.catId}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            English Question
                                        </label>
                                        <input
                                            className='w-100'
                                            maxLength={100}
                                            name='questionEn'
                                            placeholder='Enter English Question'
                                            type="text"
                                            onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                                            onBlur={(e) => validateOnBlurHandle(e)}
                                            value={formData.questionEn} // Controlled input
                                        />
                                        {error.questionEn && <p className='validate-error'>{error.questionEn}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Hindi Question
                                        </label>
                                        <input
                                            className='w-100'
                                            maxLength={100}
                                            name='questionHi'
                                            placeholder='Enter Hindi Question'
                                            type="text"
                                            onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                                            onBlur={(e) => validateOnBlurHandle(e)}
                                            value={formData.questionHi}
                                        />
                                        {error.questionHi && <p className='validate-error'>{error.questionHi}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            English Answer
                                        </label>
                                        <input
                                            className='w-100'
                                            maxLength={100}
                                            name='answerEn'
                                            placeholder='Enter English Answer'
                                            type="text"
                                            onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                                            onBlur={(e) => validateOnBlurHandle(e)}
                                            value={formData.answerEn}
                                        />
                                        {error.answerEn && <p className='validate-error'>{error.answerEn}</p>}

                                    </div>
                                </div>
                                <div className="col-md-4 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>

                                        <label className='mb-1 w-100'>
                                            Hindi Answer
                                        </label>
                                        <input
                                            className='w-100'
                                            maxLength={100}
                                            name='answerHi'
                                            placeholder='Enter Hindi Answer'
                                            type="text"
                                            onChange={(e) => handleInputChange(e)} // Use onChange for real-time validation
                                            onBlur={(e) => validateOnBlurHandle(e)}
                                            value={formData.answerHi}
                                        />
                                        {error.answerHi && <p className='validate-error'>{error.answerHi}</p>}

                                    </div>
                                </div>



                                <div className="row ad-cat-row">
                                    <div className=" update-btns-block">
                                        {
                                            faqId ?
                                                <>
                                                    <div className="w-auto gap-4 mt-3 d-flex">
                                                        <button onClick={(e) => updateFaq(e)}>
                                                            Update
                                                        </button> </div>
                                                </> :
                                                <div className="w-auto mt-3">
                                                    <button onClick={(e) => handleSubmit(e)}>
                                                        Submit
                                                    </button>
                                                </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}



export default AddAdminCategoryFaqs
