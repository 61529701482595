import AdminDashboard from '../AdminDashboard/AdminDashboard'
import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { BsChevronLeft } from 'react-icons/bs'
import { HiPlus } from 'react-icons/hi'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { useForm, useFieldArray } from "react-hook-form"
import { useLocation, useNavigate } from 'react-router-dom'
import { addNewDropDownList, updateNewDropDownList, updateStateData } from '../../../services/api/admin'
import { checkPattern } from '../../../Utils/common'
import { Message } from '../../../components/message'
import { DeleteIcon } from '../AdminIcon'

const AddDropDown = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [nameEn, setNameEn] = useState('');
    const [nameHi, setNameHi] = useState('');
    const [nameEnError, setNameEnError] = useState('');
    const [nameHiError, setNameHiError] = useState('');
    const [editData, setEditData] = useState();  // Holds data for edit scenario
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [itemsErrors, setItemsErrors] = useState([]);

    const {
        control,
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
    } = useForm({
        defaultValues: {
            name_en: '',
            name_hi: '',
            items: [{ name_en: '', name_hi: '' }],
        },
    });

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'items',
    });


    // Populate form for edit scenario
    useEffect(() => {
        if (location?.state && location.pathname === "/admin/form/dropdowns/update") {
            const item = location.state;
            editStoredData(item);

            if (item?.dropdown_values) {
                const parsedData = JSON.parse(item?.dropdown_values);
                reset({
                    name_en: item.name_en,
                    name_hi: item.name_hi,
                    items: parsedData?.items,
                });
            }
        }
    }, [location?.state, reset]);

    const editStoredData = (item) => {
        const parsedData = JSON.parse(item?.dropdown_values); // Parse the dropdown values

        setEditData(item);
        setNameEn(item.name_en);
        setNameHi(item.name_hi);

        // Reset form with the parsed data
        reset({ items: parsedData?.items });
    };

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        const cursorPosition = e.target.selectionStart;
        if (name == "nameEn") {
            if (await checkPattern(value) == false) {
                return;
            } else {
                setNameEn(value)
            }
        } else if (name == "nameHi") {
            if (await checkPattern(value) == false) {
                return;
            } else {
                setNameHi(value)
            }
        }
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
        validateForm(name, value);
    };

    const handleItemInputChange = (e, index, field) => {
        const { value } = e.target;
        const newItemsErrors = [...itemsErrors];

        // Validate individual form array fields
        if (field === 'name_en') {
            if (!value.trim()) {
                newItemsErrors[index] = { ...newItemsErrors[index], name_en: "Please Enter the English Name" };
            } else {
                newItemsErrors[index] = { ...newItemsErrors[index], name_en: "" };
            }
        }

        if (field === 'name_hi') {
            if (!value.trim()) {
                newItemsErrors[index] = { ...newItemsErrors[index], name_hi: "Please Enter the Hindi Name" };
            } else {
                newItemsErrors[index] = { ...newItemsErrors[index], name_hi: "" };
            }
        }

        setItemsErrors(newItemsErrors);
    };


    const validateFormArray = (items) => {
        const newItemsErrors = [];

        let isValid = true;
        items.forEach((item, index) => {
            const errors = {};
            if (!item.name_en.trim()) {
                errors.name_en = "Please Enter the English Name";
                isValid = false;
            }
            if (!item.name_hi.trim()) {
                errors.name_hi = "Please Enter the Hindi Name";
                isValid = false;
            }
            newItemsErrors[index] = errors;
        });

        setItemsErrors(newItemsErrors); // Update errors state
        return isValid;
    };


    const validateForm = (name, value) => {
        console.log(name, value)
        if (name == "nameEn") {
            if (!value.trim()) {
                setNameEnError("Please Enter the DropDown English Name")
                return false;
            } else {
                setNameEnError("")
            }
        } else if (name == "nameHi") {
            if (!value.trim()) {
                setNameHiError("Please Enter the DropDown Hindi Name")
                return false;
            } else {
                setNameHiError("")
            }
        }

        return true;
    };

    const onSubmit = async (data) => {
        const isNameEnValid = validateForm("nameEn", nameEn);
        const isNameHiValid = validateForm("nameHi", nameHi);

        if (!isNameEnValid || !isNameHiValid) {
            return;
        }

        const isItemsValid = validateFormArray(data.items);

        if (!isItemsValid) {
            setApiMessage("Please complete at least one dropdown option with both English and Hindi names.");
            return;
        }

        const formData = new FormData();
        formData.append('name_en', nameEn);
        formData.append('name_hi', nameHi);
        formData.append('dropdown_values', JSON.stringify({ items: data.items }));

        try {
            if (editData) {
                formData.append('dropdown_id', editData.id);
                formData.append('status', editData.status);
                const response = await updateNewDropDownList(formData);
                if (response?.status === 200) navigate("/admin/form/dropdowns");
            } else {
                const response = await addNewDropDownList(formData);
                if (response?.status === 200) navigate("/admin/form/dropdowns");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <HomeIcon />
                        <b onClick={() => navigate("/admin/")}>Home /</b>
                        <span> DropDown Form</span>
                    </div>
                </div>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className="aod-inner">
                    <div className={editData ? "aod-update aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head">
                            <button onClick={() => navigate("/admin/form/dropdowns")}>
                                <BsChevronLeft /> Back
                            </button>
                            {editData ? "Update" : "Add"} DropDown
                        </div>

                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'> English Name</label>
                                    <input
                                        className='w-100'
                                        placeholder='Enter English Name'
                                        type="text"
                                        name="nameEn"
                                        value={nameEn}
                                        onChange={(e) => { handleInputChange(e) }}
                                    />
                                    {nameEnError && <p className='validate-error'>{nameEnError}</p>}
                                </div>
                            </div>

                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'> Hindi Name</label>
                                    <input
                                        className='w-100'
                                        placeholder='Enter Hindi Name'
                                        type="text"
                                        name="nameHi"
                                        value={nameHi}
                                        onChange={(e) => { handleInputChange(e) }}
                                    />
                                    {nameHiError && <p className='validate-error'>{nameHiError}</p>}
                                </div>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h5 className='mb-3 w-100 fw-semibold'>Dropdown Options</h5>
                            {fields.map((item, index) => (
                                <div className="row py-2" key={item.id}>
                                    <div className="col-md-4 aod-resp-tab add-fields">
                                        <div className='suf-input-box aod-form-input mb-1'>
                                            <label className='mb-1 w-100'>English name</label>
                                            <input
                                                className='w-100 mb-2'
                                                {...register(`items.${index}.name_en`)}
                                                placeholder="Name (EN)"
                                                onChange={(e) => handleItemInputChange(e, index, 'name_en')}
                                                required
                                            />
                                            {itemsErrors[index]?.name_en && <p className='validate-error'>{itemsErrors[index].name_en}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <label className='mb-1 w-100'>Hindi name</label>

                                            <input
                                                className='w-100 mb-2'
                                                {...register(`items.${index}.name_hi`)}
                                                placeholder="Name (HI)"
                                                onChange={(e) => handleItemInputChange(e, index, 'name_hi')}
                                                required
                                            />
                                            {itemsErrors[index]?.name_hi && <p className='validate-error'>{itemsErrors[index].name_hi}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-3 p-0 ad-reg-btn'>
                                        {fields.length > 1 && (
                                            <div className='ad-reg-delete' onClick={() => remove(index)}>
                                                <DeleteIcon />
                                            </div>
                                        )}
                                        {fields.length === index + 1 && (
                                            <div className='ad-reg-plus' onClick={() => append({ name_en: "", name_hi: "" })}>+</div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="gap-4 d-flex justify-content-between">
                                        {/* <button className='add-btn  cta-f add-btn'
                                            type="button"
                                            onClick={() => append({ name_en: "", name_hi: "" })}
                                        >   <FontAwesomeIcon icon={faPlus} className="me-1" />
                                            Add More
                                        </button> */}
                                        <button className=" ad-reset-btn" type="submit">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    );
};

export default AddDropDown;
