import React from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ComingSoon from "../ComingSoon/ComingSoon";

const Categories = () => {
  const { language, setLanguage } = useLanguage();
  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "श्रेणियाँ" : "Categories"}
      head={language ? "श्रेणियाँ" : "Categories"}
    >
      <>
        <ComingSoon></ComingSoon>
      </>
    </Dashboard>
  );
};

export default Categories;
