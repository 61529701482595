import React, { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Overview from "../../components/Application/Overview";
import "../../components/Application/Application.css";
import OrganizationDetails from "../../components/Application/OrganizationDetails";
import {
  Apiurl,
  GetLocalStorage,
  GetLocalStorageParsed,
  RemoveSession,
} from "../../Utils/Utils";
import axios from "axios";
import { getTabs } from "../../services/api/user";

const Application = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");
  const getWithoutLoginSubCatClick = GetLocalStorageParsed("subCategoryClick");
  const token = GetLocalStorage("userInfo");

  const [tabsData, setTabsData] = useState();
  const [tabActiveData, setTabActiveData] = useState();

  useEffect(() => {
    if (location || tabsData) {
      const pathname = location.pathname?.split("/");
      const changePath = pathname?.[2]?.replace(/-/g, "_");
      const filterTabData = tabsData?.find((i) => i?.tableName === changePath);
      setTabActiveData(filterTabData);
    }
  }, [location, tabsData]);
  const [inputFormData, setInputFormData] = useState();

  /*const getTabsBySubCategory = () => {

    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "authorization": `Bearer ${token}`

      },
      url: `${Apiurl()}/application-forms/application-tabs/${getLoginSubCabData?.id || getWithoutLoginSubCatClick?.id}`,
    };
    axios(option)
      .then((e) => {
        setTabsData(e?.data?.data?.tabs?.filter((i)=>i?.tableName !== null))

      })
      .catch((error) => {
    

      });

  } */

  const getTabsBySubCategory = async () => {
    try {
      const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");
      if (getLoginSubCabData) {
        const payload = {
          category_id: getLoginSubCabData?.id || getWithoutLoginSubCatClick?.id,
        };

        const response = await getTabs(payload);
        const filteredTabs = response?.data.filter(
          (i) => i?.tableName !== null
        );
        // console.log("Filtered Tabs:", filteredTabs);
        setTabsData(filteredTabs);
      }
    } catch (error) {
      console.error("Error fetching tabs:", error);
    }
  };

  useEffect(() => {
    if (getLoginSubCabData || getWithoutLoginSubCatClick) {
      getTabsBySubCategory();
    }
  }, []);
  const getInputFieldsonBasisOfTab = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        authorization: `Bearer ${token}`,
      },
      url: `${Apiurl()}/application-forms/organisation-details/${
        tabActiveData?.id
      }`,
    };

    axios(option)
      .then((response) => {
        const data = response?.data?.data;
        if (data && Array.isArray(data.sections)) {
          const updatedData = data.sections.map((section) => ({
            ...section,
            category_form_feilds: section.category_form_feilds.map((field) => ({
              ...field,
              value: "",
            })),
          }));
          const filterDeleteddata = updatedData?.filter((i) => i !== 0);

          setInputFormData({ ...data, sections: filterDeleteddata });
        } else {
          setInputFormData(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (tabActiveData) {
      getInputFieldsonBasisOfTab();
    }
  }, [tabActiveData]);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="overview/" element={<Overview />} />
        <Route
          path="application-form/"
          element={
            <OrganizationDetails
              tabsData={tabsData}
              tabActiveData={tabActiveData}
              setTabActiveData={setTabActiveData}
              inputFormData={inputFormData}
              setInputFormData={setInputFormData}
              getLoginSubCabData={getLoginSubCabData}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default Application;
