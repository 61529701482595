import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { allModuleListData, allSubModuleByModuleIdListData, getPermissionDataById, getPermissionDataByRoleId, getRolesListData, postUserPermission, updatePermissionsData ,addUserPermissionData ,getUserPermissionData} from "../../../services/api/admin";
import { Message } from '../../../components/message';
import Select from 'react-select';
import { BsChevronLeft } from "react-icons/bs";
import { customStylesAcc, selectTheme } from "../../../Utils/common";

const UserPermissions = () => {
	const navigate = useNavigate();
	const location = useLocation()
	const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
	const [modulesList, setModuleList] = useState([])
	const [subModuleList, setSubModuleList] = useState([])
	const [userList, setUserList] = useState([])
	const [roleList, setRoleList] = useState([])
	const [roleId, setRoleId] = useState('')
	const [userId, setUserId] = useState('')
	const [permissionId, setPermissionId] = useState('')
	const [selectedModuleIndex, setSelectedModuleIndex] = useState('');

	const [permissions, setPermissions] = useState({
		role_id: '',
		user_id: '',
		module_permission: [],
	});

	useEffect(() => {
		if (location?.state && location?.pathname == "/admin/roles/permissions/edit") {
			editPermission(location?.state)

		} else if (location?.state && location?.pathname == "/admin/admin-users/user-permissions/edit") {
			editUserPermission(location?.state)

		}
	}, [])



	useEffect(() => {
		getModulesList();
		getRolesList();
		getUserList()
	}, []);

	const getModulesList = async () => {
		try {
			const data = await allModuleListData();
			if (data?.status == 200) {
				data?.data.sort((a, b) => a.sort - b.sort);
				const modulesData = data?.data?.map((i) => ({
					label: i?.name,
					value: i?.id,
				}));
				setModuleList(modulesData);
				const firstModuleId = modulesData[0].value;
				setSelectedModuleIndex(firstModuleId);
				if (firstModuleId) {
					getSubModuleList(firstModuleId);
				}
			}
		} catch (err) {
			console.error(err);
		}
	};

	const getSubModuleList = async (moduleId) => {
		try {
			const formData = new FormData();
			formData.append("module_id", moduleId);
			const data = await allSubModuleByModuleIdListData(formData);
			if (data?.status == 200) {
				const subModulesData = data?.data?.map((i) => ({
					label: i?.name,
					value: i?.id,
				}));
				setSubModuleList(subModulesData);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const getRolesList = async () => {
		try {
			const RolesData = await getRolesListData(1)
			const data = RolesData?.data?.data?.map(i => ({
				label: i?.title, value: i?.id
			}))
			setRoleList(data)
		} catch (err) {
			setApiMessage({ type: 'error', message: err?.message });
		}
	}

	const getUserList = async () => {
		try {
			const RolesData = await getRolesListData(1)
			const data = RolesData?.data?.data?.map(i => ({
				label: i?.title, value: i?.id
			}))
			setUserList(data)
		} catch (err) {
			setApiMessage({ type: 'error', message: err?.message });
		}
	}

	const editPermission = async (item) => {
		setRoleId(item?.id);
		try {
			const formData = new FormData();
			formData.append("permission_id", item?.permission_id);

			const data = await getPermissionDataById(formData);

			setPermissionId(data?.data?.id);

			// Parse and store the module permissions
			const existingPermissions = JSON.parse(data?.data?.module_permission).map(permission => {
				if (permission.module_permission) {
					// Convert the permission values from string to boolean
					Object.keys(permission.module_permission).forEach(key => {
						if (permission.module_permission[key] === "false") {
							permission.module_permission[key] = false;
						} else if (permission.module_permission[key] === "true") {
							permission.module_permission[key] = true;
						}
					});
				}
				return permission;
			});

			setPermissions({
				role_id: item.id,
				module_permission: existingPermissions, // Prepopulate the state with existing permissions
			});

		} catch (err) {
			console.error("Error fetching permission data", err);
		}
	};

	const editUserPermission = async (item) => {
		try {
			setUserId(item?.id)
			setRoleId(item?.role_id)
			const formData = new FormData();
			formData.append("role_id", item?.role_id);
			formData.append("user_id", item?.id);
			let data = await getUserPermissionData(formData);
			let existingPermissions ;
			if (data?.message === "Record not found") {
				formData.append("permission_id", permissionId);
				const data = await getPermissionDataByRoleId(formData);
				existingPermissions = JSON.parse(data?.data?.module_permission).map(permission => {
					if (permission.module_permission) {
						// Convert the permission values from string to boolean
						Object.keys(permission.module_permission).forEach(key => {
							if (permission.module_permission[key] === "false") {
								permission.module_permission[key] = false;
							} else if (permission.module_permission[key] === "true") {
								permission.module_permission[key] = true;
							}
						});
					}
					return permission;
				});
			}else{
				existingPermissions = JSON.parse(data?.data?.permissions).map(permission => {
					if (permission.module_permission) {
						// Convert the permission values from string to boolean
						Object.keys(permission.module_permission).forEach(key => {
							if (permission.module_permission[key] === "false") {
								permission.module_permission[key] = false;
							} else if (permission.module_permission[key] === "true") {
								permission.module_permission[key] = true;
							}
						});
					}
					return permission;
				});
			}

			setPermissionId(data?.data?.id);

			setPermissions({
				role_id: item?.role_id,
				user_id: item?.id,
				permission_id: data?.data?.id,
				module_permission: existingPermissions, // Prepopulate the state with existing permissions
			});

		} catch (err) {

		}
	}


	const handleCheckBox = (e, item) => {
		if (!roleId) {
			setApiMessage({ type: "error", message: "Please Select Role first" });
			return;
		}

		const { name, checked } = e.target;
		setPermissions((permissions) => {
			// Find the permission entry for the current module and parent module
			const existingPermissionIndex = permissions?.module_permission?.findIndex(
				(p) => p.admin_module_id == item.value && p.admin_parent_module_id == selectedModuleIndex
			);

			// If permission already exists, update it
			if (existingPermissionIndex !== -1) {
				const existingPermission = permissions.module_permission[existingPermissionIndex];

				const updatedPermissionsForModule = {
					...existingPermission.module_permission,
					[name]: checked, // Toggle the current permission based on the checkbox
				};

				// Check if all permissions are unchecked, if yes, remove the module from the array
				const isAllUnchecked = Object.values(updatedPermissionsForModule).every((val) => val === false);

				const updatedPermissionsArray = isAllUnchecked
					? permissions.module_permission.filter((_, index) => index !== existingPermissionIndex) // Remove the permission if all unchecked
					: permissions.module_permission.map((p, index) =>
						index === existingPermissionIndex
							? { ...existingPermission, module_permission: updatedPermissionsForModule }
							: p
					);

				return {
					...permissions,
					module_permission: updatedPermissionsArray,
				};
			} else {
				// If permission does not exist, create a new entry
				const newPermission = {
					admin_parent_module_id: selectedModuleIndex,
					admin_module_id: item.value,
					module_permission: { [name]: checked },
				};

				return {
					...permissions,
					role_id: roleId,
					module_permission: [...permissions.module_permission, newPermission],
				};
			}
		});
	};

	// Handle Form Submission
	const handleSubmit = async () => {

		if (!roleId) {
			setApiMessage({ type: 'error', message: "Please Select Role first" });
			return;
		} else if (permissions?.module_permission?.length == 0) {
			setApiMessage({ type: 'error', message: "Please Give permission  first" });
			return;
		}
		console.log(permissions)
		// return;
		try {
			// const payload = permissions
			const data = await postUserPermission(permissions)
			navigate("/admin/roles", { state: { message: data.message } })
		} catch (err) {
			setApiMessage({ type: 'error', message: err?.message });
		}
	};

	const updatePermisiions = async () => {

		try {
			const payload = {
				permission_id: permissionId,
				role_id: roleId,
				module_permission: permissions.module_permission
			}
			const updateData = await updatePermissionsData(payload)
			navigate("/admin/roles", { state: { message: updateData.message } })
		} catch (err) {

		}
	}

	const updateUserPermisiions = async () => {
		if (!roleId) {
			setApiMessage({ type: 'error', message: "Please Select Role first" });
			return;
		} else if (permissions?.module_permission?.length == 0) {
			setApiMessage({ type: 'error', message: "Please Give permission  first" });
			return;
		}
		console.log(permissions)
		const updatedPermissions = {
			...permissions,
			permissions: permissions.module_permission, // Rename the key
			user_id:userId
		};
		
		delete updatedPermissions.module_permission; // Remove the old key
		try {
			// const payload = permissions
			//const data = await postUserPermission(permissions)
			const data = await addUserPermissionData(updatedPermissions)
			navigate("/admin/admin-users", { state: { message: data.message } })
		} catch (err) {
			setApiMessage({ type: 'error', message: err?.message });
		}
	};

	return (
		<AdminDashboard>
			<div className="aod-outer">
				{apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
				<div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
					<div>
						<HomeIcon />
						<b onClick={() => navigate("/admin/")}>Home /</b>
						<span> Permission</span>
					</div>
				</div>
				<div className="aod-inner">
					<div className="aod-bottom">
						<div className="aod-head text-center adm-head"> <button onClick={() => { userId ? navigate("/admin/admin-users") : navigate("/admin/roles") }}><BsChevronLeft /> Back</button>{roleId ? "Update" : "Add"} Permission</div>
						{/* <div className="adm-tabs-outer">
							<h3 className="mb-0 w-100">Permissions</h3>
						</div> */}
						{/* <div className="aod-head"></div> */}

						<section className="top-form-wrapper">
							<div className="row mt-4">
								<div className="col-md-4">
									<div className="form-control">
										<label htmlFor="name" className="form-label"><span className="en-lable">Select Role</span>
										</label>

										<Select
											styles={customStylesAcc}
											theme={selectTheme}
											components={{
												IndicatorSeparator: () => null
											}}
											className="my-select selectpicker"
											classNamePrefix="ad-menu_open_select_class"
											aria-label="Default select example"
											// isDisabled={editData?.id}
											value={roleList?.find(option => option.value === roleId) || null}
											options={roleList}
											onChange={(selectedOption) => {
												setRoleId(selectedOption.value)
											}}
											isDisabled={!!roleId} // Disable if roleId exists
											placeholder="Select Role"
										/>
									</div>
								</div>

								<div className="content-wrapper" id="all-users-table">
									<section className="permissions">
										<div className="d-flex align-items-start">
											<div className="permission-left-tab">
												<div
													className="nav flex-column nav-pills me-4 NavBtn"
													id="v-pills-tab"
													role="tablist"
													aria-orientation="vertical"
												>
													{modulesList.map((item, index) => (
														<button
															key={index}
															className={`nav-link ${selectedModuleIndex === item.value ? "active" : ""}`}
															id={`v-pills-${item.label}-tab`}
															data-bs-toggle="pill"
															data-bs-target={`#v-pills-${item.label}`}
															type="button"
															role="tab"
															aria-controls={`v-pills-${item.label}`}
															aria-selected={selectedModuleIndex === item.value}
															onClick={() => { setSelectedModuleIndex(item.value); getSubModuleList(item.value) }} 												>
															{item.label}
														</button>
													))}
												</div>
											</div>
											<div className="permission-right-tab">
												<div className="tab-content" id="v-pills-tabContent">
													<div
														className="tab-pane fade show active"
														id="v-pills-user"
														role="tabpanel"
														aria-labelledby="v-pills-user-tab"
														tabIndex="0"
													>
														<div className="table-responsive px-0">
															<table className="table  custom_table mb-0 ">
																<thead className="">
																	<tr>
																		<th>Permissions</th>
																		<th>Add</th>
																		<th>Edit</th>
																		<th>View</th>
																		<th>Delete</th>
																	</tr>
																</thead>
																<tbody>
																	{subModuleList.map((item, index) => (
																		<tr key={index}>
																			<td>
																				<label htmlFor={`condition_${item.value}`}>{item.label}</label>
																			</td>
																			<td>
																				<div className="form-group">
																					<input
																						type="checkbox"
																						id={`add_condition_${item.value}`}
																						name="add"
																						className="permission_checknbox_value"
																						checked={
																							!!permissions?.module_permission?.find(
																								(p) => p.admin_module_id == item.value && p.admin_parent_module_id == selectedModuleIndex
																							)?.module_permission?.add // Prepopulate "add" permission
																						}
																						itemId = {item.value}
																						onChange={(e) => handleCheckBox(e, item)}
																					/>
																				</div>
																			</td>
																			<td>
																				<div className="form-group">
																					<input
																						type="checkbox"
																						id={`edit_condition_${item.value}`}
																						name="edit"
																						className="permission_checknbox_value"
																						checked={
																							!!permissions?.module_permission?.find(
																								(p) => p.admin_module_id == item.value && p.admin_parent_module_id == selectedModuleIndex
																							)?.module_permission?.edit // Prepopulate "edit" permission
																						}
																						itemId = {item.value}
																						onChange={(e) => handleCheckBox(e, item)}
																					/>
																				</div>
																			</td>
																			<td>
																				<div className="form-group">
																					<input
																						type="checkbox"
																						id={`view_condition_${item.value}`}
																						name="view"
																						className="permission_checknbox_value"
																						checked={
																							!!permissions?.module_permission?.find(
																								(p) => p.admin_module_id == item.value && p.admin_parent_module_id == selectedModuleIndex
																							)?.module_permission?.view // Prepopulate "view" permission
																						}
																						itemId = {item.value}
																						onChange={(e) => handleCheckBox(e, item)}
																					/>
																				</div>
																			</td>
																			<td>
																				<div className="form-group">
																					<input
																						type="checkbox"
																						id={`delete_condition_${item.value}`}
																						name="delete"
																						className="permission_checknbox_value"
																						checked={
																							!!permissions?.module_permission?.find(
																								(p) => p.admin_module_id == item.value && p.admin_parent_module_id == selectedModuleIndex
																							)?.module_permission?.delete // Prepopulate "delete" permission
																						}
																						itemId = {item.value}
																						onChange={(e) => handleCheckBox(e, item)}
																					/>
																				</div>
																			</td>
																		</tr>
																	))}
																</tbody>

															</table>
														</div>
													</div>
												</div>
												<div className="col-md-6 gap-4 d-flex mt-3">
													{
														!userId ? (
															permissionId ? (
																<button onClick={() => updatePermisiions()} className='suf-can-butt w-auto px-4'>
																	Update
																</button>
															) : (
																<button onClick={() => handleSubmit()} className='suf-can-butt w-auto px-4'>
																	Submit
																</button>
															)
														) : (
															<button onClick={() => updateUserPermisiions()} className='suf-can-butt w-auto px-4'>
																Submit
															</button>
														)
													}

												</div>
											</div>
										</div>

									</section>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</AdminDashboard>
	);
};

export default UserPermissions;
