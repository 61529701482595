import React from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ComingSoon from "../ComingSoon/ComingSoon";

const Transactions = () => {
  const { language, setLanguage } = useLanguage();
  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "भुगतान रसीद" : "Payment Receipt"}
      head={language ? "भुगतान रसीद" : "Payment Receipt"}
    >
      <>
        <div className="aod-bottom">
          <div className="page_heading">
            <h5>{language ? "लेनदेन" : "Transactions"}</h5>
          </div>
          <div className="col-md-12">
            <div className="reciept-table table-responsive mt-2">
              <table
                className="table table mb-0 dataTable transaction_table"
                id="receipt_table"
                role="grid"
              >
                <thead className="">
                  <tr role="row">
                    <th>{language ? "क्रम संख्या" : "S.No"}</th>
                    <th>{language ? "आवेदन संख्या" : "Application Number"}</th>
                    <th>{language ? "ट्रैकिंग नंबर" : "Tracking Number"}</th>
                    <th>{language ? "प्रकार" : "Type"}</th>
                    <th>{language ? "मात्रा" : "Amount"}</th>
                    <th>{language ? "स्थिति" : "Status"}</th>
                    <th>{language ? "देखना" : "View"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row">
                    <td>1</td>
                    <td>UTTT14706601</td>
                    <td>UTHS641182</td>
                    <td>{language ? "होमस्टे एप्लीकेशन" : "Homestay Application"}</td>
                    <td>500.00</td>
                    <td>{language ? "चुकाया गया" : "Paid"}</td>
                    <td>
                      <button className="btn btn-green-view">{language ? "देखना" : "View"}</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </Dashboard>
  );
};

export default Transactions;
