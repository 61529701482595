import React, { useEffect, useState } from 'react'
import SignUpForm from '../../../components/Auth/SignUp/SignUpForm'
import Banner from '../../../components/Banner/Banner'
import { useLocation } from 'react-router-dom'
import MainLayout from '../../../components/MainLayout/MainLayout'
import { useLanguage } from '../../../components/LanguageProvider/LanguageProvider '
import { getHomePageContent } from '../../../services/api/outer'
import { ClearLocalStorage } from '../../../Utils/Utils'

const SignUp = () => {

  const { language, setLanguage } = useLanguage();
  const [homePageData, setHomePageData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const [homePageDataResponse] = await Promise.all([
          getHomePageContent(),
        ]);
        setHomePageData(homePageDataResponse);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    ClearLocalStorage();
  }, []);

  if (loading || homePageData === null) {
    return <></>;
  }
  return (
    <>
      <MainLayout homePageData={homePageData}>
        <Banner first={language ? "होम" : "Home"} second={language ? "रेजिस्ट्रेशन पृष्ठ" : "Registration Page"} head={language ? "रेजिस्ट्रेशन " : "Registration "} />
        <SignUpForm />
      </MainLayout>
    </>
  )
}
export default SignUp