import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import { Message } from '../../../components/message';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import { getHomepageLogo } from '../../../services/api/admin';
import { formatDateWithSlash } from '../../../Utils/common';
import { Baseurl } from '../../../Utils/Utils';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { EditIcon } from "../AdminIcon";

const HomepageLogo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const [userList, setUserList] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  useEffect(() => {
    WebsiteLogo();
  }, []);

  const WebsiteLogo = async () => {
    try {
      const usersListData = await getHomepageLogo();
      if (usersListData.status === 200) {
        setUserList(usersListData?.data || []);
        setTableDataLoaded(true);
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    WebsiteLogo();
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
      {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Logo</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Logo</h4>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-long">Name</th>
                    <th className="ad-long">Icon</th>
                    <th className="ad-long">Created At</th>
                   <th className="ad-long">Edit</th>
                  </tr>
                </thead>
                <tbody>
                      <tr key={userList.id}>
                        <td>{userList.id}</td>
                        <td>{userList.name}</td>
                        <td>
                          <img
                            src={`${Baseurl()}/${userList.path}`}
                            alt="Banner"
                            style={{ width: "30px", height: "auto" }}
                          />
                        </td>
                        <td>{formatDateWithSlash(userList.created_at)}</td>
                          <>
                        <td>
                          <div className="action-btn d-flex">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id={`edit-tooltip-${userList.id}`}>Edit</Tooltip>}
                            >
                              <span
                                className="ad-cat-edit"
                                onClick={() => {
                                  navigate("/admin/homepage-logo/update", {
                                    state: userList,
                                  });
                                }}
                              >
                                <EditIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </td></>
                      </tr>
                </tbody>
              </Table>
              {/* <div className="d-flex w-100 justify-content-end">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default HomepageLogo;
