import React from 'react';
import "../../pages/Admin/Admin.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import "../Accordion/FormSubSectionAccordion.css"
import { CgChevronDown, CgChevronRight } from "react-icons/cg";

const FormSubSectionAccordion = ({ title, children, isOpen, onToggle, onRemove }) => {
    return (
        <div className="row py-1">
            <div className='accordion-wrapper'>
                <div onClick={onToggle} style={{ cursor: 'pointer' }} className="accordion-header">
                    <div className='accordion-heading'>
                        <h5>{title}</h5>
                    </div>
                    <div className='accordion-arrow'>
                        {isOpen ? <CgChevronDown /> : <CgChevronRight />}
                    </div>

                </div>
                {isOpen && (
                    <>
                        <div className='row'>
                            <div className="col-md-11" >
                                {children}</div>
                            <div className="col-md-1 form-sub-section-del-btn"> <button className='dlt-btn cta-f' onClick={onRemove}>
                                <FontAwesomeIcon icon={faTrashCan} className="trash-icon" /> </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default FormSubSectionAccordion;
