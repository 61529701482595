import React, { useEffect, useRef, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon, UploadIcon } from '../AdminIcon'
import { Button, Modal, Spinner, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage, Imageurl } from '../../../Utils/Utils'
import axios from 'axios'
import Select from 'react-select';
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsChevronLeft } from "react-icons/bs";
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { addRegistrationFieldData, getFontFamilyData, getValidationsListData, updateRegistrationFieldData } from '../../../services/api/admin'
import { customStylesAcc, selectTheme } from '../../../Utils/common'

const RegistrationFormAdd = () => {
    const token = GetLocalStorage("userInfo")
    const location = useLocation()

    const navigate = useNavigate()
    const [enName, setEnName] = useState();
    const [enNameError, setEnNameError] = useState();

    const [hnName, setHnName] = useState();
    const [hnNameError, setHnNameError] = useState();

    // const [inputName, setInputName] = useState();
    // const [inputNameError, setInputNameError] = useState();

    const [inputType, setInputType] = useState();
    const [inputTypeError, setInputTypeError] = useState();

    const [fontFamily, setFontFamily] = useState({ label: "Arial", value: 1 });
    const [fontFamilyError, setFontFamilyError] = useState();

    const [fontColor, setFontColor] = useState();
    const [fontColorError, setFontColorError] = useState();

    const [fontSize, setFontSize] = useState();
    const [fontSizeError, setFontSizeError] = useState();

    const [inputRequired, setInputRequired] = useState()
    const [inputRequiredError, setInputRequiredError] = useState()

    const [verifyOtp, setVerifyOtp] = useState('')

    const [dropdownValues, setDropDownValues] = useState([{ value: '' }])
    const [dropdownValuesError, setDropDownValuesError] = useState()

    const [validationError, setValidationError] = useState()
    const [fontsList, setFontsList] = useState()
    const [editData, setEditData] = useState()

    const [showValidations, setShowValidations] = useState(false);
    const [preventData, setPreventData] = useState([{ id: Date.now(), type: '', unit: '', value: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' }]);
    const [otherValidations, setOtherValidations] = useState([{ id: Date.now(), type: '', unit: "", value: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' }]);
    const [validationsList, setValidationList] = useState([])
    const [validationsType, setValidationType] = useState([])
    const [loading, setLoading] = useState(false)

    //buttons
    //button data
    const [editBtnData, setEditBtnData] = useState([])

    const [btnName, setBtnName] = useState();
    const [btnNameError, setBtnNameError] = useState();

    const [btnNameHi, setBtnNameHi] = useState();
    const [btnNameHiError, setBtnNameHiError] = useState();

    const [btnFontFamily, setBtnFontFamily] = useState();
    const [btnFontFamilyError, setBtnFontFamilyError] = useState();

    const [btnFontColor, setBtnFontColor] = useState();
    const [btnFontColorError, setBtnFontColorError] = useState();

    const [btnFontSize, setBtnFontSize] = useState();
    const [btnFontSizeError, setBtnFontSizeError] = useState();

    const [btnBackgroundColor, setBtnBackgroundColor] = useState();
    const [btnBackgroundColorError, setBtnBackgroundColorError] = useState();


    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    };
    useEffect(() => {
        if (location?.state && location?.pathname == "/admin/registration/update") {
            editRegistrationField(location?.state)
        } else if (location?.state && location?.pathname == "/admin/registration/update-buttons") {
            editCategoryBtn(location?.state)
        }
    }, [location?.state, validationsList])
    //validations
    const validateForm = (item) => {
        let isValid = true;

        if (item === "enName" || item === "all") {
            if (!enName || enName.trim() === "") {
                setEnNameError("Please Enter English Name");
                isValid = false;
            } else {
                setEnNameError("");
            }
        }

        if (item === "hnName" || item === "all") {
            if (!hnName || hnName.trim() === "") {
                setHnNameError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setHnNameError("");
            }
        }

        // if (item === "fontFamily" || item === "all") {
        //     if (!fontFamily) {
        //         setFontFamilyError("Please Select Font Family");
        //         isValid = false;
        //     } else {
        //         setFontFamilyError("");
        //     }
        // }

        if (item === "fontColor" || item === "all") {
            if (!fontColor || fontColor.trim() === "") {
                setFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setFontColorError("");
            }
        }

        if (item === "fontSize" || item === "all") {
            if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
                setFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setFontSizeError("");
            }
        }
        if (item === "inputType" || item === "all") {
            if (!inputType) {
                setInputTypeError("Please Select Input Type");
                isValid = false;
            } else {
                setInputTypeError("");
            }
        }
        if (item === "required" || item === "all") {
            if (inputType?.value == "dropdown" && dropdownValues?.length < 2) {
                setDropDownValuesError("Drop down must contain atleast two values")
            }
            if (!inputRequired) {
                setInputRequiredError("Please select Status");
                isValid = false;
            }
            else {
                setInputRequiredError("");
            }
        }
        if (showValidations?.value == true) {
            let count = 0;
            preventData?.forEach(item => {
                if (item?.type?.value?.length) {
                    count++;
                }
            });
            otherValidations?.forEach(item => {
                if (item?.type?.value?.length) {
                    count++;
                }
            });
            if (count <= 0) {
                setValidationError("Please Enter Validation");
                isValid = false;
            } else {
                setValidationError("");
            }
        } else {
            setValidationError("");
        }
        return isValid;
    };

    useEffect(() => {
        if (inputType?.value == "dropdown" && dropdownValues?.length >= 2) {
            setDropDownValuesError("")
        }

    }, [dropdownValues])
    // add registration field
    const addRegistrationField = async () => {
        if (!validateForm("all")) return
        try {
            const formData = new FormData();
            formData.append("name_en", enName)
            formData.append("name_hi", hnName)
            formData.append("verify_otp_button", verifyOtp ?? null)
            const inputName = enName?.toLowerCase()?.replace(" ", "_")
            formData.append("input_field_name", inputName)
            formData.append("font_family_id", Number(fontFamily?.value ?? "1"))
            formData.append("font_color", fontColor)
            formData.append("font_size", Number(fontSize))
            formData.append("type", inputType?.value)
            const dropdata = JSON.stringify({ [inputName]: dropdownValues?.map(i => i?.value) });
            { inputType?.value == "dropdown" && formData.append("dropdown_values", dropdata) }
            const preventdata = preventData?.filter(i => i?.type?.value)?.map(item => ({
                type: item?.type?.value, unit: item?.unit?.label, value: item?.value, numberUpperLimit: item?.numberUpperLimit, numberLowerLimit: item?.numberUpperLimit, messageEnglish: item?.messageEnglish, messageHindi: item?.messageHindi
            }))
            const otherdata = otherValidations?.filter(i => i?.type?.value)?.map(item => ({
                type: item?.type?.value, unit: item?.unit?.label, value: item?.value, numberUpperLimit: item?.numberUpperLimit, numberLowerLimit: item?.numberUpperLimit, messageEnglish: item?.messageEnglish, messageHindi: item?.messageHindi
            }))
            const validData = JSON.stringify({ validation: JSON.stringify({ onChange: preventdata, onKeyUp: otherdata }) })
            // const validData = JSON.stringify({ validation: JSON.stringify(validation.map(i => i?.value)) })
            formData.append("validations", validData ? validData : null)
            formData.append("required", inputRequired?.value)
            formData.append("status", "1")
            const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                    "authorization": `Bearer ${token}`
                },
                url: `${Apiurl()}/admin/registration-form/create`,
                data: formData
            };
            const response = await addRegistrationFieldData(formData)
            navigate("/admin/registration")
        } catch (error) {
            // navigate("/")
        }
    }
    //validations list
    const getValidationsList = async () => {
        try {
            let validationListData = await getValidationsListData()
            const data = validationListData?.data
            const uniqueValidationTypes = data
                .map(item => item.validation_type)
                .filter((value, index, self) => self.indexOf(value) === index).map(item => ({ label: item, value: item }));
            // const uniqueValidationTypes = [...new Set(data)];

            setValidationList(data)
            setValidationType(uniqueValidationTypes)
        } catch (err) {
            console.log("error in validation list", err)
        }
    };
    //get font families
    const getFontFamily = async () => {
        try {
            const fontFamilyData = await getFontFamilyData()
            const data = fontFamilyData?.data?.map(i => ({
                label: i?.name, value: i?.id
            }))

            setFontsList(data)
        } catch (err) {
            console.log("font families error :", err)
        }
    };

    useEffect(() => {
        getFontFamily()
        getValidationsList()
    }, [])

    //edit sub-category
    const editRegistrationField = (item) => {

        setEditData(item)
        setEnName(item?.name_en)
        setHnName(item?.name_hi)
        setVerifyOtp(item?.verify_otp_button)
        // setInputName(item?.input_field_name)
        setInputType(inputTypeList?.find(i => i.value == item?.type))
        setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setFontColor(item?.font_color)
        setFontSize(item?.font_size)
        const validations = item?.validations ? JSON.parse(item?.validations) : null;
        const validationsparsed = JSON.parse(validations?.validation);

        console.log(validationsparsed, typeof validationsparsed)
        if (validationsparsed) {
            setShowValidations({ label: 'Yes', value: true })
            const preventData = validationsparsed?.onChange?.length ? validationsparsed?.onChange?.map((item, index) => {
                const unitList = validationsList?.filter(i => i?.validation_type == item?.type)?.map(i => ({ label: i?.name, value: i?.id }))
                return ({
                    id: Date.now() + index,
                    type: item?.type ? validationsType?.find(i => i?.value == item?.type) : "",
                    unit: item?.unit ? unitList?.find(i => i?.label == item?.unit) : "",
                    value: item?.value,
                    numberUpperLimit: item?.numberUpperLimit,
                    numberLowerLimit: item?.numberLowerLimit,
                    messageEnglish: item?.messageEnglish,
                    messageHindi: item?.messageHindi

                })
            }) : [{ id: Date.now(), type: '', value: '', unit: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' }]
            const otherData = validationsparsed?.onKeyUp?.length ? validationsparsed?.onKeyUp?.map((item, index) => {
                const unitList = validationsList?.filter(i => i?.validation_type == item?.type)?.map(i => ({ label: i?.name, value: i?.id }))
                return ({
                    id: Date.now() + index,
                    type: item?.type ? validationsType?.find(i => i?.value == item?.type) : "",
                    unit: item?.unit ? unitList?.find(i => i?.label == item?.unit) : "",
                    value: item?.value,
                    numberUpperLimit: item?.numberUpperLimit,
                    numberLowerLimit: item?.numberLowerLimit,
                    messageEnglish: item?.messageEnglish,
                    messageHindi: item?.messageHindi

                })
            }) : [{ id: Date.now(), type: '', value: '', unit: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' }]

            setPreventData(preventData)
            setOtherValidations(otherData)
        } else {
            setShowValidations({ label: 'No', value: false })

        }

        // const valid = validations && Array.isArray(validations.validation) ? validations.validation.map(i => ({ value: i })) : "";

        const dropdownValues = item?.dropdown_values ? JSON.parse(item?.dropdown_values) : null;
        const drop = dropdownValues && dropdownValues[item?.input_field_name] && Array.isArray(dropdownValues[item?.input_field_name])
            ? dropdownValues[item?.input_field_name].map(i => ({ value: i }))
            : "";
        // setValidation(valid ? valid : [{ value: '' }])
        setDropDownValues(drop ? drop : [{ value: '' }])
        setInputRequired(statusList?.find(i => i?.value == item?.required))
        window.scrollTo(0, 0);
    }
    //update sub-category
    const updateRegistrationField = async () => {
        if (!validateForm("all")) return
        try {
            setLoading(true)
            const formData = new FormData();
            formData.append("field_id", editData?.id)
            formData.append("name_en", enName)
            formData.append("name_hi", hnName)
            formData.append("verify_otp_button", verifyOtp ?? null)
            const inputName = editData?.input_field_name
            formData.append("input_field_name", inputName)
            formData.append("font_family_id", Number(fontFamily?.value ?? "1"))
            formData.append("font_color", fontColor)
            formData.append("font_size", Number(fontSize))
            formData.append("type", inputType?.value)
            formData.append("status", 1)

            const dropdata = JSON.stringify({ [inputName]: dropdownValues?.map(i => i?.value) });
            { inputType?.value == "dropdown" && formData.append("dropdown_values", dropdata) }
            const preventdata = preventData?.filter(i => i?.type?.value)?.map(item => ({
                type: item?.type?.value, unit: item?.unit?.label, value: item?.value, numberUpperLimit: item?.numberUpperLimit, numberLowerLimit: item?.numberLowerLimit, messageEnglish: item?.messageEnglish, messageHindi: item?.messageHindi
            }))
            const otherdata = otherValidations?.filter(i => i?.type?.value)?.map(item => ({
                type: item?.type?.value, unit: item?.unit?.label, value: item?.value, numberUpperLimit: item?.numberUpperLimit, numberLowerLimit: item?.numberLowerLimit, messageEnglish: item?.messageEnglish, messageHindi: item?.messageHindi
            }))

            // return
            const validData = JSON.stringify({ validation: JSON.stringify({ onChange: preventdata, onKeyUp: otherdata }) })

            // const validData = JSON.stringify({ validation: JSON.stringify(validation.map(i => i?.value)) })
            formData.append("validations", validData)
            formData.append("required", inputRequired?.value)
            const response = await updateRegistrationFieldData(formData)
            setLoading(false)
            navigate("/admin/registration")
        } catch (error) {
            console.log("Registration field update error: ", error)
            // navigate("/")
        }
    }
    const inputTypeList = [
        { label: "Text", value: "text" },
        { label: "Number", value: "number" },
        { label: "Password", value: "password" },
        { label: "File", value: "file" },
        { label: "Dropdown", value: "dropdown" },
        { label: "Calender", value: "calender" },
        { label: "Checkbox", value: "checkbox" },
    ]
    const statusList = [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
    ]
    const validationRequired = [
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]

    const verifyOtpstatus = [
        { label: "Yes", value: "1" },
        { label: "No", value: "0" },
    ]

    const handleDropdownChange = (index, event) => {
        const values = [...dropdownValues];
        values[index].value = event.target.value;
        setDropDownValues(values);
    };

    const handleDropDownAddInput = () => {
        const values = [...dropdownValues];
        values.push({ value: '' });
        setDropDownValues(values);
    };

    const handleDropDownRemoveInput = (index) => {
        const values = [...dropdownValues];
        values.splice(index, 1);
        setDropDownValues(values);
    };

    const handleAddRow = (setter, list) => {
        setter([...list, { id: Date.now(), selectValue: '', value: '' }]);
    };

    const handleRemoveRow = (setter, list, id) => {
        setter(list.filter(item => item.id !== id));
    };
    //validations
    const handleCheckboxChange = (event) => {
        setShowValidations(event);
    };

    const handleValidationChange = (setter, list, id, field, value) => {
        setter(list.map(item => {
            if (item.id === id) {
                if (field == "type") {
                    return { id: item.id, type: value, value: '', unit: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' };
                } else {
                    return { ...item, [field]: value };
                }
            }
            return item;
        }));
        setValidationError("");
    };

    //form buttons
    const handleKeyUpLoginDetailsBtn = (item) => {
        validateFormBtn(item);
    };
    //button validations
    const validateFormBtn = (item) => {
        let isValid = true;

        if (item === "name" || item === "both") {
            if (!btnName || btnName.trim() === "") {
                setBtnNameError("Please Enter English Name");
                isValid = false;
            } else {
                setBtnNameError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            if (!btnNameHi || btnNameHi.trim() === "") {
                setBtnNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setBtnNameHiError("");
            }
        }

      /*   if (item === "family" || item === "both") {
            if (!btnFontFamily) {
                setBtnFontFamilyError("Please Select Font Family");
                isValid = false;
            } else {
                setBtnFontFamilyError("");
            }
        } */

        if (item === "color" || item === "both") {
            if (!btnFontColor || btnFontColor.trim() === "") {
                setBtnFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setBtnFontColorError("");
            }
        }

        if (item === "size" || item === "both") {
            if (!btnFontSize || isNaN(btnFontSize) || btnFontSize <= 0) {
                setBtnFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setBtnFontSizeError("");
            }
        }
        if (item === "bgcolor" || item === "both") {
            if (btnBackgroundColor == btnFontColor) {
                setBtnBackgroundColorError("Font color and background color cannot be same.");
                isValid = false;
            } else {
                setBtnBackgroundColorError("");
            }
        }

        return isValid;
    };
    //edit button 
    const editCategoryBtn = (item) => {
        setEditBtnData(item)
        setBtnName(item?.name_en);
        setBtnNameHi(item?.name_hi);
        setBtnFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setBtnFontColor(item?.font_color)
        setBtnFontSize(item?.font_size)
        setBtnBackgroundColor(item?.background_color)
        window.scrollTo(0, 0)
    }
    //update button
    const updateCategoryBtn = async () => {
        if (!validateFormBtn("both")) return
        try {
            const formData = new FormData();
            formData.append("field_id", editBtnData?.id)
            formData.append("name_en", btnName)
            formData.append("name_hi", btnNameHi)
            formData.append("font_family_id", btnFontFamily?.value ?? "1")
            formData.append("font_color", btnFontColor)
            formData.append("font_size", btnFontSize)
            formData.append("background_color", btnBackgroundColor)
            formData.append("input_field_name", editBtnData?.input_field_name)
            formData.append("required", editBtnData?.required ? editBtnData?.required : "1")
            formData.append("type", editBtnData?.type)
            formData.append("status", editBtnData?.status)
            const response = await updateRegistrationFieldData(formData)
            navigate("/admin/registration")

        } catch (error) {
            console.log("Registration field update error: ", error)
            // navigate("/")
        }
    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-4'>
                    <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b> <span>Registration Form</span>
                </div>
                {editBtnData?.id ? <div className="aod-inner" >
                    <div className={editBtnData?.id ? "aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/registration")}><BsChevronLeft /> Back</button>{editBtnData?.id ? "Update" : "Add"} Form Buttons</div>
                        <div className="row py-4">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div style={{ backgroundColor: btnBackgroundColor, fontSize: `${btnFontSize}px`, color: btnFontColor, fontFamily: btnFontFamily?.label, padding: "10px", textAlign: "center" }}>
                                    {btnName} {btnNameHi}
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <div className="row py-4">
                            <div className="col-md-6 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        English Name
                                    </label>
                                    <input className='w-100' placeholder='Enter English Name' type="text" onKeyUp={() => handleKeyUpLoginDetailsBtn("name")} value={btnName} onChange={(e) => setBtnName(e?.target?.value)} />

                                    {btnNameError && <p className='validate-error'>{btnNameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-6 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Hindi Name
                                    </label>
                                    <input className='w-100' placeholder='Enter Hindi Name' type="text" onKeyUp={() => handleKeyUpLoginDetailsBtn("nameHi")} value={btnNameHi} onChange={(e) => setBtnNameHi(e?.target?.value)} />

                                    {btnNameHiError && <p className='validate-error'>{btnNameHiError}</p>}

                                </div>
                            </div>

                           {/*  <div className="col-md-6 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Family</label>
                                    <Select
                                        styles={customStylesAcc}
                                        theme={selectTheme}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={btnFontFamily}
                                        options={fontsList}
                                        onChange={(e) => { setBtnFontFamily(e); setBtnFontFamilyError(""); }}
                                        placeholder="Select Font Family"
                                        onMenuOpen={() => handleKeyUpLoginDetailsBtn("family")}
                                        onMenuClose={() => handleKeyUpLoginDetailsBtn("family")}
                                    />
                                    {btnFontFamilyError && <p className='validate-error'>{btnFontFamilyError}</p>}
                                </div>
                            </div> */}
                            <div className="col-md-6 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Color</label>
                                    <label className='adm-label-color'>
                                        {btnFontColor ? btnFontColor : <span>Select Font Color</span>}
                                        <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetailsBtn("color")} onBlur={() => handleKeyUpLoginDetailsBtn("color")} value={btnFontColor} onChange={(e) => setBtnFontColor(e?.target?.value)} />
                                    </label>
                                    {btnFontColorError && <p className='validate-error'>{btnFontColorError}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                    <input className='w-100' placeholder='Enter Font Size' type="number" value={btnFontSize} onKeyUp={() => handleKeyUpLoginDetailsBtn("size")}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > 0) {
                                                setBtnFontSize(value);
                                                setBtnFontSizeError("")
                                            } else {
                                                setBtnFontSize("");

                                            }
                                        }} />
                                    {btnFontSizeError && <p className='validate-error'>{btnFontSizeError}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Background Color</label>
                                    <label className='adm-label-color'>
                                        {btnBackgroundColor ? btnBackgroundColor : <span>Select Background Color</span>}
                                        <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetailsBtn("bgcolor")} onBlur={() => handleKeyUpLoginDetailsBtn("bgcolor")} value={btnBackgroundColor} onChange={(e) => setBtnBackgroundColor(e?.target?.value)} />
                                    </label>
                                    {btnBackgroundColorError && <p className='validate-error'>{btnBackgroundColorError}</p>}
                                </div>
                            </div>

                        </div>
                        <div className="row ad-cat-row">
                            <div className="row ad-cat-row">

                                <div className="w-auto gap-4 d-flex">
                                    {/* <button onClick={() => handleEmptyDataBtn()} className='suf-can-butt w-auto px-4'>
                                        Cancel
                                    </button> */}
                                    <button onClick={() => updateCategoryBtn()}>
                                        Update Form Button
                                    </button> </div>

                            </div>

                        </div>

                    </div>
                </div> :

                    <div className="aod-inner">
                        <div className={editData?.id ? "aod-bottom" : "aod-bottom"}>
                            <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/registration")}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Registration Field</div>
                            <div className="row py-4">
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>English Name</label>
                                        <input className='w-100' placeholder='Enter English Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("enName")} value={enName} onChange={(e) => setEnName(e?.target?.value)} disabled="true" />
                                        {enNameError && <p className='validate-error'>{enNameError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Hindi Name</label>
                                        <input className='w-100' placeholder='Enter Hindi Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("hnName")} value={hnName} onChange={(e) => setHnName(e?.target?.value)} />
                                        {hnNameError && <p className='validate-error'>{hnNameError}</p>}
                                    </div>
                                </div>
                              {/*   <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Family</label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={fontFamily}
                                            options={fontsList}
                                            onChange={(e) => { setFontFamily(e); setFontFamilyError("") }}
                                            placeholder="Select Font Family"
                                            onMenuOpen={() => handleKeyUpLoginDetails("fontFamily")}
                                            onMenuClose={() => handleKeyUpLoginDetails("fontFamily")}
                                        />
                                        {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                    </div>
                                </div> */}
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Color</label>
                                        <label className='adm-label-color'>
                                            {fontColor ? fontColor : <span>Select Font Color</span>}
                                            <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("fontColor")} onBlur={() => handleKeyUpLoginDetails("fontColor")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                        </label>
                                        {fontColorError && <p className='validate-error'>{fontColorError}</p>}


                                    </div>
                                </div>
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                        <input className='w-100' placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                            onKeyDown={(e) => {
                                                if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value > 0) {
                                                    setFontSize(value);
                                                    setFontSizeError("")
                                                } else {
                                                    setFontSize("");

                                                }
                                            }} />
                                        {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Input Type</label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={inputType}
                                            options={inputTypeList}
                                            onChange={(e) => { setInputType(e); setInputTypeError("") }}
                                            placeholder="Select Input Type"
                                            onMenuOpen={() => handleKeyUpLoginDetails("inputType")}
                                            onMenuClose={() => handleKeyUpLoginDetails("inputType")}
                                        />
                                        {inputTypeError && <p className='validate-error'>{inputTypeError}</p>}
                                    </div>
                                    {
                                        inputType?.value == "dropdown" ? <>
                                            <div className='suf-input-box aod-form-input'>
                                                <label className='mb-1 w-100'>Dropdown Values</label>
                                            </div>
                                            {dropdownValues.map((input, index) => (
                                                <div className='row ad-reg-res' key={index}>
                                                    <div className="col-md-10">
                                                        <div className='suf-input-box aod-form-input mb-2'>
                                                            <input
                                                                className='w-100'
                                                                placeholder={`Enter Value ${index + 1}`}
                                                                type="text"
                                                                value={input.value}
                                                                onChange={(event) => handleDropdownChange(index, event)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-md-2 p-0 ad-reg-btn'>
                                                        {
                                                            dropdownValues.length > 1 &&
                                                            <span className='ad-reg-delete' onClick={() => handleDropDownRemoveInput(index)}><DeleteIcon /></span>

                                                        }
                                                        {
                                                            dropdownValues.length == index + 1 &&
                                                            <span className='ad-reg-plus' onClick={() => handleDropDownAddInput()}>+</span>

                                                        }
                                                    </div>


                                                </div>
                                            ))}
                                            {dropdownValuesError && <p className='validate-error'>{dropdownValuesError}</p>}
                                        </> : null
                                    }
                                </div>
                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1'>Input Status</label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={inputRequired}
                                            options={statusList}
                                            onChange={(e) => { setInputRequired(e); setInputRequiredError("") }}
                                            placeholder="Select Input Status"
                                            onMenuOpen={() => handleKeyUpLoginDetails("required")}
                                            onMenuClose={() => handleKeyUpLoginDetails("required")}
                                        />
                                        {inputRequiredError && <p className='validate-error'>{inputRequiredError}</p>}
                                    </div>
                                </div>
                                {enName == "Mobile Number" || enName == "Email" ?
                                    <div className="col-md-6 aod-resp-tab">
                                        <div className='suf-input-box aod-form-input mb-2'>
                                            <label className='mb-1'>Verify Otp</label>
                                            <Select
                                                styles={customStylesAcc}
                                                theme={selectTheme}
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                }}
                                                className="menu_open_select"
                                                classNamePrefix="ad-menu_open_select_class"
                                                aria-label="Default select example"
                                                value={verifyOtpstatus.find((x) => x.value == verifyOtp)}
                                                options={verifyOtpstatus}
                                                onChange={(selected) => setVerifyOtp(selected.value)}
                                                placeholder="Select"
                                            />

                                        </div>
                                    </div>: null
                                }

                                <div className="col-md-6 aod-resp-tab">
                                    <div className='suf-input-box aod-form-input mb-2'>
                                        <label className='mb-1 w-100'>Validations</label>
                                        <Select
                                            styles={customStylesAcc}
                                            theme={selectTheme}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className='menu_open_select'
                                            classNamePrefix='ad-menu_open_select_class'
                                            aria-label="Default select example"
                                            value={showValidations}
                                            onChange={handleCheckboxChange}
                                            options={validationRequired}
                                            placeholder="Select "
                                        />
                                        {validationError && <p className='validate-error'>{validationError}</p>}
                                    </div>

                                </div>
                                {showValidations?.value == true && (
                                    <div className="col-md-12">
                                        <div className='row'>
                                            {
                                                inputType?.value == "dropdown" ? null : <>
                                                    <div className='row-head-ad'>Prevent Data from Entering</div>

                                                    {preventData.map((item, index) => (
                                                        <div className='row ad-reg-res'>
                                                            <div className="ad-col-width-1 aod-resp-tab">
                                                                <div className='suf-input-box aod-form-input mb-2'>
                                                                    <Select
                                                                        styles={customStylesAcc}
                                                                        theme={selectTheme}
                                                                        components={{
                                                                            IndicatorSeparator: () => null
                                                                        }}
                                                                        className='menu_open_select'
                                                                        classNamePrefix='ad-menu_open_select_class'
                                                                        aria-label="Default select example"
                                                                        value={item.type}
                                                                        onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'type', e)}
                                                                        options={validationsType}
                                                                        placeholder="Select Type"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                item?.type &&

                                                                <div className="ad-col-width-1 aod-resp-tab">
                                                                    <div className='suf-input-box aod-form-input mb-2'>
                                                                        <Select
                                                                            styles={customStylesAcc}
                                                                            theme={selectTheme}
                                                                            components={{
                                                                                IndicatorSeparator: () => null
                                                                            }}
                                                                            className='menu_open_select'
                                                                            classNamePrefix='ad-menu_open_select_class'
                                                                            aria-label="Default select example"
                                                                            value={item.unit}
                                                                            onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'unit', e)}
                                                                            options={validationsList?.filter(i => i?.validation_type == item?.type?.value)?.map(i => ({ label: i?.name, value: i?.id }))}
                                                                            placeholder="Select Validation"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                item?.unit?.value ? <>
                                                                    {
                                                                        validationsList?.find(i => i?.id == item?.unit?.value)?.input_required == 1 ? <div className="ad-col-width-1 aod-resp-tab">
                                                                            <div className='suf-input-box aod-form-input mb-2'>
                                                                                <input
                                                                                    type="number"
                                                                                    className='w-100'
                                                                                    placeholder="Enter value"
                                                                                    value={item.value}
                                                                                    onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'value', e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div> : validationsList?.find(i => i?.id == item?.unit?.value)?.input_required == 2 ? <div className="ad-col-width-1 ad-reg-btn aod-resp-tab">
                                                                            <div className='suf-input-box aod-form-input mb-2'>
                                                                                <input
                                                                                    type="number"
                                                                                    className='w-50'
                                                                                    placeholder="Lower limit"
                                                                                    value={item?.numberLowerLimit}
                                                                                    onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'numberLowerLimit', e.target.value)}
                                                                                />
                                                                                <input
                                                                                    type="number"
                                                                                    className='w-50'
                                                                                    placeholder="Upper limit"
                                                                                    value={item?.numberUpperLimit}
                                                                                    onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'numberUpperLimit', e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div> : ""
                                                                    }
                                                                </> : null
                                                            }

                                                            {item.unit?.value && (
                                                                <>
                                                                    <div className="ad-col-width-1 aod-resp-tab">
                                                                        <div className='suf-input-box aod-form-input mb-2'>
                                                                            <input
                                                                                type="text"
                                                                                className='w-100'
                                                                                placeholder="Message English"
                                                                                value={item.messageEnglish}
                                                                                onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'messageEnglish', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="ad-col-width-1 aod-resp-tab">
                                                                        <div className='suf-input-box aod-form-input mb-2'>
                                                                            <input
                                                                                type="text"
                                                                                className='w-100'
                                                                                placeholder="Message Hindi"
                                                                                value={item.messageHindi}
                                                                                onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'messageHindi', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className='ad-col-width-btn ad-reg-btn'>
                                                                {
                                                                    preventData.length > 1 &&
                                                                    <span className='ad-reg-delete' onClick={() => handleRemoveRow(setPreventData, preventData, item.id)}><DeleteIcon /></span>

                                                                }
                                                                {
                                                                    preventData.length == index + 1 && preventData.length < 7 &&
                                                                    <span className='ad-reg-plus' onClick={() => handleAddRow(setPreventData, preventData)}>+</span>

                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            }


                                            <div className='row-head-ad'>Other Validations</div>
                                            {otherValidations?.map((item, index) => (
                                                <div className='row ad-reg-res'>
                                                    <div className="ad-col-width-1 aod-resp-tab">
                                                        <div className='suf-input-box aod-form-input mb-2'>
                                                            <Select
                                                                styles={customStylesAcc}
                                                                theme={selectTheme}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                className='menu_open_select'
                                                                classNamePrefix='ad-menu_open_select_class'
                                                                aria-label="Default select example"
                                                                value={item.type}
                                                                onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'type', e)}
                                                                options={validationsType}
                                                                placeholder="Select Type"
                                                            />
                                                        </div>
                                                    </div>

                                                    {
                                                        item?.type && <div className="ad-col-width-1 aod-resp-tab">
                                                            <div className='suf-input-box aod-form-input mb-2'>
                                                                <Select
                                                                    styles={customStylesAcc}
                                                                    theme={selectTheme}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    className='menu_open_select'
                                                                    classNamePrefix='ad-menu_open_select_class'
                                                                    aria-label="Default select example"
                                                                    value={item.unit}
                                                                    onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'unit', e)}
                                                                    options={validationsList?.filter(i => i?.validation_type == item?.type?.value)?.map(i => ({ label: i?.name, value: i?.id }))}
                                                                    placeholder="Select Validation"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        item?.unit?.value ? <>
                                                            {
                                                                validationsList?.find(i => i?.id == item?.unit?.value)?.input_required == 1 ? <div className="ad-col-width-1 aod-resp-tab">
                                                                    <div className='suf-input-box aod-form-input mb-2'>
                                                                        <input
                                                                            type="number"
                                                                            className='w-100'
                                                                            placeholder="Enter value"
                                                                            value={item.value}
                                                                            onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'value', e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div> : validationsList?.find(i => i?.id == item?.unit?.value)?.input_required == 2 ? <div className="ad-col-width-1 ad-reg-btn aod-resp-tab">
                                                                    <div className='suf-input-box aod-form-input mb-2'>
                                                                        <input
                                                                            type="number"
                                                                            className='w-50'
                                                                            placeholder="Lower limit"
                                                                            value={item?.numberLowerLimit}
                                                                            onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'numberLowerLimit', e.target.value)}
                                                                        />
                                                                        <input
                                                                            type="number"
                                                                            className='w-50'
                                                                            placeholder="Upper limit"
                                                                            value={item?.numberUpperLimit}
                                                                            onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'numberUpperLimit', e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div> : ""
                                                            }
                                                        </> : null
                                                    }

                                                    {item.unit?.value && (
                                                        <>
                                                            <div className="ad-col-width-1 aod-resp-tab">
                                                                <div className='suf-input-box aod-form-input mb-2'>
                                                                    <input
                                                                        type="text"
                                                                        className='w-100'
                                                                        placeholder="Message English"
                                                                        value={item.messageEnglish}
                                                                        onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'messageEnglish', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ad-col-width-1 aod-resp-tab">
                                                                <div className='suf-input-box aod-form-input mb-2'>
                                                                    <input
                                                                        type="text"
                                                                        className='w-100'
                                                                        placeholder="Message Hindi"
                                                                        value={item.messageHindi}
                                                                        onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'messageHindi', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className='ad-col-width-btn ad-reg-btn'>
                                                        {
                                                            otherValidations.length > 1 &&
                                                            <span className='ad-reg-delete' onClick={() => handleRemoveRow(setOtherValidations, otherValidations, item.id)}><DeleteIcon /></span>

                                                        }
                                                        {
                                                            otherValidations.length == index + 1 && otherValidations.length < 7 &&
                                                            <span className='ad-reg-plus' onClick={() => handleAddRow(setOtherValidations, otherValidations)}>+</span>

                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>)}


                            </div>
                            <div className="row ad-cat-row">
                                {
                                    editData?.id ?
                                        <>
                                            <div className="w-auto gap-4 d-flex">
                                                {/* <button onClick={() => handleEmptyData()} className='suf-can-butt w-auto px-4'>
                                                Cancel
                                            </button> */}
                                                {
                                                    loading ?
                                                        <button>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </button> : <button onClick={() => updateRegistrationField()}>
                                                            Update
                                                        </button>
                                                }
                                            </div></> :
                                        <div className="w-auto">
                                            <button onClick={addRegistrationField}>
                                                Submit
                                            </button></div>
                                }

                            </div>

                        </div>
                    </div>
                }
            </div>
        </AdminDashboard>
    )
}

export default RegistrationFormAdd
