import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { HiPlus } from "react-icons/hi";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import {
  deletePermissionData,
  getEnableDisablePermissionData,
  getUserPermissionListDataSort,
  getUserPermissionListData,
  getRolesListData,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import sortByIcon from "../../../assets/images/sort.png";
import { Message } from "../../../components/message";
import { formatDateWithSlash } from "../../../Utils/common";

const UserPermissionList = () => {
  const navigate = useNavigate();
  const [userPermissionList, setUserPermissionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderBy, setOrderBy] = useState("asc");
  const [deleteData, setDeleteData] = useState();
  const { state } = useLocation();
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [rolesList, setRolesList] = useState([]);

  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: "success", message: state.message });
      // Clear the message once it is rendered
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  useEffect(() => {
    getUserPermissionList(currentPage);
    getRolesList();
  }, []);

  const getRolesList = async () => {
    try {
      const RolesData = await getRolesListData(1);
      const data = RolesData?.data?.data?.map((i) => ({
        label: i?.title,
        value: i?.id,
      }));
      setRolesList(data);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  const getUserPermissionList = async (pageNo) => {
    try {
      const permissionList = await getUserPermissionListData(pageNo);
      const data = permissionList?.data;
      setUserPermissionList(data);
      // setTotalPages(Math.ceil(permissionList?.data?.total / permissionList?.data?.per_page)); // Calculate total pages
    } catch (error) {
      setApiMessage({ type: "success", message: error.message });
    }
  };

  // const handleEnableDisable = async (e, item) => {
  //     try {
  //         const formData = new FormData();
  //         formData.append(" permission_id", item?.id)
  //         formData.append("role_id", item?.role_id)
  //         formData.append("status", e?.value)
  //         await getEnableDisablePermissionData(formData)
  //         getUserPermissionList(currentPage)
  //     } catch (error) {
  //         console.error(error);
  //     }
  // }

  const deleteUserPermission = async (item) => {
    try {
      const data = await deletePermissionData(item?.id);
      if (data.status == 200) {
        setDeleteData("");
        getUserPermissionList(currentPage);
      } else {
        setDeleteData("");
      }
    } catch (error) {
      console.error(error);
      setDeleteData("");
    }
  };

  // const handlePageChange = (selectedPage) => {
  //     setCurrentPage(selectedPage.selected);
  //     getUserPermissionList(selectedPage.selected + 1); // Increment by 1 if the API starts page numbers from 1
  // };

  const searchSortFromList = async (e, sortBy) => {
    try {
      const searchBy = e.target.value ? e.target.value : "";
      setOrderBy(orderBy == "asc" ? "desc" : "asc");
      const tabList = await getUserPermissionListDataSort(
        sortBy,
        orderBy,
        searchBy
      );
      const data = tabList?.data?.data;
      setUserPermissionList(data);
    } catch (err) {
      console.log("error on categoryList: ", err);
      // navigate("/")
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
            <span>Roles </span>
          </div>
          <button
            className="adm-tab-btn"
            onClick={() => navigate("/admin/permissions/add")}
          >
            <span>
              <HiPlus />
            </span>{" "}
            Add Permsission
          </button>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer d-flex justify-content-between">
              <h4 className="mb-0">Permissions List </h4>
              {/* <input className='w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => searchSortFromList(e, 'title')} /> */}
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-sno">Roles</th>
                    <th className="ad-sno">Users</th>
                    <th className="ad-sno">Created At</th>
                    {/* <th className='ad-long'>status</th> */}
                    <th className="ad-long">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userPermissionList?.map((item, index) => (
                    <tr key={index}>
                      <td>{currentPage * 10 + (index + 1)}</td>
                      <td>
                        {item?.role_id
                          ? rolesList?.find(
                              (option) => option.value == item?.role_id
                            )?.label
                          : "--"}{" "}
                      </td>
                      <td>{item?.title ? item?.title : "--"}</td>
                      <td>
                        {item?.created_at
                          ? formatDateWithSlash(item?.created_at)
                          : "--"}
                      </td>
                      {/* <td>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={item?.status == 1}
                                                            onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </td> */}
                      <td>
                        <span
                          className="ad-cat-edit"
                          onClick={() =>
                            navigate("/admin/roles/permissions/edit", {
                              state: item,
                            })
                          }
                        >
                          <EditIcon />
                        </span>
                        <span
                          className="ad-cat-delete"
                          onClick={() => setDeleteData(item)}
                        >
                          <DeleteIcon />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* <div className="d-flex w-100 justify-content-end">
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={totalPages} // Total number of pages from API
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    onPageChange={handlePageChange} // Method to handle page click
                                    containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete this Tab ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2 d-flex flex-row">
            <button
              className="mx-2"
              onClick={() => {
                deleteUserPermission(deleteData);
              }}
            >
              Yes
            </button>
            <button
              className="suf-can-butt no-btn mx-2"
              onClick={() => setDeleteData("")}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default UserPermissionList;
