/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ImageurlIcons } from '../../../Utils/Utils';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { Table } from 'react-bootstrap';
import { EditIcon } from '../AdminIcon';
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { getLoginFieldsData } from '../../../services/api/admin';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkPermission , permissionsConfig } from '../AdminPermission/PermissionChecker';

const AdminLogin = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(1)
    const [loginData, setLoginData] = useState([])

    const [buttData, setButtData] = useState([]);

    const grantPermissionForEditAdminLogin = checkPermission(permissionsConfig.editAdminLogin.role, permissionsConfig.editAdminLogin.action, permissionsConfig.editAdminLogin.type);

    //login fields list
    const getLoginFields = async () => {
        try {
            const loginFieldsData = await getLoginFieldsData();
            const data = loginFieldsData?.data
            setLoginData(data)
            setButtData([...loginFieldsData?.data?.button_fields, ...loginFieldsData?.data?.link_fields, ...loginFieldsData?.data?.formFields]);
        } catch (err) {
            console.log("loginField data Error: ", err)
        }
    };

    useEffect(() => {
        getLoginFields()
    }, [])

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-4'>
                    <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>  <span>Login Form</span>
                </div>
                {/* Input Fields Table */}
                <div className="aod-inner">
                    <div className={"aod-bottom"}>
                        <div className="adm-tabs-outer">
                            <button className={activeTab == 1 ? "active-adm-tab btn-first" : "inactive-adm-tab btn-first"} onClick={() => setActiveTab(1)}>Login Form Fields </button>
                            <button className={activeTab == 2 ? "active-adm-tab btn-last" : "inactive-adm-tab btn-last"} onClick={() => setActiveTab(2)}>Login Form Buttons </button>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            {
                                activeTab == 1 ? <Table size="sm" className='ad-cat-table mt-4'>
                                    <thead>
                                        <tr>
                                            <th className='ad-sno'>S. No</th>
                                            <th className='ad-long'>Icon</th>
                                            <th className='ad-long'>English Name</th>
                                            <th className='ad-long'>Hindi Name</th>
                                            <th className='ad-long'>Placeholder English Name</th>
                                            <th className='ad-long'>Placeholder Hindi Name</th>
                                            {(grantPermissionForEditAdminLogin) ? (
                                                <th className='ad-long'>Actions</th>
                                            ) : ''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loginData?.input_fields?.map((item, index) => (
                                                <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.icon ? <img className='ad-cat-td-img' src={`${ImageurlIcons()}/${item?.icon}`} alt="" /> : "--"}</td>
                                                    <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                    <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                    <td>{item?.placeholder_en ? item?.placeholder_en : "--"}</td>
                                                    <td>{item?.placeholder_hi ? item?.placeholder_hi : "--"}</td>
                                                    {(grantPermissionForEditAdminLogin) ? (
                                                        <td>

                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                            >
                                                                <span className='ad-cat-edit' onClick={() => navigate("/admin/login/update", { state: item })}><EditIcon /></span>
                                                            </OverlayTrigger>

                                                        </td>
                                                    ) : ''}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table> : activeTab == 2 ?
                                    <Table size="sm" className='ad-cat-table mt-4'>
                                        <thead>
                                            <tr>
                                                <th className='ad-sno'>S. No</th>
                                                <th className='ad-long'>English Name</th>
                                                <th className='ad-long'>Hindi Name</th>
                                                <th className='ad-long'>Type</th>
                                                {(grantPermissionForEditAdminLogin) ? (
                                                    <th className='ad-long'>Actions</th>
                                                ) : ''}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                buttData?.map((item, index) => (
                                                    <tr key={index} className={index % 2 != 0 ? "adm-even" : ""}>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.name_en ? item?.name_en : "--"}</td>
                                                        <td>{item?.name_hi ? item?.name_hi : "--"}</td>
                                                        <td>{item?.type ? item?.type : "--"}</td>
                                                        {(grantPermissionForEditAdminLogin) ? (
                                                            <td>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                                >
                                                                    <span className='ad-cat-edit' onClick={() => navigate("/admin/login/update-buttons", { state: item })}><EditIcon /></span>
                                                                </OverlayTrigger>
                                                            </td>
                                                        ) : ''}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table> : null
                            }

                        </div>

                    </div>
                </div>
                {/* Input Fields Table End */}
            </div>
        </AdminDashboard>
    )
}

export default AdminLogin