import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { GetLocalStorage } from "../Utils/Utils";
import { useEffect, useState } from "react";
import Home from "../pages/OuterPages/Home/Home"; // Assuming Home component is imported

const PrivateRoute = ({ children, roles, redirectPath }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userType = GetLocalStorage("user_type");
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
  
    if (!userType) {
      if (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/signup"
        || location.pathname === "/privacy-policy" || location.pathname === "/contact-us"
        || location.pathname === "/rules" || location.pathname === "/terms-and-conditions"
        || location.pathname === "/disclaimer" || location.pathname === "/terms-and-conditions"
        || location.pathname === "/categoryInfo" || location.pathname === "/forgot-password"
        || location.pathname === "/reset-password" || location.pathname === "/notFound") {
        setIsAllowed(true); // Allow Home page access
      } else {
        navigate("/", { replace: true }); // Redirect to home if user is not logged in
      }
      return;
    }

    // Redirect superadmin or admin if they're not on /admin
    if ((userType === "superadmin" || userType === "admin") && !location.pathname.startsWith("/admin")) {
      if (location.pathname !== "/admin") {
        navigate("/admin", { state: { from: location }, replace: true });
        return;
      }
    }

    // Redirect normal user if they're on /admin
    if (userType === "user" && location.pathname.startsWith("/admin")) {
      if (location.pathname !== "/dashboard") {
        navigate("/dashboard", { state: { from: location }, replace: true });
        return;
      }
    }

    // Check if user has the correct role
    if (!roles.includes(userType)) {
      navigate(redirectPath, { state: { from: location }, replace: true });
      return;
    }

    setIsAllowed(true);
  }, [userType, roles, location.pathname, redirectPath, navigate]);

  // If userType is null, navigate to Home or if it's the homepage, render it
  if (location.pathname === "/") {
    return userType ? (
      userType === "superadmin" || userType === "admin" ? (
        <Navigate to="/admin" replace />
      ) : (
        <Navigate to="/dashboard" replace />
      )
    ) : (
      <Home />
    );
  }

  // Otherwise, only render children if allowed
  return isAllowed ? children : null;
};

export default PrivateRoute;
