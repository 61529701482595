/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react'
import { Apiurl, GetLocalStorage } from '../../../Utils/Utils';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Users = () => {
    const token = GetLocalStorage("userInfo")
    const navigate = useNavigate()
    // const [users, setUsers] = useState([])
    //users list
    const getUsersList = () => {
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            },
            url: `${Apiurl()}/admin/users`,
        };
        axios(option)
            .then((e) => {
                // const data = e?.data?.data

                // setUsers(data)

            })
            .catch((error) => {
                // navigate("/")
            });
    };
    useEffect(() => {
        getUsersList()
    }, [])
    return (
        <div>

        </div>
    )
}

export default Users
