import React from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ComingSoon from "../ComingSoon/ComingSoon";

const Forms = () => {
  const { language, setLanguage } = useLanguage();
  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "फार्म" : "Forms"}
      head={language ? "फार्म" : "Forms"}
    >
      <>
        <ComingSoon></ComingSoon>
      </>
    </Dashboard>
  );
};

export default Forms;
