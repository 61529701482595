import React, { useEffect, useState } from "react";
import { UploadIcon } from "../Application/ApplicationIcon";
import { CgSoftwareUpload } from "react-icons/cg";

const InputTypeText = ({ id, type, name, value, placeholder, onChange, isSubSectionInput, fieldsDisabled, field }) => {
  // const [image, setImage] = useState(value);
  // const handleFileChange = (e) => {
  //   const file = e?.target?.files?.[0];
  //   if (file) {
  //     console.log(file?.name);
  //     setImage(file?.name);
  //     onchange()
  //   }
  // };

  const [maxLength, setMaxLength] = useState(null);

  
  useEffect(() => {
    try{
      if (
        JSON.parse(JSON.parse(field?.validations)?.validation)?.onKeyUp &&
        JSON.parse(JSON.parse(field?.validations)?.validation)?.onKeyUp
          ?.length > 0
      ) {
        const data = JSON.parse(field?.validations);
        const validations = JSON.parse(data?.validation)?.onKeyUp;
    
        for (let item of validations) {
          if (item?.type === "numeric" && item?.unit === "Maximum length") {
            setMaxLength(item.value); // Update state
            break; // Stop further iteration
          }
        }
      }
    }
    catch {
    }
  }, [field])

  return (
    <>
      {type == "file" ? (
        <>
          <input
            type="file"
            id={id}
            name={name}
            className="w-100 ad-cat-input-field-image "
            onChange={onChange}
          />
          <label
            htmlFor={id}
            className="ad-cat-input-upload-image p-0 fire-file-upload-img"
          >
            <p className="ad-cat-text-upload-image">
              <span>
                <CgSoftwareUpload />
              </span>{" "}
              {value ? (
                <p className="mb-0 fw-semibold">
                  {value.includes('\\') ? value.split('\\').pop() : value}
                </p>
              ) : (
                "Upload File"
              )}
            </p>
          </label>
        </>
      ) : (
        <input
          type={type}
          id={id}
          name={name}
          className={type === "checkbox" ? "" : "form-control"}
          value={value} // Controlled input
          onChange={onChange} // Trigger change in parent
          placeholder={placeholder}
          max
          disabled={fieldsDisabled}
          onBlur={(e) => {
            if(isSubSectionInput) {
              if(e.target.value === ""){
                e.target.value = "1"
              }
            }
          }}
          maxLength={maxLength ? maxLength : undefined}
        />
      )}
    </>
   
  );
};

export default InputTypeText;
