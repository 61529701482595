import React, { useEffect, useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { HiPlus } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { Message } from "../../../components/message";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import {
    deletePageDocument,
    getpageDocument,
    handleEnableDisablepageDocument,
} from "../../../services/api/admin";
import { formatDateWithSlash } from "../../../Utils/common";
import { Baseurl } from "../../../Utils/Utils";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { EditIcon } from "../AdminIcon";
/* eslint-disable react-hooks/exhaustive-deps */

import { DeleteIcon } from "../AdminIcon";

const PageDocument = () => {

    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { state } = useLocation();
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [tableDataLoaded, setTableDataLoaded] = useState(false);
    const [deleteData, setDeleteData] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getPageName = (pageId) => {
        const page = pageIdOptions.find((option) => option.value === pageId);
        return page ? page.label : "Unknown Page";
    };

    useEffect(() => {
        if (state && state.message) {
            setApiMessage({ type: 'success', message: state.message });
        }
        pageDocument();
    }, []);

    const pageDocument = async () => {
        try {
            const usersListData = await getpageDocument();
            if (usersListData.status === 200) {
                setUserList(usersListData?.data || []);
                setTableDataLoaded(true);
            }
        } catch (err) {
            setApiMessage({ type: "error", message: err?.message });
        }
    };

    const pageIdOptions = [
        { value: 1, label: "Privacy Policy" },
        { value: 2, label: "Terms and Conditions" },
        { value: 3, label: "Disclaimer" },
        { value: 4, label: "Rules" },
    ];


    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1);
        pageDocument();
    };

    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("document_id", item?.id);
            formData.append("status", e?.value);
            const data = await handleEnableDisablepageDocument(formData);
            if (data?.status == 200) {
                setApiMessage({ type: "success", message: data?.message });
                pageDocument();
            } else {
                setApiMessage({ type: "error", message: data?.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: "error", message: error?.message });
        }
    };

    const deletePageDocumentAPI = async (item) => {
        try {
            const formData = new FormData();
            formData.append("document_id", item?.id);
            const data = await deletePageDocument(formData);
            if (data?.status == 200) {
                setApiMessage({ type: "success", message: data?.message });
                pageDocument();
            } else {
                setApiMessage({ type: "error", message: data?.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: "error", message: error?.message });
        }
    };


    return (
        <AdminDashboard>
            <div className="aod-outer">
                {apiMessage && (
                    <Message
                        apiMessage={apiMessage}
                        setApiMessage={setApiMessage}
                    ></Message>
                )}
                <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
                    <div>
                        <HomeIcon />
                        <b onClick={() => navigate("/admin/")}>Home /</b>
                        <span> Page Document</span>
                    </div>
                    <button
                        className="adm-tab-btn"
                        onClick={() => navigate("/admin/page-document/add")}
                    >
                        <span>
                            <HiPlus />
                        </span>{" "}
                        Add
                    </button>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer">
                            <h4 className="mb-0 w-100">Page Document List</h4>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            <Table size="sm" className="ad-cat-table mt-4">
                                <thead>
                                    <tr>

                                        <th className="ad-long">Id</th>
                                        <th className="ad-long">Page Name</th>
                                        <th className="ad-long">Created At</th>
                                        <th className="ad-long">Updated At</th>
                                        <th className="ad-long">Document Name</th>
                                        <th className="ad-long">Status</th>
                                        <th className="ad-long">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList.length > 0 ? (
                                        userList.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{getPageName(item.page_id)}</td>
                                                <td>{formatDateWithSlash(item.created_at)}</td>
                                                <td>{formatDateWithSlash(item.updated_at)}</td>
                                                <td>
                                                    {item.document ? (
                                                        <a
                                                            href={`${Baseurl()}/${item.document}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {item.document.split("/").pop()}
                                                        </a>
                                                    ) : (
                                                        "No document uploaded"
                                                    )}
                                                </td>
                                                <td>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={item?.status == 1}
                                                            onChange={(e) =>
                                                                handleEnableDisable(
                                                                    e.target.checked
                                                                        ? { value: "1" }
                                                                        : { value: "0" },
                                                                    item
                                                                )
                                                            }
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </td>


                                                <td>
                                                    <div className="action-btn d-flex">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`edit-tooltip-${item.id}`}>
                                                                    Edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span
                                                                className="ad-cat-edit"
                                                                onClick={() => {
                                                                    navigate("/admin/page-document/update", {
                                                                        state: item,
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </span>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`delete-tooltip-${item.id}`}>
                                                                    Delete
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span
                                                                className="ad-cat-delete"
                                                                onClick={() => deletePageDocumentAPI(item)}
                                                            >
                                                                <DeleteIcon />
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <NotFoundTable colSpan="9"></NotFoundTable>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    );
};

export default PageDocument;
