
import React, { useEffect, useState } from 'react';
import { subSectionData } from '../../../services/api/user';

const SubSectionTableApplicationView = ({ showEditIcon, setTabsWholeData, subSectionDetails, fieldValue, tabsWholeData, fieldKey, setErrors, errors }) => {

    const [subSectionFields, setSubSectionFields] = useState([]);
    const [tableData, setTableData] = useState([]);
    // const [errors, setErrors] = useState({});
    const [errorsFields, setErrorsFields] = useState([]);

    useEffect(() => {
        setErrorsFields(errors ?? []); // Sync the `errors` state with `invalidFields`
    }, [errors]);


    useEffect(() => {
        const fetchFields = async () => {
            try {
                const formData = new FormData();
                formData.append("sub_section_id", subSectionDetails?.sub_section_id);

                const response = await subSectionData(formData);
                const jsonValue = response?.data?.json_value;

                if (jsonValue) {
                    const parsedJsonValue = JSON.parse(jsonValue);
                    setSubSectionFields(parsedJsonValue?.items || []);
                } else {
                    setSubSectionFields([]); // Set to an empty array or handle accordingly
                }
                const tableDataKey = `${fieldKey}_json`;
                const tableDataValue = Array.isArray(tabsWholeData[tableDataKey]) ? tabsWholeData[tableDataKey] : [];
                setTableData(tableDataValue);
            } catch (err) {
                console.log(err)
            }
        };

        fetchFields();
    }, [subSectionDetails]);

    useEffect(() => {
        if (!subSectionFields || subSectionFields.length === 0) return;

        const newRowCount = Math.min(Math.max(parseInt(fieldValue, 10) || 1, 1), 9);
        const tableDataKey = `${fieldKey}_json`;

        if (newRowCount !== tableData.length) {
            const updatedTableData = [...tableData];
            if (newRowCount > tableData.length) {
                // Add rows
                for (let i = tableData.length; i < newRowCount; i++) {
                    const newRow = subSectionFields.reduce(
                        (acc, field) => ({ ...acc, [field.name_en]: "" }),
                        {}
                    );
                    updatedTableData.push(newRow);
                }
            } else if (newRowCount < tableData.length) {
                // Remove rows
                updatedTableData.splice(newRowCount);
            }
            setTableData(updatedTableData);
            setTabsWholeData({
                ...tabsWholeData,
                [tableDataKey]: updatedTableData,
                [fieldKey]: newRowCount.toString(),
            });
        }
    }, [fieldValue, fieldKey, subSectionFields, tableData, tabsWholeData, setTabsWholeData]);


    // Function to handle changes in the input fields
    const handleInputChange = (e, rowIndex, fieldName) => {
        const { value } = e.target;
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex] = {
            ...updatedTableData[rowIndex],
            [fieldName]: value,
        };
        setTableData(updatedTableData);
        const updatedErrors = [...errors];
        if (updatedErrors[rowIndex]) {
            delete updatedErrors[rowIndex][fieldName];
        }
        setErrorsFields(updatedErrors);
        const tableDataKey = `${fieldKey}_json`;
        const updatedTabsWholeData = { ...tabsWholeData, [tableDataKey]: updatedTableData };
        setTabsWholeData(updatedTabsWholeData);
    };

    const handleOnBlur = (e, rowIndex, fieldName) => {
        const value = e?.target?.value;
        const updatedErrorsFields = [...errorsFields];

        if (!value || value === '--') {
            // Ensure the row exists in errorsFields
            if (!updatedErrorsFields[rowIndex]) {
                updatedErrorsFields[rowIndex] = {};
            }

            // Set the error message for the specific field
            updatedErrorsFields[rowIndex][fieldName] = `${fieldName} is required`;

            // Update the state with the new errors array
            setErrorsFields(updatedErrorsFields);
        } else {
            // Clear the error if the value is valid
            if (updatedErrorsFields[rowIndex]) {
                delete updatedErrorsFields[rowIndex][fieldName];

                // If the row has no more errors, remove it entirely
                if (Object.keys(updatedErrorsFields[rowIndex]).length === 0) {
                    updatedErrorsFields[rowIndex] = undefined;
                }
            }

            setErrorsFields(updatedErrorsFields);
        }
    };




    return (
        <div className="table-responsive">
            <table className="table bg-table mb-0">
                <thead>
                    <tr>
                        {subSectionFields?.map((item, index) => (
                            <th key={index} scope="col">{item?.name_en}</th>
                        ))}
                        {/* {!showEditIcon && <th scope="col">Actions</th>} */}
                    </tr>
                </thead>
                <tbody>
                    {tableData.length > 0 ? (
                        tableData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {subSectionFields.map((field, colIndex) => (
                                    <td key={colIndex}>
                                        {showEditIcon ? (
                                            <span>{row[field?.name_en] || "--"}</span>
                                        ) : (
                                            <div>

                                                <input
                                                    type="text"
                                                    name={field?.name_en}
                                                    // className={`form-control ${errorsFields[rowIndex]?.[field?.name_en] || ${errorsFields[rowIndex]?.[field?.name_en] ? 'is-invalid' : ''}`}
                                                    className={`form-control ${errorsFields[rowIndex]?.[field?.name_en]} || ${errorsFields[rowIndex]?.[field?.name_en]} ? 'is-invalid' : ''
                                                            }`}
                                                    value={row[field?.name_en] || ""}
                                                    onChange={(e) => handleInputChange(e, rowIndex, field?.name_en)}
                                                    placeholder={field?.placeholder_en}
                                                    onBlur={(e) => handleOnBlur(e, rowIndex, field?.name_en)}
                                                />
                                                {errorsFields[rowIndex]?.[field?.name_en] ? (
                                                    <small className="text-danger">
                                                        {errorsFields[rowIndex][field?.name_en]}
                                                    </small>
                                                ) :
                                                    errors[rowIndex]?.[field?.name_en] ? (
                                                        <small className="text-danger">
                                                            {errors[rowIndex][field?.name_en]}
                                                        </small>
                                                    ) : ''
                                                }

                                            </div>
                                        )}
                                    </td>
                                ))}

                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={subSectionFields?.length}>No data available</td>
                        </tr>
                    )}
                </tbody>
            </table>

        </div>
    );
}

export default SubSectionTableApplicationView

