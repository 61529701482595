import { useEffect } from "react";

export const Message = ({ apiMessage, setApiMessage }) => {

    useEffect(() => {
        if (apiMessage.message) {
            const timeoutId = setTimeout(() => {
                setApiMessage({ type: '', message: '' });
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiMessage, setApiMessage]);

    return (
        <div className={apiMessage.type === 'success' ? 'alert alert-success OtpMess' : apiMessage.type === 'error' ? 'alert alert-danger OtpMess' : ''}>
            {apiMessage.message}
        </div>
    )
}