import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { notificationData, notificationDataRead } from '../../services/api/user'
import { GetLocalStorageParsed } from '../../Utils/Utils'
import AdminDashboard from '../../pages/Admin/AdminDashboard/AdminDashboard'
import Dashboard from '../../pages/Dashboard/Dashboard'
import { useLanguage } from '../LanguageProvider/LanguageProvider '
import { formatDateTimeWithSlash, formatDateWithSlash } from '../../Utils/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const Notification = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [notificationDetails, setNotificationDetails] = useState([]);
    const [notificationFrom, setNotificationFrom] = useState('')
    const userDetail = GetLocalStorageParsed("userDetail");
    const { language, setLanguage } = useLanguage();

    useEffect(() => {
        if (location?.state) {
            setNotificationFrom(location?.state)
        }
    }, [location?.state])

    useEffect(() => {
        const fetchNotification = async () => {
            try {
                const payload = new FormData()
                payload.append('user_id', userDetail?.id)
                if (notificationFrom == "user") {
                    payload.append('user_type', 1)
                } else {
                    payload.append('user_type', 2)
                }
                const response = await notificationData(payload);
                const data = response.data;
                if (response.status== 200) {
                    setNotificationDetails(data);
                    markAllAsRead()
                } else {
                    setNotificationDetails([]);
                }
            } catch (error) {
                setNotificationDetails([]);
            }
        };
        fetchNotification();
    }, []);

    const markAllAsRead = async () => {
        try {
            const payload = new FormData()
            payload.append('user_id', userDetail?.id)
            if (notificationFrom == "user") {
                payload.append('user_type', 1)
            } else {
                payload.append('user_type', 2)
            }

            const data = await notificationDataRead(payload);
            // getNotifications();

        } catch (error) {
            console.error('Failed to mark notifications as read:', error);
        }
    };

    return (
        <>
            {
                notificationFrom == 'user' ? (
                    <Dashboard
                        first={language ? "होम" : "Home"}
                        second={language ? "अधिसूचना" : "Notification"}
                        head={language ? "अधिसूचना" : "Notification"}
                    >
                     
                            <div class="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                                <h4 class="mb-0 w-100">{language ? "अधिसूचना" : "Notification"}</h4>
                                <div class="suf-input-box aod-form-input mb-2 ms-3 w-50">
                                </div>
                            </div>
                            {/* <div class="aod-head"></div> */}
                            <div class="row g-2 mt-2">
                                <div class=" mt-3  user_card_h noti_card_s">
                                    {notificationDetails.length > 0 ? (
                                        notificationDetails.map((item, index) => (
                                            <div class="" key={index + 1}>
                                                <div class="col-md-12 flex-wrap detail_card_desc ">
                                                    <div class="notifi_wrapper">
                                                        <div class="notifi_left_wrapper">
                                                            <div class="bell_icon">
                                                              
                                                                <div class="notification-detail">
                                                                    <p className="notification_d"><FontAwesomeIcon icon={faBell} className='mx-2 b-icon' />{!language ? item?.remark_en : item?.remark_hi}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="notifi_right_wrapper">
                                                            {formatDateTimeWithSlash(item?.created_at)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))) :
                                        (
                                            <div className="notification-ui_dd-content">
                                                <div class="card-body">
                                                    <div class="notification-detail">
                                                        <p>{language ? 'कोई सूचना नहीं' : "No notification"} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                    
                    </Dashboard>
                ) : (
                        <AdminDashboard>
                            <div className="aod-outer adminNotification">
                                <div class="aod-inner pt-0">
                                    <div class="aod-bottom">
                                        <div class="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                                            <h4 class="mb-0 w-100">Notifications</h4>
                                        </div>
                                        <div class="aod-head"></div>
                                        <div class="row g-2 mt-2">
                                            <div class=" mt-3  user_card_h noti_card_s">
                                                {notificationDetails.length > 0 ? (
                                                    notificationDetails.map((item, index) => (
                                                        <div class="" key={index + 1}>
                                                            <div class="col-md-12 flex-wrap detail_card_desc ">
                                                                <div class="notifi_wrapper">
                                                                    <div class="notifi_left_wrapper">
                                                                        <div class="bell_icon">

                                                                            <div class="notification-detail">
                                                                                <p className="notification_d"><FontAwesomeIcon icon={faBell} className='mx-2 b-icon' />{item?.remark_en}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="notifi_right_wrapper">
                                                                        {formatDateTimeWithSlash(item?.created_at)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))) :
                                                    (
                                                        <div className="notification-ui_dd-content">
                                                            <div class="card-body">
                                                                <div class="notification-detail">
                                                                    <p>No notification </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AdminDashboard>
                )}

        </>
    )
}

export default Notification