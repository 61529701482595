import React, { useState , useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import "./Header.css";
import { BsList, BsXLg } from 'react-icons/bs';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
import logoImage from '../../assets/images/uttrLogo.png'
import { getHomepageLogo } from '../../services/api/admin';
import { Baseurl } from '../../Utils/Utils';
const HeaderBottom = ({ homePageContent }) => {
    const { language, setLanguage } = useLanguage()
    const [open, setOpen] = useState(false)
    const [imagePath, setImagePath ] = useState(true);

    useEffect(() => {
        WebsiteLogo();
      }, []);

    const WebsiteLogo = async () => {
        try {
          const usersListData = await getHomepageLogo();
          if (usersListData.status === 200) {
            setImagePath(Baseurl() +"/"+ usersListData?.data?.path);
          }else{
            setImagePath("https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png");
          }
        } catch (err) {
          setImagePath("https://utdbtraveltstg.dilonline.in/static/media/uttrLogo.5f55d1f48deb13a04f1a.png");
        }
      };

    return (
        <div className='hd-cont hd-bg'>
            <Navbar className="bg-body hd-main container p-0">
                <div className='m-0 w-100 d-flex hd-res-cont'>
                    <NavLink to="/" className="navbar-brand"><img className="hd-logo" src={`${imagePath}`} /></NavLink>
                    <div className='hd-toggle'>{
                        open ? <BsXLg onClick={() => setOpen(false)} /> :
                            <BsList onClick={() => setOpen(true)} />}
                    </div>
                    <div id="basic-navbar-nav" className={`${open ? "open-toggle" : "close-toggle"} justify-content-end pe-5`}>
                        <Nav className="hd-links">
                            {
                               homePageContent?.header_menu
                               ?.filter((item) => item.status === "1") // Filter items with status 1
                               .map((item) => (
                                   <NavLink
                                       key={item.id}
                                       to={item.slug}
                                   >
                                       {language ? item?.name_hi : item?.name}
                                   </NavLink>
                               ))
                            }
                        </Nav>
                    </div>
                </div>
            </Navbar>
        </div>
    )
}
export default HeaderBottom