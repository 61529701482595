import React, { useEffect, useRef, useState } from 'react'
import "./ForgotPasswordForm.css";
import { AiOutlineUser } from "react-icons/ai";
import leftImg from "../../../assets/images/bg-down.png"
import rightImg from "../../../assets/images/bg-up.png"
import clock from "../../../assets/images/clock.png"
import { Apiurl, validateonChangeHandle, validateonKeyUpHandle } from '../../../Utils/Utils';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useLanguage } from '../../LanguageProvider/LanguageProvider ';
import { checkEmailMobile, sendMobileOtp, sendOtp, verifyOtp } from '../../../services/api/auth';

const ForgotPasswordForm = ({ forgetPasswordFormData, forgetPasswordAllData, setForgetPasswordFormData }) => {
    const { language, setLanguage } = useLanguage();


    const [checkActiveTab, setCheckActiveTab] = useState({
        email: forgetPasswordFormData?.find((inp) => inp.input_field_name == "email")?.status == "1",
        mobile: forgetPasswordFormData?.find((inp) => inp.input_field_name == "mobile_number")?.status !== "1"
    });
    const [activeTab, setActiveTab] = useState("email")
    const [email, setEmail] = useState("")
    const [showSendEmailButt, setShowSendEmailButt] = useState(false);
    const [showSendMobileButt, setShowSendMobileButt] = useState(false);
    const [emailValue, setEmailValue] = useState();
    const [mobilenumber, setMobileNumber] = useState("")
    const [emailError, setEmailError] = useState("")
    const [mobileError, setMobileError] = useState("")
    const [mobileNumberError, setMobileNumberError] = useState("")
    const inputRefs = useRef([]);
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [otpMobile, setOtpMobile] = useState(new Array(6).fill(''));
    const [otpError, setOtpError] = useState(new Array(6).fill(''));
    const [otpErrorMobile, setOtpErrorMobile] = useState(new Array(6).fill(''));
    const [emailBtn, setEmailBtn] = useState(false)
    const [mobileBtn, setMobileBtn] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [expiry, setExpiry] = useState(0)
    const [expiryMobile, setExpiryMobile] = useState(0)
    const [resendActive, setResendActive] = useState(0)
    const [resendActiveMobile, setResendActiveMobile] = useState(0)
    const [otpSent, setOtpSent] = useState(false);
    const [otpSentMobile, setOtpSentMobile] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingMobile, setLoadingMobile] = useState(false)
    const navigate = useNavigate()
    const [emptyClickMessage, setEmptyClickMessage] = useState();
    const [emptyClickMessageMobile, setEmptyClickMessageMobile] = useState();
    const [showEmailSendSection, setShowEmailSendSection] = useState(false);
    const [showMobileSendSection, setShowMobileSendSection] = useState(false);
    const [showMobileOtp, setShowMobileOtp] = useState()


    useEffect(() => {
        let data = forgetPasswordAllData?.tab_fields?.find((inp) => inp.id == 4)
        if (data?.status == "1") {
            setActiveTab("email")
        } else {
            setActiveTab("mobile")
        }
    }, [forgetPasswordFormData?.length])

    const handeEmailSubmit = async (item) => {

        setExpiry(0)

        setOtp(new Array(6).fill(''))
        const exists = await fetchEmailExistence();
        if (exists.status ===  400 || exists.status === 204) {
            setEmailError({ en: "Email id not registered", hi: "ईमेल आईडी पंजीकृत नहीं है" })
        } else if (item == 'btn') {
            setEmailError("")
            setOtpSent(true);
            handleSendOtp()
        } else {
            setEmailError("")
        }
    }
    const handeMobileSubmit = async (item) => {
        setExpiryMobile(0)

        setOtpMobile(new Array(6).fill(''))
        const exists = await fetchMobileExistence();
        if (exists.status == 400 || exists.status === 204) {
            setMobileError({ en: "Mobile number not registered", hi: "मोबाइल नंबर पंजीकृत नहीं है" })
        } else if (item == 'btn') {
            setMobileError("")
            setOtpSentMobile(true);
            handleSendMobileOtp()
        } else {
            setMobileError("")
        }
    }


    /* const fetchEmailExistence = async () => {
        try {
            const formData = new FormData();
            formData.append("email", emailValue);
            const reponse = await checkEmail(formData).then(res=>{
                return reponse?.data
            }) 
            //await axios.post(`${Apiurl()}/is-email-mobileno-exists`, formData);
            return reponse?.data
        } catch (error) {
        }
    } */

    const fetchEmailExistence = async () => {
        try {
            const formData = new FormData();
            formData.append("email", emailValue);
            return await checkEmailMobile(formData).then(reponse => {
                console.log(reponse)
                return reponse
            })
        } catch (error) {
        }
    };


    const fetchMobileExistence = async () => {
        try {
            const formData = new FormData();
            formData.append("mobile_number", mobileNumberValue);
            return await checkEmailMobile(formData).then(reponse => {
                return reponse
            })
            //const reponse = await axios.post(`${Apiurl()}/is-email-mobileno-exists`, formData);
        } catch (error) {
        }
    }

    const handleSendOtp = async () => {
        try {
            setLoading(true)
            const formData = new FormData();
            formData.append("email", emailValue);
            //const reponse = await axios.post(`${Apiurl()}/forgot-password`, formData);
            return await sendOtp(formData).then(response => {
                console.log(response)
                if (response?.success) {
                    setOtpError("")
                    setShowEmailSendSection(true);
                    setLoading(false)
                    setEmailBtn(true)
                    setIsDisabled(true)
                    setExpiry(900)
                    setResendActive(120)
                }
            }).catch(err => {
                console.log("inside catch block ");
                console.log(err)
            })
        } catch (error) {
            console.error('Error sending OTP:', error);
        }
    }

    const handleSendMobileOtp = async () => {
        try {
            setLoadingMobile(true)
            const formData = new FormData();
            formData.append("mobile_number", mobileNumberValue);
            //const reponse = await axios.post(`${Apiurl()}/sms-otp`, formData);
            return await sendMobileOtp(formData).then(reponse => {
                if (reponse?.data) {
                    setOtpErrorMobile("")
                    setShowMobileSendSection(true);
                    setLoadingMobile(false)
                    setMobileBtn(true)
                    setIsDisabled(true)
                    setExpiryMobile(900)
                    setResendActiveMobile(120)
                    setShowMobileOtp(reponse?.data)
                }
            })
        } catch (error) {
            console.error('Error sending mobile OTP:', error);

        }
    }

    useEffect(() => {
        if (isDisabled) {
            const timer = setTimeout(() => {
                setIsDisabled(false);
            }, 2 * 60 * 1000);
            return () => clearTimeout(timer);
        }
    }, [isDisabled]);
    useEffect(() => {
        if (expiry > 0) {
            const timer = setInterval(() => {
                setExpiry(prevTime => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [expiry]);
    useEffect(() => {
        if (expiryMobile > 0) {
            const timer = setInterval(() => {
                setExpiryMobile(prevTime => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [expiryMobile]);
    useEffect(() => {
        if (resendActive > 0) {

            const Resendtimer = setInterval(() => {
                setResendActive(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(Resendtimer);
        }
    }, [resendActive]);
    useEffect(() => {
        if (resendActiveMobile > 0) {

            const Resendtimer = setInterval(() => {
                setResendActiveMobile(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(Resendtimer);
        }
    }, [resendActiveMobile]);
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };
    const handleChangeOtp = (element, index) => {
        const value = element.value;
        if (/^\d$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (index < 5 && value) {
                inputRefs.current[index + 1].focus();
            }
        } else if (value === '') {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
        }
        setOtpError("");
    };
    const handleChangeOtpMobile = (element, index) => {
        const value = element.value;
        if (/^\d$/.test(value)) {
            const newOtp = [...otpMobile];
            newOtp[index] = value;
            setOtpMobile(newOtp);
            if (index < 5 && value) {
                inputRefs.current[index + 1].focus();
            }
        } else if (value === '') {
            const newOtp = [...otpMobile];
            newOtp[index] = '';
            setOtpMobile(newOtp);
        }
        setOtpErrorMobile("");
    };
    const handleKeyDownOtp = (e, index) => {
        if (e.key === 'Backspace' && !otp[index]) {
            if (index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
        }
    };
    const handleKeyDownOtpMobile = (e, index) => {
        if (e.key === 'Backspace' && !otpMobile[index]) {
            if (index > 0) {
                document.getElementById(`otp-input-mobile-${index - 1}`).focus();
            }
        }
    };
    const handleVerifyOtp = () => {
        let otpnew = otp?.map(i => i).join("")
        if (!otpnew || otpnew.trim() === "" || otpnew.length !== 6) {
            setOtpError("Please enter correct OTP.")
            return
        }


        handlesendVerifyOtp(otpnew)

    }
    const handleVerifyOtpMobile = () => {
        let otpnew = otpMobile?.map(i => i).join("")
        if (!otpnew || otpnew.trim() === "" || otpnew.length !== 6) {
            setOtpErrorMobile("Please enter correct OTP.")
            return
        }
        handlesendVerifyMobileOtp(otpnew)


    }

    const handlesendVerifyOtp = async (otp) => {
        setOtpError("")
        try {
            const data = {
                "type": "forgotPassword",
                "email": emailValue,
                "otp": otp
            }
            //const reponse = await axios.post(`${Apiurl()}/verify-otp`, data);
            return await verifyOtp(data).then(reponse => {
                if (reponse?.success) {
                    navigate("/reset-password", { state: { email: emailValue } })
                } else{
                    setOtpError(reponse?.message)
                }
            });
        } catch (error) {
            setOtpError("The One-Time Password (OTP) you entered isn't valid or may be expired")
        }
    }

    const handlesendVerifyMobileOtp = async (otp) => {
        setOtpErrorMobile("")
        try {
            const data = {
                "type": "forgotPassword",
                "mobile_number": mobileNumberValue,
                "otp": otp
            }
            //const reponse = await axios.post(`${Apiurl()}/verify-otp`, data);
            return await verifyOtp(data).then(reponse => {
                if (reponse?.success) {
                    navigate("/reset-password", { state: { mobile: mobileNumberValue } })
                }
                else {
                    setOtpErrorMobile("The One-Time Password (OTP) you entered isn't valid or may be expired")
                }
            });
        } catch (error) {
            setOtpErrorMobile("The One-Time Password (OTP) you entered isn't valid or may be expired")
        }
    }

    // const [activeInputField, setActiveInput] = useState();
    // Get the active input field based on the active tab
    const activeInputField = forgetPasswordFormData?.find((inp) => {
        if (activeTab == "email") {
            return inp.id === 2; // Email field
        } else if (activeTab == "mobile") {
            return inp.id === 3; // Mobile Number field
        } else {
            // Fallback if both statuses are inactive
            return inp.status === "1";
        }
    });



    const activeButtonField = otpSent
        ? forgetPasswordAllData?.button_fields?.find((btn) => btn.id === 7)
        : forgetPasswordAllData?.button_fields?.find((btn) => btn.id === 6);
    const activeButtonFieldMobile = otpSentMobile
        ? forgetPasswordAllData?.button_fields?.find((btn) => btn.id === 7)
        : forgetPasswordAllData?.button_fields?.find((btn) => btn.id === 6);

    useEffect(() => {
        if (forgetPasswordFormData?.find(field => field.input_field_name === "email")?.error === "" && forgetPasswordFormData?.find(field => field.input_field_name === "email")?.value?.length) {
            const email = forgetPasswordFormData?.find(field => field.input_field_name === 'email')?.value;
            setEmailValue(email)
            setShowSendEmailButt(true);

        } else {
            setEmailError("");
            setEmailBtn(false);
            setShowEmailSendSection(false)
            setOtpSent(false);
            setShowSendEmailButt(false);
        }


    }, [forgetPasswordFormData?.find(field => field.input_field_name === "email")?.error === "" && forgetPasswordFormData?.find(field => field.input_field_name === "email")?.value?.length])
    const [mobileNumberValue, setMobileNumberValue] = useState();


    useEffect(() => {
        if (forgetPasswordFormData?.find(field => field.input_field_name === "mobile_number")?.value?.length >= 10) {
            const mobile = forgetPasswordFormData?.find(field => field.input_field_name === 'mobile_number')?.value;
            setMobileNumberValue(mobile)
            setShowSendMobileButt(true);

        } else {
            setMobileError("");
            setMobileBtn(false);
            setOtpSentMobile(false);
            setShowMobileSendSection(false);
            setShowSendMobileButt(false);
        }


    }, [forgetPasswordFormData?.find(field => field.input_field_name === "mobile_number")?.value])
    const hadleShowMessgae = () => {
        if (!emailValue) {

            setEmptyClickMessage({ hi: "कृपया उपरोक्त विवरण भरें", en: "Please fill above detail" })
        } else {
            setEmptyClickMessage({ hi: "कृपया उपरोक्त फ़ील्ड को सत्यापित करें", en: "Please validate above field" })

        }
    }
    const hadleShowMessgaeMobile = () => {
        if (!mobileNumberValue) {
            setEmptyClickMessageMobile({ hi: "कृपया उपरोक्त विवरण भरें", en: "Please fill above detail" })

        } else {
            setEmptyClickMessageMobile({ hi: "कृपया उपरोक्त फ़ील्ड को सत्यापित करें", en: "Please validate above field" })

        }

    }

    return (
        <section className=' main-outer-container'>
            <img src={leftImg} alt="background-image" className='main-bg-imgleft' />
            <img src={rightImg} alt="background-image" className='main-bg-imgright' />
            <div className='container'>
                <div className='row lf-row d-flex justify-content-center align-items-center'>
                    <div className="col-md-8 lf-col p-0">
                        <div className="lf-outer-con">
                            {forgetPasswordAllData?.form_fields?.map((i) => (<h2 className='lf-heading'>{language ? i?.name_hi : i?.name_en}</h2>))}

                            <div className="lf-inner">
                                <div className="lf-outer">
                                    <div className='mobile-email-outer-div mb-2'>
                                        {forgetPasswordAllData?.tab_fields?.filter(tab => tab.status !== "0")?.length > 1  && forgetPasswordAllData?.tab_fields?.filter(tab => tab.status !== "0").map((tab) => (
                                            <React.Fragment key={tab.id}>
                                                <button
                                                    className={
                                                        (tab.id === 4 && activeTab == "email") || (tab.id === 5 && activeTab == "mobile")
                                                            ? 'email-butt-for'
                                                            : 'email-butt-active'
                                                    }
                                                    style={{
                                                        backgroundColor: tab?.background_color,
                                                        fontSize: `${tab?.font_size}px`,
                                                        color: tab?.font_color
                                                    }}
                                                    disabled={tab.status == "0"}
                                                    onClick={() => {
                                                        if (tab.id === 4) {
                                                            setActiveTab("email")
                                                        } else {
                                                            setActiveTab("mobile")
                                                        }
                                                        // setCheckActiveTab({
                                                        //     email: tab.id === 4,
                                                        //     mobile: tab.id === 5
                                                        // });
                                                        setEmptyClickMessage()
                                                        setEmptyClickMessageMobile()
                                                        setMobileNumberError("");
                                                    }}
                                                >
                                                    {language ? tab?.name_hi : tab?.name_en}
                                                </button>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    {/* Email */}
                                    <div className='lf-input-box mb-2 main-div-input' onClick={() => { setEmptyClickMessage(); setEmptyClickMessageMobile(); }}>
                                        <div className="used-icon-password-visible">
                                            <AiOutlineUser className='lg-input-icon' />
                                            {activeInputField && (
                                                <>
                                                    {activeInputField?.input_field_name === "email" ? (
                                                        <>
                                                            {/* <h2>  {JSON.stringify(activeInputField)}</h2> */}
                                                            <input 
                                                                style={{
                                                                  
                                                                    fontSize: `${activeInputField?.font_size}px`,
                                                                    color: activeInputField?.font_color
                                                                }}
                                                                className='w-100'
                                                                placeholder={language ? activeInputField.placeholder_hi : activeInputField.placeholder_en}
                                                                type={activeInputField.type}
                                                                name={activeInputField.input_field_name}
                                                                value={activeInputField?.value}
                                                                // onKeyUp={validateEmail}
                                                                // onChange={(e) => setEmail(e?.target?.value)}
                                                                onChange={(e) => {
                                                                    validateonChangeHandle(activeInputField, e, forgetPasswordFormData, setForgetPasswordFormData)
                                                                }
                                                                }
                                                                onKeyUp={(e) => { validateonKeyUpHandle(activeInputField, e?.target?.value, forgetPasswordFormData, setForgetPasswordFormData) }}
                                                            // onBlur={() => handeEmailSubmit("input")}
                                                            />
                                                            {activeInputField?.error ? (
                                                                <p className='validate-error-forgot-password'>{language ? activeInputField?.error?.hi : activeInputField?.error?.en}</p>
                                                            ) :
                                                                emailError ? (
                                                                    <p className='sucess-message-otp'>{language ? emailError?.hi : emailError?.en}</p>

                                                                )
                                                                    : null
                                                            }
                                                            {/* {activeInputField?.error && <p className='validate-error'>{language ? activeInputField?.error?.hi : activeInputField?.error?.en}</p>} */}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <input
                                                                className='w-100'
                                                                placeholder={language ? activeInputField.placeholder_hi : activeInputField.placeholder_en}
                                                                type={activeInputField.type}
                                                                maxLength={activeInputField.field_max_length || 10}
                                                                value={activeInputField?.value}
                                                                onChange={(e) => {
                                                                    validateonChangeHandle(activeInputField, e, forgetPasswordFormData, setForgetPasswordFormData)
                                                                }
                                                                }
                                                                onKeyUp={(e) => { validateonKeyUpHandle(activeInputField, e?.target?.value, forgetPasswordFormData, setForgetPasswordFormData) }}
                                                            // onKeyUp={validateMobile}
                                                            // onChange={(e) => {
                                                            //     let inputVal = e.target.value;
                                                            //     if (/^[12345]/.test(inputVal)) {
                                                            //         inputVal = '';
                                                            //     }
                                                            //     inputVal = inputVal.replace(/[^\d]/g, '');
                                                            //     setMobileNumber(inputVal);
                                                            // }}
                                                            />
                                                            {activeInputField?.error ? (
                                                                <p className='validate-error-forgot-password'>{language ? activeInputField?.error?.hi : activeInputField?.error?.en}</p>
                                                            ) :
                                                                mobileError ? (
                                                                    <p className='sucess-message-otp'>{language ? mobileError?.hi : mobileError?.en}</p>

                                                                )
                                                                    : null
                                                            }

                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>

                                    </div>
                                    {/* send otp */}

                                    <div className="lf-btn border-0 py-3">
                                        <p className='validate-error-forgot-password text-center mb-2'>
                                            {
                                                emptyClickMessage ?
                                                    (language ? emptyClickMessage.hi : emptyClickMessage.en) :
                                                    (emptyClickMessageMobile ?
                                                        (language ? emptyClickMessageMobile.hi : emptyClickMessageMobile.en)
                                                        : null
                                                    )
                                            }
                                        </p>
                                        {activeTab == "email" ? (
                                            <>
                                                {loading ? (
                                                    <button>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </button>
                                                ) : emailBtn ? (
                                                    resendActive <= 1 ? (
                                                        <button disabled={isDisabled} onClick={() => handeEmailSubmit("btn")}>
                                                            {language ? "ओटीपी पुनः भेजें" : "Resend OTP"}

                                                        </button>
                                                    ) : (
                                                        <button disabled> {language
                                                            ? `${formatTime(resendActive)} मिनट में पुनः भेजें`
                                                            : `Resend in ${formatTime(resendActive)} minutes`}</button>

                                                    )
                                                ) : (
                                                    <>
                                                        {showSendEmailButt ?
                                                            <button disabled={!showSendEmailButt} onClick={() => handeEmailSubmit("btn")} >{language ? activeButtonField?.name_hi : activeButtonField?.name_en}</button>
                                                            :
                                                            <button onClick={hadleShowMessgae}>{language ? activeButtonField?.name_hi : activeButtonField?.name_en}</button>

                                                        }


                                                    </>
                                                )
                                                }
                                                {showEmailSendSection ?
                                                    <>
                                                        {expiry > 0 && (
                                                            <>
                                                                {emailBtn && (
                                                                    <div className="fp-expire">
                                                                        <img src={clock} alt="clock" />
                                                                        {language
                                                                            ? `ओटीपी ${formatTime(expiry)} मिनट में समाप्त हो जाएगा`
                                                                            : ` OTP will expire in  ${formatTime(expiry)} minutes`}
                                                                    </div>
                                                                )}
                                                                {/* Otp */}
                                                                <div className="fp-otp">
                                                                    {otp.map((data, index) => (
                                                                        <input
                                                                            key={index}
                                                                            id={`otp-input-${index}`}
                                                                            ref={el => (inputRefs.current[index] = el)}
                                                                            type="text"
                                                                            placeholder="*"
                                                                            maxLength="1"
                                                                            className="fp-otp-input"
                                                                            value={otp[index]}
                                                                            onChange={e => handleChangeOtp(e.target, index)}
                                                                            onKeyDown={e => handleKeyDownOtp(e, index)}
                                                                        />
                                                                    ))}
                                                                </div>
                                                                {expiry <= 1 ? (
                                                                    <p className="validate-error">{language ? "ओटीपी समाप्त हो गया!" : "OTP Expired!"}</p>
                                                                ) : otpError ? (
                                                                    <p className="validate-error">{otpError}</p>
                                                                ) : null}
                                                                {/* Verify otp */}
                                                                <div className="lf-btn border-0 pt-3">
                                                                    <button disabled={expiry <= 1} onClick={handleVerifyOtp}>
                                                                        {language ? activeButtonField?.name_hi : activeButtonField?.name_en}
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )}

                                                    </> : null
                                                }

                                            </>

                                        ) : (
                                            <>
                                                {loadingMobile ? (
                                                    <button>
                                                        <Spinner animation="border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </button>
                                                ) :

                                                    mobileBtn ? (
                                                        resendActiveMobile <= 1 ? (

                                                            <button disabled={isDisabled} onClick={() => handeMobileSubmit("btn")}>
                                                                {language ? "ओटीपी पुनः भेजें" : "Resend OTP"}


                                                            </button>


                                                        ) : (
                                                            <button disabled> {language
                                                                ? `${formatTime(resendActiveMobile)} मिनट में पुनः भेजें`
                                                                : `Resend in ${formatTime(resendActiveMobile)} minutes`}</button>

                                                        )
                                                    ) : (
                                                        <>
                                                            {showSendMobileButt ?
                                                                <button disabled={!showSendMobileButt} onClick={() => handeMobileSubmit("btn")}>{language ? activeButtonFieldMobile?.name_hi : activeButtonFieldMobile?.name_en}</button>

                                                                :
                                                                <button onClick={hadleShowMessgaeMobile}>{language ? activeButtonFieldMobile?.name_hi : activeButtonFieldMobile?.name_en}</button>

                                                            }


                                                        </>
                                                    )


                                                }

                                                {showMobileSendSection ? <>
                                                    {expiryMobile > 0 && (
                                                        <>
                                                            {mobileBtn && (
                                                                <>
                                                                        {/* Otp sent, Enter the otp below and verify your number */}
                                                                    <div className="fp-expire">
                                                                        <img src={clock} alt="clock" />
                                                                        {language
                                                                            ? `ओटीपी ${formatTime(expiryMobile)} मिनट में समाप्त हो जाएगा`
                                                                            : ` OTP will expire in  ${formatTime(expiryMobile)} minutes`}
                                                                    </div>
                                                                    <div className='validate-error'>
                                                                        {showMobileOtp && language
                                                                                ? ` ${showMobileOtp} ओटीपी भेजा गया, नीचे ओटीपी दर्ज करें और अपना नंबर सत्यापित करें`
                                                                                : ` ${showMobileOtp} Otp sent, Enter the otp below and verify your number`}
                                                                    </div>
                                                                    <div className="fp-otp">
                                                                        {otpMobile.map((data, index) => (
                                                                            <input
                                                                                key={index}
                                                                                id={`otp-input-mobile-${index}`}
                                                                                ref={el => (inputRefs.current[index] = el)}
                                                                                type="text"
                                                                                placeholder="*"
                                                                                maxLength="1"
                                                                                className="fp-otp-input"
                                                                                value={otpMobile[index]}
                                                                                onChange={e => handleChangeOtpMobile(e.target, index)}
                                                                                onKeyDown={e => handleKeyDownOtpMobile(e, index)}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                    {expiryMobile <= 1 ? (
                                                                        <p className="validate-error">{language ? "ओटीपी समाप्त हो गया!" : "OTP Expired!"}</p>
                                                                    ) : otpErrorMobile ? (
                                                                        <p className="validate-error">{otpErrorMobile}</p>
                                                                    ) : null}
                                                                    {/* Verify otp */}
                                                                    <div className="lf-btn border-0 pt-3">
                                                                        <button disabled={expiryMobile <= 1} onClick={handleVerifyOtpMobile}>
                                                                            {language ? activeButtonFieldMobile?.name_hi : activeButtonFieldMobile?.name_en}
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )}


                                                        </>
                                                    )}
                                                </> : null
                                                }

                                            </>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ForgotPasswordForm
