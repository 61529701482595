import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Box, Typography } from "@mui/material"; // Import Box and Typography for layout and styling

export default function PieActiveArc({ responseData }) {
  // Parse the JSON data dynamically to match PieChart input structure
  const dataKeysToLabels = {
    active: "Active",
    pending: "Pending",
    approved: "Approved",
    rejected: "Rejected",
    draft: "Draft",
  };

  const desktopOS = Object.entries(responseData).map(([key, value]) => ({
    label: dataKeysToLabels[key] || key, // Map keys to human-readable labels
    value: value, // Use corresponding values
  }));

  const colorMapping = {
    Active: "#02b2af",
    Pending: "#2e96ff",
    Approved: "#b800d8",
    Rejected: "#60009b",
    Draft: "#2731c8",
  };


  const valueFormatter = (item) => `${item.value}`;

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* PieChart without legend */}
      <PieChart
        series={[
          {
            data: desktopOS,
            highlightScope: { fade: "global", highlight: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            valueFormatter,
            // Explicitly not adding any legend-related properties here
          },
        ]}
        height={300}
      />

      {/* Custom Legend */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",

          marginTop: 2,
        }}
      >
        {desktopOS?.filter((d) => d.value > 0).map((item) => (
          <Box
            key={item.label}
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: 2,
              marginTop: 1,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: colorMapping[item.label] || "gray",
                marginRight: 1,
              }}
            />
            <Typography variant="body2">{item.label}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
