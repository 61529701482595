import React, { useEffect, useState } from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { CgPen } from "react-icons/cg";
import { GetLocalStorage, GetLocalStorageParsed } from "../../Utils/Utils";
import { checkApplicationsData, getApplicationFields, getApplicationForm, getTabs, uploadDocumentlistData } from "../../services/api/user";
import InputTableData from "../ApplicationDraft/InputTableData";
import DocsUploadDraft from "../ApplicationDraft/DocsUploadDraft";
import DesignationTable from "../ApplicationDraft/DesignationTable";
import { useLocation } from "react-router-dom";
import ComingSoon from "../ComingSoon/ComingSoon";

const ApplicationDraft = () => {
  const { language, setLanguage } = useLanguage();
  const [tabsData, setTabsData] = useState();
  const [isEditable, setisEditable] = useState(true);
  const [showEditIcon, setShowEditIcon] = useState(true);
  const loginCatInfo = GetLocalStorageParsed("LoginSubCatData");
  const location = useLocation();
  const userDetail = GetLocalStorageParsed("userDetail");
  const applicationId = GetLocalStorage("application_id");
  const [isDesignationData, setIsDesignationData] = useState();
  const [documentList, setDocumentList] = useState([]);
  const [isData, setIsData] = useState(true)
  useEffect(() => {
    getApplicationData();
    getTabsBySubCategory();
    docsList()
    fetchApplicationFormData(loginCatInfo?.id)
  }, [])

  const fetchApplicationFormData = async (id) => {
    try {

      const formData = new FormData();
      formData.append("sub_category_id", id);
      formData.append("application_detail_id", applicationId);
      const applicationFormResponse = await getApplicationForm(formData);
      if (
        applicationFormResponse.status === "200" ||
        applicationFormResponse.status === 200
      ) {
      
          // applicationFormResponse?.data?.length > 0 ? setIsData(true) : setIsData(false)
        const fieldsResponse = await getApplicationFields(formData);
        const designationEntry = Object.entries(applicationFormResponse?.data || {}).find(
          ([key]) => key.startsWith("f_designation")
        );
        setIsDesignationData(designationEntry)
      } else {
        setIsData(false)
      }
    } catch (err) {
      console.error("Error fetching application data:", err);
    }
  };

  const getApplicationData = async () => {
    const formData = new FormData();
    formData.append("user_id", userDetail?.id);
    const data = await checkApplicationsData(formData);
    const appStatus = data?.data?.filter((a) => a.id == applicationId)[0]?.status;
    setisEditable(appStatus == "5" || appStatus == '3' ? true : false) 
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const getTabsBySubCategory = async () => {
    try {
      const getLoginSubCabData = GetLocalStorageParsed("LoginSubCatData");
      if (getLoginSubCabData) {
        const payload = {
          category_id: getLoginSubCabData?.id
        };
        const response = await getTabs(payload);
        if (response?.status == 200) {

          const filteredTabs = response?.data.filter(
            (i) => i?.tableName !== null
          );
          setTabsData(filteredTabs);
        }
      }
    } catch (error) {
      console.error("Error fetching tabs:", error);
    }
  };

  const editToggle = () => {
    setShowEditIcon(showEditIcon ? false : true)
  }

  const docsList = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", loginCatInfo?.id);
      formData.append("application_id", applicationId);
      const getList = await uploadDocumentlistData(formData);
      if (getList?.status === 200) {
        const filteredData = getList?.data?.filter(item => {
          // Check if upload_documents exists and is non-empty
          return item.upload_documents && Object.keys(item.upload_documents).length > 0;
        });

        setDocumentList(filteredData);
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <Dashboard 
      first={language ? "होम" : "Home"}
      second={language ? "आवेदन फार्म" : loginCatInfo?.category_name ? loginCatInfo?.category_name + "/Application Form" : loginCatInfo?.name + "/Application Form"}
      head={language ? "आवेदन फार्म" : loginCatInfo?.category_name ? loginCatInfo?.category_name + "/Application Form" : loginCatInfo?.name + "/Application Form"}
    >
      <>
        {isData ?
          <div className="">
            <div className="preview-page-main-wrapper">
              <div className="row">
                <div className="col-md-12">
                  {tabsData && tabsData.length > 0 ? tabsData?.map((item) => (
                    <div key={item?.id}>
                      <InputTableData tabsData={item} isEditable={isEditable} />
                    </div>
                  )) : null}
                </div>
              </div>
              {isDesignationData && isDesignationData?.length > 0  && isDesignationData[1]?.length > 0 ?
                <div className="col-lg-12 mt-4">
                  {tabsData?.some(item => item.name_en == "Additional Details") && isDesignationData &&
                    <div>
                      <DesignationTable tabsData={tabsData} subCatId={loginCatInfo?.id} isEditable={isEditable} />
                    </div>
                  }

                </div> : ''
              }
              {documentList?.length > 0 &&
                <div className="col-md-12 mt-4">
                  <div className="data-wrapper">
                    <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                      <div className="data-wrapper-heading">
                        <h5 className="mb-0">Upload Documents</h5>
                      </div>
                      {isEditable ? <div className="data-wrapper-edit-icon" onClick={editToggle}>
                        {showEditIcon ? <CgPen /> : (
                          <>
                            {/* <div>Save</div> */}
                            <div onClick={editToggle}>{!language ? "Cancel" : "रद्द करें"}</div>

                          </>
                        )}
                      </div> : <></>}
                    </div>
                    <div className="row">
                      <div className="mt-4">
                        <DocsUploadDraft subCatId={loginCatInfo?.id} showEditIcon={showEditIcon} />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          : <ComingSoon />}
      </>
    </Dashboard>
  );
};

export default ApplicationDraft;
