import React, { useState, useEffect } from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import "../User/User.css";
import { changeAdminPasswordAPI, changePasswordAPI } from '../../services/api/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { Message } from '../../components/message'
import AdminDashboard from "../../pages/Admin/AdminDashboard/AdminDashboard";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const ChangePassword = () => {
  const { language, setLanguage } = useLanguage();
  const location = useLocation()
  const navigate = useNavigate()
  // State for password fields and errors
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [reqFrom, setReqFrom] = useState('')
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setCofirmPasswordVisible] = useState(false);
  const [adminOldPasswordVisible, setAdminOldPasswordVisible] = useState(false);
  const [adminNewPasswordVisible, setAdminNewPasswordVisible] = useState(false);
  const [adminConfirmPasswordVisible, setAdminCofirmPasswordVisible] = useState(false);

  useEffect(()=>{
    if (location?.pathname == "/admin/change-password") {
      setReqFrom("admin")
    }else{
      setReqFrom("user")
    }
  }, [location?.state])
  // Validation for passwords
  const validatePasswords = () => {
    const errors = {};
    // Validation for Old Password
    if (!oldPassword) {
      errors.oldPassword_en = "Old password is required";
      errors.oldPassword_hi = "पुराना पासवर्ड आवश्यक है";
    }
    // Validation for New Password
    if (!newPassword) {
      errors.newPassword_en = "New password is required";
      errors.newPassword_hi = "नया पासवर्ड आवश्यक है";
    } else if (newPassword.length < 6) {
      errors.newPassword_en = "Password must be at least 6 characters";
      errors.newPassword_hi = "पासवर्ड कम से कम 6 वर्णों  होना चाहिए";
    } else if (newPassword.length > 39) {
      errors.newPassword_en = "Password cannot exceed 39 characters";
      errors.newPassword_hi = "पासवर्ड अधिकतम 39 वर्णों का होना चाहिए"
    } else {
      // Additional password strength requirements
      if (!/[a-z]/.test(newPassword)) {
        errors.newPassword_en = "Password must contain at least one lowercase letter (a-z)";
        errors.newPassword_hi = "पासवर्ड में कम से कम एक लोअरकेस अक्षर (a-z) होना चाहिए";
      }
      if (!/[A-Z]/.test(newPassword)) {
       errors.newPassword_en = "Password must contain at least one uppercase letter (A-Z)";
        errors.newPassword_hi = "पासवर्ड में कम से कम एक बड़ा अक्षर (A-Z) होना चाहिए";
      }
      if (!/[0-9]/.test(newPassword)) {
        errors.newPassword_en = "Password must contain at least one number (0-9)";
        errors.newPassword_hi = "पासवर्ड में कम से कम एक नंबर होना चाहिए (0-9)";
      }
      if (!/[@$!%*?&]/.test(newPassword)) {
       errors.newPassword_en = "Password must contain at least one special character (@$!%*?&)";
        errors.newPassword_hi = "पासवर्ड में कम से कम एक विशेष अक्षर (@$!%*?&) होना चाहिए";
      }
    }
    // Ensure Old Password and New Password are not the same
    if (oldPassword && newPassword && oldPassword === newPassword) {
      errors.newPassword_en = "New password must be different from the old password";
      errors.newPassword_hi = "नया पासवर्ड पुराने पासवर्ड से अलग होना चाहिए";
    }
    // Validation for Confirm Password
    if (!confirmPassword) {
      errors.confirmPasswor_en = "Please confirm your new password";
      errors.confirmPassword_hi = "कृपया अपने नये पासवर्ड की पुष्टि करें";
    } else if (newPassword !== confirmPassword) {
      errors.newPassword_en = "Passwords do not match";
      errors.newPassword_hi = "सांकेतिक शब्द मेल नहीं खाते";
    }
    // Set error messages in English and Hindi
    if (Object.keys(errors).length > 0) {
      setErrors({
        ...errors,
        hindi: {
          oldPassword: "पुराना पासवर्ड आवश्यक है",
          newPassword: {
            required: "नया पासवर्ड आवश्यक है",
            length: "पासवर्ड कम से कम 6 और अधिकतम 39 वर्णों का होना चाहिए",
            lowercase: "पासवर्ड में कम से कम एक लोअरकेस अक्षर होना चाहिए",
            uppercase: "पासवर्ड में कम से कम एक बड़ा (अपरकेस) अक्षर होना चाहिए",
            numeric: "पासवर्ड में कम से कम एक नंबर होना चाहिए [0-9]",
            special: "पासवर्ड में कम से कम एक विशेष वर्ण होना चाहिए (@$!%*?&)",
            different: "नया पासवर्ड पुराने पासवर्ड से भिन्न होना चाहिए"
          },
          confirmPassword: {
            required: "कृपया अपना नया पासवर्ड पुष्टि करें",
            match: "पासवर्ड मेल नहीं खाते"
          }
        }
      });
    } else {
      setErrors(errors);
    }
  
    return Object.keys(errors).length === 0;
  };
  
  

  const handleSubmitLogin = async () => {
    if (!validatePasswords()) return;
  
    try {
      const formData = new FormData();
      formData.append("old_password", oldPassword);
      formData.append("password", newPassword);
      formData.append("password_confirmation", confirmPassword);
  
   
       const response = await changePasswordAPI(formData);

       // Assuming the API response structure as provided
       if (response?.status === 200) {
         setApiMessage({ type: "success", message: response.message });
       } else if (response?.data) {
         // Handle validation errors from the API response
         const errorMessages = [];
         if (response.data.password && response.data.password.length > 0) {
           errorMessages.push(response.data.password[0]);
         }
         if (response.data.password_confirmation && response.data.password_confirmation.length > 0) {
           errorMessages.push(response.data.password_confirmation[0]);
         }

         setApiMessage({
           type: "error",
           message: errorMessages.join(", ") || response.message,
         });

      
     } else if (response?.message) {
      setApiMessage({
        type: "error",
        message: response.message,
      });
     }
    } catch (err) {
      setApiMessage({ type: "error", message: err.message });
    }
  };
  
  const handleAdminSubmitLogin = async () => {
    if (!validatePasswords()) return;
    try{
      const formData = new FormData();
      formData.append("old_password", oldPassword);
      formData.append("password", newPassword);
      formData.append("password_confirmation", confirmPassword);


      const response = await changeAdminPasswordAPI(formData);

      // Assuming the API response structure as provided
      if (response?.status === 200) {
        setApiMessage({ type: "success", message: response.message });
      } else if (response?.data) {
        // Handle validation errors from the API response
        const errorMessages = [];
        if (response.data.password && response.data.password.length > 0) {
          errorMessages.push(response.data.password[0]);
        }
        if (response.data.password_confirmation && response.data.password_confirmation.length > 0) {
          errorMessages.push(response.data.password_confirmation[0]);
        }

        setApiMessage({
          type: "error",
          message: errorMessages.join(", ") || response.message,
        });
      }
    }catch(err){
      setApiMessage({ type: "error", message: err.message });
    }

}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
     { reqFrom == "user" ? 
        <Dashboard
          first={language ? "होम" : "Home"}
          second={language ? "प्रोफ़ाइल संपादित करें" : "Change Password"}
          head={language ? "प्रोफ़ाइल संपादित करें" : "Change Password"}
        > 
          <div className="aod-bottom">
            {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3 f_fields pwd-fields">
                  <label className="form-label">
                    <span className="en-label">{language ? "पुराना पासवर्ड" : "Old Password"}</span>
                  </label>
                  <input
                    type={oldPasswordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder={language ? "पुराना पासवर्ड डालें" : "Enter Old Password"}
                    value={oldPassword}
                    onChange={(e) => { setOldPassword(e.target.value); errors.oldPassword_en = ""; errors.oldPassword_hi = "" }}
                  />
                  {
                     oldPasswordVisible ?
                      <BsEye onClick={() => setOldPasswordVisible(!oldPasswordVisible)} /> :
                      <BsEyeSlash onClick={() => setOldPasswordVisible(!oldPasswordVisible)} />
                  }
                  {(errors.oldPassword_en || errors.oldPassword_hi) && <small className="text-danger">{language ? errors.oldPassword_hi : errors.oldPassword_en}</small>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields pwd-fields">
                  <label className="form-label">
                    <span className="en-label">{language ? "नया पासवर्ड" : "New Password"}</span>
                  </label>
                  <input
                    type={newPasswordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder={language ? "नया पासवर्ड डालें" : "Enter New Password"}
                    value={newPassword}
                    onChange={(e) => { setNewPassword(e.target.value); errors.newPassword_en = ""; errors.newPassword_hi = "" }}
                  />
                  {
                     newPasswordVisible ?
                      <BsEye onClick={() => setNewPasswordVisible(!newPasswordVisible)} /> :
                      <BsEyeSlash onClick={() => setNewPasswordVisible(!newPasswordVisible)} />
                  }
                  {(errors.newPassword_en || errors.newPassword_hi) && <small className="text-danger">{language ? errors.newPassword_hi : errors.newPassword_en}</small>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3 f_fields pwd-fields">
                  <label className="form-label">
                    <span className="en-label">{language ? "पासवर्ड की पुष्टि कीजिये" : "Confirm Password"}</span>
                  </label>
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder={language ? "पासवर्ड की पुष्टि कीजिये" : "Confirm Password"}
                    value={confirmPassword}
                    onChange={(e) => { setConfirmPassword(e.target.value); errors.confirmPassword_en = ""; errors.confirmPassword_hi = "" }}
                  />
                  {
                     confirmPasswordVisible ?
                      <BsEye onClick={() => setCofirmPasswordVisible(!confirmPasswordVisible)} /> :
                      <BsEyeSlash onClick={() => setCofirmPasswordVisible(!confirmPasswordVisible)} />
                  }
                  {(errors.confirmPassword_en || errors.confirmPassword_hi) && <small className="text-danger">{language ? errors.confirmPassword_hi : errors.confirmPasswor_en}</small>}
                </div>
              </div>
            </div>
            <button onClick={handleSubmitLogin} type="submit" className="btn btn-primary user-theme-btn mt-3">
              {language ? 'जमा करना' : 'Submit'}
            </button>
          </div>
        </Dashboard> :
        <AdminDashboard> 
          <div className='aod-outer'>
          {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
          <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
            <div>
              <b onClick={() => navigate("/admin/")}>Home  /</b><span> Change Password</span>
            </div>
          </div>
         <div className="aod-bottom">
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3 f_fields pwd-fields">
                <label className="form-label">
                  <span className="en-label">{language ? "पुराना पासवर्ड" : "Old Password"}</span>
                </label>
                <input
                  type={adminOldPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder={language ? "पुराना पासवर्ड डालें" : "Enter Old Password"}
                  value={oldPassword}
                  onChange={(e) => {setOldPassword(e.target.value); errors.oldPassword_en = ""; errors.oldPassword_hi = ""}}
                />
                {
                  adminOldPasswordVisible ?
                    <BsEye onClick={() => setAdminOldPasswordVisible(!adminOldPasswordVisible)} /> :
                    <BsEyeSlash onClick={() => setAdminOldPasswordVisible(!adminOldPasswordVisible)} />
                }
                {(errors.oldPassword_en || errors.oldPassword_hi) && <small className="text-danger">{language ? errors.oldPassword_hi : errors.oldPassword_en}</small>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3 f_fields pwd-fields">
                <label className="form-label">
                  <span className="en-label">{language ? "नया पासवर्ड" : "New Password"}</span>
                </label>
                <input
                  type={adminNewPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder={language ? "नया पासवर्ड डालें" : "Enter New Password"}
                  value={newPassword}
                  onChange={(e) => { setNewPassword(e.target.value); errors.newPassword_en = ""; errors.newPassword_hi = "" }}
                />
                {
                  adminNewPasswordVisible ?
                    <BsEye onClick={() => setAdminNewPasswordVisible(!adminNewPasswordVisible)} /> :
                    <BsEyeSlash onClick={() => setAdminNewPasswordVisible(!adminNewPasswordVisible)} />
                }
                {(errors.newPassword_en || errors.newPassword_hi) && <small className="text-danger">{language ? errors.newPassword_hi : errors.newPassword_en}</small>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3 f_fields pwd-fields">
                <label className="form-label">
                  <span className="en-label">{language ? "पासवर्ड की पुष्टि कीजिये" : "Confirm Password"}</span>
                </label>
                <input
                  type={adminConfirmPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder={language ? "पासवर्ड की पुष्टि कीजिये" : "Confirm Password"}
                  value={confirmPassword}
                  onChange={(e) => { setConfirmPassword(e.target.value); errors.confirmPassword_en = ""; errors.confirmPassword_hi = "" }}
                />
                {
                  adminConfirmPasswordVisible ?
                    <BsEye onClick={() => setAdminCofirmPasswordVisible(!adminConfirmPasswordVisible)} /> :
                    <BsEyeSlash onClick={() => setAdminCofirmPasswordVisible(!adminConfirmPasswordVisible)} />
                }
                {(errors.confirmPassword_en || errors.confirmPassword_hi) && <small className="text-danger">{language ? errors.confirmPassword_hi : errors.confirmPasswor_en}</small>}
              </div>
            </div>
          </div>
              <div className=" update-btns-block mt-3" >
                <button onClick={handleAdminSubmitLogin}>
                  Submit
                </button>
            </div>
        </div>
        </div>
        </AdminDashboard>
       
     }
       
    </div>
  );
};

export default ChangePassword;
