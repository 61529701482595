import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { HiPlus } from "react-icons/hi";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import {
  deleteRoleData,
  getEnableDisableRolesData,
  getRoleListDataSort,
  getRolesListData,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { DeleteIcon, EditIcon, PermissionIcon } from "../AdminIcon";
import { Message } from "../../../components/message";
import { formatDateWithSlash } from "../../../Utils/common";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const RolesList = () => {
  const navigate = useNavigate();
  const [rolesList, setRolesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderBy, setOrderBy] = useState("asc");
  const [deleteData, setDeleteData] = useState();
  const { state } = useLocation();
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  const grantPermissionForAddRoles = checkPermission(
    permissionsConfig.addRole.role,
    permissionsConfig.addRole.action,
    permissionsConfig.addRole.type
  );
  const grantPermissionForEditRoles = checkPermission(
    permissionsConfig.editRole.role,
    permissionsConfig.editRole.action,
    permissionsConfig.editRole.type
  );
  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: "success", message: state.message });
      // Clear the message once it is rendered
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  useEffect(() => {
    getRolesList(currentPage);
  }, []);

  const getRolesList = async (pageNo) => {
    try {
      const roleList = await getRolesListData(pageNo);
      const data = roleList?.data?.data;
      setRolesList(data);
      setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
      setTableDataLoaded(true);
      setTotalPages(
        Math.ceil(roleList?.data?.total / roleList?.data?.per_page)
      ); // Calculate total pages
    } catch (error) {
      console.log("error on tab list: ", error);
    }
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("role_id", item?.id);
      formData.append("status", e?.value);
      const data = await getEnableDisableRolesData(formData);
      if (data?.status == 200) {
        setApiMessage({ type: "success", message: data.message });
        getRolesList(currentPage);
      }
    } catch (error) {
      setApiMessage({ type: "error", message: error.message });
    }
  };

  const deleteRole = async (item) => {
    try {
      // const formData = new FormData();
      // formData.append("role_id", item?.id)
      const data = await deleteRoleData(item?.id);
      if (data.status == 200) {
        setDeleteData("");
        getRolesList(currentPage);
        setApiMessage({ type: "success", message: data.message });
      } else {
        setDeleteData("");
      }
    } catch (error) {
      console.error(error);
      setDeleteData("");
      setApiMessage({ type: "error", message: error.message });
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    getRolesList(selectedPage.selected + 1); // Increment by 1 if the API starts page numbers from 1
  };

  const searchSortFromList = async (e, sortBy) => {
    try {
      const searchBy = e.target.value ? e.target.value : "";
      const validInput = /^[A-Za-z\s]*$/;
      if (!validInput.test(searchBy)) {
        e.target.value = searchBy.replace(/[^A-Za-z\s]/g, "");
        return;
      }
      setOrderBy(orderBy === "asc" ? "desc" : "asc");
      const tabList = await getRoleListDataSort(sortBy, orderBy, searchBy);
      const data = tabList?.data?.data;
      setRolesList(data);
    } catch (err) {
      console.log("error on categoryList: ", err);
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
            <span>Roles and Permissions</span>
          </div>
          {grantPermissionForAddRoles ? (
            <button
              className="adm-tab-btn"
              onClick={() => navigate("/admin/roles/add")}
            >
              <span>
                <HiPlus />
              </span>{" "}
              Add
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-inner pt-0">
            <div className="aod-bottom">
              <div className="adm-tabs-outer d-flex justify-content-between">
                <h4 className="mb-0">Roles </h4>
                {/* <input className='w-25 px-2 form-control' placeholder='search' type="text"
                                onChange={(e) => searchSortFromList(e, 'title')}
                            /> */}
                <div className="position-relative  w-md-50 w-25">
                  <input
                    className="px-2 form-control"
                    placeholder="Search"
                    type="text"
                    value={searchTerm} // Add a state for the input value
                    onChange={(e) => {
                      setSearchTerm(e.target.value); // Update the state
                      searchSortFromList(e, "title");
                    }}
                  />
                  {searchTerm && ( // Show the clear button only when there's text
                    <button
                      className="btn btn-clear position-absolute"
                      onClick={(e) => {
                        setSearchTerm(""); // Clear the input value
                        searchSortFromList(e, "title"); // Fetch data with empty search term
                      }}
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        border: "none",
                        background: "transparent",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                    >
                      &times; {/* This is the "X" icon */}
                    </button>
                  )}
                </div>
              </div>
              <div className="aod-head"></div>
              <div className="ad-reg-table">
                <Table size="sm" className="ad-cat-table mt-4">
                  <thead>
                    <tr>
                      <th className="ad-sno">S. No</th>
                      <th
                        className="ad-long cursor-pointer"
                        onClick={(e) => searchSortFromList(e, "title")}
                      >
                        Name{" "}
                        <span>
                          <i className="fa-solid fa-sort"></i>
                        </span>
                      </th>
                      <th
                        className="ad-long cursor-pointer"
                        onClick={(e) => searchSortFromList(e, "created_at")}
                      >
                        Created At{" "}
                        <span>
                          <i className="fa-solid fa-sort"></i>
                        </span>
                      </th>
                      {grantPermissionForEditRoles ? (
                        <>
                          <th className="ad-long">Status</th>

                          <th
                            className="ad-long"
                            style={{ paddingLeft: "30px" }}
                          >
                            Actions
                          </th>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {rolesList?.length > 0 ? (
                      rolesList?.map((item, index) => (
                        <tr key={index}>
                          <td>{currentPage * 10 + (index + 1)}</td>
                          <td>{item?.title ? item?.title : "--"}</td>
                          <td>
                            {item?.created_at
                              ? formatDateWithSlash(item?.created_at)
                              : "--"}
                          </td>
                          {grantPermissionForEditRoles ? (
                            <>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.status == 1}
                                    onChange={(e) =>
                                      handleEnableDisable(
                                        e.target.checked
                                          ? { value: "1" }
                                          : { value: "0" },
                                        item
                                      )
                                    }
                                  />
                                  <span className="slider"></span>
                                </label>
                              </td>

                              <td>
                                <div className="icon-col">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`edit-tooltip-${item.id}`}>
                                        Edit
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      className="ad-cat-edit"
                                      onClick={() =>
                                        navigate("/admin/roles/update", {
                                          state: item,
                                        })
                                      }
                                    >
                                      <EditIcon />
                                    </span>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`delete-tooltip-${item.id}`}>
                                        Delete
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      className="ad-cat-delete"
                                      onClick={() => setDeleteData(item)}
                                    >
                                      <DeleteIcon />
                                    </span>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id={`permission-tooltip-${item.id}`}
                                      >
                                        Permissions
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      className="ad-cat-permission"
                                      onClick={() => {
                                        navigate(
                                          "/admin/roles/permissions/edit",
                                          { state: item }
                                        );
                                      }}
                                    >
                                      <PermissionIcon />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </>
                          ) : (
                            ""
                          )}
                        </tr>
                      ))
                    ) : tableDataLoaded ? (
                      <NotFoundTable colSpan="8"></NotFoundTable>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
                {totalPages > 1 && rolesList?.length > 0 && (
                  <div className="d-flex w-100 justify-content-end">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={totalPages} // Total number of pages from API
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageChange} // Method to handle page click
                      containerClassName={"pagination justify-content-center"} // Custom CSS classes
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete this Role ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2 d-flex flex-row">
            <button
              className="mx-2"
              onClick={() => {
                deleteRole(deleteData);
              }}
            >
              Yes
            </button>
            <button
              className="suf-can-butt no-btn mx-2"
              onClick={() => setDeleteData("")}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default RolesList;
