import React from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'

const UserLog = () => {
    return (
        <AdminDashboard>

        </AdminDashboard>
    )
}

export default UserLog
