import React from "react";
import "./Banner.css";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
const Banner = ({ first, second, head }) => {
  const { language, setLanguage } = useLanguage();

  const location = useLocation();
  return (
    <section className="banner-section banner pt-5">
      <div className="banner-inner">
        <div className="container">
          <div className="row ">
            <h2 className="banner-head">{second}</h2>
            <div className="banner-breadcrumb">
              <h5>
                <Link to="/" className="bread_crumb_f">
                  {first}{" "}
                </Link>{" "}
                <span> /{head}</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
