import React, { useState, useEffect } from 'react';
import { GetLocalStorageParsed } from '../../../Utils/Utils';

export function checkPermission(adminParentModuleId, adminModuleId, permissionType) {
    // Fetch the user details from local storage synchronously
    let userDetail = {};
    const storedData = sessionStorage.getItem("userDetail");

    if (storedData && storedData !== "undefined") {
        try {
            userDetail = JSON.parse(storedData);
        } catch (error) {
            return true;
        }
    } else {
        return true;
    }

    // Ensure superadmin check works correctly
    if (userDetail.user_type && userDetail.user_type === '1' && userDetail.roles === 'superadmin') {
        return true;
    }

    if (userDetail && userDetail.permissions && userDetail.permissions.length > 0) {
        const permission = userDetail.permissions.find(
            (perm) =>
                perm.admin_parent_module_id === adminParentModuleId &&
                perm.admin_module_id === adminModuleId
        );

        // Return true if the permission matches and is set to "true", otherwise false
        return !!(permission && permission.module_permission[permissionType] === "true");
    }

    // If no valid permissions or conditions match, return true as per the new requirement
    return true;
}

const PermissionChecker = ({ adminParentModuleId, adminModuleId, permissionType, children }) => {
    const [hasPermission, setHasPermission] = useState(() =>
        checkPermission(adminParentModuleId, adminModuleId, permissionType)
    );

    useEffect(() => {
        // Perform permission check synchronously
        const permission = checkPermission(adminParentModuleId, adminModuleId, permissionType);
        setHasPermission(permission);
    }, [adminParentModuleId, adminModuleId, permissionType]);

    return hasPermission ? <>{children}</> : null;
};

export const permissionsConfig = {
    // View categories
    viewCategories: { role: "3", action: "17", type: "view" },
    viewSubCategories: { role: "3", action: "18", type: "view" },
    viewGuidelines: { role: "3", action: "19", type: "view" },
    viewCategoryFAQList: { role: "3", action: "60", type: "view" },
    viewDocumentList: { role: "3", action: "61", type: "view" },

    // Form permissions
    viewFormTabs: { role: "58", action: "59", type: "view" },
    viewFormSection: { role: "58", action: "62", type: "view" },
    viewFormSubSection: { role: "58", action: "63", type: "view" },
    viewFormFields: { role: "58", action: "64", type: "view" },
    viewFormDropdowns: { role: "58", action: "65", type: "view" },
    viewEmployeeDesignation: { role: "58", action: "66", type: "view" },

    // Application permissions
    totalApplications: { role: "5", action: "23", type: "view" },
    viewPending: { role: "5", action: "24", type: "view" },
    viewApproved: { role: "5", action: "25", type: "view" },
    viewRejected: { role: "5", action: "26", type: "view" },

    // Certificate permissions
    certificates: { role: "6", action: "27", type: "view" },
    expired: { role: "6", action: "29", type: "view" },

    // Authentication permissions
    viewLogin: { role: "10", action: "38", type: "view" },
    viewResetPassword: { role: "10", action: "39", type: "view" },
    viewForgotPassword: { role: "10", action: "40", type: "view" },
    viewRegistration: { role: "10", action: "41", type: "view" },

    // Roles and permissions
    rolesAndPermissions: { role: "70", action: "71", type: "view" },
    viewAdminUser: { role: "70", action: "72", type: "view" },

    // Management permissions
    manageStates: { role: "11", action: "49", type: "view" },
    manageDistricts: { role: "11", action: "50", type: "view" },
    manageCities: { role: "11", action: "51", type: "view" },
    viewSMSSetting: { role: "11", action: "52", type: "view" },
    viewEmailSetting: { role: "11", action: "53", type: "view" },

    editForgotPassword: { role: "10", action: "40", type: "edit" },
    editGuidelines: { role: "3", action: "19", type: "edit" },
    addGuidelines: { role: "3", action: "19", type: "add" },
    editAdminLogin: { role: "10", action: "38", type: "edit" },
    editAdminResetPassword: { role: "10", action: "39", type: "edit" },
    addFormSubSection: { role: "58", action: "63", type: "add" },
    editFormSubSection: { role: "58", action: "63", type: "edit" },
    addAdminUser: { role: "70", action: "72", type: "add" },
    editAdminUser: { role: "70", action: "72", type: "edit" },
    addFormSection: { role: "58", action: "62", type: "add" },
    editFormSection: { role: "58", action: "62", type: "edit" },
    addApplicationTab: { role: "58", action: "59", type: "add" },
    editApplicationTab: { role: "58", action: "59", type: "edit" },
    addCategories: { role: "3", action: "17", type: "add" },
    editCategories: { role: "3", action: "17", type: "edit" },
    addEmployeeDesignation: { role: "58", action: "65", type: "add" },
    editEmployeeDesignation: { role: "58", action: "65", type: "edit" },
    addFormFields: { role: "58", action: "64", type: "add" },
    editFormFields: { role: "58", action: "64", type: "edit" },
    editRegistrationFields: { role: "10", action: "41", type: "edit" },
    addCities: { role: "11", action: "50", type: "add" },
    editCities: { role: "11", action: "50", type: "edit" },
    addDistrict: { role: "11", action: "50", type: "add" },
    editDistrict: { role: "11", action: "50", type: "edit" },
    addState: { role: "11", action: "49", type: "add" },
    editState: { role: "11", action: "49", type: "edit" },
    addSubCategory: { role: "3", action: "18", type: "add" },
    editSubCategory: { role: "3", action: "18", type: "edit" },
    addDocumentList: { role: "3", action: "61", type: "add" },
    editDocumentList: { role: "3", action: "61", type: "edit" },
    addRole: { role: "70", action: "71", type: "add" },
    editRole: { role: "70", action: "71", type: "edit" },


};


export default PermissionChecker;
