import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";

const SignUpModal = (props) => {
  const { language, setLanguage } = useLanguage();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div
          className="welcome-popup text-center"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3>
            {language
              ? `उत्तराखंड पर्यटन में आपका स्वागत है!`
              : `Welcome to Uttarakhand Tourism! `}
          </h3>
          <p className="homestay-text">
            {language ? `(ट्रेवल ट्रेड)` : `(Travel Trade)`}
          </p>
          <p className="homestay-subtext">
            {language
              ? `अपना खाता बनाने के लिए, कृपया अपना मोबाइल नंबर दर्ज करें। एक बार सुरक्षित OTP से सत्यापित होने के बाद, आपका मोबाइल नंबर भविष्य में लॉगिन के लिए आपके उपयोगकर्ता नाम के रूप में काम करेगा। हमें चुनने के लिए धन्यवाद!`
              : `  To create your account, please enter your mobile number. Once verified with a secure OTP, your mobile number will serve as your username for future logins. Thank you for choosing us!`}
          </p>
          <p className="homestay-subtext">
            {" "}
            {language
              ? `यदि आप पहले से पंजीकृत हैं, तो लॉगिन पेज पर जाएं  ${" "} `
              : `If you are already registered, go to login page ${" "} `}
            <Link to="/login">
              {language ? `यहां क्लिक करें,` : `Click Here,`}
            </Link>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          border: "0px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="modal-btns">
          <Button className="suf-btn-regis-inactive" onClick={props.onHide}>
            {" "}
            {language ? `ठीक है` : `OK`}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SignUpModal;
