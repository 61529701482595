import Banner from "../../components/Banner/Banner";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider ";
import MainLayout from "../../components/MainLayout/MainLayout";
import React, { useEffect, useState } from "react";
import "../Home/Home.css";
import { Baseurl, ClearLocalStorage } from "../../Utils/Utils";
import { getHomePageContent } from "../../services/api/outer";
import { getPrivacyPolicyDetails } from  "../../services/api/admin";

const PrivacyPolicy = () => {
  const { language, setLanguage } = useLanguage();
  const [homePageData, setHomePageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [privacyPolicy_en, setprivacyPolicy_en] = useState('');
  const [privacyPolicy_hi, setprivacyPolicy_hi] = useState('');
  const [privacyPolicyDocuments, setprivacyPolicyDocuments] = useState('');
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  useEffect(() => {
    window.scrollTo(0, 0);
    getPrivacyPolicy();
    const fetchData = async () => {
      try {
        const [homePageDataResponse] = await Promise.all([
          getHomePageContent(),
        ]);
        setHomePageData(homePageDataResponse);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    ClearLocalStorage();
  }, []);

  const getPrivacyPolicy = async () => {
    try {
      const formData = new FormData();
      formData.append("page_id", 1);
      const usersListData = await getPrivacyPolicyDetails(formData);
      if (usersListData.status === 200) {
        setprivacyPolicy_en(usersListData?.data?.content_en)
        setprivacyPolicy_hi(usersListData?.data?.content_hi)
        setprivacyPolicyDocuments(usersListData?.data?.documents)
      }
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  };
  if (loading || homePageData === null) {
    return <></>;
  }
  return (
    <>
      <div>
        <MainLayout homePageData={homePageData}>
          {/* <section className="banner-section contact-banner">
            <div className="container">
              <div className="banner-breadcrumb">
                <h5>
                  <a className="home-link" href="/">
                    <span>Home &gt;</span>
                  </a>
                  <span> Privacy Policy</span>
                </h5>
              </div>
            </div>
          </section> */}
          <section className="privacy-policy section-padding">
            <div className="container">
              <div className="privacy-wrapper">
                <div className="privacy-heading">
                  <div className="text-center pt-2 pb-3 hcat-main">
                    <p className="hex-p"> {language ? "गोपनीयता पालिसी" : "Privacy Policies"} </p>
                    <div className="hex-explore">
                     {language ? "यात्रा व्यापार गोपनीयता नीतियों का अन्वेषण करें" : "Explore Travel Trade Privacy Policies"} 
                    </div>
                  </div>
                </div>
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: language
                        ? privacyPolicy_hi
                        : privacyPolicy_en,
                    }}
                  ></span>
                </p>
                <p>&nbsp;</p>
                {privacyPolicyDocuments.length > 0 ? <>
                <div className="pdf-link-block mt-5">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr. no</th>
                        <th>File</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {privacyPolicyDocuments?.map((item, index) => (
                        <tr className="align-middle" key={index}>
                          <td>{index + 1}</td>
                          <td>{language ? item.name_hi : item.name_en}</td>
                          <td>
                            <a
                              className="btn btn-green desktopBtn d-block"
                              href={Baseurl() + "/"+item.document} // Assuming item.path holds the document URL
                              target="_blank" // Opens the document in a new tab
                              rel="noopener noreferrer" // Adds security for external links
                            >
                              <i className="bi bi-download bi-icon-font me-1"></i>
                              Download
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div></>
                : ""}
              </div>
            </div>
          </section>
        </MainLayout>
      </div>
    </>
  );
};
export default PrivacyPolicy;
