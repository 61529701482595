import React, { useEffect, useState } from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import "../User/User.css";
import { updateUserEmailMobileNumberAPI, getUserEmailMobileNumberAPI, sendNotificationData } from "../../services/api/user";
import { Message } from '../../components/message';
import { GetLocalStorageParsed } from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";

const ChangeRequest = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const userDetail = GetLocalStorageParsed("userDetail");
  const [oldEmail, setOldEmail] = useState(userDetail.email || "");
  const [oldPhoneNumber, setOldPhoneNumber] = useState(userDetail.mobile_number || "");
  const [newEmail, setNewEmail] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [userId, setUserId] = useState("");
  const [userDetails, setUserDetail] = useState({});
  const [status, setStatus] = useState(null);
  const [isNewEmailDisabled, setIsNewEmailDisabled] = useState(false);
  const [isNewPhoneDisabled, setIsNewPhoneDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setUserId(userDetail.id || "");
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getUserEmailMobileNumberAPI();
        setUserDetail(response?.data);
        setStatus(response?.data?.status); // Save status for disable check
        if (response?.data && response?.data?.status === '0') {
          if (response?.data?.new_email != null) {
            setIsNewEmailDisabled(true)
            setNewEmail(response.data.new_email);
          } else {
            setIsNewEmailDisabled(false)
          }
          if (response?.data?.new_mobile != null) {
            setIsNewPhoneDisabled(true)
            setNewPhoneNumber(response.data.new_mobile);
          } else {
            setIsNewPhoneDisabled(false)
          }

          if (response?.data?.new_email != null && response?.data?.new_mobile != null) {
            setIsSubmitDisabled(true)
          } else {
            setIsSubmitDisabled(false)
          }
        } else if (response?.data && response?.data?.status == '1') {
          if (response?.data?.type == "1") {
            setOldEmail(response.data.new_email)
          } else if (response?.data?.type == "2") {
            setOldPhoneNumber(response.data.new_mobile)
          } else if (response?.data?.type == "3") {
            setOldEmail(response.data.new_email)
            setOldPhoneNumber(response.data.new_mobile)
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    loadData();
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[6-9]\d{9}$/;

  const validateEmail = () => {
    if (newEmail && !emailRegex.test(newEmail)) {
      setErrors((prevErrors) => ({ ...prevErrors, newEmail_en: "Invalid email format.", newEmail_hi: "अमान्य ईमेल प्रारूप." }));
      return false;
    }
    setErrors((prevErrors) => {
      const { newEmail_en, newEmail_hi, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validatePhoneNumber = () => {
    if (newPhoneNumber && !phoneRegex.test(newPhoneNumber)) {
      setErrors((prevErrors) => ({ ...prevErrors, newPhoneNumber_en: "Phone number must be 10 digits long.", newPhoneNumber_hi: "फ़ोन नंबर 10 अंक लंबा होना चाहिए." }));
      return false;
    }
    setErrors((prevErrors) => {
      const { newPhoneNumber_en, newPhoneNumber_hi, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validateAtLeastOneField = () => {
    if (!newEmail && !newPhoneNumber) {
      setErrors({ form_en: "Please enter at least one of the new email or new phone number.", form_hi: 'कृपया नया ईमेल या नया फ़ोन नंबर में से कम से कम एक दर्ज करें।' });
      return false;
    }
    setErrors((prevErrors) => {
      const { form, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isAtLeastOneFieldFilled = validateAtLeastOneField();
    const isEmailValid = validateEmail();
    const isPhoneValid = validatePhoneNumber();

    if (isAtLeastOneFieldFilled && isEmailValid && isPhoneValid) {
      let requestType = 0;
      if (newEmail && newPhoneNumber) {
        requestType = 3;
      } else if (newEmail) {
        requestType = 1;
      } else if (newPhoneNumber) {
        requestType = 2;
      }

      const payload = {
        old_email: oldEmail,
        old_mobile_number: oldPhoneNumber,
        new_email: newEmail,
        new_mobile_number: newPhoneNumber,
        user_id: userId,
        type: requestType,
      };

      try {
        const response = await updateUserEmailMobileNumberAPI(payload);
        if (response?.status === 200) {
          navigate("/dashboard", { state: response?.message })
          try {
            const notifcation = new FormData()
            notifcation.append("user_id", userDetail?.id);
            notifcation.append("remark_en", "Change request submitted");
            notifcation.append("remark_hi", 'परिवर्तन अनुरोध सबमिट किया गया');
            notifcation.append("user_type", 2);
            notifcation.append('district_id', userDetail?.district)
            const notificationSend = await sendNotificationData(notifcation)
          } catch (err) {
            console.error("Error submitting the form:", err);
          }
          // setApiMessage({ type: "success", message: response.message });
        } else if (response?.data) {
          const errorMessages = [];
          for (const field in response.data) {
            if (response.data[field] && response.data[field].length > 0) {
              errorMessages.push(response.data[field][0]);
            }
          }
          setApiMessage({ type: "error", message: errorMessages.join(", ") || response.message });
        }
      } catch (error) {
        setApiMessage({ type: "error", message: error.message });
      }
    }
  };



  return (
    <div>
      <Dashboard
        first={language ? "होम" : "Home"}
        second={language ? "मोबाइल/ईमेल परिवर्तन अनुरोध" : "Mobile/Email Change Request"}
        head={language ? "मोबाइल/ईमेल परिवर्तन अनुरोध" : "Mobile/Email Change Request"}
      >
        <form onSubmit={handleSubmit} className="aod-bottom">
          {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage} />)}
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">{language ? "ईमेल" : "Email"}</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="test@gmail.com"
                  value={oldEmail}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">{language ? "मोबाइल नंबर " : "Mobile Number"}</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="9876543210"
                  value={oldPhoneNumber}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">{language ? "नया ईमेल" : "New Email"}</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={language ? "नया ईमेल दर्ज करें" : "Enter new Email"}
                  value={newEmail}
                  onChange={(e) => { setNewEmail(e.target.value); setErrors({ form_en: "", form_hi: '' }); }}
                  onBlur={validateEmail}
                  disabled={isNewEmailDisabled}
                />
                {(errors.newEmail_hi || errors.newEmail_en) && <p className="text-danger">{language ? errors.newEmail_hi : errors.newEmail_en}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">{language ? "नया मोबाइल नंबर " : "New Mobile Number"}</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={language ? 'नया फ़ोन नंबर दर्ज करें' : "Enter New Phone Number"}
                  value={newPhoneNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setNewPhoneNumber(e.target.value);
                      setErrors({ form_en: "", form_hi: '' })
                    }
                  }}
                  onBlur={validatePhoneNumber}
                  disabled={isNewPhoneDisabled}
                />
                {(errors.newPhoneNumber_hi || errors.newPhoneNumber_en) && <p className="text-danger">{language ? errors.newPhoneNumber_hi : errors.newPhoneNumber_en}</p>}
              </div>
            </div>
          </div>
          {(errors.form_hi || errors.form_en) && <p className="text-danger">{language ? errors.form_hi : errors.form_en}</p>}
          <button type="submit" className="btn btn-primary user-theme-btn mt-3" disabled={isSubmitDisabled}>
            {language ? 'जमा करना' : 'Submit'}
          </button>
        </form>
      </Dashboard>
    </div>
  );
};

export default ChangeRequest;
