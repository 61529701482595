import apiInstance from "./config";

export const getCategoryListData = async (pageNo) => {
    return await apiInstance.get(`/admin/categories?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getCategoryListDataSort = async (sortBy, orderBy, serachBy, pageNo) => {
    return await apiInstance.get(`/admin/categories?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getSubCategoryListDataSort = async (sortBy, orderBy, serachBy, pageNo, categoryId) => {
    return await apiInstance.get(`/admin/sub-categories?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}&category_id=${categoryId}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const addCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const addSubCategoryData = async (payload) => {
    return await apiInstance.post('/admin/sub-categories/create', payload)
        .then(res => res)
        .catch(err => err);
}


export const updatCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubCategoryData = async (payload) => {
    return await apiInstance.post('/admin/sub-categories/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const subCategoryDataEnableDisable = async (payload) => {
    return await apiInstance.post('/admin/sub-categories/enable-disable', payload)
        .then(res => res)
        .catch(err => err);
}

export const subCategoryFeesEnableDisable = async (payload) => {
    return await apiInstance.post('/admin/categories/fees/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getSubCategory = async (pageNo) => {
    return await apiInstance.get(`/admin/sub-categories?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}


export const getFontFamilyData = async () => {
    return await apiInstance.get('/get-fontfamilies')
        .then(res => res)
        .catch(err => err);
}

export const getSubCategoryListData = async (pageNo) => {
    return await apiInstance.get(`/admin/sub-categories?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getRegistrationFieldsListData = async () => {
    return await apiInstance.get('/admin/registration-form/fields')
        .then(res => res)
        .catch(err => err);
}

export const addRegistrationFieldData = async (payload) => {
    return await apiInstance.post('/admin/registration-form/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteRegistrationFieldData = async (payload) => {
    return await apiInstance.post('/admin/registration-form/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const getValidationsListData = async () => {
    return await apiInstance.get('/admin/validation-rules')
        .then(res => res)
        .catch(err => err);
}

export const updateRegistrationFieldData = async (payload) => {
    return await apiInstance.post('/admin/registration-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const addSubCategoryTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormTabListDataSort = async (sortBy, orderBy, serachBy, pageNo, subCategoryId) => {
    return await apiInstance.get(`/admin/categories/tabs?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}&category_id=${subCategoryId}`)
        .then(res => res)
        .catch(err => err);
}

export const getFormTabListData = async (pageNo) => {
    return await apiInstance.get(`/admin/categories/tabs?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteApplicationTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubCategoryTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubCategoryTabSectionData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/update', payload)
        .then(res => res)
        .catch(err => err);
}


export const handleEnableDisableCategoryData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/enable-disable', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableDisableDocumentData = async (payload) => {
    return await apiInstance.post('/admin/categories/documents/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const addSubCategorySectionTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormTabSubCategoryListData = async (payload) => {
    return await apiInstance.post('admin/categories/tabs/list', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormTabDropDownData = async () => {
    return await apiInstance.post('admin/categories/tabs/all')
        .then(res => res)
        .catch(err => err);
}

export const getSectionsData = async (sortBy, orderBy, serachBy, pageNo, subCategoryId, categoryTabId) => {
    return await apiInstance.get(`/admin/categories/tabs/sections?page=${pageNo}&sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&category_id=${subCategoryId}&tab_id=${categoryTabId}`)
        .then(res => res)
        .catch(err => err);
}
export const getSectionsDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/categories/tabs/sections?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteApplicationSectionsTabData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableSectionsDisableData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const addFormFieldData = async (payload) => {
    return await apiInstance.post('/admin/sections/fields/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getSectionListDataByTabId = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/sections/by-tab_id', payload)
        .then(res => res)
        .catch(err => err);
}

export const getFormFieldsListData = async (pageNo) => {
    return await apiInstance.get(`/admin/sections/fields?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getFormFieldListDataSort = async (sortBy, orderBy, serachBy, pageNo, subCategoryId, formTabId, formSectionId) => {
    return await apiInstance.get(`/admin/sections/fields?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}&category_id=${subCategoryId}&tab_id=${formTabId}&section_id=${formSectionId}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteFormFieldData = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/fields/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateFormFieldData = async (payload) => {
    return await apiInstance.post('/admin/sections/fields/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableDisableFormFieldsData = async (payload) => {
    return await apiInstance.post('/admin/sections/fields/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getUsersListData = async () => {
    return await apiInstance.get('/admin/users')
        .then(res => res)
        .catch(err => err);
}

export const getallUsersListData = async () => {
    return await apiInstance.get('admin/users/list/all')
        .then(res => res)
        .catch(err => err);
}

export const getLoginFieldsData = async () => {
    return await apiInstance.get('/login-form')
        .then(res => res)
        .catch(err => err);
}

export const updateLoginFieldData = async (payload) => {
    return await apiInstance.post('/admin/login-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getResetPasswordFieldData = async () => {
    return await apiInstance.get('/admin/reset-password-form')
        .then(res => res)
        .catch(err => err);
}


export const updateResetPasswordFieldData = async (payload) => {
    return await apiInstance.post('/admin/reset-password-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getForgotPasswordFieldsData = async () => {
    return await apiInstance.get('/admin/forgot-password-form')
        .then(res => res)
        .catch(err => err);
}

export const updateForgotPasswordFieldsData = async (payload) => {
    return await apiInstance.post('/admin/forgot-password-form/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getGuidelinesFieldsDataBySubCategory = async (subCategoryId) => {
    return await apiInstance.get(`/admin/guidelines?sub_category_id=${subCategoryId}`)
        .then(res => res)
        .catch(err => err);
}

export const getGuidelinesFieldsDataSort = async (sortBy, orderBy, serachBy, pageNo, subCategoryId) => {
    return await apiInstance.get(`/admin/guidelines?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}&sub_category_id=${subCategoryId}`)
        .then(res => res)
        .catch(err => err);
}

export const addGuidelinesFieldsData = async (payload) => {
    return await apiInstance.post('/admin/guidelines/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateGuidelinesFieldsData = async (payload) => {
    return await apiInstance.post('/admin/guidelines/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteGuidelinesFieldsData = async (payload) => {
    return await apiInstance.post('/admin/guidelines/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const activeInactiveTabs = async (payload) => {
    return await apiInstance.post('/admin/categories/tabs/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getCategoriesDropdown = async () => {
    return await apiInstance.get('/admin/categories-dropdown')
        .then(res => res)
        .catch(err => err);
}

export const getCategoriesFees = async (sortBy, orderBy, serachBy, pageNo) => {
    return await apiInstance.get(`/admin/categories/fees?page=${pageNo}&sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}


export const addCategoryFees = async (payload) => {
    return await apiInstance.post('/admin/categories/fees/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateCategoryFees = async (payload) => {
    return await apiInstance.post('/admin/categories/fees/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getSubCategoriesDropdown = async () => {
    return await apiInstance.get('/admin/sub-categories-dropdown')
        .then(res => res)
        .catch(err => err);
}

export const getSubSectionsDropdown = async () => {
    return await apiInstance.get('/admin/sub-sections/all')
        .then(res => res)
        .catch(err => err);
}

export const addRolesData = async (payload) => {
    return await apiInstance.post('/admin/roles/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateRolesData = async (payload) => {
    return await apiInstance.post('/admin/roles/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const getRolesListData = async (pageNo) => {
    return await apiInstance.get(`/admin/roles?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getRolesAllListData = async () => {
    return await apiInstance.get(`/admin/roles/list`)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisableRolesData = async (payload) => {
    return await apiInstance.post('/admin/roles/enable-disable', payload)
        .then(res => res)
        .catch(err => err);
}

export const getRoleListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/roles?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteRoleData = async (id) => {
    return await apiInstance.delete(`/admin/roles/delete/${id}`)
        .then(res => res)
        .catch(err => err);
}

export const handleEnablecategoryDisableData = async (payload) => {
    return await apiInstance.post('/admin/categories/enable-disable', payload)
        .then(res => res)
        .catch(err => err);
}

export const totalApplications = async () => {
    return await apiInstance.get(`/admin/applications/total`)
        .then(res => res)
        .catch(err => err);
}

export const activeApplication = async () => {
    return await apiInstance.get(`applications/active`)
        .then(res => res)
        .catch(err => err);
}

export const inActiveapplication = async () => {
    return await apiInstance.get(`applications/inactive`)
        .then(res => res)
        .catch(err => err);
}

export const totalPendingApplication = async () => {
    return await apiInstance.get(`/admin/applications/pending`)
        .then(res => res)
        .catch(err => err);
}

export const totalApprovedApplication = async () => {
    return await apiInstance.get(`/admin/applications/approved`)
        .then(res => res)
        .catch(err => err);
}

export const totalRejectedApplication = async () => {
    return await apiInstance.get(`/admin/applications/rejected`)
        .then(res => res)
        .catch(err => err);
}

export const totalRenewApplication = async () => {
    return await apiInstance.get(`/admin/applications/renew`)
        .then(res => res)
        .catch(err => err);
}

export const totalUpDateApplications = async () => {
    return await apiInstance.get(`/admin/applications/update`)
        .then(res => res)
        .catch(err => err);
}

export const frontendUser = async () => {
    return await apiInstance.get(`/frontend/users`)
        .then(res => res)
        .catch(err => err);
}

export const totalAdminUser = async () => {
    return await apiInstance.get(`/admin/users`)
        .then(res => res)
        .catch(err => err);
}

export const totalExpiredcertificate = async () => {
    return await apiInstance.get(`/certificates/expired`)
        .then(res => res)
        .catch(err => err);
}

export const expiredCertificateIn60days = async () => {
    return await apiInstance.get(`/certificates/expired/sexty-days`)
        .then(res => res)
        .catch(err => err);
}

export const expiredCertificateIn30days = async () => {
    return await apiInstance.get(`/certificates/expired/thirty-days`)
        .then(res => res)
        .catch(err => err);
}

export const districtWiseApplication = async () => {
    return await apiInstance.get(`/district/applications`)
        .then(res => res)
        .catch(err => err);
}

export const applicationDistrictByCategoryId = async () => {
    return await apiInstance.get(`/district/applications/category_id`)
        .then(res => res)
        .catch(err => err);
}

export const applicationDistrictBySubCategoryId = async () => {
    return await apiInstance.get(`/district/applications/sub_category_id`)
        .then(res => res)
        .catch(err => err);
}

export const allModuleListData = async () => {
    return await apiInstance.get(`/admin/modules`)
        .then(res => res)
        .catch(err => err);
}

export const allSubModuleByModuleIdListData = async (payload) => {
    return await apiInstance.post(`/admin/sub-modules`, payload)
        .then(res => res)
        .catch(err => err);
}

export const allActiveUsers = async () => {
    return await apiInstance.get(`/admin/total-active`)
        .then(res => res)
        .catch(err => err);
}

export const allInActiveUsers = async () => {
    return await apiInstance.get(`/admin/total-inactive`)
        .then(res => res)
        .catch(err => err);
}

export const postUserPermission = async (payload) => {
    return await apiInstance.post(`/admin/modules/permission/create`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updatePermissionsData = async (payload) => {
    return await apiInstance.post(`/admin/modules/permission/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getPermissionDataById = async (payload) => {
    return await apiInstance.post(`/admin/modules/permission/details`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getPermissionDataByRoleId = async (payload) => {
    return await apiInstance.post(`/admin/roles/permission/details`, payload)
        .then(res => res)
        .catch(err => err);
}

export const allAdminUserList = async (pageNo) => {
    return await apiInstance.get(`/admin/users?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisableAdminUserData = async (payload, id) => {
    return await apiInstance.post(`/admin/users/active-inactive/${id}`, payload)
}

export const getStateListData = async (pageNo) => {
    return await apiInstance.get(`/admin/states?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const allAdminUserDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/users?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
}
export const addStateData = async (payload) => {
    return await apiInstance.post('/admin/states/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getStateListDataSort = async (sortBy, orderBy, serachBy, pageNo) => {
    return await apiInstance.get(`/admin/states?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const updateStateData = async (payload) => {
    return await apiInstance.post('/admin/states/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const activeInactiveState = async (payload) => {
    return await apiInstance.post('/admin/states/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteStateData = async (payload) => {
    return await apiInstance.post('/admin/states/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const addDistrictData = async (payload) => {
    return await apiInstance.post('/admin/districts/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getStateDropdown = async () => {
    return await apiInstance.get('/states/all')
        .then(res => res)
        .catch(err => err);
}

export const getDistrictListData = async (pageNo) => {
    return await apiInstance.get(`/admin/districts?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getDistrictListDataSort = async (sortBy, orderBy, serachBy, pageNo, stateId) => {
    return await apiInstance.get(`/admin/districts?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}&state_id=${stateId}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteDistrictData = async (payload) => {
    return await apiInstance.post('admin/districts/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateDistrictData = async (payload) => {
    return await apiInstance.post('/admin/districts/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const activeInactiveDistrict = async (payload) => {
    return await apiInstance.post('/admin/districts/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getDistrictDropdown = async () => {
    return await apiInstance.get('/districts/all')
        .then(res => res)
        .catch(err => err);
}

export const getCityListData = async (pageNo) => {
    return await apiInstance.get(`/admin/cities?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const addCityData = async (payload) => {
    return await apiInstance.post('/admin/cities/create', payload)
        .then(res => res)
        .catch(err => err);
}

export const getCityListDataSort = async (sortBy, orderBy, serachBy, pageNo, stateId, districtId) => {
    return await apiInstance.get(`/admin/cities?page=${pageNo}&sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&state_id=${stateId}&district_id=${districtId}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteCityData = async (payload) => {
    return await apiInstance.post('admin/cities/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateCityData = async (payload) => {
    return await apiInstance.post('/admin/cities/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const activeInactiveCity = async (payload) => {
    return await apiInstance.post('/admin/cities/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getDistrictListDataByState = async (payload) => {
    return await await apiInstance.post('/state/districts', payload)
        .then(res => res)
        .catch(err => err);
}


export const getUserPermissionListData = async (pageNo) => {
    return await apiInstance.get(`/admin/module/permissions?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisablePermissionData = async (payload) => {
    return await apiInstance.post('/admin/roles/modules/permission/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getUserPermissionListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/module/permissions?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deletePermissionData = async (id) => {
    return await apiInstance.delete(`/admin/modules/permission/delete/${id}`)
        .then(res => res)
        .catch(err => err);
}

export const submitAdminUserData = async (payload) => {
    return await apiInstance.post(`/admin/users/add`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updateAdminUserData = async (payload) => {
    return await apiInstance.post(`/admin/users/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteAdminUserData = async (id) => {
    return await apiInstance.delete(`/admin/users/delete/${id}`)
        .then(res => res)
        .catch(err => err);
}

export const fetchAllCategoryFaq = async (sortBy, orderBy, serachBy, pageNo, subCategoryId) => {
    return await apiInstance.get(`/admin/sub-categories/faqs?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}&sub_category_id=${subCategoryId}`)
        .then(res => res)
        .catch(err => err);
}

export const getAllCategoryFaqSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/sub-categories/faqs?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteCategoryFaqById = async (payload) => {
    return await apiInstance.post(`/admin/sub-categories/faqs/delete`, payload)
        .then(res => res)
        .catch(err => err);
}

export const submitCategoryFaqData = async (payload) => {
    return await apiInstance.post(`/admin/sub-categories/faqs/create`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updateCategoryFaqdData = async (payload) => {
    return await apiInstance.post(`/admin/sub-categories/faqs/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisableCategoryFaqData = async (payload) => {
    return await apiInstance.post('/admin/sub-categories/faqs/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const addUserPermissionData = async (payload) => {
    return await apiInstance.post('admin/user/permissions/add', payload)
        .then(res => res)
        .catch(err => err);
}

export const getUserPermissionData = async (payload) => {
    return await apiInstance.post('admin/user/permissions/details', payload)
        .then(res => res)
        .catch(err => err);
}


export const getempDesignationListData = async (sortBy, orderBy, serachBy, pageNo, subCategoryId) => {
    return await apiInstance.get(`/admin/employee/designations?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}&sub_category_id=${subCategoryId}`)
        .then(res => res)
        .catch(err => err);
}

export const getEmpDesignationListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`/admin/employee/designations?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const deleteEmpDesignationListData = async (payload) => {
    return await apiInstance.post(`/admin/employee/designations/delete`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisableEmpDesignationListsData = async (payload) => {
    return await apiInstance.post('/admin/employee/designations/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const submitEmpDesignationListData = async (payload) => {
    return await apiInstance.post(`/admin/employee/designations/create`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updateEmpDesignationListData = async (payload) => {
    return await apiInstance.post(`/admin/employee/designations/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const addNewDropDownList = async (payload) => {
    return await apiInstance.post(`/admin/fields/dropdown/create`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getOtherDropDowndata = async (sortBy, orderBy, serachBy, pageNo) => {
    return await apiInstance.get(`/admin/fields/dropdown?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const otherDropDownListDataACtiveInactive = async (payload) => {
    return await apiInstance.post(`/admin/fields/dropdown/active-inactive`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updateNewDropDownList = async (payload) => {
    return await apiInstance.post(`/admin/fields/dropdown/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteNewDropDownList = async (payload) => {
    return await apiInstance.post(`/admin/fields/dropdown/delete`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getSubSectionList = async (sortBy, orderBy, serachBy, pageNo) => {
    return await apiInstance.get(`/admin/sub-sections?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const subSectionListDataACtiveInactive = async (payload) => {
    return await apiInstance.post(`/admin/sub-section/active-inactive`, payload)
        .then(res => res)
        .catch(err => err);
}

export const deleteSubSectionList = async (payload) => {
    return await apiInstance.post(`/admin/sub-section/delete`, payload)
        .then(res => res)
        .catch(err => err);
}

export const addSubSectionData = async (payload) => {
    return await apiInstance.post(`/admin/sub-section/create`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSubSectionData = async (payload) => {
    return await apiInstance.post(`/admin/sub-section/update`, payload)
        .then(res => res)
        .catch(err => err);
}


export const getAllSMSData = async () => {
    return await apiInstance.get('/admin/settings/template/sms')
        .then(res => res)
        .catch(err => err);
}

export const getAllSMSlabelListData = async () => {
    return await apiInstance.get('/admin/settings/template/sms/list')
        .then(res => res)
        .catch(err => err);
}

export const addNewSMSlabelData = async (payload) => {
    return await apiInstance.post('/admin/settings/template/sms/add', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateSMSlabelData = async (payload, id) => {
    return await apiInstance.post(`/admin/settings/template/sms/update/${id}`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getAllEmaillabelListData = async () => {
    return await apiInstance.get('/admin/settings/template/email/list')
        .then(res => res)
        .catch(err => err);
}

export const getAllEmailData = async () => {
    return await apiInstance.get('/admin/settings/template/email')
        .then(res => res)
        .catch(err => err);
}

export const getEmailData = async (id) => {
    return await apiInstance.get(`admin/settings/template/email/details/${id}`)
        .then(res => res)
        .catch(err => err);
}

export const updateEmaillabelData = async (payload, id) => {
    return await apiInstance.post(`/admin/settings/template/email/update/${id}`, payload)
        .then(res => res)
        .catch(err => err);
}

export const addEmailLabelData = async (payload) => {
    return await apiInstance.post(`/admin/settings/template/email/add`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getDocumentListData = async (sortBy, orderBy, serachBy, pageNo, subCategoryId) => {
    return await apiInstance.get(`admin/categories/documents?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}&sub_category_id=${subCategoryId}`)
        .then(res => res)
        .catch(err => err);
}

export const getDocumenListDataSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`admin/categories/documents?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

// export const getEnableDisableDocumenListData = async (payload) => {
//     return await apiInstance.post('/admin/roles/enable-disable', payload)
//         .then(res => res)
//         .catch(err => err);
// }

export const deleteUploadDocumentData = async (id) => {
    return await apiInstance.delete(`/admin/categories/documents/delete/${id}`)
        .then(res => res)
        .catch(err => err);
}

export const addDocumentData = async (payload) => {
    return await apiInstance.post(`/admin/categories/documents/create`, payload)
        .then(res => res)
        .catch(err => err);
}

export const updateDocumentData = async (payload, id) => {
    return await apiInstance.post(`/admin/categories/documents/update/${id}`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getAllDropdownList = async () => {
    return await apiInstance.get(`/admin/fields/dropdown/all`)
        .then(res => res)
        .catch(err => err);
}

export const getMobileEmailDataList = async (pageNo) => {
    return await apiInstance.get(`/admin/users/request/list?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const updateEmailMobileVerify = async (payload, id) => {
    return await apiInstance.post(`/admin/users/request/update`, payload)
        .then(res => res)
        .catch(err => err);
}

export const getFrontendUserList = async (pageNo) => {
    return await apiInstance.get(`/admin/travel-trade/users?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getFrontendUserListSort = async (sortBy,orderBy,serachBy) => {
    return await apiInstance.get(`/admin/travel-trade/users?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
        .then(res => res)
        .catch(err => err);
}

export const getEnableDisableUser = async (payload) => {
    return await apiInstance.post(`/admin/travel-trade/change-status`, payload)
        .then(res => res)
        .catch(err => err);
}


export const deleteUserFromUserId = async (payload) => {
    return await apiInstance.post('/admin/travel-trade/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const recoverAdminUser = async (payload) => {
    return await apiInstance.post('/admin/users/recover', payload)
        .then(res => res)
        .catch(err => err);
}

export const getUserOTPLogs = async (pageNo) => {
    return await apiInstance.get(`/admin/logs/otp?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getUserLoginLogs = async (pageNo) => {
    return await apiInstance.get(`/admin/users/log/list?page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getHomepageBanners = async () => {
    return await apiInstance.get(`/admin/banners`)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableDisableHomepageBanner = async (payload) => {
    return await apiInstance.post('/admin/banners/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getHomepageBannersLinks = async () => {
    return await apiInstance.get(`/admin/banners/links`)
        .then(res => res)
        .catch(err => err);
}

export const getSocialMediaLinks = async () => {
    return await apiInstance.get(`/admin/social-media-links`)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableDisableHomepageBannerLinks = async (payload) => {
    return await apiInstance.post('/admin/banners/links/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const updateBannerImage = async (payload) => {
    return await apiInstance.post('/admin/banners/update', payload)
        .then(res => res)
        .catch(err => err);
}


export const updateBannerLink = async (payload) => {
    return await apiInstance.post('/admin/banners/links/update', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableDisableHomepageHeader = async (payload) => {
    return await apiInstance.post('/admin/homepage/menu/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getHomepageLogo = async () => {
    return await apiInstance.get(`/logos`)
        .then(res => res)
        .catch(err => err);
}

export const updateWebsiteLogo = async (payload) => {
    return await apiInstance.post('/admin/logos/update' , payload)
    .then(res => res)
    .catch(err => err);
}

export const getPrivacyPolicyDetails = async (payload) => {
    return await apiInstance.post('/pages/get-details' , payload)
    .then(res => res)
    .catch(err => err);
}

export const getTermsConditionsDetails = async (payload) => {
    return await apiInstance.post('/pages/get-details' , payload)
    .then(res => res)
    .catch(err => err);
}

export const getDisclaimerDetails = async (payload) => {
    return await apiInstance.post('/pages/get-details' , payload)
    .then(res => res)
    .catch(err => err);
}

export const updatePrivacyPolicyDetails = async (payload) => {
    return await apiInstance.post('/admin/pages/privacy-policy/update' , payload)
    .then(res => res)
    .catch(err => err);
}

export const updateTermsConditionsDetails = async (payload) => {
    return await apiInstance.post('/admin/pages/terms-conditions/update' , payload)
    .then(res => res)
    .catch(err => err);

}

export const updateRules = async (payload) => {
    return await apiInstance.post('/admin/pages/rules/update' , payload)
    .then(res => res)
    .catch(err => err);

}

export const getRules = async (payload) => {
    return await apiInstance.post('/admin/pages/rules/update' , payload)
    .then(res => res)
    .catch(err => err);

}

export const getpageDocument = async (payload) => {
    return await apiInstance.post('/pages/documents' , payload)
    .then(res => res)
    .catch(err => err);
}

export const addPageDocument = async (payload) => {
    return await apiInstance.post('/admin/pages/documents/add', payload)
        .then(res => res)
        .catch(err => err);
}
export const updatePageDocument = async (payload) => {
    return await apiInstance.post('/admin/pages/documents/update', payload)
        .then(res => res)
        .catch(err => err);
}
export const deletePageDocument = async (payload) => {
    return await apiInstance.post('/admin/pages/documents/delete', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableDisablepageDocument = async (payload) => {
    return await apiInstance.post('/admin/pages/documents/active-inactive', payload)
        .then(res => res)
        .catch(err => err);

}

export const updateDisclaimerDetails = async (payload) => {
    return await apiInstance.post('/admin/pages/terms-conditions/update' , payload)
    .then(res => res)
    .catch(err => err);

}

export const getNiamvaliDetails = async (payload) => {
    return await apiInstance.post('/pages/get-details' , payload)
    .then(res => res)
    .catch(err => err);
}
export const updateNiamvaliDetails = async (payload) => {
    return await apiInstance.post('/admin/pages/terms-conditions/update' , payload)
    .then(res => res)
    .catch(err => err);

}

export const getPaymentTabList = async () => {
    return await apiInstance.get(`/admin/payment/tab`)
        .then(res => res)
        .catch(err => err);
}

export const activeInactivePaymentTab = async (payload) => {
    return await apiInstance.post('/admin/payment/tab/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const handleEnableDisableSocialMediaLinks = async (payload) => {
    return await apiInstance.post('/admin/social-media-links/active-inactive', payload)
        .then(res => res)
        .catch(err => err);
}

export const getallApplicationList = async (sortBy, orderBy, serachBy, pageNo) => {
    return await apiInstance.get(`admin/sub-categories/applications/list?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}&page=${pageNo}`)
        .then(res => res)
        .catch(err => err);
}

export const getApplicationListBystatus = async (pageNo, status) => {
    return await apiInstance.get(`admin/sub-categories/applications/list?page=${pageNo}&status=${status}`)
        .then(res => res)
        .catch(err => err);
}

export const getallApplicationListSort = async (sortBy, orderBy, serachBy) => {
    return await apiInstance.get(`admin/sub-categories/applications/list?sortBy=${sortBy}&orderBy=${orderBy}&search=${serachBy}`)
}

export const uploadedDocumentlistData = async (payload) => {
    return await apiInstance
        .post(`/admin/sub-categories/applications/documents/list`, payload)
        .then((response) => response)
        .catch((err) => err);
};

export const applicationCertificatelistData = async (payload) => {
    return await apiInstance
        .post(`/user/certificates/get-details`, payload)
        .then((response) => response)
        .catch((err) => err);
};

export const uploadedDocumentDelete = async (payload) => {
    return await apiInstance
        .post(`/application/document/delete`, payload)
        .then((response) => response)
        .catch((err) => err);
};

export const certifiateGenerate = async (payload) => {
    return await apiInstance
        .post(`application/certificate`, payload)
        .then((response) => response)
        .catch((err) => err);
};

export const approvedCategoryList = async () => {
    return await apiInstance.get(`/admin/applications/types`)
        .then(res => res)
        .catch(err => err);
}

export const applicationLogData = async (payload) => {
    return await apiInstance
        .post(`user/application/logs`, payload)
        .then((response) => response)
        .catch((err) => err);
};

export const getDashboardDataAPI = async () => {
    return await apiInstance.get(`/admin/dashboard/data`)
        .then(res => res)
        .catch(err => err);
}
export const getDeletedUsers =  async (payload) => {
    return await apiInstance.post(`/admin/users/deleted`, payload)
        .then(res => res)
        .catch(err => err);
}
export const getApplicationsReport = async (payload) => {
    return await apiInstance.post(`/admin/reports/applications/by-status`, payload)
        .then(res => res)
        .catch(err => err);
}
export const getAllApplicationsReport = async () => {
    return await apiInstance.get(`/admin/reports/applications`)
        .then(res => res)
        .catch(err => err);
}
export const getSubCategoryApplicationsData = async (payload) => {
    return await apiInstance.post(`/admin/sub-category/applications`, payload)
        .then(res => res)
        .catch(err => err);
}
export const userCertificateListData = async (payload) => {
    return await apiInstance
        .post(`/certificates/list`, payload)
        .then((response) => response)
        .catch((err) => err);
};
export const getDistrictApplicationsData = async (payload) => {
    return await apiInstance.post(`/admin/sub-category/district/applications`, payload)
        .then(res => res)
        .catch(err => err);
}
