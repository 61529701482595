import React, { useEffect, useState } from "react";
import InputTypeText from "./inputTypeText";
import SelectType from "./SelectType";
import { customStylesAcc, selectTheme } from "../../Utils/common";
import { subSectionData } from "../../services/api/user";
import { DeleteIcon } from "../../pages/Admin/AdminIcon";

const SubSection = ({
  fieldData,
  handleInputChange,
  inputTypeList,
  sectionId,
  language,
  formValues,
  subSectionFieldKey,
  setTabsWholeData,
  subSectionId,
  errors,
  setErrors,
  setFormValues,
  applicationFromData,
  tabsWholeData,
  fieldsDisabled,
  totalRoomsPremesisesKey
}) => {
  const [inputValue, setInputValue] = useState("1");
  const [parsedJsonValue, setParsedJsonValue] = useState([]);
  const [sectionFormData, setSectionFormData] = useState([]);
  useEffect(() => {
    setInputValue(applicationFromData?.["f_" + fieldData?.field_key] || "1"); //prepopulating the input field for the formarray
    const fetchOptions = async () => {
      try {
        const formData = new FormData();
        formData.append("sub_section_id", subSectionId);

        const response = await subSectionData(formData);

        setSectionFormData(response?.data);

        const parsedValue = JSON.parse(response?.data?.json_value)?.items || [];

        const applicationDataKey = `f_${subSectionFieldKey}_json`;
        const jsonDataFromAppData = applicationFromData?.[applicationDataKey]; // Use empty array as fallback if data is missing

        if (jsonDataFromAppData) {
        } else {
          setTabsWholeData((prevData) => {
            const updatedSubSections = () => {
              if (
                prevData?.sub_section_fields?.[subSectionFieldKey] !== undefined
              ) {
                return {
                  ...prevData?.sub_section_fields,
                  [`${subSectionFieldKey}_json`]: Array(inputValue)
                    .fill()
                    .map(() => {
                      // Create an object with each field in parsedValue set to null
                      return parsedValue.reduce((acc, item) => {
                        const fieldKeyJson = `${item.name_en}`; // Using `field_key_json` format
                        acc[fieldKeyJson] = null;
                        return acc;
                      }, {});
                    }),
                };
              } else {
                return {
                  ...prevData?.sub_section_fields,
                };
              }
            };

            return {
              ...prevData,
              sub_section_fields: updatedSubSections(),
            };
          });
        }

        setParsedJsonValue(parsedValue);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    fetchOptions();
  }, [fieldData]);

  const handleInputValueChange = (e) => {
    const { value } = e.target;
    const newValue = parseInt(e.target.value) || 1;

    

    if (value === "" || (/^\d*$/.test(value) && Number(value) <= 10 && Number(value) > 0)) {
      let err = null;

      setErrors((prevErrors) => ({
        ...prevErrors,
        [subSectionFieldKey]:
          fieldData?.required && !value
            ? null //`${fieldData?.name_en} is required`
            : null, // Include field name in the error message
      }));

      if(fieldData?.name_en.toLowerCase() === "total number of lettable beds" || 
        fieldData?.name_en.toLowerCase() === "total number of washrooms" && totalRoomsPremesisesKey) {
          const totalNoOfrooms = tabsWholeData?.section_fields?.[totalRoomsPremesisesKey]
          if(totalNoOfrooms && Number.isInteger(Number(totalNoOfrooms))) {
            if(value >= Number(totalNoOfrooms)){
              err = "err"
              setErrors((prevErrors) => ({
                ...prevErrors,
                [subSectionFieldKey]: {
                  hi: `संख्या परिसर में कुल कमरों से कम होनी चाहिए`,
                  en: `Value should be less than Total Rooms in Premises`,
                }
              }));
            }
          } else {
            err = "err"
            setErrors((prevErrors) => ({
              ...prevErrors,
              [subSectionFieldKey]: {
                hi: `पहले परिसर में वैध कुल कमरे दर्ज करें`,
                en: `Enter valid Total Rooms in Premises first`,
              } 
            }));
          }        
      }

      //handleInputChange(e, fieldData, sectionId, e?.target?.value); // Call parent handler if needed
      if(err === null) {
        setInputValue(value);
        setTabsWholeData((prevData) => {
          if (
            tabsWholeData?.sub_section_fields &&
            tabsWholeData.sub_section_fields[subSectionFieldKey + "_json"]
              ?.length > 0
          ) {
            let updatedJsonArray =
              tabsWholeData?.sub_section_fields[`${subSectionFieldKey}_json`]; // Access the correct json key
            if (updatedJsonArray && Array.isArray(updatedJsonArray)) {
              if (newValue > updatedJsonArray.length) {
                // Add new objects if `newValue` is greater than the current length
                const itemsToAdd = newValue - updatedJsonArray.length;
                const additionalItems = Array(itemsToAdd)
                  ?.fill(null)
                  ?.map(() => {
                    // Initialize each new object with string keys
                    return parsedJsonValue.reduce((acc, item) => {
                      acc[`${item.name_en}`] = null; // Use the correct key format
                      return acc;
                    }, {});
                  });
                updatedJsonArray = [...updatedJsonArray, ...additionalItems];
              } else if (newValue < updatedJsonArray?.length) {
                // Trim the array if `newValue` is smaller than the current length
                updatedJsonArray = updatedJsonArray?.slice(0, newValue);
              }

              return {
                ...prevData,
                sub_section_fields: {
                  ...prevData?.sub_section_fields,
                  [subSectionFieldKey]: newValue,
                  [`${subSectionFieldKey}_json`]: updatedJsonArray, // Update with the correct key
                },
              };
            }

            return {
              ...prevData,
            };
          } else {
            return {
              ...prevData,
              sub_section_fields: {
                ...prevData.sub_section_fields,
                [`${subSectionFieldKey}_json`]: Array(inputValue)
                  .fill()
                  .map(() => {
                    // Create an object with each field in parsedValue set to null
                    return parsedJsonValue.reduce((acc, item) => {
                      const fieldKeyJson = `${item.name_en}`; // Using `field_key_json` format
                      acc[fieldKeyJson] = null;
                      return acc;
                    }, {});
                  }), // Dynamic key with value as an object
              },
            };
          }
        });
      }
    }
  };

  // Function to handle change in each form array item
  const handleFormArrayChange = (e, index, fieldKey) => {
    const { value } = e.target;
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (!value) {
        newErrors[`${fieldData.field_key}_json_${index}_${fieldKey}`] = {
          hi: `आवश्यक है`,
          en: `Required`,
        };
      } else {
        // Clear the error if the input is filled
        delete newErrors[`${fieldData.field_key}_json_${index}_${fieldKey}`];
      }

      return newErrors;
    });

    setTabsWholeData((prevData) => {
      const currentJsonArray =
        prevData?.sub_section_fields?.[`${subSectionFieldKey}_json`] || [];

      // Ensure the index exists in the array; if not, return the previous state
      if (!currentJsonArray[index]) {
        return prevData;
      }

      // Update the specific entry at the given index with the new fieldKey and value
      const updatedJsonArray = [...currentJsonArray];
      updatedJsonArray[index] = {
        ...updatedJsonArray[index],
        [fieldKey]: value,
      };

      // Return the updated state with the modified JSON array
      return {
        ...prevData,
        sub_section_fields: {
          ...prevData.sub_section_fields,
          [`${subSectionFieldKey}_json`]: updatedJsonArray,
        },
      };
    });
  };

  // Dynamically determine the number of form arrays
  const formArrayLength = Number(inputValue) > 0 ? Number(inputValue) : 1;

  const remove = (index) => {
    setFormValues((prevFormValues) => {
      const updatedFormValues = { ...prevFormValues };

      if (updatedFormValues[sectionId]) {
        delete updatedFormValues[sectionId][index];
        const newFormValuesSection = Object.entries(
          updatedFormValues[sectionId]
        )
          .filter(([key]) => key !== index.toString())
          .sort(([keyA], [keyB]) => keyA - keyB)
          .reduce((acc, [key, value], idx) => {
            acc[idx] = value;
            return acc;
          }, {});

        updatedFormValues[sectionId] = newFormValuesSection;
      }

      return updatedFormValues;
    });

    setInputValue((prevInputValue) => Math.max(prevInputValue - 1, 0));

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      Object.keys(updatedErrors).forEach((key) => {
        if (key.includes(`_${index}_`)) {
          delete updatedErrors[key];
        }
      });
      return updatedErrors;
    });

    setTabsWholeData((prevData) => {
      const updatedSections = () => {
        if (
          tabsWholeData?.sub_section_fields?.hasOwnProperty(subSectionFieldKey)
        ) {
          const updatedJson = [
            ...tabsWholeData?.sub_section_fields[`${subSectionFieldKey}_json`],
          ];

          // Remove the item at the specified index
          if (index < updatedJson.length) {
            updatedJson.splice(index, 1);
          }

          return {
            ...tabsWholeData?.sub_section_fields,
            [subSectionFieldKey]: Math.max(inputValue - 1, 0),
            [`${subSectionFieldKey}_json`]: updatedJson,
          };
        }
        return {
          ...tabsWholeData?.sub_section_fields,
        };
      };

      return {
        ...prevData,
        sub_section_fields: updatedSections(),
      };
    });

    console.log("Updated formValues:", formValues[sectionId]);
  };

  return (
    <div className="row partner-subsection">
      <div className="col-md-12">
        <div className="col-md-4 mb-4">
          <InputTypeText
            id={fieldData.field_key}
            name={fieldData.field_key}
            value={inputValue} // Controlled value
            onChange={handleInputValueChange} // Change handler for main input
            placeholder={
              language ? fieldData.placeHolder_hi : fieldData.placeHolder_en
            } // Placeholder based on language
            isSubSectionInput={true}
            fieldsDisabled={fieldsDisabled}
            field={fieldData}
          />
          {errors[fieldData.field_key] && (
            <span className="validate-error">
              {language
                ? errors?.[fieldData.field_key]?.hi
                : errors?.[fieldData.field_key]?.en}
            </span>
          )}
        </div>
        {formArrayLength > 0 && (
          <div className="form-array-section col-md-12">
            {[...Array(formArrayLength)].map((_, index) => (
              <div key={index} className="form-array-item">
                <div className="d-flex ad-owner-bar ">
                  <div className="ad-owner-name">
                    <h5>
                      {language
                        ? sectionFormData.name_hi
                        : sectionFormData.name_en}{" "}
                      {index + 1}
                    </h5>
                  </div>
                  {inputValue > 1 && (
                    <>
                      <div
                        className="ad-reg-delete"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon />
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  {parsedJsonValue.map((item, idx) => (
                    <div className="col-md-4" key={idx}>
                      <div className="mb-3 f_fields">
                        <label
                          htmlFor={item.name_en}
                          style={{
                            color: item?.font_color,
                            fontSize: `${item?.font_size}px`,
                            backgroundColor: item?.background_color,
                          }}
                          className="form-label"
                        >
                          {language ? item.name_hi : item.name_en}
                        </label>

                        {/* Controlled input field for each form array item */}
                        <InputTypeText
                          id={item.name_en}
                          name={item.name_en}
                          value={
                            tabsWholeData?.sub_section_fields?.[
                              fieldData?.field_key + "_json"
                            ]?.[index]?.[item.name_en] || ""
                          }
                          onChange={(e) =>
                            handleFormArrayChange(e, index, item.name_en)
                          } // Change handler for form array fields
                          placeholder={
                            language ? item.placeHolder_hi : item.placeHolder_en
                          }
                          isSubSectionInput={false}
                          fieldsDisabled={fieldsDisabled}
                          field={fieldData}
                        />
                        {typeof errors[
                          `${fieldData.field_key}_json_${index}_${item.name_en}`
                        ] === "object" &&
                          errors[
                            `${fieldData.field_key}_json_${index}_${item.name_en}`
                          ] !== null && (
                            <div className="validate-error">
                              {language
                                ? errors?.[
                                    `${fieldData.field_key}_json_${index}_${item.name_en}`
                                  ]?.hi
                                : errors?.[
                                    `${fieldData.field_key}_json_${index}_${item.name_en}`
                                  ]?.en}
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubSection;
