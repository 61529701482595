import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { getSubCategories } from "../../services/api/outer";
import { changeApplicationStatus, checkApplicationData, checkApplicationsData, downloadApplicationPDFAPI } from "../../services/api/user";
import { formatDateWithSlash } from "../../Utils/common";
import {
    GetLocalStorageParsed,
    SetLocalStorageStringify
} from "../../Utils/Utils";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import { FaArrowCircleLeft, FaBan, FaEye, FaHistory, FaRegEdit, FaSync, FaTimesCircle, FaTrashAlt, FaUndoAlt } from "react-icons/fa";
import { RiFileDownloadLine } from "react-icons/ri";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";


const Applications = () => {
    const { language, setLanguage } = useLanguage();
    const [applicationData, setApplicationData] = useState(null);
    const formData = new FormData();
    const userDetail = GetLocalStorageParsed("userDetail");
    const navigate = useNavigate();
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [pdfLoadingId, setPdfLoadingId] = useState(null)

    useEffect(() => {
        getApplicationData();
    }, []);

    const getApplicationData = async () => {
        const formData = new FormData();
        formData.append("user_id", userDetail?.id);
        const data = await checkApplicationsData(formData);
        setApplicationData(data?.data)
        try {
        } catch (err) {
            console.log(err);
        }
    };


     // Sub category click function
  const handleSubCatClick = async (item, eventFor) => {
    try {
      if(eventFor === "withdraw") {
        const formData = new FormData();
        formData.append("status", "10");
        formData.append("application_id", item?.id);
        const changeApplicationStatusResponse = await changeApplicationStatus(formData);
        if (changeApplicationStatusResponse.status === "200" || changeApplicationStatusResponse.status === 200) {
          getApplicationData()
        } 
      } else {
        const formData = new FormData();
        formData.append("sub_category_id", item?.category_id);
        formData.append("user_id", userDetail?.id);
        const data = await checkApplicationData(formData);
        SetLocalStorageStringify("LoginSubCatData", item?.sub_category);
        if (data?.status == 200) {
            SetLocalStorageStringify("application_id", data?.data?.id);
            SetLocalStorageStringify("district_id", data?.data?.district_id);
            if (eventFor == "track") {
                navigate("/track-application");
            } else {
                navigate("/application/application-form");
            }
        } else {
            navigate("/guidelines");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const downloadPDF = async (item) => {
    try {
        setPdfLoadingId(item?.id)
        const formData = new FormData();
        formData.append("application_id", item?.id);
        const downloadApplicationPDF = await downloadApplicationPDFAPI(
          formData
        );
        if (downloadApplicationPDF.success === true) {
          window.open(downloadApplicationPDF?.data);
        }
        setPdfLoadingId(null)
    } catch (err) {
        setPdfLoadingId(null)
        console.log(err);
    }
  };
    return (
        <Dashboard
            first={language ? "होम" : "Home"}
            second={language ? "प्रमाणपत्र" : "Applications"}
            head={language ? "प्रमाणपत्र" : "Applications"}
        >
            <>
                <div className="aod-bottom">
                    <div className="page_heading">
                        <h5>{language ? "अनुप्रयोग" : "Applications"}</h5>
                    </div>
                    <div className="row">
                        <div className="reciept-table table-responsive mt-2">
                            <table
                                className="table table mb-0 dataTable transaction_table"
                                id="receipt_table"
                                role="grid"
                            >
                                <thead className="">
                                    <tr role="row">
                                        <th>{language ? "क्रम संख्या" : "S.No"}</th>
                                        <th>{language ? "आवेदन संख्या" : "Application Number"}</th>
                                        <th>{language ? "श्रेणी नाम" : "Category Name"}</th>
                                        <th>{language ? "तारीख" : "Date"}</th>
                                        <th>{language ? "स्थिति" : "Status"}</th>
                                        <th>{language ? "कार्रवाई" : "Action"}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {applicationData?.length > 0 ? (
                                        applicationData?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.application_number}</td>
                                                <td>{language ? item?.sub_category?.name_hi : item?.sub_category?.name}</td>
                                                <td>
                                                    {item?.created_at
                                                        ? formatDateWithSlash(item?.created_at)
                                                        : "--"}
                                                </td>
                                                <td>
                                                    {(() => {
                                                        switch (item?.status) {
                                                            case "2": return language ? "लंबित" : "Pending";
                                                            case "3": return language ? "मसौदा" : "Draft";
                                                            case "4": return language ? "Approved" : "Approved";
                                                            case "5": return language ? "रक्ष्" : "Rejected";
                                                            case "6": return language ? "खत्म हो चुका" : "Expired";
                                                            case "7": return language ? "समीक्षाधीन" : "Under Review";
                                                            case "8": return language ? "प्रस्तुत" : "Submitted";
                                                            case "10": return language ? "वापस लिया गया" : "Withdrawn";
                                                            default: return language ? "अज्ञात" : "Unknown"; // in case of an undefined status
                                                        }
                                                    })()}
                                                </td>
                                                <td>
                                                    {/* {item?.status !== "4" && item?.status !== "3" ?  
                                                                <a
                                                                    className="btn btn-green-view me-2"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => handleSubCatClick(item, 'view')}
                                                                >
                                                                    <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>{language ? "देखना" : "View"}</Tooltip>}
                                                                        >
                                                                            <span>
                                                                                <FaEye style={{ color: "#fff" }} />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                     </a> : <></>} */}
                                                    {item?.status == "4" || item?.status == "8" || item?.status == "7" || item?.status == "6" || item?.status == "10" ? (
                                                       <>
                                                            {item?.status === "6" ? 
                                                                <a
                                                                    className="btn btn-green-view me-2"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => handleSubCatClick(item, 'renew')}
                                                                >
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{language ? "नवीकरण" : "Renew"}</Tooltip>}
                                                                    >
                                                                        <span>
                                                                            <FaSync style={{ color: "#fff" }} />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </a> : 
                                                                    <a
                                                                        className="btn btn-green-view me-2"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        onClick={() => handleSubCatClick(item, 'track')}
                                                                    >
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>{language ? "ट्रैक" : "Track"}</Tooltip>}
                                                                        >
                                                                            <span>
                                                                                <FaHistory style={{ color: "#fff" }} />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </a>
                                                            }
                                                            {pdfLoadingId === item?.id ? <a className="btn btn-green-view me-2">
                                                                
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>{language ? "डाउनलोड हो रहा है..." : "Downloading..."}</Tooltip>}
                                                                >
                                                                    <span>
                                                                    <Spinner animation="border" role="status" 
                                                                        style={{
                                                                            height: "16px",
                                                                            width: "16px",
                                                                            borderWidth: "2px", // Matches the size of the icon stroke
                                                                            color: "#fff",
                                                                        }}>
                                                                    <span className="visually-hidden"></span>
                                                                </Spinner>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </a> : <a
                                                                className="btn btn-green-view me-2"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={() => downloadPDF(item)}
                                                            >
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>{language ? "पीडीएफ डाउनलोड करें" : "Download PDF"}</Tooltip>}
                                                                >
                                                                    <span>
                                                                        <RiFileDownloadLine style={{ color: "#fff" }} />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </a> }
                                                       </>
                                                        
                                                    ) :
                                                        (
                                                            <>
                                                                {item?.status == '5' || item?.status == '2'  ?
                                                            <a
                                                                className="btn btn-green-view me-2"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={() => handleSubCatClick(item, 'track')}
                                                            >
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>{language ? "ट्रैक" : "Track"}</Tooltip>}
                                                                >
                                                                    <span>
                                                                        <FaHistory style={{ color: "#fff" }} />
                                                                    </span>
                                                                </OverlayTrigger></a> : null

                                                                }
                                                            <a
                                                                    className="btn btn-green-view me-2"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={() => handleSubCatClick(item, 'resume')}
                                                            >
                                                                 <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{language ? "जारी रखना" : "Continue"}</Tooltip>}
                                                                    >
                                                                        <span>
                                                                            <FaRegEdit style={{ color: "#fff" }} />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                            </a>
                                                       </>
                                                        )}
                                                        {!(item?.status === "4" || item?.status === "10") ? 
                                                        (
                                                            <>
                                                                <a
                                                                    className="btn btn-green-view me-2"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => handleSubCatClick(item, 'withdraw')}
                                                                >
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{language ? "निकालना" : "Withdraw"}</Tooltip>}
                                                                    >
                                                                        <span>
                                                                            <FaTrashAlt style={{ color: "#fff" }} />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </a>
                                                            </>
                                                        )  : (<></>)
                                                        }
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        </Dashboard>
    );
};

export default Applications;
