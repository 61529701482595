import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import { handleEnableDisableHomepageHeader } from '../../../services/api/admin';
import { formatDateWithSlash } from '../../../Utils/common';
import { Baseurl } from '../../../Utils/Utils';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import { EditIcon } from "../AdminIcon";
import { Message } from '../../../components/message'
import { getHomePageContent } from '../../../services/api/outer';

const HomepageHeader = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const [homepageHeader, sethomepageHeaderContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  useEffect(() => {
    homepageHeaderContent(currentPage);
  }, [currentPage]);

  const homepageHeaderContent = async (pageNo) => {
    try {
      const homepageHeaderContent = await getHomePageContent();
        sethomepageHeaderContent(homepageHeaderContent?.header_menu || []);
        setTableDataLoaded(true);
    } catch (err) {
      setApiMessage({ type: 'error', message: err?.message });
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
    homepageHeaderContent(currentPage);
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("menu_id", item?.id);
      formData.append("status", e?.value);
      const data = await handleEnableDisableHomepageHeader(formData);
      if (data?.status == 200) {
        homepageHeaderContent();
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
      {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span>Header Menu</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Header Menu</h4>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-long">English Name</th>
                    <th className="ad-long">Hindi Name</th>
                    <th className="ad-long">URL</th>
                    <th className="ad-long">Created At</th>
                    {homepageHeader.length > 1 && <th className="ad-long">Status</th>}
                    {homepageHeader.length > 1 && <th className="ad-long">Edit</th>}
                  </tr>
                </thead>
                <tbody>
                  {homepageHeader.length > 0 ? (
                    homepageHeader.map((item, index) => (
                      <tr key={item.id}>
                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.name_hi}</td>
                        <td>{item.slug}</td>
                        <td>{formatDateWithSlash(item.created_at)}</td>
                        {homepageHeader.length > 1 && (
                          <>
                          <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item?.status == 1}
                                  onChange={(e) =>
                                    handleEnableDisable(
                                      e.target.checked
                                        ? { value: "1" }
                                        : { value: "0" },
                                      item
                                    )
                                  }
                                />
                                <span className="slider"></span>
                              </label>
                            </td>

                          
                        <td>
                          <div className="action-btn d-flex">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                            >
                              <span
                                className="ad-cat-edit"
                                onClick={() => {
                                  navigate("/admin/banner-link/update", {
                                    state: item,
                                  });
                                }}
                              >
                                <EditIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </td></>
                        )}
                      </tr>
                    ))
                  ) : (
                    <NotFoundTable colSpan="9"></NotFoundTable>
                  )}
                </tbody>
              </Table>
              {/* <div className="d-flex w-100 justify-content-end">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default HomepageHeader;
