import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom';
import { Message } from '../../../components/message';
import { addNewSMSlabelData, getAllSMSData, getAllSMSlabelListData, updateSMSlabelData } from '../../../services/api/admin';
import { checkPatternWithSpecialCharacter } from '../../../Utils/common';

const SmsSettingPage = () => {
    const navigate = useNavigate();
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [selectedModuleIndex, setSelectedModuleIndex] = useState(1);
    const [templateList, setTemplateList] = useState([])
    const [templateData, setTemplateData] = useState({})
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')
    const [err, setErr] = useState({
        subject: '',
        content: ''
    })

    useEffect(() => {
        getTemplateList()
    }, [])

    const smsDataByLabel = async (item) => {
        try {
            let formErr = { ...err }
            formErr.subject = ''
            formErr.content = ''
            setErr(formErr)
            const dataList = await getAllSMSData()
            const labelData = dataList?.data?.data?.filter(x => x.label == item.label)
            if (labelData && labelData.length != 0) {
                setTemplateData(labelData[0])
                setSubject(labelData[0].subject)
                setContent(labelData[0].content)
            } else {
                setSubject('')
                setContent('')
            }

        } catch (err) {

        }
    }

    const getTemplateList = async () => {
        try {
            const listData = await getAllSMSlabelListData()
            if (listData?.status == 200) {
                setTemplateList(listData?.data)
                const firstLabelData = listData?.data[0];
                if (firstLabelData) {
                    smsDataByLabel(firstLabelData);
                }
            }
        } catch (err) {

        }
    }

    const handleInput = async (e) => {
        const { name, value } = e?.target
        const cursorPosition = e.target.selectionStart;
        if (name == "subject") {
            if (await checkPatternWithSpecialCharacter(value) == false) {
                return;
            } else {
                setSubject(value)
            }
        } else if (name == "content") {
            setContent(value)
        }
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
        validateForm(name, value);
    }

    const validateForm = (name, value) => {
        console.log(name, value)
        let formErr = { ...err }
        if (name == "subject") {
            if (!value.trim()) {
                formErr.subject = "Please Enter the Subject"
                // return false;
            } else {
                formErr.subject = ''
            }
        } else if (name == "content") {
            if (!value.trim()) {
                formErr.content = "Please Enter the Content"
                // return false;
            } else {
                formErr.content = ""
            }
        }
        setErr(formErr)
        return true;
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (!validateForm("subject", subject) || !validateForm("content", content)) {
                return;
            }

            const formData = new FormData()
            formData.append("slug", templateData?.slug)
            formData.append("label", templateData?.label)
            formData.append("content", content)
            formData.append("tags", templateData?.tags)
            formData.append("subject", subject)


            const dataUpdate = await updateSMSlabelData(formData, templateData.id)
            if (dataUpdate?.success) {
                await smsDataByLabel(templateData)
                setApiMessage({ type: 'success', message: dataUpdate?.data });

            } else {
                setApiMessage({ type: 'error', message: dataUpdate?.message });
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }

    }

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <b onClick={() => navigate("/admin/")}>Home  /</b><span> SMS Settings</span>
                    </div>
                </div>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className="aod-inner">
                    <div className="content-wrapper" id="sms-page">
                        <div className="flex-grow-1 container-p-y">
                            <h4 className="mb-4">SMS settings</h4>
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card mb-4 border-0">
                                        <h5 className="card-header">List</h5>
                                        <ul className="template-list">
                                            {
                                                templateList?.map((item, index) => (
                                                    <a >
                                                        <li
                                                            className={`odd ${selectedModuleIndex === item.id ? "active" : ""}`}
                                                            id={`v-pills-${item.label}-tab`}
                                                            aria-selected={selectedModuleIndex === item.id}
                                                            onClick={() => { setSelectedModuleIndex(item.id); smsDataByLabel(item) }}
                                                        >{item.label}</li>
                                                    </a>
                                                ))}
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8">
                                    <div className="card mb-4 border-0">


                                        <form className="card-body admin-role-form" >
                                            <div className="col-md-12">
                                                <label className="form-label" for="Map-provider">Subject</label>
                                                <input type="text" id="map-provider" className="form-control" maxLength={40}
                                                    placeholder="Enter Your Subject" name="subject" value={subject} onChange={handleInput} />
                                                {err.subject && <p className='validate-error'>{err.subject}</p>}

                                            </div>
                                            <div className="col-md-12 mt-4">

                                                <label for="exampleFormControlTextarea1" className="form-label">Content</label>
                                                <textarea className="form-control" id="exampleFormControlTextarea1" maxLength={100}
                                                    placeholder="Add here" rows="3" spellcheck="false" name="content" value={content} onChange={handleInput}></textarea>
                                                {err.content && <p className='validate-error'>{err.content}</p>}

                                            </div>

                                            <div className="pt-4">
                                                <button type="button"
                                                    className="btn btn-primary me-sm-3 me-1 theme-cta" onClick={handleSubmit}>Submit</button>

                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>

                    <div className="content-backdrop fade"></div>
                </div>

            </div>
        </AdminDashboard >
    )
}

export default SmsSettingPage
