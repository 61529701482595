import React, { useEffect, useState } from "react";
import { empDesignationData } from "../../services/api/user";
import { GetLocalStorage } from "../../Utils/Utils";
import { customStylesAcc, customStylesAccUser, selectTheme, selectThemeUser } from "../../Utils/common";
import Select from 'react-select';

const EmpDetails = ({
  empDesignationTableData,
  subCatId,
  language,
  tabsWholeData,
  empDesignationField,
  setTabsWholeData,
  applicationFromData,
  errors,
  setErrors,
  selectedTab,
  setSelectedTab,
  checkEmpDetailDataFilled,
  fieldsDisabled
}) => {
  const [empList, setEmpList] = useState([]);
  const [filteredTabs, setFilteredTabs] = useState([]);
  const [tabData, setTabData] = useState({});
  const [formData, setFormData] = useState({}); // Stores data for each employee section
  const statusList = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ]

  const applicationId = GetLocalStorage("application_id");

  // Fetch dropdown options and application form data on mount
  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    // Automatically select the first tab and populate the form if data is available
    if (filteredTabs.length > 0) {
      setSelectedTab(filteredTabs[0]);
      filteredTabs?.forEach((tab) => {
        populateFormData(tab);
      })
    }
  }, [filteredTabs]);

  const fetchOptions = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", subCatId);
      const response = await empDesignationData(formData);
      setEmpList(response?.data || []);

      // Check for data structure and set tabs and form data accordingly
      if (
        tabsWholeData &&
        tabsWholeData.section_fields &&
        empDesignationField &&
        empDesignationField.field_key &&
        tabsWholeData.section_fields[empDesignationField.field_key]
      ) {
        const jsonDataFromAppData =
          applicationFromData &&
          applicationFromData?.["f_" + empDesignationField?.field_key] &&
          Array.isArray(
            applicationFromData?.["f_" + empDesignationField?.field_key]
          )
            ? JSON.parse(
                applicationFromData?.["f_" + empDesignationField?.field_key]
              )
            : tabsWholeData.section_fields[empDesignationField.field_key];
        setTabData(jsonDataFromAppData[0] || {});

        if(applicationFromData &&
          applicationFromData?.["f_" + empDesignationField?.field_key]) {
            const checkbox = document.getElementById("wp-comment-cookies-consent");
            checkbox.checked = await checkEmpDetailDataFilled() ?? false;
        }

        // Filter tabs to include only those with "No. of People" greater than 0
        const tabs = Object.keys(jsonDataFromAppData[0] || {}).filter(
          (key) => parseInt(jsonDataFromAppData[0][key]["No. of People"]) > 0
        );
        setFilteredTabs(tabs);
      }
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  

  const populateFormData = (tab) => {
    // Avoid resetting if formData for the selected tab already exists
    if (formData[tab] && formData[tab].length > 0) return;

    const peopleCount = parseInt(tabData[tab]?.["No. of People"]) || 0;

    // Check for pre-existing data in tabsWholeData, using empDesignationField's key
    const existingData =
      tabsWholeData.section_fields?.[empDesignationField.field_key]?.[0]?.[
        tab
      ]?.["Employee Details"] || [];

    // Initialize form data based on existingData or default to empty fields
    const initialData = Array.from({ length: peopleCount }, (_, index) => ({
      "Employee Name": existingData[index]?.["Employee Name"] || "",
      "Date Of Joining": existingData[index]?.["Date Of Joining"] || "",
      "Contact No.": existingData[index]?.["Contact No."] || "",
      "Staff Aadhaar No.": existingData[index]?.["Staff Aadhaar No."] || "",
      "Bank Account No.": existingData[index]?.["Bank Account No."] || "",
      "Bank Name": existingData[index]?.["Bank Name"] || "",
      "IFSC Code": existingData[index]?.["IFSC Code"] || "",
      "Status": existingData[index]?.["Status"] || "",
    }));

    setFormData((prevData) => ({
      ...prevData,
      [tab]: initialData,
    }));
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    populateFormData(tab);
  };

  const handleInputChange = (tab, index, event, employeeField) => {
    const value = event?.target?.value ?? event?.label;
    setFormData((prevData) => {
      const updatedTabData = [...(prevData[tab] || [])];
      updatedTabData[index] = {
        ...updatedTabData[index],
        [employeeField]: value,
      };

      return {
        ...prevData,
        [tab]: updatedTabData,
      };
    });

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
    
      if (!value) {
        let requiredMessage = {
          hi: `आवश्यक है`,
          en: `Required`,
        };
    
        switch (employeeField) {
          case "Employee Name":
            requiredMessage = {
              hi: `कर्मचारी का नाम आवश्यक है`,
              en: `Employee Name is required`,
            };
            break;
    
          case "Contact No.":
            requiredMessage = {
              hi: `संपर्क नंबर आवश्यक है`,
              en: `Contact Number is required`,
            };
            break;
    
          case "Staff Aadhaar No.":
            requiredMessage = {
              hi: `स्टाफ आधार नंबर आवश्यक है`,
              en: `Staff Aadhaar Number is required`,
            };
            break;
    
          case "Bank Account No.":
            requiredMessage = {
              hi: `बैंक खाता संख्या आवश्यक है`,
              en: `Bank Account Number is required`,
            };
            break;
    
          case "IFSC Code":
            requiredMessage = {
              hi: `आईएफएससी कोड आवश्यक है`,
              en: `IFSC Code is required`,
            };
            break;

            case "Bank Name":
              requiredMessage = {
                hi: `बैंक का नाम आवश्यक है`,
                en: `Bank Name is required`,
              };
              break;
            
            case "Status":
              requiredMessage = {
                hi: `स्थिति आवश्यक है`,
                en: `Status is required`,
              };
              break;
            
            case "Date Of Joining":
                requiredMessage = {
                  hi: `शामिल होने की तिथि आवश्यक है`,
                  en: `Date Of Joining is required`,
              };
              break;
                        
    
          default:
            requiredMessage = {
              hi: `आवश्यक है`,
              en: `Required`,
            };
        }

        newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`] = requiredMessage;
      } else {
        // Specific validations for each field
        switch (employeeField) {
          case "Employee Name":
            if (value.length > 60) {
              newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`] = {
                hi: `कर्मचारी का नाम 60 वर्णों से अधिक नहीं हो सकता`,
                en: `Employee Name cannot exceed 60 characters`,
              };
            } else {
              delete newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`];
            }
            break;
    
          case "Contact No.":
            if (!/^[6-9]\d{9}$/.test(value)) {
              newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`] = {
                hi: `संपर्क नंबर 10 अंकों का होना चाहिए और 6 से 9 से शुरू होना चाहिए`,
                en: `Contact No. must be a 10-digit number starting with 6-9`,
              };
            } else {
              delete newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`];
            }
            break;
    
          case "Staff Aadhaar No.":
            if (!/^\d{12}$/.test(value)) {
              newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`] = {
                hi: `आधार नंबर 12 अंकों का होना चाहिए`,
                en: `Aadhaar No. must be a 12-digit number`,
              };
            } else {
              delete newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`];
            }
            break;
    
          case "Bank Account No.":
            if (!/^\d{9,17}$/.test(value)) {
              newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`] = {
                hi: `बैंक खाता संख्या 9 से 17 अंकों के बीच होनी चाहिए`,
                en: `Bank Account No. must be between 9 to 17 digits`,
              };
            } else {
              delete newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`];
            }
            break;
    
          case "IFSC Code":
            if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
              newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`] = {
                hi: `आईएफएससी कोड मान्य भारतीय प्रारूप में होना चाहिए`,
                en: `IFSC Code must be in a valid Indian format`,
              };
            } else {
              delete newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`];
            }
            break;
    
          default:
            delete newErrors[`${empDesignationField?.field_key}_${tab}_emp_${index}_${employeeField}`];
        }
      }
    
      return newErrors;
    });

    

    setTabsWholeData((prevData) => {
      const currentJsonArray =
        prevData?.section_fields?.[`${empDesignationField?.field_key}`] || [];

      // Clone the array to avoid modifying the original reference
      const updatedJsonArray = [...currentJsonArray];

      // Check that the first element exists, and initialize if it doesn't
      if (!updatedJsonArray[0]) {
        updatedJsonArray[0] = {};
      }

      // Ensure that the tab exists within the first item
      if (!updatedJsonArray[0][tab]) {
        updatedJsonArray[0][tab] = { "Employee Details": [] };
      }

      // Ensure "Employee Details" is initialized as an array
      if (!Array.isArray(updatedJsonArray[0][tab]["Employee Details"])) {
        updatedJsonArray[0][tab]["Employee Details"] = [];
      }

      // Ensure that the index within "Employee Details" exists
      if (!updatedJsonArray[0][tab]["Employee Details"][index]) {
        updatedJsonArray[0][tab]["Employee Details"][index] = {};
      }

      // Now, safely update the value for the specified employee field
      updatedJsonArray[0][tab]["Employee Details"][index][
        employeeField
      ] = value;

      return {
        ...prevData,
        section_fields: {
          ...prevData?.section_fields,
          [empDesignationField?.field_key]: updatedJsonArray,
        },
      };
    });
  };

  return (
    <div className="mb-3 f_fields">
      <div className="employe-details-tabs">
        <div className="emp-main-wrapper d-flex align-items-start justify-content-between">
          {/* Left Tabs */}
          <div className="nav emp-tab-wrapper flex-column emp-left-tabs nav-pills me-5">
            {filteredTabs.map((tab) => (
              <div key={tab}>
                <button
                  type="button"
                  className={`nav-link ${selectedTab === tab ? "active" : ""}`}
                  onClick={() => handleTabChange(tab)}
                >
                  {tab}
                </button>
              </div>
            ))}
          </div>

          {/* Right Panel - Display Separate Employee Sections */}
          {selectedTab && (
            <form className="emp-right-tab w-100">
              {(formData[selectedTab] || []).map((data, index) => (
                <div className="emp-right-tab-wrapper">
                  <div className="emp-right-header">
                    <h6 className="mb-0">
                      {language ? "कर्मचारी विवरण" : `${selectedTab} ${index + 1}`}
                    </h6>
                  </div>
                  <div className="tab-pane fade show active">
                    <div className="row p-3 col-md-12">
                      {/* Render employee sections based on "No. of People" */}
                      <React.Fragment key={index}>
                        <div className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="field-label">
                                {language
                                  ? "कर्मचारी का नाम"
                                  : "Employee Name"}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={60}
                              placeholder={
                                language
                                  ? "कर्मचारी का नाम दर्ज करें"
                                  : "Enter Employee Name"
                              }
                              value={
                                formData[selectedTab]?.[index]?.[
                                  "Employee Name"
                                ] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  selectedTab,
                                  index,
                                  e,
                                  "Employee Name"
                                )
                              }
                              name="Employee Name"
                              required
                              disabled={fieldsDisabled}
                            />
                            {typeof errors[
                              `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Employee Name"}`
                            ] === "object" &&
                              errors[
                                `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Employee Name"}`
                              ] !== null && (
                                <div className="validate-error">
                                  {language
                                    ? errors?.[
                                      `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Employee Name"}`
                                    ]?.hi
                                  : errors?.[
                                     `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Employee Name"}`
                                    ]?.en}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="field-label">
                                {language
                                  ? "शामिल होने की तिथि"
                                  : "Date Of Joining"}
                              </span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder={
                                language
                                  ? "शामिल होने की तिथि दर्ज करें"
                                  : "Date Of Joining"
                              }
                              value={
                                formData[selectedTab]?.[index]?.[
                                  "Date Of Joining"
                                ] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  selectedTab,
                                  index,
                                  e,
                                  "Date Of Joining"
                                )
                              }
                              name="Date Of Joining"
                              required
                              disabled={fieldsDisabled}
                            />
                            {typeof errors[
                              `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Date Of Joining"}`
                            ] === "object" &&
                              errors[
                                `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Date Of Joining"}`
                              ] !== null && (
                                <div className="validate-error">
                                  {language
                                    ? errors?.[
                                      `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Date Of Joining"}`
                                    ]?.hi
                                  : errors?.[
                                     `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Date Of Joining"}`
                                    ]?.en}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="field-label">
                                {language
                                  ? "संपर्क नंबर।"
                                  : "Contact No."}
                              </span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              maxLength={10}
                              placeholder={
                                language ? "संपर्क नंबर दर्ज करें." : "Enter Contact No."
                              }
                              value={
                                formData[selectedTab]?.[index]?.[
                                  "Contact No."
                                ] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  selectedTab,
                                  index,
                                  e,
                                  "Contact No."
                                )
                              }
                              name="Contact No."
                              required
                              disabled={fieldsDisabled}
                            />
                            {typeof errors[
                              `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Contact No."}`
                            ] === "object" &&
                              errors[
                                `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Contact No."}`
                              ] !== null && (
                                <div className="validate-error">
                                  {language
                                    ? errors?.[
                                      `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Contact No."}`
                                    ]?.hi
                                  : errors?.[
                                     `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Contact No."}`
                                    ]?.en}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="field-label">
                                {language
                                  ? "कर्मचारी आधार नं."
                                  : "Staff Aadhaar No."}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={12}
                              placeholder={
                                language ? "स्टाफ आधार नंबर दर्ज करें." : "Enter Staff Aadhaar No."
                              }
                              value={
                                formData[selectedTab]?.[index]?.[
                                  "Staff Aadhaar No."
                                ] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  selectedTab,
                                  index,
                                  e,
                                  "Staff Aadhaar No."
                                )
                              }
                              name="Staff Aadhaar No."
                              required
                              disabled={fieldsDisabled}
                            />
                            {typeof errors[
                              `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Staff Aadhaar No."}`
                            ] === "object" &&
                              errors[
                                `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Staff Aadhaar No."}`
                              ] !== null && (
                                <div className="validate-error">
                                  {language
                                    ? errors?.[
                                      `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Staff Aadhaar No."}`
                                    ]?.hi
                                  : errors?.[
                                     `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Staff Aadhaar No."}`
                                    ]?.en}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="field-label">
                                {language
                                  ? "बैंक खाता संख्या"
                                  : "Bank Account No."}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={17}
                              placeholder={
                                language ? "बैंक खाता संख्या दर्ज करें." : "Enter Bank Account No."
                              }
                              value={
                                formData[selectedTab]?.[index]?.[
                                  "Bank Account No."
                                ] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  selectedTab,
                                  index,
                                  e,
                                  "Bank Account No."
                                )
                              }
                              name="Bank Account No."
                              required
                              disabled={fieldsDisabled}
                            />
                            {typeof errors[
                              `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Bank Account No."}`
                            ] === "object" &&
                              errors[
                                `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Bank Account No."}`
                              ] !== null && (
                                <div className="validate-error">
                                  {language
                                    ? errors?.[
                                      `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Bank Account No."}`
                                    ]?.hi
                                  : errors?.[
                                     `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Bank Account No."}`
                                    ]?.en}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="field-label">
                                {language
                                  ? "बैंक का नाम"
                                  : "Bank Name"}
                              </span>
                            </label>
                            <input
                              type="text"
                              maxLength={60}
                              className="form-control"
                              placeholder={
                                language ? "बैंक का नाम दर्ज करें." : "Enter Bank Name"
                              }
                              value={
                                formData[selectedTab]?.[index]?.[
                                  "Bank Name"
                                ] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  selectedTab,
                                  index,
                                  e,
                                  "Bank Name"
                                )
                              }
                              name="Bank Name"
                              required
                              disabled={fieldsDisabled}
                            />
                            {typeof errors[
                              `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Bank Name"}`
                            ] === "object" &&
                              errors[
                                `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Bank Name"}`
                              ] !== null && (
                                <div className="validate-error">
                                  {language
                                    ? errors?.[
                                      `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Bank Name"}`
                                    ]?.hi
                                  : errors?.[
                                     `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Bank Name"}`
                                    ]?.en}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="field-label">
                                {language
                                  ? "आईएफएससी कोड"
                                  : "IFSC Code"}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength={11}
                              placeholder={
                                language ? "आईएफएससी कोड दर्ज करें." : "Enter IFSC Code"
                              }
                              value={
                                formData[selectedTab]?.[index]?.[
                                  "IFSC Code"
                                ] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  selectedTab,
                                  index,
                                  e,
                                  "IFSC Code"
                                )
                              }
                              name="IFSC Code"
                              required
                              disabled={fieldsDisabled}
                            />
                            {typeof errors[
                              `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"IFSC Code"}`
                            ] === "object" &&
                              errors[
                                `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"IFSC Code"}`
                              ] !== null && (
                                <div className="validate-error">
                                  {language
                                    ? errors?.[
                                      `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"IFSC Code"}`
                                    ]?.hi
                                  : errors?.[
                                     `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"IFSC Code"}`
                                    ]?.en}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="field-label">
                                {language
                                  ? "स्थिति"
                                  : "Status"}
                              </span>
                            </label>
                            <Select
                              components={{
                                  IndicatorSeparator: () => null,
                              }}
                              styles={customStylesAccUser}
                              theme={selectThemeUser}
                              options={statusList}
                              placeholder={
                                language ? "स्थिति दर्ज करें." : "Enter Status"
                              }
                              value={
                                statusList?.find(option => option?.label === String(formData[selectedTab]?.[index]?.["Status"])
                                  ? option?.label === String(formData[selectedTab]?.[index]?.["Status"]) :
                                  option?.value === String(formData[selectedTab]?.[index]?.["Status"])
                                ) || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  selectedTab,
                                  index,
                                  e,
                                  "Status"
                                )
                              }
                              name="Status"
                              required
                              isDisabled={fieldsDisabled}
                          />
                          {typeof errors[
                              `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Status"}`
                            ] === "object" &&
                              errors[
                                `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Status"}`
                              ] !== null && (
                                <div className="validate-error">
                                  {language
                                    ? errors?.[
                                      `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Status"}`
                                    ]?.hi
                                  : errors?.[
                                     `${empDesignationField?.field_key}_${selectedTab}_emp_${index}_${"Status"}`
                                    ]?.en}
                                </div>
                              )}
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              ))}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmpDetails;
