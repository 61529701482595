import React, { useEffect, useState } from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import { CgPen, CgSoftwareUpload } from "react-icons/cg";
import {
  createApplicationForm,
  dropDownsList,
  getApplicationForm,
  getSection,
  getSectionFields,
  staticDropDownsList,
} from "../../services/api/user";
import InputTypeText from "../inputType/inputTypeText";
import { GetLocalStorage, GetLocalStorageParsed, validateonSubmitHandle } from "../../Utils/Utils";
import SelectTypeDraft from "./SelectTypeDraft";
import { customStylesAccUser, selectThemeUser } from "../../Utils/common";
import SubSectionTable from "./SubSectionTable";

const InputTableData = ({ tabsData, isEditable }) => {
  const { language } = useLanguage();
  const userDetail = GetLocalStorageParsed("userDetail");
  const applicationId = GetLocalStorage("application_id");
  const [sectionFields, setSectionFields] = useState({});
  const [subSectionFields, setsubSectionFields] = useState({});
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [tabsWholeData, setTabsWholeData] = useState([]);
  const [tabsSectionData, setTabsSectionData] = useState([]);
  const [errors, setErrors] = useState({}); // State for field-specific errors
  const [invalidFields, setInvalidFields] = useState([]);
  const [invalidSubFieldErr, setInvalidSubFieldErr] = useState([]);
  const [subSectionApplicationData, setSubSectionApplicationData] = useState() 
  const inputTypeList = [
    { label: "text", value: 1 },
    { label: "radio", value: 4 },
    { label: "password", value: 7 },
    { label: "file", value: 5 },
    { label: "dropdown", value: 2 },
    { label: "checkbox", value: 3 },
    { label: "Designation Table", value: 8 },
    { label: "date", value: 9 },
  ];

  useEffect(() => {
    fetchSectionData(tabsData);
  }, [tabsData]);
  useEffect(() => {
    fetchApplicationFormData();
  }, [showEditIcon]);

  const fetchApplicationFormData = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", tabsData?.category_id);
      formData.append("application_detail_id", applicationId);
      const applicationFormResponse = await getApplicationForm(formData);
      if (applicationFormResponse.status == "200" || applicationFormResponse.status == 200) {
        const fetchedData = applicationFormResponse?.data || {};
        // Update state with the fetched data
        setTabsWholeData(fetchedData); // Directly replace the state with new data
      } else {
        console.error("Failed to fetch application data:", applicationFormResponse);
      }
    } catch (err) {
      console.error("Error fetching application data:", err);
    }
  };

  const editToggle = async () => {
    setShowEditIcon(showEditIcon ? false : true);
    setInvalidFields([])
    setInvalidSubFieldErr([])
  };

  const fetchSectionData = async (tab) => {
    try {
      const formData = new FormData();
      formData.append("category_id", tab?.category_id);
      formData.append("tab_id", tab.id);

      const sectionResponse = await getSection(formData);
      if (sectionResponse.status == 200) {
        const sections = sectionResponse.data;
        const allFields = [];
        const allSubSectionFields = [];

        await Promise.all(
          sections.map(async (section) => {
            const fields = await fetchSectionFormData(section, tab.id);
            allFields.push(...fields?.section);
            allSubSectionFields.push(...fields.subSection);
          })
        );

        setsubSectionFields((prev) => ({ ...prev, [tab.id]: allSubSectionFields }))
        setSectionFields((prev) => ({ ...prev, [tab.id]: allFields }));
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  const fetchSectionFormData = async (sectionObj, tabId) => {
    try {
      const formData = new FormData();
      formData.append("category_id", sectionObj.category_id);
      formData.append("tab_id", tabId);
      formData.append("section_id", sectionObj.id);

      const sectionFormResponse = await getSectionFields(formData);
      if (sectionFormResponse.status == 200) {
        const sectionForm = sectionFormResponse.data.filter(
          (item) => !item.sub_section_id && item.type != 8
        );
        const subSectionForm = sectionFormResponse.data.filter(
          (item) => item.sub_section_id
        );
        return {
          section: sectionForm,
          subSection: subSectionForm
        }
      }
      return [];
    } catch (err) {
      console.error("Error fetching section form data:", err);
      return [];
    }
  };

  const handleOnChange = (e) => {
    const  name = e?.target?.name;
    const value = e?.target?.value !== undefined && e?.target?.value !== ""
      ? e?.target?.value
      : e?.target?.files?.[0];


    setTabsWholeData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
    if (value || value != "--") {
      setInvalidFields((prev) => prev.filter((field) => field.fieldKey !== name)); // Remove the error if valid
    }
  };

  const handleSubmit = async () => {
    const validate = validateFunction()
    if (!validate){
      setShowEditIcon(false)
      return;
    }
    try {
      // Filter and prepare section data
      const sectionData = Object.entries(tabsWholeData || {}).reduce((acc, [key, value]) => {
        if (key.startsWith("f_") && !key.endsWith("json") && value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      // Filter and prepare subsection data
      const subsectionData = Object.entries(tabsWholeData || {}).reduce((acc, [key, value]) => {
        if (key.endsWith("json") && value) {
          acc[key] = value;
        }
        return acc;
      }, {});

     
      setTabsSectionData((prevdata) => ({ ...prevdata, ...sectionData }));
      setSubSectionApplicationData((prevdata) => ({ ...prevdata, ...subsectionData }));

      // Prepare the payload
      const payload = {
        sub_category_id: tabsData?.category_id,
        user_id: userDetail?.id,
        application_id: applicationId,
        section_fields: sectionData, // Section data as an object
        sub_section_fields: subsectionData, // Subsection data as an object
      };

      console.log("Payload:", payload);
      // Make API request
      const sectionResponse = await createApplicationForm(payload); // Send as JSON
      if (sectionResponse.status == 200) {
        setShowEditIcon(true)
        fetchApplicationFormData();
        setInvalidFields([])
      } else {
        console.error("Failed to submit the form", sectionResponse);
      }
    } catch (err) {
      console.error("Error submitting the form:", err);
    }
  };

  const handleBlur = (fieldKey, value, field) => {
    const required = field?.required == "1";
    if (required && (!value || value == "" || value == "--")) {
      setInvalidFields((prev) => {
        const existingError = prev.find((field) => field.fieldKey == fieldKey);
        if (existingError) {
          return prev;
        }
        return [...prev, { fieldKey, message:[`${field?.name_hi} आवश्यक है` , `${field?.name_en} is required`] }];
      });
    } else {
      if (JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp && JSON.parse(JSON.parse(field?.validations).validation)?.onKeyUp?.length > 0) {
        const validations = validateonSubmitHandle(field, value, null);
        if (validations != "") {
          setInvalidFields((prev) => {
            const existingError = prev.find((field) => field.fieldKey == fieldKey);
            if (existingError) {
              return prev;
            }
            return [...prev, { fieldKey, message: [`${validations?.hi}` , `${validations?.en}` ]}];
          });
          
        }
      }
    }
  };

  const validateFunction = () => {
    const invalidSubFields = []; 
    const invalidFieldsArray = []; 

    const sectionFieldsForTab = sectionFields[tabsData.id] || [];
    sectionFieldsForTab.forEach((item) => {
      const fieldKey = `f_${item.field_key}`;
      const required = item?.required == "1";
      const value = tabsWholeData[fieldKey];

      if (required && (!value || value == "" || value == "--")) {
        invalidFieldsArray.push({
          fieldKey,
          message:[`${item.name_hi} आवश्यक है` , `${item.name_en} is required`], 
        });
      } else {
        if (JSON.parse(JSON.parse(item?.validations).validation)?.onKeyUp && JSON.parse(JSON.parse(item?.validations).validation)?.onKeyUp?.length > 0) {
          const validations = validateonSubmitHandle(item, value, null);
          if (validations !== "") {
            invalidFieldsArray.push({
              fieldKey,
              message:[validations?.hi , validations?.en] 
            });
          }
        } 
      }
    })

    // Validate subsection fields
    const subSectionFieldsForTab = subSectionFields[tabsData.id] || [];
    subSectionFieldsForTab.forEach((item) => {
      const fieldKey = `f_${item.field_key}`;
      const tableDataKey = `${fieldKey}_json`;
      const tableData = tabsWholeData[tableDataKey] || [];

      tableData.forEach((row, rowIndex) => {
        if (!invalidSubFields[rowIndex]) {
          invalidSubFields[rowIndex] = {};
        }
        Object.entries(row).forEach(([fieldName, fieldValue]) => {
          if (!fieldValue || fieldValue == "--") {
            invalidSubFields[rowIndex][fieldName] = [`${item?.name_hi} आवश्यक है`, `${item?.name_en} is required`];
          }
        });
      });
    });

    // Filter out empty objects from invalidSubFields
    const filteredInvalidSubFields = invalidSubFields.filter(
      (subField) => Object.keys(subField).length > 0
    );
    setInvalidSubFieldErr(invalidSubFields)
    const allInvalidFields = [...invalidFieldsArray, ...filteredInvalidSubFields];
    setInvalidFields(allInvalidFields); 
    return allInvalidFields.length == 0; 
  };

  const areAllFieldsEmpty = (tabId) => {
    // Check section fields
    const sectionFieldsForTab = sectionFields[tabId] || [];
    const areSectionFieldsEmpty = sectionFieldsForTab.every((field) => {
      const fieldKey = `f_${field.field_key}`;
      const fieldValue = tabsWholeData[fieldKey];
      return !fieldValue || fieldValue == "--" || fieldValue == "";
    });

    // Check subsection fields
    const subSectionFieldsForTab = subSectionFields[tabId] || [];
    const areSubSectionFieldsEmpty = subSectionFieldsForTab.every((field) => {
      const fieldKey = `f_${field.field_key}_json`;
      const subSectionData = tabsWholeData[fieldKey] || [];
      return subSectionData.every((row) =>
        Object.values(row).every((value) => !value || value == "--" || value == "")
      );
    });

    return areSectionFieldsEmpty && areSubSectionFieldsEmpty;
  };


  return (
    <>
      {!areAllFieldsEmpty(tabsData?.id) && (
        <div>
          <div className="mb-4">
            <div className="col-md-12">
              <div className={areAllFieldsEmpty(tabsData?.id) ? "" : 'data-wrapper'}>
                {!areAllFieldsEmpty(tabsData?.id) && (
                  <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                    <div className="data-wrapper-heading">
                      <h5 className="mb-0">
                        {language ? tabsData.name_hi : tabsData.name_en}
                      </h5>
                    </div>
                    {isEditable && <div className="data-wrapper-edit-icon">
                      {showEditIcon ? (
                        <CgPen onClick={editToggle} />
                      ) : (
                        <>
                          <div onClick={handleSubmit}>{!language ? "Save" : "सहेजें"}</div>
                          <div onClick={editToggle}>{!language ? "Cancel" : "रद्द करें"}</div>
                        </>
                      )}
                    </div>}
                  </div>
                )}
                <div className="row">
                  {(sectionFields[tabsData.id] || []).map((item) => {
                    const fieldKey = `f_${item.field_key}`;
                    const fieldValue = tabsWholeData[fieldKey] || "--";
                    const isInvalid = Array.isArray(invalidFields) && invalidFields.some((field) => field.fieldKey == fieldKey);
                    return (
                      <>
                        {!areAllFieldsEmpty(tabsData?.id) && (
                          <div className="col-md-6" key={item.id}>
                            <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                              <div className="preview-left-heading">
                                <h6 className="fw-bold mb-0">
                                  {language ? item.name_hi : item.name_en}
                                </h6>
                              </div>

                              {
                                showEditIcon ? (
                                  item?.type == '2' ? (
                                    <div
                                      className={
                                        showEditIcon
                                          ? "preview-right-heading"
                                          : "preview-right-input"
                                      }
                                    >
                                      <SelectTypeDraft
                                        fieldKey={fieldKey}
                                        styles={customStylesAccUser}
                                        theme={selectThemeUser}
                                        fieldValue={fieldValue}
                                        showEditIcon={showEditIcon}
                                        setTabsWholeData={setTabsWholeData}
                                        placeholder={
                                          language
                                            ? item?.placeholder_hi
                                            : item?.placeholder_en
                                        }
                                        dropdownId={item?.dropdown_id}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        showEditIcon
                                          ? "preview-right-heading"
                                          : "preview-right-input"
                                      }
                                    >
                                      <p className="mb-0">{typeof fieldValue == "string" && fieldValue.includes("\\") ? fieldValue.split('\\').pop() : fieldValue}</p>

                                    </div>
                                  )
                                ) : item?.type == '2' ? (
                                  <div
                                    className={
                                      showEditIcon
                                        ? "preview-right-heading"
                                        : "preview-right-input"
                                    }
                                  >
                                    <SelectTypeDraft
                                      fieldKey={fieldKey}
                                      styles={customStylesAccUser}
                                      theme={selectThemeUser}
                                      fieldValue={fieldValue}
                                      setTabsWholeData={setTabsWholeData}
                                      placeholder={
                                        language
                                          ? item?.placeholder_hi
                                          : item?.placeholder_en
                                      }
                                      dropdownId={item?.dropdown_id}
                                    />
                                    {isInvalid && (
                                      <small className="text-danger">
                                        {
                                          invalidFields.find(
                                            (field) => field.fieldKey == fieldKey
                                          )?.message
                                        }
                                      </small>
                                    )}
                                  </div>
                                ) : item?.type == '5' ? (
                                  <div
                                    className={
                                      showEditIcon
                                        ? "preview-right-heading"
                                        : "preview-right-input"
                                    }
                                  >
                                    <>
                                      <input
                                        type="file"
                                        id={item.field_key}
                                        name={fieldKey}
                                        className="w-100 ad-cat-input-field-image "
                                        onChange={(e) => {
                                          handleOnChange(e);
                                        }}
                                      />
                                      <label
                                        htmlFor={item.field_key}

                                        className="ad-cat-input-upload-image p-0 fire-file-upload-img"
                                      >
                                        <p className="ad-cat-text-upload-image">
                                          <span>
                                            <CgSoftwareUpload />
                                          </span>{" "}
                                          {fieldValue ? (
                                            <p className="mb-0 fw-semibold">
                                              {tabsWholeData[fieldKey].includes('\\') ? tabsWholeData[fieldKey].split('\\').pop() : tabsWholeData[fieldKey]}
                                            </p>
                                          ) : (
                                            "Upload File"
                                          )}
                                        </p>
                                      </label>
                                      {isInvalid && (
                                        <small className="text-danger">
                                          { language ? 
                                            invalidFields.find(
                                              (field) => field.fieldKey == fieldKey
                                            )?.message[0] : 
                                                invalidFields.find(
                                                  (field) => field.fieldKey == fieldKey
                                                )?.message[1]
                                          }
                                        </small>
                                      )}
                                    </>

                                  </div>
                                ) : (
                                  <div
                                    className={
                                      showEditIcon
                                        ? "preview-right-heading"
                                        : "preview-right-input"
                                    }
                                  >
                                    <input
                                      type={inputTypeList.find((x) => x.value == item?.type)?.label}
                                      id={item.field_key}
                                      name={fieldKey}
                                      className="form-control"
                                      value={tabsWholeData[fieldKey] || ""}
                                      onChange={(e) => {
                                        const { name, value } = e?.target;
                                        const fieldValidation = JSON.parse(item?.validations);
                                        const parsedValidation = fieldValidation?.validation
                                          ? JSON.parse(fieldValidation?.validation)?.onKeyUp
                                          : null;
                                        if (parsedValidation) {
                                          for (let validation of parsedValidation) {
                                            if (
                                              validation?.type == "numeric" &&
                                              validation?.unit == "Integer Only"
                                            ) {
                                              if (!/^-?\d*$/.test(value)) {
                                                return;
                                              }
                                            } else if (
                                              validation?.type == "text" &&
                                              validation?.unit == "Alphabets (a-zA-Z) only"
                                            ) {
                                              if (!/^[a-zA-Z]*$/.test(value)) {
                                                return;
                                              }
                                            }
                                          }
                                        }
                                        handleOnChange(e);
                                      }}
                                      placeholder={
                                        language
                                          ? item?.placeholder_hi
                                          : item?.placeholder_en
                                      }
                                      onBlur={() =>
                                        handleBlur(fieldKey, tabsWholeData[fieldKey], item)
                                      }
                                    />
                                    {isInvalid && (
                                      <small className="text-danger">
                                        {/* {
                                          invalidFields.find(
                                            (field) => field.fieldKey == fieldKey
                                          )?.message
                                        } */}
                                              {language ?
                                                invalidFields.find(
                                                  (field) => field.fieldKey == fieldKey
                                                )?.message[0] :
                                                invalidFields.find(
                                                  (field) => field.fieldKey == fieldKey
                                                )?.message[1]
                                              }
                                      </small>
                                    )}
                                  </div>
                                )
                              }

                            </div>
                          </div>
                        )}
                      </>

                    );
                  })}

                </div>
              </div>
            </div>
          </div>

          {subSectionFields[tabsData.id] && subSectionFields[tabsData.id]?.length > 0 && (
            <div className="mb-4">

              {subSectionFields[tabsData.id]?.map((item) => {
                const fieldKey = `f_${item?.field_key}`;
                const fieldValue = tabsWholeData[fieldKey] || "--";
                const isInvalid = Array.isArray(invalidFields) && invalidFields.some((field) => field.fieldKey == fieldKey);
                return (
                  <div className="col-lg-12 mt-4">
                    <div className="card mb-3">
                      <div className="card-body bg-card user-tab-table ">
                        <div className="form-bar d-flex justify-content-between align-items-center p-3">
                          <div className="bar-left-detail d-flex col-md-3">
                            <h5 className="p-0 text-dark mb-0"> {language ? item.name_hi : item.name_en} :</h5>
                            {showEditIcon ? (
                              <div
                                className={
                                  showEditIcon
                                    ? "preview-right-heading"
                                    : "preview-right-input"
                                }
                              >
                                <p className="mb-0 ms-4">{fieldValue}</p>
                              </div>

                            ) : (
                              <div
                                className={
                                  showEditIcon
                                    ? "preview-right-heading"
                                    : "preview-right-input"
                                }
                              >
                                <input
                                  type="text" // Ensure type is "text" for full control
                                  id={item?.field_key}
                                  name={fieldKey}
                                  className="form-control"
                                  value={tabsWholeData[fieldKey] || ""}
                                  onChange={(e) => {
                                    const value = e?.target?.value;
                                    // Allow empty values for deletion, otherwise check for numeric input
                                    if (value == "" || /^[1-9]+$/.test(value)) {
                                      handleOnChange(e);
                                    }
                                  }}
                                  placeholder={
                                    language
                                      ? item?.placeholder_hi
                                      : item?.placeholder_en
                                  }
                                  onBlur={() => handleBlur(fieldKey, tabsWholeData[fieldKey], item)}
                                />
                                {/* Display error messages */}
                                  <small className="text-danger">
                                  {language ?
                                    invalidFields.find(
                                      (field) => field.fieldKey == fieldKey
                                    )?.message[0] :
                                    invalidFields.find(
                                      (field) => field.fieldKey == fieldKey
                                    )?.message[1]
                                  }
                                  </small>
                              </div>
                            )}
                          </div>
                          {isEditable && <div className="data-wrapper-edit-icon">
                            {showEditIcon ? (
                              <CgPen onClick={editToggle} />
                            ) : (
                              <>
                                <div onClick={handleSubmit}>{!language ? "Save" : "सहेजें"}</div>
                                <div onClick={editToggle}>{!language ? "Cancel" : "रद्द करें"}</div>
                              </>
                            )}
                          </div>}
                        </div>
                        <SubSectionTable showEditIcon={showEditIcon} setTabsWholeData={setTabsWholeData} subSectionDetails={item} fieldValue={fieldValue} tabsWholeData={tabsWholeData} fieldKey={fieldKey} setErrors={setInvalidFields} errors={invalidSubFieldErr} />
                      </div>
                    </div>
                  </div>
                );
              })}


            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InputTableData;
