import Banner from "../../components/Banner/Banner";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider ";
import MainLayout from "../../components/MainLayout/MainLayout";
import React, { useEffect, useState } from "react";
import "../Home/Home.css";
import { ClearLocalStorage } from "../../Utils/Utils";
import { getHomePageContent } from "../../services/api/outer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const ContactUs = () => {
  const { language, setLanguage } = useLanguage();
  const [homePageData, setHomePageData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const [homePageDataResponse] = await Promise.all([
          getHomePageContent(),
        ]);
        setHomePageData(homePageDataResponse);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    ClearLocalStorage();
  }, []);

  if (loading || homePageData === null) {
    return <></>;
  }
  return (
    <>
      <div>
        <MainLayout homePageData={homePageData}>
          <section className="contact-inner section-padding">
            <div className="container">
              <div className="contact-info-main mt-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="contact-info bg-white">
                      <div className="contact-info-title text-center mb-5 px-5">
                        <p className="hex-p">{language ? "कोई प्रश्न है?": "Got Questions"}</p>
                        <div className="hex-explore">{language ? "हमसे संपर्क करें" : "Contact Us"}</div>
                      </div>
                      <div className="contact-info-content row mb-1">
                        <div className="col-lg-4 col-md-6 mb-4">
                          <div className="info-item bg-lgrey px-4 py-5 border-all text-center rounded">
                            <div className="info-icon mb-2">
                              <FontAwesomeIcon icon={faLocationDot} />
                            </div>
                            <div className="info-content">
                              <h3>{language ? "कार्यालय स्थान" : "Office Location"}</h3>
                              <p>
                                {language ? `पं. दीन दयाल उपाध्याय पर्यटन भवन, ओएनजीसी हेलीपैड के पास गढ़ी कैंट, देहरादून-248001 (भारत)` : ` Pt. Deendayal Upadhyay Paryatan Bhawan, Near
                                ONGC Helipad Garhi Cantt, Dehradun-248001
                                (India)`}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                          <div className="info-item bg-lgrey px-4 py-5 border-all text-center rounded">
                            <div className="info-icon mb-2">
                              <FontAwesomeIcon icon={faPhone} />
                            </div>
                            <div className="info-content">
                              <h3>{language ? `फ़ोन नंबर` : `Phone Number`}</h3>
                              <p>
                                <strong>{language ? `संपर्क नंबर ` : `Contact No.`}</strong> :
                                <a href="tel:+91-135-2552626">
                                  +91-135-2552626
                                </a>
                              </p>
                              <p>
                                <strong>{language ? `पर्यटक हेल्पलाइन नंबर :` : `Tourist Helpline Number:`}</strong> :1364
                                {language ? ` (अन्य राज्य के लिए: +91- 135- 1364)` :` (For other state: +91- 135- 1364)`}
                              </p>
                              <p>
                                <strong>{language ? `चारधाम यात्रा नियंत्रण कक्ष:` : `CharDham Yatra Control Room:`}</strong>{" "}
                                0135 - 2559898, 2552627, 0135–3520100
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                          <div className="info-item bg-lgrey px-4 py-5 border-all text-center rounded">
                            <div className="info-icon mb-2">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <div className="info-content ps-4">
                              <h3>{language ? `मेल पता` : `Email Address`}</h3>
                              <p>
                                <strong>{language ? `ई-मेल UTDB :` : `E-mail UTDB : `}</strong>
                                <a href="mailto: publicityutdb@gmail.com">
                                  publicityutdb@gmail.com
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </MainLayout>
      </div>
    </>
  );
};
export default ContactUs;
