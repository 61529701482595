import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {
    GoogleMap,
    Autocomplete,
    Marker,
    useJsApiLoader
} from "@react-google-maps/api";
import { CgPen } from 'react-icons/cg';

const GeoLocationView = ({ subCatId, mapLocation, showEditIcon, setGeoLocationAddress }) => {
    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(null);
    const [searchAddress, setSearchAddress] = useState(mapLocation);
    const [autocomplete, setAutocomplete] = useState(null);
    const [error, setError] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setGeoLocationAddress(searchAddress)
    }, [searchAddress])

    const [formData, setFormData] = useState({
        latitude: null,
        longitude: null
    });

    const defaultCenter = { lat: 30.3165, lng: 78.0322 }; // Fallback coordinates for Dehradun center

    const GmapOptions = {
        disableDefaultUI: false,
        draggable: true,
        scrollwheel: true,
        streetViewControl: false,
    };

    const loaderOptions = useMemo(() => ({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCnOLiPcQ3RCoPoLG5tEd8gcqGqHVL3Nuc',
        libraries: ["places"],
    }), []);

    const { isLoaded } = useJsApiLoader(loaderOptions);

    const geocodeAddress = useCallback((address) => {
        if (window.google) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
                if (status === "OK" && results[0]) {
                    const location = results[0].geometry.location;
                    const latLng = { lat: location.lat(), lng: location.lng() };
                    setMarkerPosition(latLng);
                    // setGeoLocationAddress(address)
                    setSearchAddress(address);
                    setFormData({
                        latitude: latLng.lat,
                        longitude: latLng.lng
                    });
                    map?.setCenter(latLng);
                    map?.setZoom(15);
                } else {
                    setMarkerPosition(defaultCenter); // Fallback if geocode fails
                    setFormData({
                        latitude: defaultCenter.lat,
                        longitude: defaultCenter.lng
                    });
                    map?.setCenter(defaultCenter);
                    map?.setZoom(12);
                }
            });
        }
    }, [map]);

    const onLoad = useCallback((mapInstance) => {
        setMap(mapInstance);
        if (mapLocation) {
            geocodeAddress(mapLocation);
        } else {
            setMarkerPosition(defaultCenter);
            setFormData({
                latitude: defaultCenter.lat,
                longitude: defaultCenter.lng,
            });
            mapInstance.setCenter(defaultCenter);
            mapInstance.setZoom(15);
        }
    }, [mapLocation, geocodeAddress]);


    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                const location = place.geometry.location;
                const latLng = { lat: location.lat(), lng: location.lng() };
                setMarkerPosition(latLng);
                // setGeoLocationAddress(`${place.name} ${place.formatted_address}` || "")
                setSearchAddress(`${place.name} ${place.formatted_address}` || "");
                map?.panTo(latLng);
                map?.setZoom(15);
                setFormData({
                    latitude: location.lat(),
                    longitude: location.lng(),
                });
            }
        }
    };

    const onAutocompleteLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onMarkerDragEnd = (e) => {
        const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setMarkerPosition(latLng);
        setFormData({ latitude: latLng.lat, longitude: latLng.lng });

        if (window.google) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === "OK" && results[0]) {
                    // setGeoLocationAddress(results[0].formatted_address)
                    setSearchAddress(results[0].formatted_address);
                }
            });
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <>

            <div className="col-md-12 mt-2">
                    <div className="row">
                        <div className="mt-4">
                            <div className="form-block">
                                {!showEditIcon ?
                                    <>
                                    <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
                                        <input
                                            type="text"
                                            ref={inputRef}
                                            placeholder="Search for an address"
                                            value={mapLocation}
                                            onChange={(e) => {
                                                setSearchAddress(e.target.value)
                                                setGeoLocationAddress(e.target.value)
                                            }}
                                            className="form-control"
                                            onBlur={(e) => {
                                                const value = e?.target?.value 
                                            }}
                                        />
                                    </Autocomplete>
                                    </>
                                : <p>{mapLocation}</p>
                                }

                                <GoogleMap
                                    onLoad={onLoad}
                                    center={markerPosition || defaultCenter}
                                    zoom={15}
                                    mapContainerStyle={{ height: "400px", width: "100%" }}
                                    options={GmapOptions}

                                >
                                    {markerPosition && (
                                        <Marker
                                            position={markerPosition}
                                            draggable={!showEditIcon}
                                            onDragEnd={onMarkerDragEnd}
                                        />
                                    )}
                                </GoogleMap>
                            </div>
                        </div>
                    </div>
            </div>

        </>
    );
};

export default GeoLocationView;
